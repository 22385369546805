import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

/**
 * Color Input component
 *
 * @return {JSX}
 */
const ColorInput = ({onChangeValue, defaultValue, ignoreMarginTop})=>{
  const ref = useRef(null);
  const [currentColor, setCurrentColor] = useState(defaultValue);

  useEffect(()=>{
    if (defaultValue) {
      setCurrentColor(defaultValue);
    } else {
      setCurrentColor('#000000');
    }
  }, [defaultValue]);

  return (
    <>
      <div className={`flex items-center transition-all relative cursor-pointer rounded-lg ${ignoreMarginTop?'':'mt-2'} color-input border-slate-200 border`} onClick={()=>{
        ref.current.click();
      }}>
        <div className='p-3 rounded-md mr-2' style={{backgroundColor: currentColor}}>
        </div>
        <p className='text-xs p-0'>{currentColor}</p>
        <input defaultValue={currentColor} type="color" className='invisible absolute z-0' ref={ref} onChange={(event)=>{
          setCurrentColor(event?.target?.value);
          onChangeValue(event?.target?.value);
        }}/>
      </div>
    </>
  );
};


ColorInput.propTypes = {
  onChangeValue: PropTypes.func,
  defaultValue: PropTypes.string,
  ignoreMarginTop: PropTypes.bool,
};

export default ColorInput;
