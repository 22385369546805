import React, {useEffect, useState} from 'react';
import UpgradeButton from '../../../components/button/upgradeButton.component';
import Chips from '../../../components/chips';
import Heading from '../../../components/heading';
import {getValue} from '../../../config/localStorage.config';
import {USER_DETAILS} from '../../../constants/application.const';
import {useDispatch, useSelector} from 'react-redux';
import {GET_PLAN_FAILED, GET_PLAN_SUCCESS} from '../../../constants/reducer.const';
import {getPlanDetailsAction} from '../../../actions/plans.actions';
import {toMb} from '../../../helpers/common.helper';

/**
 * Current Plan
 *
 * @return {JSX}
 */
const CurrentPlan = ()=>{
  const [curretUserPlan, setCurrentUserPlan] = useState({});
  const planSelector = useSelector((state)=>state?.plan);
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();

  // Get user data from local storage
  const getLocalStorageUserData = ()=>{
    setLoader(true);
    const userData = getValue(USER_DETAILS);
    if (userData) {
      const currentUserPlanDetails = JSON.parse(userData)?.plan;
      dispatch(getPlanDetailsAction({
        planId: currentUserPlanDetails?.id,
      }));
    }
  };

  useEffect(()=>{
    getLocalStorageUserData();
  }, []);

  useEffect(()=>{
    if (planSelector?.planDetails?.type === GET_PLAN_SUCCESS) {
      setCurrentUserPlan(planSelector?.planDetails?.payload?.data);
      setLoader(false);
    }
    if (planSelector?.planDetails?.type === GET_PLAN_FAILED) {
      setLoader(false);
    }
  }, [planSelector?.planDetails]);

  const PlanDetailsLoader = ()=>(
    <tbody>
      <tr>
        <td className="pr-4">Pricing</td>
        <div className='h-30 w-24 bg-slate-200 rounded-md mr-4 animate-pulse'>
        &nbsp;</div>
      </tr>
      <tr>
        <td className="pr-4">Storage Cap</td>
        <div className='h-30 w-24 bg-slate-200 rounded-md mr-4 animate-pulse'>
        &nbsp;</div>
      </tr>
      <tr>
        <td className="pr-4">Impression Count Limit</td>
        <div className='h-30 w-24 bg-slate-200 rounded-md mr-4 animate-pulse'>
        &nbsp;</div>
      </tr>
      <tr>
        <td className="pr-4">Widgets Limit</td>
        <div className='h-30 w-24 bg-slate-200 rounded-md mr-4 animate-pulse'>
        &nbsp;</div>
      </tr>
    </tbody>
  );

  return (
    <div className="p-6 w-1/2">
      <div className='mt-4'>
        <Heading className={'font-semibold text-xl'}>Current Plan</Heading>
      </div>
      <div className='mt-4'>
        <div className='flex'>
          {loader && <div className='h-30 w-24 bg-slate-200 rounded-md mr-4 animate-pulse'>
          &nbsp;</div>}
          {!loader && <Chips
            roundClass={'rounded-md'}
            textSizeClass={'text-lg'}
            text={curretUserPlan?.name}
            color={'teal'}
            height={30}
            width={30}
          />}
        </div>
        <p className='text-sm text-slate-600 mt-2'>
          {loader && <div className='h-30 w-32 bg-slate-200 rounded-md mr-4 animate-pulse'>
          &nbsp;</div>}
          {!loader && curretUserPlan?.description}
        </p>
        <table className='table mt-2 border-collapse'>
          { loader && <PlanDetailsLoader/>}
          { !loader && <tbody>
            <tr>
              <td className="pr-4">Pricing</td>
              <td>: ${curretUserPlan?.price}</td>
            </tr>
            <tr>
              <td className="pr-4">Storage Cap</td>
              <td>: {toMb(curretUserPlan?.storageCap)?.toFixed(2)}mb</td>
            </tr>
            <tr>
              <td className="pr-4">Impression Count Limit</td>
              <td>: {curretUserPlan?.impressionCount}</td>
            </tr>
            <tr>
              <td className="pr-4">Widgets Limit</td>
              <td>: {curretUserPlan?.modules?.P?.WIDGETS?.WIDGET_LIMIT}</td>
            </tr>
          </tbody>}
        </table>
        <div className='mt-2'>
          <UpgradeButton/>
        </div>
      </div>
    </div>
  );
};

export default CurrentPlan;
