import React from 'react';
import PropTypes from 'prop-types';

/**
 * Cta Settings icon for whole project
 *
 * @return {JSX}
 */
const CtaSettingsIcon = ({height, width, title, ...props})=>{
  return (
    <svg width={width || '25'} height={height || '25'} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M29 8H3c-1.7 0-3 1.3-3 3v10c0 1.7 1.3 3 3 3h26c1.7 0 3-1.3 3-3V11c0-1.7-1.3-3-3-3zm-8.3 6.7-4 4c-.2.2-.4.3-.7.3s-.5-.1-.7-.3l-3-3c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l2.3 2.3 3.3-3.3c.4-.4 1-.4 1.4 0s.4 1 0 1.4z" fill="currentColor"></path></svg>
  );
};

CtaSettingsIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  title: PropTypes.string,
};

export default CtaSettingsIcon;
