import React from 'react';

/**
 * Collapse Up icon
 *
 * @return {JSX}
 */
const CollapseUpIcon = ({...props})=>{
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" {...props} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.3195 8.59807C15.3872 8.66399 15.6765 8.91289 15.9145 9.14475C17.4113 10.5041 19.8613 14.0501 20.6092 15.9061C20.7293 16.1879 20.9837 16.9006 21 17.2813C21 17.6461 20.916 17.9939 20.7457 18.3258C20.5077 18.7395 20.1332 19.0714 19.691 19.2532C19.3842 19.3703 18.466 19.5521 18.4497 19.5521C17.4452 19.734 15.813 19.834 14.0093 19.834C12.2908 19.834 10.7252 19.734 9.7055 19.5851C9.68917 19.568 8.54817 19.3862 8.15733 19.1873C7.44333 18.8225 7 18.1098 7 17.3472L7 17.2813C7.0175 16.7846 7.46083 15.7401 7.47717 15.7401C8.22617 13.9842 10.556 10.52 12.1042 9.1277C12.1042 9.1277 12.502 8.73559 12.7505 8.56511C13.1075 8.29916 13.5497 8.16732 13.9918 8.16732C14.4853 8.16732 14.945 8.31621 15.3195 8.59807Z" fill="white"/>
    </svg>
  );
};

export default CollapseUpIcon;


