import React, {useEffect, useState} from 'react';
import Heading from '../../../../components/heading';
import CloseIcon from '../../../../icons/close.icon';
import PropTypes from 'prop-types';
import ColorInput from '../../../../components/input/colorInput.component';
import Button from '../../../../components/button/button.component';
import {GRADIANT_DEFAULT_VALUES} from '../../../../constants/defaultValues.const';
import Input from '../../../../components/input/input.component';
import QuestionIcon from '../../../../icons/question.icon';
import ReactTooltip from 'react-tooltip';

/**
 * Gradian Background link
 *
 * @return {JSX}
 */
const GradiantBackgroundModal = ({onClose, onConfigChange, configValues})=>{
  const [config, setConfig] = useState({...configValues});

  useEffect(()=>{
    if (configValues) {
      setConfig({...configValues});
    }
  }, [configValues]);

  // Reset form
  const resetForm = ()=>{
    setConfig({...GRADIANT_DEFAULT_VALUES});
    onConfigChange({...GRADIANT_DEFAULT_VALUES});
  };

  return (
    <>
      <div className="fixed inset-0 bg-stone-900 bg-opacity-75 transition-opacity z-50" aria-hidden="true"></div>
      <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white w-1/3 p-4 rounded-lg'>
        <Heading className='font-semibold text-lg flex justify-between  mt-4'>
          Button Link Config
          <CloseIcon className='cursor-pointer w-4 h-4' onClick={()=>{
            onClose();
          }}/>
        </Heading>
        <div id="gradiant-background-container">
          <label className='text-sm font-normal mt-4 flex items-center'>Choose Background Color</label>
          <ColorInput
            defaultValue={config?.backgroundColor?.value}
            onChangeValue={(value)=>{
              setConfig({...config, backgroundColor: {
                value: value,
                displayName: 'Background color',
                chipColor: value,
              }});
              onConfigChange({...config, backgroundColor: {
                value: value,
                displayName: 'Background color',
                chipColor: value,
              }});
            }}
          />
        </div>
        <div id="gradiant-opcity-container">
          <Heading className="text-sm font-normal flex items-center mt-4">
          Opacity Value
            <QuestionIcon
              height={20}
              width={20}
              className={'ml-1 cursor-pointer'}
              data-tip
              data-for='opacity-info'
            />
            <ReactTooltip
              id="opacity-info"
              place="right"
              effect="solid"
            >
              <span>Valid value ranges from 0 to 1. If choosen 0, the background is transparant and if 1, background is opaque.</span>
            </ReactTooltip>
          </Heading>
          <Input type="number" step=".1" min="0" max="1" defaultValue={config?.opacity?.value} onChange={(event)=>{
            setConfig({...config, opacity: {
              value: event?.target?.value,
              displayName: 'Opacity',
              chipColor: '#000',
            }});
            onConfigChange({...config, opacity: {
              value: event?.target?.value,
              displayName: 'Opacity',
              chipColor: '#000',
            }});
          }}/>
        </div>
        <div className='flex mt-8 justify-end'>
          <Button classOverride={'flex items-center justify-center px-3 py-2 mr-2 text-sm font-medium text-purple-600 duration-150 bg-transparent border-2 border-purple-600 rounded-lg hover:border-purple-700 active:border-purple-500 focus:outline-none focus:shadow-outline-purple'} type={'button'} onClick={()=>resetForm()}>Reset</Button>
          <Button onClick={()=>onClose()}>Confirm</Button>
        </div>
      </div>
    </>
  );
};

GradiantBackgroundModal.propTypes = {
  onClose: PropTypes.func,
  onConfigChange: PropTypes.func,
  configValues: PropTypes.any,
};

export default GradiantBackgroundModal;
