import React from 'react';
import PropTypes from 'prop-types';

/**
 * Plus icon
 *
 * @return {JSX}
 */
const Plus = ({height, width})=>{
  return (
    <svg width={width || '24px'} height={height || '24px'} viewBox="0 0 37 38" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.0417 11.0833H16.9583V17.4167H10.7917V20.5833H16.9583V26.9167H20.0417V20.5833H26.2083V17.4167H20.0417V11.0833ZM18.5 3.16666C9.99001 3.16666 3.08334 10.26 3.08334 19C3.08334 27.74 9.99001 34.8333 18.5 34.8333C27.01 34.8333 33.9167 27.74 33.9167 19C33.9167 10.26 27.01 3.16666 18.5 3.16666ZM18.5 31.6667C11.7013 31.6667 6.16668 25.9825 6.16668 19C6.16668 12.0175 11.7013 6.33332 18.5 6.33332C25.2988 6.33332 30.8333 12.0175 30.8333 19C30.8333 25.9825 25.2988 31.6667 18.5 31.6667Z" fill={'currentColor'}/>
    </svg>

  );
};

Plus.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Plus;
