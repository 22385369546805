import React from 'react';
import PropTypes from 'prop-types';
import PabblyIcon from '../../assets/images/pabbly.png';

/**
 * Pabbly logo
 *
 * @return {JSX}
 */
const Pabbly = ({height, width})=>{
  return (
    <img src={PabblyIcon} width={width || '80'} height={height || '80'}/>
  );
};

Pabbly.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Pabbly;
