import React, {useEffect, useState} from 'react';
import ButtonGroup from '../../../../components/button/buttonGroup.component';
import CheckBox from '../../../../components/checkbox';
import Chips from '../../../../components/chips';
import ChipContainer from '../../../../components/chips/chipContainer.component';
import Input from '../../../../components/input/input.component';
import InfoWrapper from '../../../../components/wrappers/infoWrapper.component';
import SettingsIcon from '../../../../icons/settings.icon';
import ButtonLinkModal from '../modals/buttonLink.modal';
import FieldConfigModal from '../modals/fieldConfig.modal';
import PropTypes from 'prop-types';
import ErrorText from '../../../../components/errorComponents/errorText.component';
import {updateCollapseAction, updateWidgetSettings} from '../../../../actions/widget.actions';
import {useDispatch, useSelector} from 'react-redux';
import {ACTION_BUTTON_DEFAULT_VALUES, FIELD_CONFIG_VALUES, REVIEW_FIELD_CONFIG_VALUES} from '../../../../constants/defaultValues.const';
import {ACTION_TYPE_MAPPER} from '../../../../constants/widget.const';
import {getValue} from '../../../../config/localStorage.config';
import {USER_DETAILS} from '../../../../constants/application.const';
import TextArea from '../../../../components/textarea';
import Heading from '../../../../components/heading';
import QuestionIcon from '../../../../icons/question.icon';
import ReactTooltip from 'react-tooltip';
import {GET_PABBLY_LIST_SUCCESS} from '../../../../constants/reducer.const';
import {getAllPabblyListAction, getAllPabblyListClear} from '../../../../actions/integrations.actions';
import Select from '../../../../components/select/select.component';
import {formatArraytoSelectOptions} from '../../../../helpers/common.helper';
import CollapsibleComponent from './collapsible.component';
import ColorInput from '../../../../components/input/colorInput.component';

/**
 * Action settings
 *
 * @return {JSX}
 */
const ActionSettings = ({register, errors, setValue, widgetType, ...props})=>{
  const [actionType, setActionType] = useState(ACTION_TYPE_MAPPER.AB.value);
  const [fieldConfigModal, setFieldConfigModal] = useState(false);
  const [buttonLinkModal, setButtonLinkModal] = useState(false);
  const [actionButtonConfig, setActionButtonConfig] = useState({...ACTION_BUTTON_DEFAULT_VALUES});
  const [fieldConfig, setFieldConfig] = useState({
    fieldOne: JSON.parse(JSON.stringify(FIELD_CONFIG_VALUES)),
    fieldTwo: JSON.parse(JSON.stringify(FIELD_CONFIG_VALUES)),
    reviewDescription: JSON.parse(JSON.stringify(REVIEW_FIELD_CONFIG_VALUES)),
  });
  const [currentField, setCurrentField] = useState('');
  const dispatch = useDispatch();
  const [curretUserPlan, setCurrentUserPlan] = useState({});
  const widgetStore = useSelector((state)=>state.widget);
  const integrationStore = useSelector((state)=>state.integration);
  const [pabblyList, setPabblyList] = useState([]);
  const [actionTypeButton, setActionTypeButton] = useState([
    {
      name: ACTION_TYPE_MAPPER.F.name,
      value: ACTION_TYPE_MAPPER.F.value,
    },
    {
      name: ACTION_TYPE_MAPPER.AB.name,
      value: ACTION_TYPE_MAPPER.AB.value,
    },
    {
      name: ACTION_TYPE_MAPPER.FB.name,
      value: ACTION_TYPE_MAPPER.FB.value,
    },
    {
      name: ACTION_TYPE_MAPPER.CAL.name,
      value: ACTION_TYPE_MAPPER.CAL.value,
    },
  ]);
  const [toggleCollapsible, setToggleCollapsible] = useState(false);
  const [toggleCollapsibleAutoresponder, setToggleCollapsibleAutoresponder] = useState(false);

  // Get user data from local storage
  const getLocalStorageUserData = ()=>{
    const userData = getValue(USER_DETAILS);
    if (userData) {
      setCurrentUserPlan(JSON.parse(userData)?.plan);
    }
  };

  // Open modal
  const openFieldModal = ()=>{
    setFieldConfigModal(true);
  };

  useEffect(()=>{
    setToggleCollapsible(false);
    setToggleCollapsibleAutoresponder(false);
    if (widgetStore?.collapse_widget?.payload?.CTA) {
      setToggleCollapsible(true);
    }
    if (widgetStore?.collapse_widget?.payload?.AS) {
      setToggleCollapsibleAutoresponder(true);
    }
  }, [widgetStore?.collapse_widget]);

  useEffect(()=>{
    if (integrationStore?.getPabblyList?.type === GET_PABBLY_LIST_SUCCESS) {
      const resultArray = formatArraytoSelectOptions(integrationStore?.getPabblyList?.payload?.data, 'list_id', 'list_name');
      setPabblyList(resultArray);
      dispatch(getAllPabblyListClear());
    }
  }, [integrationStore]);

  // Get pabbly list
  const getPabblyList = ()=>{
    dispatch(getAllPabblyListAction());
  };

  // called initially
  useEffect(()=>{
    dispatch(updateWidgetSettings({
      actionType: widgetStore?.widgetSettings?.actionType || (widgetType === 'BS'?ACTION_TYPE_MAPPER.AB.value:ACTION_TYPE_MAPPER.F.value),
      actionButtonConfig: widgetStore?.widgetSettings?.actionButtonConfig?{...widgetStore?.widgetSettings?.actionButtonConfig}:{...actionButtonConfig},
      fieldConfig: widgetStore?.widgetSettings?.fieldConfig?{...widgetStore?.widgetSettings?.fieldConfig}:{...fieldConfig},
    }));
    getLocalStorageUserData();
    getPabblyList();
  }, []);

  // called white store is updated
  useEffect(()=>{
    if (widgetStore?.widgetSettings) {
      setActionType(widgetStore?.widgetSettings?.actionType);
      setFieldConfig({...widgetStore?.widgetSettings?.fieldConfig});
      setActionButtonConfig({...widgetStore?.widgetSettings?.actionButtonConfig});
    }
  }, [widgetStore?.widgetSettings]);

  // to field config (nested objects)
  const updateFieldConfig = (field, fieldConfigKey, fieldConfigValue)=>{
    const fieldValueTemp = {...fieldConfig};
    fieldValueTemp[field][fieldConfigKey].value = fieldConfigValue;
    setFieldConfig(fieldValueTemp);
    dispatch(updateWidgetSettings({
      fieldConfig: fieldValueTemp,
    }));
  };

  // Widget type handler
  useEffect(()=>{
    if (widgetType === 'PS' || widgetType === 'BS') {
      setActionTypeButton([
        {
          name: ACTION_TYPE_MAPPER.F.name,
          value: ACTION_TYPE_MAPPER.F.value,
        },
        {
          name: ACTION_TYPE_MAPPER.AB.name,
          value: ACTION_TYPE_MAPPER.AB.value,
        },
      ]);
      setActionType(ACTION_TYPE_MAPPER.AB.name);
    }
  }, [widgetType]);

  return (
    <div {...props}>
      <CollapsibleComponent
        type={'CTA'}
        heading={'Call To Action Settings'}
        defaultValue={toggleCollapsible}
        toggleCallback={(toggle)=>{
          setToggleCollapsible(toggle);
          const tempCollapse = {...widgetStore?.collapse_widget};
          dispatch(updateWidgetSettings({
            toggleStatusMessageSuccess: false,
          }));
          Object.keys(tempCollapse).map((key)=>tempCollapse[key] = false);
          tempCollapse['CTA'] = toggle;
          dispatch(updateCollapseAction({...tempCollapse}));
        }}
      />
      <div className={toggleCollapsible?'px-2':'hidden'}>
        {/* Type button group */}
        {widgetType !== 'R' && <div id="type-button-container">
          <ButtonGroup
            planBlock={!curretUserPlan?.modules?.P?.WIDGETS?.ACTION_SETTINGS?.FORM || !curretUserPlan?.modules?.P?.WIDGETS?.ACTION_SETTINGS?.ACTION_BUTTON}
            className={'mt-4'}
            label={'Type'}
            onSelect={(value)=>{
              setActionType(value);
              dispatch(updateWidgetSettings({
                actionType: value,
              }));
            }}
            defaultSelect={widgetStore?.widgetSettings?.actionType}
            buttons={[...actionTypeButton]}
          />
        </div>}
        {/* Form container */}
        {actionType === ACTION_TYPE_MAPPER.F.value && <div id="form-container">
          <InfoWrapper
            description='The value entered in fields will be considered as the placeholders'
            additionalClass='mt-2 hidden'
          />
          <div id="field-one-container" className='mt-4'>
            <div className='flex items-center'>
              <CheckBox parentAdditionalClass={'mt-0 mr-2.5'}
                defaultValue={widgetStore?.widgetSettings?.fieldConfig?.fieldOne?.enabledStatus?.value}
                onChange={(value)=>{
                  updateFieldConfig('fieldOne', 'enabledStatus', value);
                }}
              />
              <label className="text-sm font-normal flex items-center mr-2.5">
                Field 1
              </label>
              <SettingsIcon className={'cursor-pointer'} height={'20'} width={'20'} onClick={()=>{
                setCurrentField('fieldOne');
                openFieldModal();
              }}/>
            </div>
            <div className='flex items-center mt-2'>
              <Input
                placeholder={'Ex: Enter name'}
                parentAdditionalClass={'mr-2 w-full'}
                defaultValue={widgetStore?.widgetSettings?.fieldConfig?.fieldOne?.fieldPlaceholder?.value}
                onChange={(event)=>{
                  updateFieldConfig('fieldOne', 'fieldPlaceholder', event?.target?.value);
                }}
              />
              {/* <SettingsIcon className={'cursor-pointer'} height={'20'} width={'20'} onClick={()=>{
                setCurrentField('fieldOne');
                openFieldModal();
              }}/> */}
            </div>
            <ChipContainer additionalClass='mt-2 flex-wrap' onClick={()=>{
              setCurrentField('fieldOne');
              openFieldModal();
            }}>
              {Object.keys(fieldConfig?.fieldOne)?.map((configKey, index)=>{
                return (fieldConfig?.fieldOne[configKey]?.value)?(
              <Chips key={index} classOverride={`text-xs p-1 ${fieldConfig?.fieldOne[configKey]?.chipColor?'text-white':'bg-slate-200'} rounded-lg mr-2 mt-1`} style={{backgroundColor: fieldConfig?.fieldOne[configKey]?.chipColor}}>{fieldConfig?.fieldOne[configKey]?.displayName} : {fieldConfig?.fieldOne[configKey]?.value?.toString()}</Chips>
            ):(<div key={index}></div>);
              })}
            </ChipContainer>
          </div>
          <div id="field-one-container" className='mt-4'>
            <div className='flex items-center'>
              <CheckBox parentAdditionalClass={'mt-0 mr-2.5'}
                defaultValue={widgetStore?.widgetSettings?.fieldConfig?.fieldTwo?.enabledStatus?.value}
                onChange={(value)=>{
                  updateFieldConfig('fieldTwo', 'enabledStatus', value);
                }}
              />
              <label className="text-sm font-normal flex items-center mr-2.5">
                Field 2
              </label>
              <SettingsIcon className={'cursor-pointer mr-2.5'} height={'20'} width={'20'} onClick={()=>{
                setCurrentField('fieldTwo');
                openFieldModal();
              }}/>
            </div>
            <div className='flex items-center mt-2'>
              <Input
                placeholder={'Ex: Enter email'}
                parentAdditionalClass={'mr-2 w-full'}
                onChange={(event)=>{
                  updateFieldConfig('fieldTwo', 'fieldPlaceholder', event?.target?.value);
                }}
                defaultValue={widgetStore?.widgetSettings?.fieldConfig?.fieldTwo?.fieldPlaceholder?.value}
              />
            </div>
            <ChipContainer additionalClass='mt-2 flex-wrap' onClick={()=>{
              setCurrentField('fieldTwo');
              openFieldModal();
            }}>
              {Object.keys(fieldConfig?.fieldTwo)?.map((configKey, index)=>{
                return (fieldConfig?.fieldTwo[configKey]?.value)?(
              <Chips key={index} classOverride={`text-xs p-1 ${fieldConfig?.fieldTwo[configKey]?.chipColor?'text-white':'bg-slate-200'} rounded-lg mr-2 mt-1`} style={{backgroundColor: fieldConfig?.fieldTwo[configKey]?.chipColor}}>{fieldConfig?.fieldTwo[configKey]?.displayName} : {fieldConfig?.fieldTwo[configKey]?.value?.toString()}</Chips>
            ):(<div key={index}></div>);
              })}
            </ChipContainer>
          </div>
          <div className="flex items-center mt-4">
            <div className='flex-2 mr-2'>
              <Heading className="text-sm font-normal flex items-center">
                Button Name
              </Heading>
              <Input
                placeholder={'Ex: Submit'}
                parentAdditionalClass={'mr-2 mt-1 w-full'}
                {...register('actionButtonName', {
                  onChange: (event)=>{
                    dispatch(updateWidgetSettings({
                      actionButtonName: event?.target?.value,
                    }));
                  },
                })}
                errorStatus={errors?.actionButtonName}
                defaultValue={widgetStore?.widgetSettings?.actionButtonName}
              />
            </div>
            {/* <SettingsIcon className={'cursor-pointer'} height={'20'} width={'20'} onClick={()=>{
              setButtonLinkModal(true);
            }}/> */}
            <div id="button-color" className='flex-1'>
              <label className='text-sm font-normal flex items-center'>Button Color</label>
              <ColorInput
                defaultValue={widgetStore?.widgetSettings?.actionButtonConfig?.actionButtonColor?.value}
                onChangeValue={(value)=>{
                  const configTemp = {...actionButtonConfig, actionButtonColor: {
                    value: value,
                    displayName: 'Button color',
                    chipColor: value,
                  }};
                  setActionButtonConfig({...configTemp});
                  dispatch(updateWidgetSettings({
                    actionButtonConfig: configTemp,
                  }));
                }}
              />
            </div>
          </div>
          <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errors?.actionButtonName?.message}</ErrorText>
          <ChipContainer additionalClass='mt-2'>
            <Chips classOverride='text-xs text-white font-thin p-1 bg-slate-200 rounded-lg' style={{backgroundColor: actionButtonConfig?.actionButtonColor?.value}}>Button color : {actionButtonConfig?.actionButtonColor?.value}</Chips>
          </ChipContainer>
        </div>}

        {/* Description container(For review component) */}
        {widgetType === 'R' && (
          <div id="review-description-container">
            <label className="text-sm font-normal mt-4 flex items-center">
          Review description input(Text entered here will be the placehoder vale when deployed)
              {widgetStore?.widgetSettings?.fieldConfig?.reviewDescription?.enabledStatus?.value?.toString()}
            </label>
            <div className='flex items-center'>
              <CheckBox parentAdditionalClass={'mr-2'}
                defaultValue={widgetStore?.widgetSettings?.fieldConfig?.reviewDescription?.enabledStatus?.value}
                onChange={(value)=>{
                  updateFieldConfig('reviewDescription', 'enabledStatus', value);
                }}
              />
              <TextArea
                {...register('reviewDescription', {
                  onChange: (event)=>{
                    updateFieldConfig('reviewDescription', 'fieldPlaceholder', event?.target?.value);
                  },
                })}
                defaultValue={widgetStore?.widgetSettings?.reviewDescription?.fieldTwo?.fieldPlaceholder?.value}
                placeholder={'Ex: Please provide us a feedback'}
                errorStatus={errors?.subHeadingText}
                rows={4}
                additionalClass={'mr-2'}
              />
              <SettingsIcon className={'cursor-pointer'} height={'20'} width={'20'} onClick={()=>{
                setCurrentField('reviewDescription');
                openFieldModal();
              }}/>
            </div>
            {fieldConfig?.reviewDescription && <ChipContainer additionalClass='mt-2 flex-wrap'>
              {Object.keys(fieldConfig?.reviewDescription)?.map((configKey, index)=>{
                return (fieldConfig?.reviewDescription[configKey]?.value)?(
            <Chips key={index} classOverride={`text-sm font-thin p-1 ${fieldConfig?.reviewDescription[configKey]?.chipColor?'text-white':'bg-slate-200'} rounded-lg mr-2 mt-1`} style={{backgroundColor: fieldConfig?.reviewDescription[configKey]?.chipColor}}>{fieldConfig?.reviewDescription[configKey]?.displayName} : {fieldConfig?.reviewDescription[configKey]?.value?.toString()}</Chips>
          ):(<div key={index}></div>);
              })}
            </ChipContainer>}
          </div>
        ) }

        {actionType === ACTION_TYPE_MAPPER.AB.value && <div id="form-container" className="mt-4">
          <div id="action-button-container">
            <div className='flex items-center'>
              <div className='flex-2 mr-2'>
                <Heading className="text-sm font-normal flex items-center">
                  Button Name
                </Heading>
                <Input
                  placeholder={'Ex: Button name'}
                  parentAdditionalClass={'mr-2 mt-1 w-full'}
                  {...register('actionButtonName', {
                    onChange: (event)=>{
                      dispatch(updateWidgetSettings({
                        actionButtonName: event?.target?.value,
                      }));
                    },
                  })}
                  errorStatus={errors?.actionButtonName}
                  defaultValue={widgetStore?.widgetSettings?.actionButtonName}
                />
              </div>
              {/* <SettingsIcon className={'cursor-pointer'} height={'20'} width={'20'} onClick={()=>{
                setButtonLinkModal(true);
              }}/> */}
              <div id="button-color" className='flex-1'>
                <label className='text-sm font-normal flex items-center'>Button Color</label>
                <ColorInput
                  defaultValue={widgetStore?.widgetSettings?.actionButtonConfig?.actionButtonColor?.value}
                  onChangeValue={(value)=>{
                    const configTemp = {...actionButtonConfig, actionButtonColor: {
                      value: value,
                      displayName: 'Button color',
                      chipColor: value,
                    }};
                    setActionButtonConfig({...configTemp});
                    dispatch(updateWidgetSettings({
                      actionButtonConfig: configTemp,
                    }));
                  }}
                />
              </div>
            </div>
            <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errors?.actionButtonName?.message}</ErrorText>
            {widgetType !== 'R' && widgetType !== 'PS' && <div>
              <InfoWrapper
                description='Only https:// links are allowed'
                additionalClass='mt-2'
              />
              <Input
                placeholder={'Ex: https://example.com'}
                parentAdditionalClass={'mr-2 w-full'}
                {...register('actionButtonLink', {
                  onChange: (event)=>{
                    dispatch(updateWidgetSettings({
                      actionButtonLink: event?.target?.value,
                    }));
                  },
                })}
                errorStatus={errors?.actionButtonLink}
                defaultValue={widgetStore?.widgetSettings?.actionButtonLink}
              />
              <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errors?.actionButtonLink?.message}</ErrorText>
            </div>}
            {/* <ChipContainer additionalClass='mt-2'>
              <Chips classOverride='text-xs text-white font-thin p-1 bg-slate-200 rounded-lg' style={{backgroundColor: actionButtonConfig?.actionButtonColor?.value}}>Button color : {actionButtonConfig?.actionButtonColor?.value}</Chips>
            </ChipContainer> */}
          </div>
        </div>}

        {actionType === ACTION_TYPE_MAPPER.FB.value && <div>
          <div id="input-container" className='mt-4'>
            <Heading className="text-sm font-normal flex items-center">Facebook User name
              <QuestionIcon
                height={20}
                width={20}
                className={'ml-1'}
                data-tip
                data-for="fb-instructions"
              />
              <ReactTooltip
                id="fb-instructions"
                place="right"
                effect="solid"
              >
                <span>Please add only username of your FB messenger <br/>For eg:  m.me/soban.kousik ( soban.kousik  is the username )</span>
              </ReactTooltip>
            </Heading>
            <Input
              prefix={{
                text: 'm.me/',
              }}
              placeholder={'Ex: john.wick'}
              parentAdditionalClass={'mr-2 w-full'}
              {...register('facebookUserName', {
                onChange: (event)=>{
                  dispatch(updateWidgetSettings({
                    facebookUserName: event?.target?.value,
                  }));
                },
              })}
              errorStatus={errors?.facebookUserName}
              defaultValue={widgetStore?.widgetSettings?.facebookUserName}
            />
          </div>
        </div>}

        {actionType === ACTION_TYPE_MAPPER.CAL.value && <div className='mt-4'>
          <Heading className="text-sm font-normal flex items-center">
          Calendly meeting link
            <QuestionIcon
              height={20}
              width={20}
              className={'ml-1'}
              data-tip
              data-for="calendly-instructions"
            />
            <ReactTooltip
              id="calendly-instructions"
              place="right"
              effect="solid"
            >
              <span>Please add only username or username/meetingid <br/>If calendly.com/soban/popsuite ( soban is the username & popsuite is your meeting id)</span>
            </ReactTooltip>
          </Heading>
          <Input
            prefix={{
              text: 'calendly.com/',
            }}
            placeholder={'Ex: username/meetingname'}
            parentAdditionalClass={'mr-2 w-full'}
            {...register('calendlyMeetingLink', {
              onChange: (event)=>{
                dispatch(updateWidgetSettings({
                  calendlyMeetingLink: event?.target?.value,
                }));
              },
            })}
            errorStatus={errors?.calendlyMeetingLink}
            defaultValue={widgetStore?.widgetSettings?.calendlyMeetingLink}
          />
          <ErrorText displayStatus={true}>{errors?.calendlyMeetingLink?.message}</ErrorText>
        </div>}

        {(curretUserPlan?.modules?.P?.INTEGRATIONS?.MAILCHIMP || curretUserPlan?.modules?.P?.INTEGRATIONS?.KLAVIYO || curretUserPlan?.modules?.P?.INTEGRATIONS?.PABBLY) && <div className='font-semibold mt-8 flex items-center'>
        Autoresponder Integrations
          <QuestionIcon
            height={20}
            width={20}
            className={'ml-1 cursor-pointer'}
            data-tip
            data-for='autoresponders-instructions'
            onClick={()=>{
              window.open(
                  'https://popsuite.tawk.help/article/how-to-select-a-particular-audience-list-for-auto-responders',
                  '_blank',
              );
            }}
          />
          <ReactTooltip
            id="autoresponders-instructions"
            place="right"
            effect="solid"
          >
            <span>Click to read the instrcutions</span>
          </ReactTooltip>
        </div>}
      </div>

      <CollapsibleComponent
        heading={'Autoresponder Integrations'}
        type={'AS'}
        defaultValue={toggleCollapsibleAutoresponder}
        parentClass={'mt-4'}
        toggleCallback={(toggle)=>{
          setToggleCollapsibleAutoresponder(toggle);
          const tempCollapse = {...widgetStore?.collapse_widget};
          dispatch(updateWidgetSettings({
            toggleStatusMessageSuccess: false,
          }));
          Object.keys(tempCollapse).map((key)=>tempCollapse[key] = false);
          tempCollapse['AS'] = toggle;
          dispatch(updateCollapseAction({...tempCollapse}));
        }}
      />
      <div className={toggleCollapsibleAutoresponder?'px-2':'hidden'}>
        {/* Mailchimp Integration */}
        {curretUserPlan?.modules?.P?.INTEGRATIONS?.MAILCHIMP && <div className='mt-4'>
          <Heading className="text-sm font-normal flex items-center">
            Mailchimp Audience Id
          </Heading>
          <Input
            placeholder={'Ex: afdd15a987yhtab1d9faed49098ikj40'}
            parentAdditionalClass={'mr-2 w-full mt-2'}
            {...register('mailChimpAudienceId', {
              onChange: (event)=>{
                dispatch(updateWidgetSettings({
                  mailChimpAudienceId: event?.target?.value,
                }));
              },
            })}
            errorStatus={errors?.mailChimpAudienceId}
            defaultValue={widgetStore?.widgetSettings?.mailChimpAudienceId}
          />
          <ErrorText displayStatus={true}>{errors?.mailChimpAudienceId?.message}</ErrorText>
        </div>}

        {/* Kalviyo Integration */}
        {curretUserPlan?.modules?.P?.INTEGRATIONS?.KLAVIYO && <div className='mt-4'>
          <Heading className="text-sm font-normal flex items-center">
            Klaviyo List Id
          </Heading>
          <Input
            placeholder={'Ex: ABByJS'}
            parentAdditionalClass={'mr-2 w-full mt-2'}
            {...register('klaviyoListId', {
              onChange: (event)=>{
                dispatch(updateWidgetSettings({
                  klaviyoListId: event?.target?.value,
                }));
              },
            })}
            errorStatus={errors?.klaviyoListId}
            defaultValue={widgetStore?.widgetSettings?.klaviyoListId}
          />
          <ErrorText displayStatus={true}>{errors?.klaviyoListId?.message}</ErrorText>
        </div>}

        {/* Pabbly Integration */}
        {curretUserPlan?.modules?.P?.INTEGRATIONS?.PABBLY && <div className='mt-4'>
          <Heading className="text-sm font-normal flex items-center mb-1">
            Pabbly subscriber Id
          </Heading>
          <Select
            defaultSelected={widgetStore?.widgetSettings?.pabblyListId}
            onChange={(event)=>{
              dispatch(updateWidgetSettings({
                pabblyListId: event,
              }));
            }}
            options={[
              {
                value: '',
                text: '------',
              },
              ...pabblyList,
            ]}
          />
          <ErrorText displayStatus={true}>{errors?.pabblyListId?.message}</ErrorText>
        </div>}

        {/* Webhook URL config */}
        <div className='mt-4'>
          <Heading className="text-sm font-normal flex items-center">
            Pabbly Webhook URL
          </Heading>
          <Input
            placeholder={'Ex: https://connect.pabbly.com/workflow/sendwebhookdata/IHUEDHUEDhiuwed823...'}
            parentAdditionalClass={'mr-2 w-full mt-2'}
            {...register('pabblyWebhookURL', {
              onChange: (event)=>{
                dispatch(updateWidgetSettings({
                  pabblyWebhookURL: event?.target?.value,
                }));
              },
            })}
            errorStatus={errors?.pabblyWebhookURL}
            defaultValue={widgetStore?.widgetSettings?.pabblyWebhookURL}
          />
        </div>
      </div>

      {/* Field config modal */}
      {fieldConfigModal && <FieldConfigModal
        onClose={()=>{
          setFieldConfigModal(false);
        }}
        currentField={currentField}
        configValues={{...fieldConfig}}
        className={`transition-all`}
        onConfigChange={(config)=>{
          setFieldConfig({...config});
          dispatch(updateWidgetSettings({
            fieldConfig: config,
          }));
        }}
        widgetType={widgetType}
      />}

      {/* Button link modal */}
      {buttonLinkModal && <ButtonLinkModal
        onClose={()=>{
          setButtonLinkModal(false);
        }}
        configValues={{...actionButtonConfig}}
        className={`transition-all`}
        onConfigChange={(config)=>{
          setActionButtonConfig({...config});
          dispatch(updateWidgetSettings({
            actionButtonConfig: config,
          }));
        }}
      />}
    </div>
  );
};

ActionSettings.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
  setValue: PropTypes.any,
  widgetType: PropTypes.string,
};

export default ActionSettings;
