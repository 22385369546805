import React from 'react';
import {Outlet, Route, Routes, useLocation} from 'react-router-dom';
import AdminProtectedRoute from '../../components/protectedRouter/adminProtectedRouter';
import TopSideBarAdminTemplate from '../../templates/topSideBarAdmin.template';
import AdminAddPlansPage from './add-plans.page';
import AdminLoginPage from './admin-login.page';
import CustomersAdminPage from './customersAdmin.page';
import AdminEditPlansPage from './edit-plans.page';
import AdminPlansPage from './plans.page';
import RedemptionAdminPage from './redemptionAdmin.page';
import SettingsAdminPage from './settings/settingsAdmin.page';

/**
 * Admin pages
 *
 * @return {JSX}
 */
const AdminPages = ()=>{
  const {pathname} = useLocation();
  /**
   * White list url
   *
   * @return {JSX}
   */
  const whiteListRouters = ()=> {
    return pathname === '/admin';
  };

  return (
    <TopSideBarAdminTemplate
      noPadding={true}
      hideSideNav={whiteListRouters()}
    >
      <Outlet/>
      <Routes>
        <Route index element={<AdminLoginPage />} />
        <Route path="plans" element={<AdminProtectedRoute><AdminPlansPage /></AdminProtectedRoute>} />
        <Route path="saaszillacodes" element={<AdminProtectedRoute><RedemptionAdminPage /></AdminProtectedRoute>} />
        <Route path="add-plans" element={<AdminProtectedRoute><AdminAddPlansPage /></AdminProtectedRoute>} />
        <Route path="edit-plan/:id" element={<AdminProtectedRoute><AdminEditPlansPage /></AdminProtectedRoute>} />
        <Route path="customers" element={<AdminProtectedRoute><CustomersAdminPage /></AdminProtectedRoute>} />
        <Route path="settings/*" element={<AdminProtectedRoute><SettingsAdminPage /></AdminProtectedRoute>} />
      </Routes>
    </TopSideBarAdminTemplate>
  );
};

export default AdminPages;
