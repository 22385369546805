import React from 'react';
import Heading from '../../../components/heading';
import CloseIcon from '../../../icons/close.icon';
import PropTypes from 'prop-types';
import Button from '../../../components/button/button.component';
import UploadInstrcutionsImage from '../../../assets/images/upload-instrcutions-image.png';

/**
 * Upload instrcutions for Media library
 *
 * @return {JSX}
 */
const UploadInstructionsModal = ({onClose, onOk})=>{
  return (
    <>
      <div className="fixed inset-0 bg-stone-900 bg-opacity-75 transition-opacity z-50" aria-hidden="true"></div>
      <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white w-1/3 p-4 rounded-lg' style={{zIndex: 60}}>
        <Heading className='font-semibold text-lg flex justify-between  mt-4'>
        Media upload instructions
          <CloseIcon className='cursor-pointer w-4 h-4' onClick={()=>{
            onClose();
          }}/>
        </Heading>
        <div id="input-container">
          <label className='text-sm font-normal mt-4 flex items-center mb-1'>For better experience, upload video in 9:16 and 16:19 ratios. Refer the below image.</label>
          <img src={UploadInstrcutionsImage}/>
        </div>
        <div className='flex mt-4 justify-end'>
          <Button
            type="button"
            onClick={()=>{
              onOk();
            }}
          >Ok</Button>
        </div>
      </div>
    </>
  );
};

UploadInstructionsModal.propTypes = {
  onClose: PropTypes.func,
  onOk: PropTypes.func,
};

export default UploadInstructionsModal;
