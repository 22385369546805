import React from 'react';
import PropTypes from 'prop-types';

/**
 * Search icon
 *
 * @return {JSX}
 */
const Search = ({height, width, ...props})=>{
  return (
    <svg width={width || '24'} height={height || '24'} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M24.0591 21.7308H22.8328L22.3982 21.3117C23.9194 19.5422 24.8352 17.2449 24.8352 14.7459C24.8352 9.17348 20.3183 4.65659 14.7459 4.65659C9.17348 4.65659 4.65659 9.17348 4.65659 14.7459C4.65659 20.3183 9.17348 24.8352 14.7459 24.8352C17.2449 24.8352 19.5422 23.9194 21.3117 22.3982L21.7308 22.8328V24.0591L29.4917 31.8045L31.8045 29.4917L24.0591 21.7308V21.7308ZM14.7459 21.7308C10.8809 21.7308 7.76098 18.6108 7.76098 14.7459C7.76098 10.8809 10.8809 7.76098 14.7459 7.76098C18.6108 7.76098 21.7308 10.8809 21.7308 14.7459C21.7308 18.6108 18.6108 21.7308 14.7459 21.7308Z" fill="currentColor"/>
    </svg>


  );
};

Search.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Search;
