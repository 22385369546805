import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {ACTION_TYPE_MAPPER, ALIGNMENT_MAPPER, COUNTER_DIRECTION_MAPPER, FONT_SIZE_MAPPER, FONT_TYPE_MAPPER, MEDIA_POSITION_MAPPER, VALIDATION_MAPPER} from './application.const';
import {useStopwatch, useTimer} from 'react-timer-hook';
import {useForm} from 'react-hook-form';
import QuestionComponent from './question.component';
import Watermark from '../assets/images/watermark.png';
import CopyToClipboard from 'react-copy-to-clipboard';
import SissorIcon from '../icons/sissor.icon';

/**
 * Popup survey widget component
 *
 * @return {JSX}
 */
const PopupSurveyWidgetComponent = ({
  mode,
  popupSize,
  headingText,
  headingConfig,
  subHeadingText,
  subHeadingConfig,
  mediaDetails,
  counterStatus,
  counterConfig,
  mediaPosition,
  actionButtonName,
  actionButtonConfig,
  actionType,
  fieldConfig,
  displayPreference,
  displayPreferenceConfig,
  pages,
  popupPosition,
  surveyQuestionSettings,
  surveyQuestionAnswerType,
  branding,
  couponCodeNotificationCode,
  couponCodeNotification,
  couponDisplayNotification,
  entrySuccessConfig,
  entryPopupSuccessText,
  entryFailConfig,
  entryPopupFailText,
  toggleStatusMessageSuccess,
  toggleStatusMessageFail,
  ctaButtonNotificationEnable,
  ctaButtonNotificationName,
  ctaButtonNotificationLink,
  ctaButtonNotificationConfig,
  logoDetails,
})=>{
  const {
    seconds,
    minutes,
    hours,
    days,
    restart,
  } = useTimer({expiryTimestamp: new Date()});
  const timerObj = useStopwatch({autoStart: true});
  const {register, handleSubmit, formState: {errors}} = useForm();
  const [currentWindowWidth, setCurrentWindowWidth] = useState(window.innerWidth);
  const [currentPage, setCurrentPage] = useState(0);
  const [copiedSuccess, setCopiedSuccess] = useState(false);
  const [surveyQuestionsData, setSurveyQuestionsData] = useState({});

  // Change copied status
  const changeCopiedStatus = () => {
    setCopiedSuccess(true);
    setTimeout(() => {
      setCopiedSuccess(false);
    }, 2000);
  };

  // Change page
  const changePage = (page)=>{
    setCurrentPage(page);
  };

  // On form submit
  const onFormSubmit = (value)=>{
    console.log(value);
    changePage(1);
  };


  // Listen for width change (For responsiveness)
  const updateDimensions = ()=>{
    setCurrentWindowWidth(window.innerWidth);
  };

  // For responsive designs
  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () =>
      window.removeEventListener('resize', updateDimensions);
  }, []);

  // Question answer submit(callback function)
  const questionAnswerSubmit = (questionAnswerData)=>{
    console.log(questionAnswerData);
  };

  useEffect(()=>{
    if (counterConfig?.counterDate) {
      if (counterConfig?.direction === COUNTER_DIRECTION_MAPPER.RB.value) {
        restart(new Date(counterConfig?.counterDate));
      }
      if (counterConfig?.direction === COUNTER_DIRECTION_MAPPER.RF.value) {
        timerObj.reset();
        timerObj.start();
      }
    }
    if (displayPreference && (mode !== 'dev' && pages?.split(',').indexOf(window?.location?.pathname) !== -1)) {
      setTimeout(()=>{
        // alert('done');
      }, displayPreferenceConfig?.delayTime);
    }
  }, [counterConfig, displayPreference, displayPreferenceConfig?.delayTime, window?.location?.pathname]);

  // get styles for images
  const getImageStyles = ()=>{
    if (mediaPosition === MEDIA_POSITION_MAPPER.L.value) {
      return (
        {objectFit: 'cover', height: '100%', width: '100%', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px'}
      );
    }
    if (mediaPosition === MEDIA_POSITION_MAPPER.R.value) {
      return (
        {objectFit: 'cover', height: '100%', width: '100%', borderTopRightRadius: '10px', borderBottomRightRadius: '10px'}
      );
    }
    if (mediaDetails?.type === 'V' && mediaPosition === MEDIA_POSITION_MAPPER.B.value) {
      return (
        {objectFit: 'cover', height: '100%', width: '100%', borderTopRightRadius: '10px', borderBottomRightRadius: '10px', position: 'absolute', zIndex: 99}
      );
    }
    return (
      {objectFit: 'cover', height: '100%', width: '100%', borderTopRightRadius: '10px', borderBottomRightRadius: '10px'}
    );
  };

  // Position calculator
  const positionCalculator = ()=>{
    const popupPositionContainer = popupPosition || 'C';
    if (mode === 'dev') {
      return ({
        top: mode === 'dev' ? '10%' : '50%',
        left: mode === 'dev' ? '10%' : '50%',
        transform: mode === 'dev' ? '':'translate(-50%, -50%)',
      });
    }
    if (popupPositionContainer === 'L') {
      return ({
        bottom: '5px',
        left: '5px',
      });
    }
    if (popupPositionContainer === 'R') {
      return ({
        bottom: '5px',
        right: '5px',
      });
    }
    if (popupPositionContainer === 'C') {
      return ({
        top: '50%',
        left: '50%',
        // transform: 'translate(-50%, -50%)',
      });
    }
  };

  /**
   * Close Button Component
   *
   * @return {JSX}
   */
  // eslint-disable-next-line react/prop-types
  const CloseButtonComponent = ({type})=>{
    return (
      <div style={{display: 'flex', justifyContent: 'flex-end', position: 'absolute', top: '1rem', right: '1rem', zIndex: 100, backgroundColor: '#ffff', padding: '4px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px', borderRadius: (type == 2)?'5px':''}}>
        <svg
          height="20px"
          width="20px"
          fill="currentColor"
          viewBox="0 0 20 20"
          role="img"
          aria-hidden="true"
          style={{cursor: 'pointer'}}
          onClick={()=>{
            if (mode !== 'dev') {
              videoRef?.current?.pause();
              document.querySelector(`#${DIV_NAME}${divIndex}`).style.display = 'none';
            }
          }}
        >
          <path
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
            fillRule="evenodd"
          ></path>
        </svg>
      </div>
    );
  };

  CloseButtonComponent.propTypes = {
    type: PropTypes.number,
  };

  return (
    <>
      <div style={{
        boxSizing: 'border-box',
        // position: mode === 'dev'?'absolute':'fixed',
        borderWidth: '1px',
        borderRadius: '10px',
        backgroundColor: '#fff',
        // width: mode === 'dev' ?'90%':POPUP_SIZE_MAPPER_VALUE[popupSize || 'XL'].width,
        // minHeight: '200px',
        // maxHeight: '800px',
        overflowY: 'auto',
        margin: '0px 100px',
        boxShadow: mode === 'dev'?'':'2px 2px 6px gray',
        zIndex: mode === 'dev'?'40':'99',
        display: 'flex',
        ...positionCalculator(),
        // width: 100%,
        zoom: 0.8,
        background: mediaPosition === MEDIA_POSITION_MAPPER.B.value?`url(${mediaDetails?.mediaPath || 'https://picsum.photos/500'}) no-repeat`:'',
        backgroundSize: mediaPosition === MEDIA_POSITION_MAPPER.B.value?'cover':'',
        flexDirection: (currentWindowWidth<800)?'column':(mediaPosition === MEDIA_POSITION_MAPPER.R.value?'row-reverse':''),
      }}>
        {mediaDetails?.type !== 'NM' && <div style={{position: 'relative'}}>
          {(currentWindowWidth<800 || mediaPosition === MEDIA_POSITION_MAPPER.R.value) && <CloseButtonComponent type={2}/>}
          { mediaDetails?.type === 'V' && <div style={{flex: (mediaPosition === MEDIA_POSITION_MAPPER.B.value)?0:1, width: '400px', height: '100%'}}>
            <video style={getImageStyles()} autoPlay loop controls controlsList='nodownload nofullscreen noremoteplayback' muted>
              <source src={mediaDetails?.mediaPath}></source>
            </video>
          </div>}
          {(mediaPosition !== MEDIA_POSITION_MAPPER.B.value && mediaDetails?.type !== 'V') &&<div style={{flex: 1, width: '400px', height: '100%'}}>
            { mediaDetails?.type === 'I' && mediaPosition !== MEDIA_POSITION_MAPPER.B.value && <img src={mediaDetails?.mediaPath} style={getImageStyles()}/>}
            {/* Default image */}
            { !mediaDetails?.type && mediaPosition !== MEDIA_POSITION_MAPPER.B.value && <img src={'https://picsum.photos/500'} style={getImageStyles()}/>}
          </div>}
        </div>}
        <div style={{flex: 1, padding: '12px', display: 'flex', justifyContent: 'center', flexDirection: 'column', backgroundColor: mediaPosition !== MEDIA_POSITION_MAPPER.B.value?'#fff':'', position: 'relative'}}>
          {(mediaDetails?.type === 'NM' || !(currentWindowWidth<800 || mediaPosition === MEDIA_POSITION_MAPPER.R.value)) && <CloseButtonComponent type={1}/>}
          {!toggleStatusMessageSuccess && !toggleStatusMessageFail && <>
            {logoDetails?.mediaPath && <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              margin: '1rem 0rem 1rem 0rem',
            }}>
              <img src={logoDetails?.mediaPath} style={{
                height: '50px',
                widget: '50px',
              }}/>
            </div>}
            <h3 style={{fontWeight: FONT_TYPE_MAPPER[headingConfig?.fontType?.value], fontSize: '20px', color: headingConfig?.textColor?.value, textAlign: ALIGNMENT_MAPPER[headingConfig?.textAlignment?.value], zIndex: 100, margin: '0px'}}>{ headingText || 'Heading' }</h3>
            <p style={{marginTop: '10px', fontWeight: FONT_TYPE_MAPPER[subHeadingConfig?.fontType?.value], fontSize: '12px', color: subHeadingConfig?.textColor?.value, textAlign: ALIGNMENT_MAPPER[subHeadingConfig?.textAlignment?.value], zIndex: 100}}>
              {subHeadingText || 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores a totam repellendus'}
            </p>
            {counterStatus && counterConfig?.direction === COUNTER_DIRECTION_MAPPER.RB.value && <div style={{display: 'flex', justifyContent: 'center', margin: '1rem 0rem 1rem 0rem', flexDirection: currentWindowWidth<800?'column':'', zIndex: 100}}>
              <p style={{color: '#fff', marginRight: '10px', backgroundColor: counterConfig?.fieldColor?.value, padding: '12px', borderRadius: '10px', textAlign: 'center', marginBottom: currentWindowWidth<800?'10px':''}}>{days} Days</p>
              <p style={{color: '#fff', marginRight: '10px', backgroundColor: counterConfig?.fieldColor?.value, padding: '12px', borderRadius: '10px', textAlign: 'center', marginBottom: currentWindowWidth<800?'10px':''}}>{hours} Hours</p>
              <p style={{color: '#fff', marginRight: '10px', backgroundColor: counterConfig?.fieldColor?.value, padding: '12px', borderRadius: '10px', textAlign: 'center', marginBottom: currentWindowWidth<800?'10px':''}}>{minutes} Minutes</p>
              <p style={{color: '#fff', marginRight: '10px', backgroundColor: counterConfig?.fieldColor?.value, padding: '12px', borderRadius: '10px', textAlign: 'center', marginBottom: currentWindowWidth<800?'10px':''}}>{seconds} Secons</p>
            </div>}
            {counterStatus && counterConfig?.direction === COUNTER_DIRECTION_MAPPER.RF.value && <div style={{display: 'flex', justifyContent: 'center', margin: '1rem 0rem 1rem 0rem', flexDirection: currentWindowWidth<800?'column':'', zIndex: 100}}>
              <p style={{color: '#fff', marginRight: '10px', backgroundColor: counterConfig?.fieldColor?.value, padding: '12px', borderRadius: '10px', textAlign: 'center', marginBottom: currentWindowWidth<800?'10px':''}}>{timerObj?.days} Days</p>
              <p style={{color: '#fff', marginRight: '10px', backgroundColor: counterConfig?.fieldColor?.value, padding: '12px', borderRadius: '10px', textAlign: 'center', marginBottom: currentWindowWidth<800?'10px':''}}>{timerObj?.hours} Hours</p>
              <p style={{color: '#fff', marginRight: '10px', backgroundColor: counterConfig?.fieldColor?.value, padding: '12px', borderRadius: '10px', textAlign: 'center', marginBottom: currentWindowWidth<800?'10px':''}}>{timerObj?.minutes} Minutes</p>
              <p style={{color: '#fff', marginRight: '10px', backgroundColor: counterConfig?.fieldColor?.value, padding: '12px', borderRadius: '10px', textAlign: 'center', marginBottom: currentWindowWidth<800?'10px':''}}>{timerObj?.seconds} Secons</p>
            </div>}
            {/* Question component */}
            {(currentPage === 1 || surveyQuestionSettings?.length === 1) && <QuestionComponent
              surveyQuestionSettings={surveyQuestionSettings}
              surveyQuestionAnswerType={surveyQuestionAnswerType || 'SC'}
              submitFunctionCallback={questionAnswerSubmit}
              changePage={changePage}
              buttonDesignStyle={{
                padding: '10px',
                backgroundColor: actionButtonConfig?.actionButtonColor?.value || 'purple',
                borderRadius: '5px',
                color: '#fff',
                fontSize: '12px',
              }}
              optionColor={headingConfig?.textColor?.value}
              oneQuestionStatus={surveyQuestionSettings?.length === 1}
              onSurveyQuestionUpdate={(question)=>{
                setSurveyQuestionsData(question);
              }}
            />}
            {currentPage === 0 && <form onSubmit={handleSubmit(onFormSubmit)} style={{marginTop: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 100}}>
              {actionType === ACTION_TYPE_MAPPER.F.value && <>
                {fieldConfig?.fieldOne?.enabledStatus?.value && <input
                  type={fieldConfig?.fieldOne?.fieldType?.value}
                  placeholder={fieldConfig?.fieldOne?.fieldPlaceholder?.value || 'Field one'}
                  {...register('fieldOne', {
                    required: fieldConfig?.fieldOne?.requiredStatus?.value === 'true',
                    pattern: (fieldConfig?.fieldOne?.validationType?.value === 'C'?fieldConfig?.fieldOne?.customValidationPattern:VALIDATION_MAPPER[fieldConfig?.fieldOne?.validationType?.value]) || VALIDATION_MAPPER['ALL'],
                  })}
                  style={{
                    border: `${errors.fieldOne?.type?'2px':'1px'} solid ${errors.fieldTwo?.type?'red':'gray'}`,
                    padding: '10px',
                    borderRadius: '5px',
                    width: '100%',
                    outline: 'none',
                    fontSize: '12px',
                  }}
                />}
                {errors.fieldOne?.type === 'required' && <div style={{color: 'red', fontSize: '12px', width: '100%'}}>Field required</div>}
                {errors.fieldOne?.type === 'pattern' && <div style={{color: 'red', fontSize: '12px', width: '100%'}}>Invalid value</div>}
                {fieldConfig?.fieldTwo?.enabledStatus?.value && <input
                  type={fieldConfig?.fieldTwo?.fieldType?.value}
                  placeholder={fieldConfig?.fieldTwo?.fieldPlaceholder?.value || 'Field two'}
                  {...register('fieldTwo', {
                    required: fieldConfig?.fieldTwo?.requiredStatus?.value === 'true',
                    pattern: (fieldConfig?.fieldTwo?.validationType?.value === 'C'?fieldConfig?.fieldTwo?.customValidationPattern:VALIDATION_MAPPER[fieldConfig?.fieldTwo?.validationType?.value]) || VALIDATION_MAPPER['ALL'],
                  })}
                  style={{
                    border: `${errors.fieldTwo?.type?'2px':'1px'} solid ${errors.fieldTwo?.type?'red':'gray'}`,
                    padding: '10px',
                    borderRadius: '5px',
                    marginTop: '10px',
                    width: '100%',
                    outline: 'none',
                    fontSize: '12px',
                  }}
                />}
              </>}
              {errors.fieldTwo?.type === 'required' && <div style={{color: 'red', fontSize: '12px', width: '100%'}}>Field required</div>}
              {errors.fieldTwo?.type === 'pattern' && <div style={{color: 'red', fontSize: '12px', width: '100%'}}>Invalid value</div>}
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '1rem', width: '100%'}}>
                <button
                  style={{
                    backgroundColor: actionButtonConfig?.actionButtonColor?.value || 'purple',
                    borderRadius: '5px',
                    marginTop: '10px',
                    color: '#fff',
                    fontSize: '12px',
                    padding: '10px',
                    width: '100%',
                    border: 'none',
                  }}
                  onClick={()=>{
                    if (actionType === ACTION_TYPE_MAPPER.AB.value && surveyQuestionSettings?.length === 1) {
                      questionAnswerSubmit(surveyQuestionsData);
                    } else if (actionType === ACTION_TYPE_MAPPER.AB.value) {
                      changePage(1);
                    }
                  }}
                  type={actionType === ACTION_TYPE_MAPPER.AB.value?'button':'submit'}
                >{actionButtonName || 'Submit'}</button>
              </div>
            </form>}

            {/* Branding */}
            {branding === 'Y' && <div style={{backgroundColor: 'white', padding: '1px 0px 1px 0px', fontSize: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: '1rem'}}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <span>Create your free widget </span><a href="http://customer.getpopsuite.com/register" target="_blank" alt="popsuite website" rel="noreferrer" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><img src={Watermark} height="15" width="60" style={{marginLeft: '2px'}}/></a>
              </div>
            </div>}
          </>}
          {/* Success message notification after survey */}
          {toggleStatusMessageSuccess && <div style={{left: mediaDetails?.type !== 'NM'?'50%':'', zIndex: 100, width: '500px', height: '400px'}}>
            <div style={{backgroundColor: entrySuccessConfig?.backgroundColor?.value || '#a955f7', padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', wordBreak: 'break-word', padding: '5rem'}}>
              <h3 style={{'color': entrySuccessConfig?.textColor?.value || '#ffff', 'fontWeight': 'bold', 'marginBottom': '1rem', 'marginTop': '1rem'}}>{entryPopupSuccessText || 'Thank you'}</h3>

              {/* Coupon code for message */}
              {couponCodeNotificationCode && couponDisplayNotification && <>
                {!copiedSuccess && <CopyToClipboard
                  options={{
                    message: 'Copied',
                  }}
                  text={couponCodeNotificationCode}
                  onCopy={()=>changeCopiedStatus()}
                >
                  <p
                    title="Copy to clipboard?"
                    style={{
                      fontWeight: FONT_TYPE_MAPPER[couponCodeNotification?.fontType?.value],
                      fontSize: FONT_SIZE_MAPPER[couponCodeNotification?.fontSize?.value],
                      color: couponCodeNotification?.textColor?.value,
                      backgroundColor: couponCodeNotification?.backgroundColor?.value,
                      padding: '4px',
                      textAlign: 'center',
                      marginBottom: counterStatus?'':'10px',
                      border: `dashed 3px ${couponCodeNotification?.textColor?.value || '#000000'}`,
                      padding: '5px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      position: 'relative',
                    }}
                  >
                    <SissorIcon
                      height={'25px'}
                      width={'25px'}
                      style={{
                        marginLeft: '-18px',
                        color: couponCodeNotification?.textColor?.value || '#000000',
                        position: 'absolute',
                      }}
                    />
                    {couponCodeNotificationCode}
                  </p></CopyToClipboard>}
                {copiedSuccess && <p
                  style={{
                    fontWeight: FONT_TYPE_MAPPER[couponCodeNotification?.fontType?.value],
                    fontSize: FONT_SIZE_MAPPER[couponCodeNotification?.fontSize?.value],
                    color: couponCodeNotification?.textColor?.value,
                    backgroundColor: couponCodeNotification?.backgroundColor?.value,
                    padding: '4px',
                    textAlign: 'center',
                    marginBottom: counterStatus?'':'10px',
                    border: `dashed 3px ${couponCodeNotification?.textColor?.value || '#000000'}`,
                    borderRadius: '5px',
                    cursor: 'pointer',
                    position: 'relative',
                  }}
                >
                  <SissorIcon
                    height={'25px'}
                    width={'25px'}
                    style={{
                      marginLeft: '-18px',
                      color: couponCodeNotification?.textColor?.value || '#000000',
                      position: 'absolute',
                    }}
                  />
                  Copied!!!
                </p>}
              </>}

              {/* Button config for success message */}
              <>
                {ctaButtonNotificationEnable && <div style={{display: 'flex', justifyContent: 'center'}}>
                  <button
                    style={{
                      padding: '8px 20px',
                      backgroundColor: ctaButtonNotificationConfig?.actionButtonColor?.value || 'purple',
                      borderRadius: '5px',
                      marginTop: '10px',
                      color: '#fff',
                    }}
                    onClick={()=>{
                      if (ctaButtonNotificationLink) {
                        window.open(
                            ctaButtonNotificationLink,
                            '_blank',
                        );
                      }
                    }}
                    type={'button'}
                  >{ctaButtonNotificationName || 'Redirect'}</button>
                </div>}
              </>

            </div>
          </div>}
          {toggleStatusMessageFail && <div style={{left: mediaDetails?.type !== 'NM'?'50%':'', zIndex: 100, width: '500px', height: '400px'}}>
            <div style={{backgroundColor: entryFailConfig?.backgroundColor?.value || '#f43f5d', padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', wordBreak: 'break-word'}}>
              <h3 style={{'color': entryFailConfig?.textColor?.value || '#ffff', 'fontWeight': 'bold', 'marginBottom': '1rem', 'marginTop': '1rem'}}>{entryPopupFailText || 'Try again later'}</h3>
            </div>
          </div>}
        </div>
      </div>
    </>
  );
};

PopupSurveyWidgetComponent.propTypes = {
  mode: PropTypes.string,
  popupSize: PropTypes.string,
  headingText: PropTypes.string,
  headingConfig: PropTypes.any,
  subHeadingText: PropTypes.string,
  subHeadingConfig: PropTypes.any,
  mediaDetails: PropTypes.any,
  counterStatus: PropTypes.bool,
  counterConfig: PropTypes.any,
  mediaPosition: PropTypes.string,
  actionButtonName: PropTypes.string,
  actionButtonConfig: PropTypes.any,
  actionType: PropTypes.string,
  fieldConfig: PropTypes.any,
  displayPreference: PropTypes.string,
  displayPreferenceConfig: PropTypes.any,
  pages: PropTypes.string,
  popupPosition: PropTypes.string,
  surveyQuestionSettings: PropTypes.any,
  surveyQuestionAnswerType: PropTypes.string,
  branding: PropTypes.string,
  couponCodeNotificationCode: PropTypes.string,
  couponCodeNotification: PropTypes.any,
  couponDisplayNotification: PropTypes.bool,
  entrySuccessConfig: PropTypes.any,
  entryPopupSuccessText: PropTypes.string,
  entryFailConfig: PropTypes.any,
  entryPopupFailText: PropTypes.string,
  toggleStatusMessageSuccess: PropTypes.bool,
  toggleStatusMessageFail: PropTypes.bool,
  ctaButtonNotificationEnable: PropTypes.bool,
  ctaButtonNotificationName: PropTypes.string,
  ctaButtonNotificationLink: PropTypes.string,
  ctaButtonNotificationConfig: PropTypes.any,
  logoDetails: PropTypes.any,
};

export default PopupSurveyWidgetComponent;
