import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {getAllVideoAction, getAllVideoClear, storeVideoAction, videoStoreClear} from '../../../actions/videoUpload.action';
import Button from '../../../components/button/button.component';
import Heading from '../../../components/heading';
import Input from '../../../components/input/input.component';
import MediaRenderer from '../../../components/mediaRenderer';
import InfoWrapper from '../../../components/wrappers/infoWrapper.component';
import {ALLOWED_MEDIA_TYPES, BASE_URL, MAX_FILE_SIZE, MEDIA_FILE_TYPE_MAPPER} from '../../../constants/application.const';
import {VIDEO_LIST_SUCCESS, VIDEO_UPLOAD_FAILURE, VIDEO_UPLOAD_SUCCESS} from '../../../constants/reducer.const';
import {MEDIA_TYPE_MAPPER} from '../../../constants/widget.const';
import BackIcon from '../../../icons/back.icon';
import UploadIcon from '../../../icons/upload.icon';
import MediaLoader from '../../../loaders/media.loader';
import UploadLoader from '../../mediaLibrary/components/uploadLoader.component';
import UploadInstructionsModal from '../../mediaLibrary/modals/uploadInstructions.modal';

/**
 * Media selection component
 *
 * @return {JSX}
 */
const MediaSelectionComponent = ()=>{
  const routerParams = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const videoLibrarySelector = useSelector((state)=>state.videoLibrary);
  const [videoList, setVideoList] = useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const [uploadInstrcutionsModal, setUploadInstrcutionsModal] = useState(false);
  const [videoBasePath, setVideoBasePath] = useState('');
  const [searchText, setSearchText] = useState('');
  const [uploadLoader, setUploadLoader] = useState(false);
  const inputFileRef = useRef();
  const [searchParams] = useSearchParams();
  const [warningWindowStatus, setWarningWindowStatus]= useState({
    warning1: true,
    warning2: true,
  });

  const changeWarningStatus = (warningType)=>{
    const temp = {...warningWindowStatus};
    temp[warningType] = false;
    setWarningWindowStatus({...temp});
  };

  const getAllVideos = useCallback((keyword = searchText)=>{
    setPageLoader(true);
    if (routerParams?.type === MEDIA_TYPE_MAPPER.U.value) {
      setUploadInstrcutionsModal(true);
    }
    dispatch(getAllVideoAction({
      type: (routerParams?.type !== MEDIA_TYPE_MAPPER.U.value)?routerParams?.type:'',
      keyword,
    }));
  }, [routerParams?.type]);

  // on File change capture
  const onFileChangeCapture = (event)=>{
    if (!event.target.files) return;
    const currentFile = event.target.files[0];
    if (ALLOWED_MEDIA_TYPES.indexOf(currentFile?.type) === -1) {
      return toast('Only videos, images and gifs are allowed', {
        type: 'error',
      });
    }
    if (currentFile?.size > MAX_FILE_SIZE * 1024 * 1024) {
      return toast('Maximum 35mb allowed', {
        type: 'error',
      });
    }

    const formData = new FormData();
    formData.append('video', currentFile);
    formData.append('type', MEDIA_FILE_TYPE_MAPPER[currentFile?.type]);
    // Upload video to server
    setUploadLoader(true);
    dispatch(storeVideoAction(formData));

    // clearing field after the file is uploaded
    event.target.value=null;
  };

  useEffect(()=>{
    getAllVideos();
  }, []);

  useEffect(()=>{
    if (videoLibrarySelector.type === VIDEO_LIST_SUCCESS) {
      setVideoBasePath(videoLibrarySelector?.payload?.misc?.videoBasePath);
      dispatch(getAllVideoClear());
      setPageLoader(false);
      setVideoList([...videoLibrarySelector?.payload?.data]);
    }
    if (videoLibrarySelector.type === VIDEO_UPLOAD_SUCCESS) {
      setUploadLoader(false);
      dispatch(videoStoreClear());
      getAllVideos();
      toast(videoLibrarySelector?.payload?.message, {
        type: 'success',
      });
    }
    if (videoLibrarySelector.type === VIDEO_UPLOAD_FAILURE) {
      setUploadLoader(false);
      dispatch(videoStoreClear());
      toast(videoLibrarySelector?.payload?.message, {
        type: 'error',
      });
    }
  }, [videoLibrarySelector]);

  const InputComponentLocal = ()=>(
    <Input
      type={'text'}
      placeholder={'search media'}
      searchEnable={true}
      className={'w-80'}
      disabled={pageLoader}
      autoFocus={true}
      defaultValue={searchText}
      onChange={(event)=>{
        setSearchText(event.target.value);
        getAllVideos(event.target.value);
      }}
    />
  );

  return (
    <div className='bg-slate-200 h-screen px-40'>
      <div className='bg-white h-screen'>
        <nav className='py-4 px-2'>
          <Button
            classOverride={'bg-transparent border-0 flex items-center text-sm'}
            buttonIcon={<BackIcon height={'15'} width={'15'}/>}
            onClick={()=>navigate(-1)}
          >
            Back
          </Button>
        </nav>
        <div id="body-container" className={'mt-4 w-full text-center'}>
          <Heading className={'text-xl font-semibold'}>Select a {MEDIA_TYPE_MAPPER[routerParams?.type]?.name} to continue</Heading>
          {warningWindowStatus?.warning1 && <InfoWrapper
            closeButtonEnable={true}
            onClose={()=>{
              changeWarningStatus('warning1');
            }}
            additionalClass={'bg-amber-200 mt-2 mx-4'}
            additionalClassInner={'text-grey-800'}
            description={`Kindly use only png, gifs and mp4 files and do not exceed the limit of 35mb per media as exceeding the limit it may affect the loading speed of your page`}
          />}
          {warningWindowStatus?.warning2 && <InfoWrapper
            closeButtonEnable={true}
            onClose={()=>{
              changeWarningStatus('warning2');
            }}
            additionalClass={'bg-amber-200 mt-2 mx-4'}
            additionalClassInner={'text-grey-800'}
            description={<p>compress your media before uploading or click here <a href="https://cloudconvert.com/" className="text-primary" rel='nofollow'>https://cloudconvert.com/</a>  for quick compress</p>}
          />}
          <div id="media-list-container" className='px-8 mt-4'>
            <div className='flex items-center justify-between my-4'>
              <InputComponentLocal />
              <Button
                buttonText='Upload'
                onClick={()=>{
                  setUploadInstrcutionsModal(true);
                }}
                buttonIcon={<UploadIcon/>}
              />
              <input type="file" accept={MEDIA_TYPE_MAPPER[routerParams?.type]?.fileType} className="hidden" ref={inputFileRef} onChange={onFileChangeCapture}/>
            </div>
            {pageLoader ? <MediaLoader />:
            <div id="media-list-container" className='flex flex-row justify-center flex-wrap w-full overflow-y-auto' style={{height: 'calc(100vh - 360px)'}}>
              {videoList?.map((mediaData, index)=>(
                <MediaRenderer
                  key={index}
                  type={mediaData?.type}
                  src={`${BASE_URL}${videoBasePath}${mediaData?.fileName}`}
                  additionalClass={'mr-2 mt-2'}
                  selectionButton={{
                    buttonName: 'Use',
                  }}
                  heading={mediaData?.originalFileName}
                  videoDetails={{...mediaData}}
                  uploadModule={searchParams.get('uploadModule')}
                />
              ))}
            </div>}
          </div>
        </div>
      </div>
      {uploadLoader && <UploadLoader />}
      {uploadInstrcutionsModal && <UploadInstructionsModal
        onClose={()=>{
          setUploadInstrcutionsModal(false);
        }}
        onOk={()=>{
          inputFileRef.current.click();
          setUploadInstrcutionsModal(false);
        }}
      />}
    </div>
  );
};

export default MediaSelectionComponent;
