import React from 'react';
import PropTypes from 'prop-types';

/**
 * Integration icon
 *
 * @return {JSX}
 */
function IntegrationIcon({color, height, width, ...props}) {
  return (
    <svg width={width || '24px'} height={height || '24px'} viewBox="0 0 50 50" fill={color || 'currentColor'} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M21.875 9.37501C22.4584 9.37501 22.9167 9.83334 22.9167 10.4167V14.5833H35.4167V27.0833H39.5834C40.1667 27.0833 40.625 27.5417 40.625 28.125C40.625 28.7083 40.1667 29.1667 39.5834 29.1667H35.4167V41.6667H31C29.5834 38.0208 26.0209 35.4167 21.875 35.4167C17.7292 35.4167 14.1667 38.0208 12.75 41.6667H8.33335V37.25C11.9792 35.8333 14.5834 32.2708 14.5834 28.125C14.5834 23.9792 12 20.4167 8.35419 19L8.33335 14.5833H20.8334V10.4167C20.8334 9.83334 21.2917 9.37501 21.875 9.37501ZM21.875 5.20834C19 5.20834 16.6667 7.54168 16.6667 10.4167H8.33335C6.04169 10.4167 4.18752 12.2917 4.18752 14.5833V22.5H4.79169C7.89585 22.5 10.4167 25.0208 10.4167 28.125C10.4167 31.2292 7.89585 33.75 4.79169 33.75H4.16669V41.6667C4.16669 43.9583 6.04169 45.8333 8.33335 45.8333H16.25V45.2083C16.25 42.1042 18.7709 39.5833 21.875 39.5833C24.9792 39.5833 27.5 42.1042 27.5 45.2083V45.8333H35.4167C37.7084 45.8333 39.5834 43.9583 39.5834 41.6667V33.3333C42.4584 33.3333 44.7917 31 44.7917 28.125C44.7917 25.25 42.4584 22.9167 39.5834 22.9167V14.5833C39.5834 12.2917 37.7084 10.4167 35.4167 10.4167H27.0834C27.0834 7.54168 24.75 5.20834 21.875 5.20834Z" fill="currentColor"/>
    </svg>

  );
}

IntegrationIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default IntegrationIcon;
