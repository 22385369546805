import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import SurveyDoughnutChart from '../../../../components/charts/surveyDoughnutChart.component';
import Heading from '../../../../components/heading';
import {useDispatch, useSelector} from 'react-redux';
import {getSurveyStatsAction, getSurveyStatsClear} from '../../../../actions/customers.action';
import {GET_SURVEY_STATS_FAILED, GET_SURVEY_STATS_SUCCESS} from '../../../../constants/reducer.const';
import {downloadFile, generateRandomColor, limitTextForDisplay} from '../../../../helpers/common.helper';
import ReactTooltip from 'react-tooltip';
import {toast} from 'react-toastify';
import Button from '../../../../components/button/button.component';
import ExportIcon from '../../../../icons/export.icon';
import {getSurveyForExport} from '../../../../services/customer.service';

/**
 * Survey Stats component
 *
 * @return {JSX}
 */
const SurveyStats = ({widgetId})=>{
  const dispatch = useDispatch();
  const customersStore = useSelector((state) => state.customer);
  const [graphData, setGraphData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [contactsList, setContactsList] = useState([]);
  const [customerLoader, setCustomerLoader] = useState(false);

  // Get survey stats
  const getSurveyStats = ()=>{
    setCustomerLoader(true);
    dispatch(getSurveyStatsAction({
      widgetId,
    }));
  };

  // Initial call
  useEffect(()=>{
    getSurveyStats();
  }, []);

  // Export survey
  const exportSurvey = async ()=>{
    const rawData = await getSurveyForExport({widgetId});
    downloadFile(rawData, 'text/csv', 'survey_stats.csv');
  };

  // Assign Graph data
  const assignGraphData = (payload)=>{
    const listDataArray = [];
    Object.keys(payload)?.forEach((listDataKey)=>{
      const listData = payload[listDataKey];
      const colorArray = listData?.data?[...listData?.data?.map(()=>generateRandomColor())]:[];
      const tempData = {
        labels: listData?.labels,
        datasets: [
          {
            label: '# of Votes',
            data: listData?.data,
            backgroundColor: colorArray,
            borderColor: colorArray,
            borderWidth: 1,
          },
        ],
        miscData: listData,
      };
      listDataArray.push(tempData);
    });
    setGraphData(listDataArray);
  };

  // Initial call
  useEffect(()=>{
    if (customersStore?.surveyStats?.type === GET_SURVEY_STATS_SUCCESS) {
      assignGraphData(customersStore?.surveyStats?.payload?.data?.chartData);
      setColumns(customersStore?.surveyStats?.payload?.data?.listData?.columns);
      setContactsList(customersStore?.surveyStats?.payload?.data?.listData?.list);
      dispatch(getSurveyStatsClear());
      setCustomerLoader(false);
    }
    if (customersStore?.surveyStats?.type === GET_SURVEY_STATS_FAILED) {
      setCustomerLoader(false);
      toast(customersStore?.surveyStats?.payload?.message, {
        type: 'error',
      });
    }
  }, [customersStore?.surveyStats]);

  return (
    <div>
      <Heading className='font-semibold'>Question Stats</Heading>
      <div className='flex flex-wrap mt-2 justify-center'>
        {
          graphData?.map((data, key)=>(
            <div className='w-48 h-48' key={key}>
              <SurveyDoughnutChart
                data={data}
              />
              <div className='text-sm text-center mt-2 break-word'
                data-tip
                data-for={`question-text-container-${key}`}
              >
                {limitTextForDisplay(data?.miscData?.questionText)}
                <ReactTooltip
                  id={`question-text-container-${key}`}
                  place="bottom"
                  effect="solid"
                >
                  <span>{data?.miscData?.questionText}</span>
                </ReactTooltip>
              </div>
            </div>
          ))
        }
      </div>
      <div className='flex justify-end items-center'>
        <div className='flex mt-4 justify-end'>
          <Button
            buttonIcon={<ExportIcon />}
            buttonText='Export'
            type={'button'}
            onClick={()=>exportSurvey()}
            classOverride={`flex items-center justify-center px-3 py-2 text-sm font-medium text-white duration-150 bg-blue-600 border border-transparent rounded-lg hover:bg-blue-700 active:bg-blue-500 focus:outline-none focus:shadow-outline-blue`}
          />
        </div>
      </div>
      <div className="sm:-mx-3 lg:-mx-4 mt-12 overflow-x-hidden">
        <div className="inline-block min-w-full sm:px-3 lg:px-4">
          <div className="overflow-hidden">
            <table className="border-collapse min-w-full rounded-md">
              <thead className="rounded-md">
                <tr className='text-xs font-semibold tracking-wide text-left text-gray-500 uppercase ddark:border-gray-700 bg-gray-50 ddark:text-gray-400 ddark:bg-gray-800 rounded-md border-b mb-4'>
                  <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize">Customer Identity(Total entries - {contactsList?.length})</th>
                  {
                    Object.values(columns)?.map((column, index)=>(
                      <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize" key={index}
                        data-tip
                        data-for={`question-list-text-container-${index}`}
                      >
                        {limitTextForDisplay(column)}
                        <ReactTooltip
                          id={`question-list-text-container-${index}`}
                          place="bottom"
                          effect="solid"
                        >
                          <span>{column}</span>
                        </ReactTooltip>
                      </th>
                    ))
                  }
                </tr>
              </thead>
              {!customerLoader && <tbody>
                {contactsList?.map((data, index) => (
                  <tr key={index}>
                    <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap">{data?.fieldOne || data?.fieldTwo || 'Anonymous'}</td>
                    {
                      Object.keys(columns)?.map((column, index)=>(
                        <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap" key={index}>{(data?.surveyDataWithValues?data?.surveyDataWithValues[column]?.join(','):'---') || '---'}</td>
                      ))
                    }
                  </tr>
                ))}
              </tbody>}
              {customerLoader && <tbody>
                <tr>
                  <td colSpan={6}>
                    <div className="p-4 w-full mx-auto">
                      <div className="animate-pulse flex space-x-4">
                        <div className="flex-1 space-y-6 py-1">
                          <div className="h-2 bg-slate-200 rounded"></div>
                          <div className="h-2 bg-slate-200 rounded"></div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>}
              {!customerLoader && !contactsList?.length && (
                <tbody>
                  <tr>
                    <td colSpan={5} className="text-center text-gray-500">
                      <div className='mt-4'>Leads from your widget will be displayed here</div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

SurveyStats.propTypes = {
  widgetId: PropTypes.string,
};

export default SurveyStats;
