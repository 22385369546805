import React, {useEffect, useState} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripeCheckoutForm from '../../components/stripeCheckoutForm';
import {STRIPE_PUBLISHABLE_KEY} from '../../constants/application.const';
import {useParams} from 'react-router-dom';
import LoaderIcon from '../../icons/loader.icon';
import Heading from '../../components/heading';
import SiteLogo from '../../assets/images/siteLogo.jpeg';
import LockIcon from '../../icons/lock.icon';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

/**
 * Payment checkout page
 *
 * @return {JSX}
 */
const PaymentCheckout = ()=>{
  const routerParams = useParams();
  const [clientSecretLocal, setClientSecretLocal] = useState('');

  useEffect(()=>{
    if (routerParams?.clientSecret) {
      setClientSecretLocal(routerParams?.clientSecret);
    }
  }, [routerParams?.clientSecret]);

  return (
    <div className='flex items-center justify-center h-full flex-col'>
      <img
        src={SiteLogo}
        height='100px'
        width='100px'
        className='rounded-lg'
      />
      <Heading
        className={'font-semibold text-xl my-2 flex items-center'}
      >
        <LockIcon
          height={'25px'}
          width={'25px'}
          className={'mr-2'}
        />
        Pay to continue
      </Heading>
      {clientSecretLocal && <Elements stripe={stripePromise} options={{
        clientSecret: clientSecretLocal,
      }}>
        <StripeCheckoutForm />
      </Elements>}
      {!clientSecretLocal && <LoaderIcon />}
    </div>
  );
};

export default PaymentCheckout;
