import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Button from '../../components/button/button.component';
import InputComponent from '../../components/input/input.component';
import {useForm} from 'react-hook-form';
import {BETA_KEY, MAX_PASSWORD_FIELD_LENGTH, MAX_TEXT_FIELD_LENGTH} from '../../constants/application.const';
import ErrorText from '../../components/errorComponents/errorText.component';
import {registerSchema} from '../../validationSchemas/auth.validation';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';
import {clearUserAction, registerUserAction} from '../../actions/users.actions';
import {GET_PLAN_SUCCESS, INITIATE_PAYMENT_STRIPE_SUCCESS, USER_REGISTER_FAILURE, USER_REGISTER_SUCCESS} from '../../constants/reducer.const';
import {toast} from 'react-toastify';
import {getPlanDetailsAction} from '../../actions/plans.actions';
import LoginLogo from '../../assets/images/widget-image.png';

/**
 * Register page
 *
 * @return {JSX}
 */
function RegisterPage() {
  const routerParams = useParams();
  const {register, handleSubmit, formState: {errors}} = useForm({
    resolver: yupResolver(registerSchema),
  });
  const dispatch = useDispatch();
  const selector = useSelector((state)=>state?.user);
  const planSelector = useSelector((state)=>state?.plan);
  const navigate = useNavigate();
  const [registerLoader, setRegisterLoader] = useState(false);
  const [currentPlanDetails, setCurrentPlanDetails] = useState({});

  // Register submit function
  const registerSubmit = (value)=>{
    setRegisterLoader(true);
    dispatch(registerUserAction({
      ...value,
      plan: routerParams?.planId || BETA_KEY,
    }));
  };

  // call for plan details
  useEffect(()=>{
    if (routerParams?.planId) {
      dispatch(getPlanDetailsAction({
        planId: routerParams?.planId,
      }));
    }
  }, [routerParams?.planId]);

  // Navigate to payment
  const navigateToPayment = (data)=>{
    // navigate(`/payment-process/M/${data?.client_secret}`);
    // Handled for beta
    if (routerParams?.type === 'SPY') {
      navigate(-1);
    } else {
      navigate(`/`);
    }
  };

  useEffect(()=>{
    if (selector.type === USER_REGISTER_SUCCESS) {
      setRegisterLoader(false);
      dispatch(clearUserAction());
      toast(selector?.payload?.message, {
        type: 'success',
      });
      // setValue(REGISTERED_USER_ID, selector?.payload?.data?.id);
      // dispatch(initiatePaymentAction({
      //   planId: routerParams?.planId,
      //   email: selector?.payload?.misc?.email,
      // }));
      navigateToPayment();
    }
    if (selector.type === USER_REGISTER_FAILURE) {
      setRegisterLoader(false);
      dispatch(clearUserAction());
      toast(selector?.payload?.message, {
        type: 'error',
      });
    }
    if (planSelector?.planDetails?.type === GET_PLAN_SUCCESS) {
      setCurrentPlanDetails(planSelector?.planDetails?.payload?.data);
    }
    if (planSelector?.paymentInitResponse?.type === INITIATE_PAYMENT_STRIPE_SUCCESS) {
      navigateToPayment(planSelector?.paymentInitResponse?.payload?.data);
    }
  }, [selector, planSelector?.planDetails, planSelector?.paymentInitResponse]);

  return (
    <div className="flex justify-center items-center h-screen bg-gray-50 py-2">
      <div
        className="w-7/12 h-full overflow-y-autoo bg-white rounded-lg grid grid-cols-12"
      >
        <div className='col-span-5 flex justify-center flex-col relative '>
          <img src={LoginLogo} alt="popsuite" className='-ml-16 max-w-none' style={{width: '24rem'}}/>
          <div className='flex justify-center text-2xl font-bold text-primary w-60 ml-5'>Be the face of your business</div>
        </div>
        <div className='col-span-7 bg-primary flex items-center px-8'>
          <div className={`w-full h-full flex justify-between flex-col py-12`}>
            <form className="w-full" onSubmit={handleSubmit(registerSubmit)} noValidate>
              <h1
                className="mb-4 text-xl font-semibold text-white "
              >
              Register
              </h1>
              <label className="block text-sm">
                <span className="text-white ddark:text-gray-400">Name</span>
                <InputComponent
                  name="name"
                  placeholder={'Enter name'}
                  type={'text'}
                  {...register('name')}
                  maxLength={MAX_TEXT_FIELD_LENGTH}
                />
                <ErrorText displayStatus={true}>{errors.name?.message}</ErrorText>
              </label>
              <label className="block mt-4 text-sm">
                <span className="text-white ddark:text-gray-400">Company Name</span>
                <InputComponent
                  name="company"
                  placeholder={'Enter company name'}
                  type={'text'}
                  {...register('company')}
                  maxLength={MAX_TEXT_FIELD_LENGTH}
                />
                <ErrorText displayStatus={true}>{errors.company?.message}</ErrorText>
              </label>
              <label className="block mt-4 text-sm">
                <span className="text-white ddark:text-gray-400">Email</span>
                <InputComponent
                  name="email"
                  placeholder={'Enter email'}
                  type={'email'}
                  {...register('email')}
                />
                <ErrorText displayStatus={true}>{errors.email?.message}</ErrorText>
              </label>
              <label className="block mt-4 text-sm">
                <span className="text-white ddark:text-gray-400">Password</span>
                <InputComponent
                  name="password"
                  placeholder={'Enter Password'}
                  type={'password'}
                  {...register('password')}
                  maxLength={MAX_PASSWORD_FIELD_LENGTH}
                />
                <ErrorText displayStatus={true}>{errors.password?.message}</ErrorText>
              </label>
              <label className="block mt-4 text-sm">
                <span className="text-white ddark:text-gray-400">Confirm Password</span>
                <InputComponent
                  placeholder={'Confirm Password'}
                  type={'password'}
                  name="cpassword"
                  {...register('cpassword')}
                  maxLength={MAX_PASSWORD_FIELD_LENGTH}
                />
                <ErrorText displayStatus={true}>{errors.cpassword?.message}</ErrorText>
              </label>
              {/* Form type field(hidden) */}
              <input
                type="hidden"
                name="registerFormType"
                {...register('registerFormType')}
                defaultValue={routerParams?.type}
              />
              {routerParams?.type === 'SZ' && <label className="block mt-4 text-sm">
                <span className="text-white ddark:text-gray-400">Redempion code</span>
                <InputComponent
                  placeholder={'Enter Redempion code'}
                  type={'text'}
                  name="saaszillacode"
                  {...register('saaszillacode')}
                />
                <ErrorText displayStatus={true}>{errors.saaszillacode?.message}</ErrorText>
              </label>}
              {routerParams?.planId && <label className="block mt-4 text-sm">
                <span className="text-white ddark:text-gray-400">Current Plan</span>
                <InputComponent
                  type={'text'}
                  name="planName"
                  disabled={true}
                  defaultValue={currentPlanDetails?.name}
                />
              </label>}
              <Button
                loader={registerLoader}
                buttonText='Register'
                classOverride={`flex items-center justify-center px-3 py-2 text-sm font-medium text-primary duration-150 bg-white border border-transparent rounded-lg hover:bg-white active:bg-white focus:outline-none focus:shadow-outline-purple w-full mt-4`}
                type={'submit'}
                disabled={registerLoader}
              />
            </form>
            <div className='flex justify-center flex-col items-center'>
              <p className="mt-1">
                <Link
                  className="text-sm font-medium text-white hover:underline"
                  to="/"
                >
                Already have an account? Login
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
