import axios from 'axios';
import {BASE_URL} from '../constants/application.const';

/**
 * Register users
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const registerUser = (payload)=>{
  return axios.post(`${BASE_URL}users/register`, payload)
      .then((response) => (response?.data));
};

/**
 * Login users
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const loginUser = (payload)=>{
  return axios.post(`${BASE_URL}auth/login`, payload)
      .then((response) => (response?.data));
};

/**
 * Get all users admin
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const getAllUsersAdmin = ()=>{
  return axios.get(`${BASE_URL}admin/get-all-users`)
      .then((response) => (response?.data));
};

/**
 * Users upate live status
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const updateUserStatus = (payload)=>{
  return axios.put(`${BASE_URL}admin/update-user-status`, payload)
      .then((response) => (response?.data));
};

/**
 * Update profile
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const updateProfile = (payload)=>{
  return axios.post(`${BASE_URL}users/update-profile`, payload)
      .then((response) => (response?.data));
};

/**
 * Update profile password
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const updateProfilePassword = (payload)=>{
  return axios.post(`${BASE_URL}users/update-profile-password`, payload)
      .then((response) => (response?.data));
};

/**
 * Forgot password request
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const forgotPasswordRequest = (payload)=>{
  return axios.post(`${BASE_URL}users/forgot-password`, payload)
      .then((response) => (response?.data));
};

/**
 * update password
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const updatePasswordApi = (payload)=>{
  return axios.post(`${BASE_URL}users/update-password`, payload)
      .then((response) => (response?.data));
};

/**
 * get login user details
 *
 * @return {Promise<any>}
 */
export const getLoginUserDetails = ()=>{
  return axios.get(`${BASE_URL}users/get-user-detail`)
      .then((response) => (response?.data));
};


