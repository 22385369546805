import {
  VIDEO_UPLOAD_CLEAR_REQUEST,
  VIDEO_UPLOAD_FAILURE,
  VIDEO_UPLOAD_SUCCESS,
  VIDEO_LIST_SUCCESS,
  VIDEO_LIST_FAILURE,
  VIDEO_LIST_CLEAR_REQUEST,
  VIDEO_DELETE_SUCCESS,
  VIDEO_DELETE_FAILURE,
  VIDEO_DELETE_CLEAR_REQUEST,
  VIDEO_UPDATE_SUCCESS,
  VIDEO_UPDATE_FAILURE,
  VIDEO_UPDATE_CLEAR_REQUEST,
} from '../constants/reducer.const';

const initialState = {};

/**
 * Video library settings reducer function
 *
 * @param {any} state
 * @param {any} action
 * @return {any}
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case VIDEO_UPLOAD_SUCCESS: return {...state, ...action};
    case VIDEO_UPLOAD_FAILURE: return {...state, ...action};
    case VIDEO_UPLOAD_CLEAR_REQUEST: return {};
    case VIDEO_LIST_SUCCESS: return {...state, ...action};
    case VIDEO_LIST_FAILURE: return {...state, ...action};
    case VIDEO_LIST_CLEAR_REQUEST: return {};
    case VIDEO_DELETE_SUCCESS: return {...state, ...action};
    case VIDEO_DELETE_FAILURE: return {...state, ...action};
    case VIDEO_DELETE_CLEAR_REQUEST: return {};
    case VIDEO_UPDATE_SUCCESS: return {...state, ...action};
    case VIDEO_UPDATE_FAILURE: return {...state, ...action};
    case VIDEO_UPDATE_CLEAR_REQUEST: return {};
    default: return {...state};
  }
}
