import * as yup from 'yup';
import {EMAIL_PATTERN, MAX_PASSWORD_FIELD_LENGTH, MAX_TEXT_FIELD_LENGTH} from '../constants/application.const';

// Register page validation schema
export const registerSchema = yup.object({
  name: yup.string().required('Name required').max(MAX_TEXT_FIELD_LENGTH, `Maximum ${MAX_TEXT_FIELD_LENGTH} allowed`),
  company: yup.string().required('Company Name required').max(MAX_TEXT_FIELD_LENGTH, `Maximum ${MAX_TEXT_FIELD_LENGTH} allowed`),
  email: yup.string().required('Email required').matches(EMAIL_PATTERN, 'Invalid email'),
  password: yup.string().required('Password required').max(MAX_PASSWORD_FIELD_LENGTH, `Maximum ${MAX_PASSWORD_FIELD_LENGTH} allowed`),
  cpassword: yup.string().required('Confirm password required').oneOf([yup.ref('password')], 'Passwords does not match'),
  registerFormType: yup.string(),
  saaszillacode: yup.string().when('registerFormType', {
    is: 'SZ',
    then: yup.string().required('Redemption code required'),
  }),
}).required();

// login page validation schema
export const loginSchema = yup.object({
  username: yup.string().required('Email required').matches(EMAIL_PATTERN, 'Invalid email'),
  password: yup.string().required('Password required'),
  loginFormType: yup.string(),
  storeEmail: yup.string().when('loginFormType', {
    is: 'SPY',
    then: yup.string().required('Store Email Required').matches(EMAIL_PATTERN, 'Invalid email'),
  }),
}).required();

// forgot password page validation schema
export const forgotPasswordSchema = yup.object({
  password: yup.string().required('Password required').max(MAX_PASSWORD_FIELD_LENGTH, `Maximum ${MAX_PASSWORD_FIELD_LENGTH} allowed`),
  cpassword: yup.string().required('Confirm password required').oneOf([yup.ref('password')], 'Passwords does not match'),
}).required();

// get email schema validation schema
export const getEmailSchema = yup.object({
  email: yup.string().required('Email required').matches(EMAIL_PATTERN, 'Invalid email'),
}).required();

// Demo user schema for admin
export const demoUserSchema = yup.object({
  name: yup.string().required('Name required').max(MAX_TEXT_FIELD_LENGTH, `Maximum ${MAX_TEXT_FIELD_LENGTH} allowed`),
  company: yup.string().required('Company Name required').max(MAX_TEXT_FIELD_LENGTH, `Maximum ${MAX_TEXT_FIELD_LENGTH} allowed`),
  email: yup.string().required('Email required').matches(EMAIL_PATTERN, 'Invalid email'),
  password: yup.string().required('Password required').max(MAX_PASSWORD_FIELD_LENGTH, `Maximum ${MAX_PASSWORD_FIELD_LENGTH} allowed`),
  plan: yup.string().required('Plan required'),
}).required();
