import React from 'react';
import Heading from '../../../components/heading';
import CloseIcon from '../../../icons/close.icon';
import PropTypes from 'prop-types';
import Button from '../../../components/button/button.component';

/**
 * Plan migration modal
 *
 * @return {JSX}
 */
const PlanMigrationModal = ({onClose, contentDetails})=>{
  return (
    <>
      <div className="fixed inset-0 bg-stone-900 bg-opacity-75 transition-opacity z-50" aria-hidden="true"></div>
      <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white w-1/2 p-4 rounded-lg'>
        <Heading className='font-semibold text-lg flex justify-between  mt-4'>
          Change of Plan Notice
          <CloseIcon className='cursor-pointer w-4 h-4' onClick={()=>{
            onClose();
          }}/>
        </Heading>
        <div className='grid grid-cols-12 gap-4 my-4'>
          <p className="text-red-600 col-span-12 font-bold">
            Fix the below errors before changing the plan
          </p>
          <ul className="list-disc col-span-12 ml-4">
            {(contentDetails?.planMigrationError?.widgetLimit!==0) && <li className='text-gray-700'>
              The <span className="font-bold text-primary">{contentDetails?.name}</span> plan has a widget(s) limit of <span className="font-bold text-primary">{contentDetails?.modules?.P?.WIDGETS?.WIDGET_LIMIT}</span> only. You need to remove <span className="font-bold text-primary">{contentDetails?.planMigrationError?.widgetLimit}</span> widget(s) to change from current plan.
            </li>}
            {(contentDetails?.planMigrationError?.storageCap !==0) && <li className='text-gray-700'>
              The <span className="font-bold text-primary">{contentDetails?.name}</span> plan has a Media storage capacity of <span className="font-bold text-primary">{contentDetails?.storageCap}</span>mb only. You need to remove <span className="font-bold text-primary">{contentDetails?.planMigrationError?.storageCap}</span>mb of media to change from current plan.
            </li>}
          </ul>
        </div>
        <div className='flex mt-8 justify-end'>
          <Button onClick={()=>onClose()}>Ok</Button>
        </div>
      </div>
    </>
  );
};

PlanMigrationModal.propTypes = {
  onClose: PropTypes.func,
  contentDetails: PropTypes.any,
};

export default PlanMigrationModal;
