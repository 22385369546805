import * as yup from 'yup';
import {EMAIL_PATTERN, SHOPIFY_STORE_NAME_PATTERN} from '../constants/application.const';

// shopify integration details
export const shopifyIntegrationDetails = yup.object({
  storeName: yup.string().required('Store name required').matches(SHOPIFY_STORE_NAME_PATTERN, 'Invalid Store name'),
  storeEmail: yup.string().required('Store email required').matches(EMAIL_PATTERN, 'Invalid email'),
}).required();

// mailchimp integration details
export const mailchimpIntegrationDetails = yup.object({
  apiKey: yup.string().required('API key required'),
}).required();

// klaviyo integration details
export const KlaviyoIntegrationDetails = yup.object({
  apiKey: yup.string().required('API key required'),
}).required();

// klaviyo integration details
export const PabblyIntegrationDetails = yup.object({
  apiKey: yup.string().required('API key required'),
}).required();
