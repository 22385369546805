// popup component
export const POPUP_POSITION_MAPPER = {
  'L': {
    bottom: '5px',
    left: '5px',
  },
  'R': {
    bottom: '5px',
    right: '5px',
  },
};

export const FONT_TYPE_CONST = {
  T: 'T',
  B: 'B',
  EB: 'EB',
};

export const FONT_SIZE_CONST = {
  SM: 'SM',
  LG: 'LG',
  XL: 'XL',
};

// Font type mapper
export const FONT_TYPE_MAPPER = {
  [FONT_TYPE_CONST.T]: 'normal',
  [FONT_TYPE_CONST.B]: 'bold',
  [FONT_TYPE_CONST.EB]: 'bolder',
};

// Font size mapper
export const FONT_SIZE_MAPPER = {
  [FONT_SIZE_CONST.SM]: '18px',
  [FONT_SIZE_CONST.LG]: '24px',
  [FONT_SIZE_CONST.XL]: '30px',
};

// Popup size mapper
export const POPUP_SIZE_MAPPER = {
  'SM': '50%',
  'LG': '60%',
  'XL': '70%',
};

// alignment mapper
export const ALIGNMENT_MAPPER = {
  L: 'left',
  C: 'center',
  R: 'right',
};
