import React from 'react';
import InfoIcon from '../../icons/info.icon';
import PropTypes from 'prop-types';
import CloseIcon from '../../icons/close.icon';

/**
 * Info wrapper for widgets
 *
 * @return {JSX}
 */
const InfoWrapper = ({description, children, additionalClass, additionalClassInner, closeButtonEnable, onClose})=>{
  return (
    <div className={`border rounded-lg bg bg-slate-300 p-4 ${additionalClass}`}>
      <div className={`flex items-center ${additionalClassInner}`}>
        <InfoIcon
          height={'30px'}
          width={'30px'}
          className={'mr-4'}
        />
        {description && <p className='flex-2 text-sm font-bold'>{description}</p>}
        {closeButtonEnable && <CloseIcon className='cursor-pointer w-4 h-4' onClick={()=>{
          if (onClose) {
            onClose();
          }
        }}/>}
      </div>
      {children}
    </div>
  );
};

InfoWrapper.propTypes = {
  description: PropTypes.string,
  children: PropTypes.any,
  additionalClass: PropTypes.string,
  additionalClassInner: PropTypes.string,
  closeButtonEnable: PropTypes.bool,
  onClose: PropTypes.func,
};

export default InfoWrapper;

/**
 * Tailwind classes
 *
 * text-red-400
 * bg-red-300
 */
