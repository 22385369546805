import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {installShopifyAppClearAction, unInstallShopifyAppClearAction} from '../../../actions/integrations.actions';
import {clearDeleteWidgetAction, clearWidgetList, clearWidgetSettings, getAllWidgets, setSelectedWidget, updateWidgetLiveStatusClearAction} from '../../../actions/widget.actions';
import Button from '../../../components/button/button.component';
import Heading from '../../../components/heading';
import Input from '../../../components/input/input.component';
import {DELETE_WIDGET_FAILED, DELETE_WIDGET_SUCCESS, GET_WIDGET_SUCCESS, INSTALL_SHOPIFY_SUCCESS, UNINSTALL_SHOPIFY_SUCCESS, UPDATE_WIDGET_LIVE_STATUS_SUCCESS} from '../../../constants/reducer.const';
import Plus from '../../../icons/plus.icon';
import AnalyticsComponent from './components/analytics.component';
import CustomerListing from './components/customer.component';
import SurveyStats from './components/surveyStats.component';
import WidgetListCard from './components/widgetListCard.component';
import WidgetTopBar from './components/widgetTopBar.component';
import WelcomeModal from './modals/welcome.modal';
import {getValue, setValue} from '../../../config/localStorage.config';
import {USER_DETAILS} from '../../../constants/application.const';
/**
 * Widget home
 *
 * @return {JSX}
 */
function WidgetHome() {
  const widgetStore = useSelector((state)=>state.widget);
  const [widgetList, setWidgetList] = useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const [thumbnailBasePath, setThumbnailBasePath] = useState('');
  const [mediaBasePath, setMediaBasePath] = useState('');
  const [welcomeModal, setWelcomeModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(2);
  const [selectedWidgetDetails, setSelectedWidgetDetails] = useState({});
  const [selectedWidgetIndex, setSelectedWidgetIndex] = useState(0);
  const [widgetSearchKeyword, setWidgetSearchKeyword] = useState('');
  const integrationStore = useSelector((state)=>state.integration);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  // Widget loader
  const WidgetLoader = ()=>{
    return (
      <div className="border border-slate-200 rounded-md p-4 max-w-sm w-full mx-auto">
        <div className="animate-pulse flex space-x-4">
          <div className="bg-slate-200 h-10 w-10"></div>
          <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-slate-200 rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                <div className="h-2 bg-slate-200 rounded col-span-1"></div>
              </div>
              <div className="h-2 bg-slate-200 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Get user data from local storage
  const getLocalStorageUserData = ()=>{
    const userData = getValue(USER_DETAILS);
    if (userData) {
      const userDetailsTemp = JSON.parse(userData);

      if (userDetailsTemp['firstVisit']) {
        setWelcomeModal(true);
      }

      userDetailsTemp['firstVisit'] = false;
      setValue(USER_DETAILS, JSON.stringify(userDetailsTemp));
    }
  };

  // get all widgets local
  const getAllWidgetsLocal = (keyword = widgetSearchKeyword)=>{
    dispatch(getAllWidgets({
      keyword,
    }));
  };

  useEffect(()=>{
    setPageLoader(true);
    getAllWidgetsLocal();
    getLocalStorageUserData();
  }, []);

  useEffect(()=>{
    if (widgetStore?.widgetList?.type === GET_WIDGET_SUCCESS) {
      setWidgetList(widgetStore?.widgetList?.payload?.data);
      setSelectedWidgetDetails({...widgetStore?.widgetList?.payload?.data[selectedWidgetIndex]});
      dispatch(setSelectedWidget({...widgetStore?.widgetList?.payload?.data[selectedWidgetIndex]}));
      dispatch(clearWidgetList());
      setPageLoader(false);
      setThumbnailBasePath(widgetStore?.widgetList?.payload?.misc?.thumbnailBasepath);
      setMediaBasePath(widgetStore?.widgetList?.payload?.misc?.mediaBasePath);
    }
    if (widgetStore?.widgetDelete?.type === DELETE_WIDGET_SUCCESS) {
      dispatch(clearDeleteWidgetAction());
      toast(widgetStore?.widgetDelete?.payload?.message, {
        type: 'success',
      });
      dispatch(getAllWidgets());
    }

    if (widgetStore?.widgetDelete?.type === DELETE_WIDGET_FAILED) {
      dispatch(clearDeleteWidgetAction());
      toast(widgetStore?.widgetDelete?.payload?.message, {
        type: 'error',
      });
    }

    if (widgetStore?.widgetLiveStatus?.type === UPDATE_WIDGET_LIVE_STATUS_SUCCESS) {
      dispatch(updateWidgetLiveStatusClearAction());
      dispatch(getAllWidgets());
    }

    if (integrationStore?.shopifyInstallResponse?.type === INSTALL_SHOPIFY_SUCCESS) {
      dispatch(installShopifyAppClearAction());
      dispatch(getAllWidgets());
    }
    if (integrationStore?.shopifyUnInstallResponse?.type === UNINSTALL_SHOPIFY_SUCCESS) {
      dispatch(unInstallShopifyAppClearAction());
      dispatch(getAllWidgets());
    }
  }, [widgetStore?.widgetList, widgetStore?.widgetDelete, widgetStore?.widgetLiveStatus, integrationStore?.shopifyInstallResponse, integrationStore?.shopifyUnInstallResponse]);

  // Handle widget selection
  const onWidgetSelectChange = (index)=>{
    setSelectedWidgetIndex(index);
    setSelectedWidgetDetails(widgetStore?.widgetList?.payload?.data[index]);
    dispatch(setSelectedWidget({...widgetStore?.widgetList?.payload?.data[index]}));
  };

  return (
    <div className='bg-gray-50 grid grid-cols-4 gap-2 overflow-hidden'>
      <div className='col-span-1 px-4 pt-8 rounded-xl'>
        <Heading className={'font-semibold text-xl'}>Widgets</Heading>
        <Button className={'mt-6 w-full flex justify-between'} onClick={()=>{
          dispatch(clearWidgetSettings());
          // navigate('/widgets/create');
          location.href = '/widgets/create';
        }}>
          <span>Create Widget</span>
          <Plus className={'text-white'}/>
        </Button>
        <Input
          placeholder='Search widgets'
          type='text'
          className={'mt-4 mb-4'}
          searchEnable={true}
          onChange={(event)=>{
            getAllWidgetsLocal(event?.target?.value);
            setWidgetSearchKeyword(event?.target?.value);
          }}
        />
        <div id="widget-list-container" className='mb-4 overflow-y-auto pr-1' style={{height: '70vh'}}>
          {!pageLoader && widgetList?.map((data, index)=>(
            <WidgetListCard
              key={index}
              widgetName={data?.widgetName}
              thumbnailBasePath={thumbnailBasePath}
              mediaBasePath={mediaBasePath}
              widgetDetails={data}
              selected={selectedWidgetIndex === index}
              onWidgetSelect={()=>{
                onWidgetSelectChange(index);
              }}
            />
          ))}
          {pageLoader && <WidgetLoader />}
          {!pageLoader && !widgetList.length &&<div className='mt-4 text-gray-500 text-center'>Widgets created by you will appear here</div>}
        </div>
      </div>
      <div className='col-span-3 bg-white rounded-l-xl overflow-y-auto'>
        <WidgetTopBar
          onTabChange={(tab)=>setCurrentTab(tab)}
          widgetDetails={{...selectedWidgetDetails}}
          publishOpen={searchParams.get('publishOpen')}
        />
        <hr />
        <div className='px-6 pt-4 h-full'>
          {(currentTab===2) && <CustomerListing
            widgetId={selectedWidgetDetails?.id}
            widgetType={selectedWidgetDetails?.widgetSettings?.widgetType}
          />}
          {(currentTab===3) && <AnalyticsComponent
            widgetId={selectedWidgetDetails?.id}
          />}
          {(currentTab===4) && <SurveyStats
            widgetId={selectedWidgetDetails?.originalLinkId}
          />}
        </div>
      </div>
      {welcomeModal && <WelcomeModal
        close={()=>{
          setWelcomeModal(false);
        }}
      />}
    </div>
  );
}

export default WidgetHome;
