import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '../../icons/close.icon';
import Button from '../button/button.component';
import CopyIcon from '../../icons/copy.icon';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {WIDGET_URL} from '../../constants/application.const';
import Heading from '../heading';

/**
 * Copy widget deploy link modal
 *
 * @return {JSX}
 */
const CopyWidgetDeployLink = ({
  onClose,
  details,
  widgetType,
}) => {
  const [copied, setCopied] = useState(false);
  const [link, setlink] = useState('');

  useEffect(()=>{
    if (details) {
      setlink(`<script id="deployer_script" src="${WIDGET_URL[widgetType]}deployer.js?linkId=${details?.linkId}"></script>`);
    }
  }, [details, widgetType]);

  // Change copied status
  const changeCopiedStatus = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div
      className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
    >
      <div
        className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg sm:rounded-lg sm:m-4 sm:max-w-xl"
        id="modal"
      >
        <header className="flex justify-between">
          <Heading>Copy deploy link</Heading>
          <Button
            classOverride="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded hover: hover:text-gray-700"
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </Button>
        </header>
        <p className="text-gray-500 text-xs">Copy the below content and paste it in the sites body(In the case of embeded widget copy the div component and paste it in place you want to render the component)</p>
        <div className={`mt-4 p-2 mb-6 border-solid border-red border-2 rounded-md flex justify-between ${copied ? 'bg-lime-300' : ''}`}>
          {!copied && <><span>{widgetType === 'E' && <><p>
            {'<div id="deployer_container_embeded"></div>'}
          </p><br/></>}{link}</span>
          <CopyToClipboard className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded hover: hover:text-gray-700 cursor-pointer" text={`${widgetType === 'E'?'<div id="deployer_container_embeded"></div>':''}${link}`} onCopy={() => changeCopiedStatus()}>
            <CopyIcon color={'#c5c5c7'} className />
          </CopyToClipboard></>}
          {copied && <p>Copied</p>}
        </div>
        <footer
          className="flex flex-row items-center justify-end"
        >
          <Button
            classOverride={'flex-1 px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-2 active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple'}
            type="button"
            onClick={onClose}
          >
              Ok
          </Button>
        </footer>
      </div>
    </div>
  );
};

CopyWidgetDeployLink.propTypes = {
  onClose: PropTypes.func,
  details: PropTypes.any,
  widgetType: PropTypes.string,
};

export default CopyWidgetDeployLink;
