import React from 'react';
import PropTypes from 'prop-types';

/**
 * Enter icon
 *
 * @return {JSX}
 */
const Enter = ({height, width, ...props})=>{
  return (
    <svg width={width || '24'} height={height || '24'} {...props} viewBox="0 0 64 64" aria-labelledby="title"
      aria-describedby="desc" role="img" fill="currentColor">
      <title>Enter Key</title>
      <desc>A line styled icon from Orion Icon Library.</desc>
      <rect data-name="layer2"
        x="2" y="2" width="60" height="60" rx="7.8" ry="7.8" fill="none" stroke="#202020"
        strokeMiterlimit="10" strokeWidth="2"></rect>
      <path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
        strokeWidth="2" d="M16 32h30v-8"></path>
      <path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10"
        strokeWidth="2" d="M24 40l-8-8 8-8"></path>
    </svg>
  );
};

Enter.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Enter;
