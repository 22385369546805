import React from 'react';
import PropTypes from 'prop-types';

/**
 * Liwapper
 *
 * @return {JSX}
 */
const LiWrapper = ({children, active, ...props})=>{
  return (
    <li className={`flex items-center my-2 ${active?'text-primary':''} hover:text-primary cursor-pointer`} {...props}>
      {children}
    </li>
  );
};

LiWrapper.propTypes = {
  children: PropTypes.any,
  active: PropTypes.bool,
};

export default LiWrapper;
