import axios from 'axios';
import {BASE_URL} from '../constants/application.const';

/**
 * Get smtp config
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const getSmtpConfig = ()=>{
  return axios.get(`${BASE_URL}mail-dispatcher/get-smtp-config`)
      .then((response) => (response?.data));
};

/**
 * update smtp config
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const updateSmtpConfig = (payload)=>{
  return axios.put(`${BASE_URL}mail-dispatcher/update-smtp`, payload)
      .then((response) => (response?.data));
};

/**
 * export customers csv
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const exportCustomersCsv = (payload)=>{
  return axios.post(`${BASE_URL}export/export-customers-admin`, payload)
      .then((response) => (response?.data));
};

/**
 * Add demo users
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const addDemoUsers = (payload)=>{
  return axios.post(`${BASE_URL}admin/add-demo-user`, payload)
      .then((response) => (response?.data));
};

/**
 * Update integration config
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const updateIntegrationConfig = (payload)=>{
  return axios.put(`${BASE_URL}admin/update-integration-details`, payload)
      .then((response) => (response?.data));
};

/**
 * Downgrade users (Redemption flow)
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const downgradeRedemption = (payload)=>{
  return axios.put(`${BASE_URL}admin/downgrade-plan-redemption`, payload)
      .then((response) => (response?.data));
};
