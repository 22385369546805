import React, {useEffect, useState} from 'react';
import sampleVideo from '../assets/videos/sample.mp4';
import PropTypes from 'prop-types';
import {STYLE_TYPE_MAPPER} from './application.const';
import Widget from './widget.component';
import {FONT_SIZE_MAPPER, FONT_TYPE_MAPPER} from './application.const';
// import CloseIcon from '../icons/close.icon';

/**
 * BubbleSurvey component
 * @return {JSX}
 */
function BubbleSurvey({
  size,
  title,
  headingConfig,
  borderColor,
  styleType,
  mode,
  position,
  backgroundColor,
  buttonColor,
  buttonText,
  headerText,
  headerTextConfig,
  buttonLink,
  counterConfig,
  actionType,
  fieldConfig,
  actionButtonConfig,
  actionButtonName,
  actionButtonLink,
  counterStatus,
  mediaDetails,
  widgetOpenStatus,
  couponConfig,
  gradiantConfig,
  couponCode,
  couponDisplay,
  branding,
  couponCodeNotificationCode,
  couponCodeNotification,
  couponDisplayNotification,
  entrySuccessConfig,
  entryPopupSuccessText,
  entryFailConfig,
  entryPopupFailText,
  toggleStatusMessageSuccess,
  toggleStatusMessageFail,
  ctaButtonNotificationEnable,
  ctaButtonNotificationName,
  ctaButtonNotificationLink,
  ctaButtonNotificationConfig,
  surveyQuestionSettings,
  surveyQuestionAnswerType,
}) {
  const [widgetToggle, setWidgeToggle] = useState(false);

  // Toggle widget
  const widgetToggleFunction = ()=>{
    setWidgeToggle((prevState)=>!prevState);
  };

  useEffect(()=>{
    setWidgeToggle(widgetOpenStatus);
  }, [widgetOpenStatus]);

  return (
    <>
      <div style={{
        position: mode === 'dev'?'':'fixed',
        zIndex: 2,
      }}>
        {widgetToggle &&
          <Widget
            closeCallback={()=>widgetToggleFunction()}
            backgroundColor={backgroundColor}
            title={title || 'Default title'}
            buttonColor={buttonColor}
            buttonText={buttonText}
            headerText={headerText}
            buttonLink={buttonLink}
            headerTextConfig={headerTextConfig}
            counterConfig={counterConfig}
            actionType={actionType}
            fieldConfig={fieldConfig}
            actionButtonConfig={actionButtonConfig}
            actionButtonName={actionButtonName}
            actionButtonLink={actionButtonLink}
            counterStatus={counterStatus}
            mediaDetails={mediaDetails}
            borderColor={borderColor}
            mode={mode}
            couponConfig={couponConfig}
            gradiantConfig={gradiantConfig}
            couponCode={couponCode}
            couponDisplay={couponDisplay}
            branding={branding}
            couponCodeNotificationCode={couponCodeNotificationCode}
            couponCodeNotification={couponCodeNotification}
            couponDisplayNotification={couponDisplayNotification}
            entrySuccessConfig={entrySuccessConfig}
            entryPopupSuccessText={entryPopupSuccessText}
            entryFailConfig={entryFailConfig}
            entryPopupFailText={entryPopupFailText}
            toggleStatusMessageSuccess={toggleStatusMessageSuccess}
            toggleStatusMessageFail={toggleStatusMessageFail}
            ctaButtonNotificationEnable={ctaButtonNotificationEnable}
            ctaButtonNotificationName={ctaButtonNotificationName}
            ctaButtonNotificationLink={ctaButtonNotificationLink}
            ctaButtonNotificationConfig={ctaButtonNotificationConfig}
            surveyQuestionSettings={surveyQuestionSettings}
            surveyQuestionAnswerType={surveyQuestionAnswerType}
          />
        }
        {!widgetToggle && <div onClick={()=>widgetToggleFunction()} style={{
          backgroundColor,
          borderColor: borderColor || '',
          borderRadius: STYLE_TYPE_MAPPER(size)[styleType || 'C']?.radius,
          width: STYLE_TYPE_MAPPER(size)[styleType || 'C']?.width,
          height: STYLE_TYPE_MAPPER(size)[styleType || 'C']?.height,
          cursor: 'pointer',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <p style={{
            fontWeight: FONT_TYPE_MAPPER[headingConfig?.fontType?.value],
            fontSize: FONT_SIZE_MAPPER[headingConfig?.fontSize?.value],
            color: headingConfig?.textColor?.value,
            position: 'absolute',
            textAlign: 'center',
            width: '100%',
          }}>{title || ''}</p>
          <button
            style={{
              position: 'absolute',
              zIndex: 50,
              top: '.5rem',
              right: '.5rem',
              cursor: 'pointer',
              backgroundColor: '#8573F2',
              borderRadius: '50%',
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 3,
              paddingBottom: 3,
              textAlign: 'center',
              color: '#fff',
              fontWeight: 'bolder',
            }}
            onClick={()=>{}}>
            x
          </button>
          {(mediaDetails?.type === 'V') && <video muted loop autoPlay style={{
            backgroundColor,
            borderColor: borderColor || '',
            borderRadius: STYLE_TYPE_MAPPER(size)[styleType || 'C']?.radius,
            width: STYLE_TYPE_MAPPER(size)[styleType || 'C']?.width,
            height: STYLE_TYPE_MAPPER(size)[styleType || 'C']?.height,
            objectFit: 'cover',
            borderWidth: '4px',
          }} src={mediaDetails?.mediaPath || sampleVideo}>
            <source src={mediaDetails?.mediaPath || sampleVideo} type="video/mp4"/>
            Your browser does not support HTML video.
          </video>}
          {(mediaDetails?.type === 'I') && <img src={mediaDetails?.mediaPath} style={{
            backgroundColor,
            borderColor: borderColor || '',
            borderRadius: STYLE_TYPE_MAPPER(size)[styleType || 'C']?.radius,
            width: STYLE_TYPE_MAPPER(size)[styleType || 'C']?.width,
            height: STYLE_TYPE_MAPPER(size)[styleType || 'C']?.height,
            objectFit: 'cover',
            borderWidth: '4px',
          }} />}
          {(!mediaDetails?.type) && <img src={'https://picsum.photos/500'} style={{
            backgroundColor,
            borderColor: borderColor || '',
            borderRadius: STYLE_TYPE_MAPPER(size)[styleType || 'C']?.radius,
            width: STYLE_TYPE_MAPPER(size)[styleType || 'C']?.width,
            height: STYLE_TYPE_MAPPER(size)[styleType || 'C']?.height,
            objectFit: 'cover',
            borderWidth: '4px',
          }} />}
        </div>}
      </div>
    </>
  );
}

BubbleSurvey.propTypes = {
  size: PropTypes.string,
  title: PropTypes.string,
  borderColor: PropTypes.string,
  styleType: PropTypes.string,
  mode: PropTypes.string,
  position: PropTypes.string,
  backgroundColor: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonText: PropTypes.string,
  headerText: PropTypes.string,
  videoPath: PropTypes.string,
  counter: PropTypes.string,
  apiCall: PropTypes.bool,
  buttonLink: PropTypes.string,
  headerTextColor: PropTypes.string,
  counterConfig: PropTypes.any,
  actionType: PropTypes.string,
  fieldConfig: PropTypes.any,
  actionButtonConfig: PropTypes.any,
  actionButtonName: PropTypes.string,
  actionButtonLink: PropTypes.string,
  counterStatus: PropTypes.bool,
  mediaDetails: PropTypes.any,
  headingConfig: PropTypes.any,
  headerTextConfig: PropTypes.any,
  widgetOpenStatus: PropTypes.bool,
  couponConfig: PropTypes.any,
  gradiantConfig: PropTypes.any,
  couponCode: PropTypes.string,
  couponDisplay: PropTypes.bool,
  branding: PropTypes.string,
  couponCodeNotificationCode: PropTypes.string,
  couponCodeNotification: PropTypes.any,
  couponDisplayNotification: PropTypes.bool,
  entrySuccessConfig: PropTypes.any,
  entryPopupSuccessText: PropTypes.string,
  entryFailConfig: PropTypes.any,
  entryPopupFailText: PropTypes.string,
  toggleStatusMessageSuccess: PropTypes.bool,
  toggleStatusMessageFail: PropTypes.bool,
  ctaButtonNotificationEnable: PropTypes.bool,
  ctaButtonNotificationName: PropTypes.string,
  ctaButtonNotificationLink: PropTypes.string,
  ctaButtonNotificationConfig: PropTypes.any,
  surveyQuestionSettings: PropTypes.any,
  surveyQuestionAnswerType: PropTypes.string,
};

export default BubbleSurvey;
