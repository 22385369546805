import axios from 'axios';
import {BASE_URL} from '../constants/application.const';

/**
 * Dahboard users
 *
 * @return {Promise<any>}
 */
export const dashboardDetails = ()=>{
  return axios.get(`${BASE_URL}dashboard/details`, {withCredentials: true})
      .then((response) => (response?.data));
};
