import React from 'react';
import Heading from '../../../components/heading';
import CloseIcon from '../../../icons/close.icon';
import PropTypes from 'prop-types';
import Button from '../../../components/button/button.component';

/**
 * Disconnect modal for integration
 *
 * @return {JSX}
 */
const DisconnectModal = ({onClose, onDisconnect})=>{
  return (
    <>
      <div className="fixed inset-0 bg-stone-900 bg-opacity-75 transition-opacity z-50" aria-hidden="true"></div>
      <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white w-1/4 p-4 rounded-lg'>
        <Heading className='font-semibold text-lg flex justify-between  mt-4'>
          Disconnect modal?
          <CloseIcon className='cursor-pointer w-4 h-4' onClick={()=>{
            onClose();
          }}/>
        </Heading>
        <div className='mt-4'>
          Disconnecting the integration will only delete the data from Popsuite. You need to mannually uninstall the application(If installed) from the respective site.
        </div>
        <div className='flex mt-8 justify-end'>
          <Button type="button" onClick={()=>{
            onClose();
            onDisconnect();
          }}>Disconnect</Button>
        </div>
      </div>
    </>
  );
};

DisconnectModal.propTypes = {
  onClose: PropTypes.func,
  onDisconnect: PropTypes.func,
};

export default DisconnectModal;
