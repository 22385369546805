import React, {useEffect, useState} from 'react';
// import WidgetIcon from '../../icons/widget.icon';
import {useLocation, useNavigate} from 'react-router-dom';
import {USER_DETAILS} from '../../constants/application.const';
import {getValue} from '../../config/localStorage.config';
import ProfileMenuWrapper from './profileMenu.component';
// import HelpIcon from '../../icons/help.icon';
import ReactTooltip from 'react-tooltip';
// import Button from '../button/button.component';
// import UploadIcon from '../../icons/upload.icon';
import LogoIcon from '../../icons/logo.icon';
import RedemptionModalComponent from '../modal/redemptionModal.component';
import MediaIcon from '../../icons/media.icon';
import UpgradeIcon from '../../icons/upgrade.icon';
import IntegrationNewIcon from '../../icons/integrationNew.icon';
import InfoNewIcon from '../../icons/infoNew.icon';
import FeatureNewIcon from '../../icons/featureNew.icon';

/**
 * Side nav new
 *
 * @return {JSX}
 */
const SideNav = ()=>{
  const location = useLocation();
  const [currentUserData, setCurrentUserData] = useState({});
  const [redemptionModalToggle, setRedemptionModalToggle] = useState(false);
  const navigate = useNavigate();

  // Get user data from local storage
  const getLocalStorageUserData = ()=>{
    const userData = getValue(USER_DETAILS);
    if (userData) {
      setCurrentUserData(JSON.parse(userData));
    }
  };

  // called initially
  useEffect(()=>{
    getLocalStorageUserData();
  }, []);

  return (
    <>
      <div className='bg-white w-20 flex items-center py-4 rounded-r-xl flex-col justify-between'>
        <div className='flex items-center flex-col'>
          <a href={'/widgets'} className={`mt-2 p-2 hover:bg-primary hover:rounded-md hover:text-white cursor-pointer ${location?.pathname === '/widgets'?'bg-primary rounded-md text-white':'text-neutral-600'}`} data-tip data-for="widgets-menu">
            <LogoIcon
              height={'30px'}
              width={'30px'}
            />
            <ReactTooltip
              id="widgets-menu"
              place="right"
              effect="solid"
            >
              <span>Widgets</span>
            </ReactTooltip>
          </a>
          {/* <div className={`mt-2 p-2 hover:bg-white hover:rounded-md text-white hover:text-primary cursor-pointer ${location?.pathname === '/messages'?'bg-white rounded-md text-primary':''}`}>
          <MailIcon
            height={'30px'}
            width={'30px'}
          />
        </div>
        <div className='mt-2 p-2 hover:bg-white hover:rounded-md text-white hover:text-primary cursor-pointer'>
          <NotificationIcon
            height={'30px'}
            width={'30px'}
          />
        </div>
        <div className='mt-2 p-2 hover:bg-white hover:rounded-md text-white hover:text-primary cursor-pointer'>
          <StatsIcon
            height={'30px'}
            width={'30px'}
          />
        </div> */}
          <a href={'/media-library'} className={`mt-2 p-2 hover:bg-primary hover:rounded-md hover:text-white cursor-pointer ${location?.pathname === '/media-library'?'bg-primary rounded-md text-white':'text-neutral-600'}`} data-tip data-for="media-menu">
            <MediaIcon
              height={'30px'}
              width={'30px'}
            />
            <ReactTooltip
              id="media-menu"
              place="right"
              effect="solid"
            >
              <span>Media Library</span>
            </ReactTooltip>
          </a>
          {currentUserData?.userType !== 'DEMO' && <a className={`mt-2 p-2 hover:bg-primary hover:rounded-md hover:text-white cursor-pointer ${location?.pathname === '/upgrade'?'bg-primary rounded-md text-white':'text-neutral-600'}`} data-tip data-for="upgrade" onClick={()=>{
            if (currentUserData?.saaszillacode) {
              setRedemptionModalToggle(true);
            } else {
              navigate('/upgrade');
            }
          }}>
            <UpgradeIcon
              height={'30px'}
              width={'30px'}
            />
            <ReactTooltip
              id="upgrade"
              place="right"
              effect="solid"
            >
              <span>Upgrade</span>
            </ReactTooltip>
          </a>}
        </div>
        <div className='flex items-center flex-col'>
          {/* {currentUserData?.userType !== 'DEMO' && <div data-tip data-for="upgrade-menu">
            <Button classOverride={'flex items-center justify-center p-3 text-sm font-medium text-white duration-150 bg-gradient-to-r from-cyan-500 via-purple-500 to-pink-500 rounded-lg focus:outline-none focus:shadow-outline-blue hover:drop-shadow-xl'}
              onClick={()=>{
                if (currentUserData?.saaszillacode) {
                  setRedemptionModalToggle(true);
                } else {
                  navigate('/upgrade');
                }
              }}
            >
              <UploadIcon color={'#fff'}/>
            </Button>
            <ReactTooltip
              id="upgrade-menu"
              place="right"
              effect="solid"
            >
              <span>Upgrade</span>
            </ReactTooltip>
          </div>} */}
          <a href='/integrations' className={`mt-4 p-2 hover:bg-primary hover:rounded-md hover:text-white cursor-pointer ${location?.pathname === '/integrations'?'bg-primary rounded-md text-white':'text-neutral-600'}`} data-tip data-for="integrations">
            <IntegrationNewIcon
              height={'30px'}
              width={'30px'}
            />
            <ReactTooltip
              id="integrations"
              place="right"
              effect="solid"
            >
              <span>Integrations</span>
            </ReactTooltip>
          </a>
          <a href='https://popsuite.tawk.help/' target="_blank" className={`mt-2 p-2 hover:bg-white hover:rounded-md text-white hover:text-primary cursor-pointer`} rel="noreferrer" data-tip data-for="help-menu">
            <InfoNewIcon
              height={'30px'}
              width={'30px'}
            />
            <ReactTooltip
              id="help-menu"
              place="right"
              effect="solid"
            >
              <span>Popsuite Help Center</span>
            </ReactTooltip>
          </a>
          <a href='https://popsuite.frill.co/b/7mlqz8v5/feature-ideas' target="_blank" className={`mt-2 p-2 hover:bg-white hover:rounded-md text-white hover:text-primary cursor-pointer`} rel="noreferrer" data-tip data-for="feature-ideas">
            <FeatureNewIcon
              height={'30px'}
              width={'30px'}
            />
            <ReactTooltip
              id="feature-ideas"
              place="right"
              effect="solid"
            >
              <span>Roadmap</span>
            </ReactTooltip>
          </a>
          <ProfileMenuWrapper
            additionalClasses='mt-4 rounded-full bg-primary p-2 w-10 h-10 text-center text-white cursor-pointer hover:bg-purple-700'
            name={currentUserData?.name}
          >
            <span className='capitalize'>{currentUserData?.name?.split(' ')[0][0] || 'U'}</span>
          </ProfileMenuWrapper>
        </div>
      </div>
      {redemptionModalToggle && <RedemptionModalComponent
        onClose={()=>{
          setRedemptionModalToggle(false);
        }}
      />}
    </>
  );
};

export default SideNav;
