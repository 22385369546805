import axios from 'axios';
import {BASE_URL} from '../constants/application.const';
import {objectToQueryString} from '../helpers/common.helper';

/**
 * Widget store
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const addWidget = (payload)=>{
  return axios.post(`${BASE_URL}widget/add-widget`, payload)
      .then((response) => (response?.data));
};

/**
 * Widget upate
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const updateWidget = (payload)=>{
  return axios.put(`${BASE_URL}widget/update-widget`, payload)
      .then((response) => (response?.data));
};

/**
 * Widget upate live status
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const updateWidgetLiveStatus = (payload)=>{
  return axios.put(`${BASE_URL}widget/update-widget-live-status`, payload)
      .then((response) => (response?.data));
};

/**
 * Widget store
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const getAllWidgets = (payload)=>{
  return axios.get(`${BASE_URL}widget/get-all-widgets${objectToQueryString(payload)}`)
      .then((response) => (response?.data));
};

/**
 * Widget get analytics
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const getWidgetAnalytics = (payload)=>{
  return axios.get(`${BASE_URL}widget/get-widget-analytics/${payload?.widgetId}`)
      .then((response) => (response?.data));
};

/**
 * Widget delete
 *
 * @param {any} id
 * @return {Promise<any>}
 */
export const deleteWidget = (id)=>{
  return axios.delete(`${BASE_URL}widget/delete-widget/${id}`)
      .then((response) => (response?.data));
};

/**
 * Add/Update widget preview
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const addUpdateWidgetPreview = (payload)=>{
  return axios.put(`${BASE_URL}widget/add-update-widget-preview`, payload)
      .then((response) => (response?.data));
};

