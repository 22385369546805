import {all, call, put, takeLatest} from 'redux-saga/effects';
import {DETAILS_DASHBOARD_FAILURE, DETAILS_DASHBOARD_REQUEST, DETAILS_DASHBOARD_SUCCESS} from '../constants/reducer.const';
import {logoutUnauthorized} from '../helpers/common.helper';
import * as dashboardService from '../services/dashboard.service';

/**
 * Dashboard details saga
 *
 * @param {Object} action
 */
function* detailsAsync(action) {
  try {
    const user = yield call(dashboardService.dashboardDetails, action.payload);
    yield put({type: DETAILS_DASHBOARD_SUCCESS, payload: user});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: DETAILS_DASHBOARD_FAILURE, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * dashboard details saga watcher
 */
function* detailsWatcher() {
  yield takeLatest(DETAILS_DASHBOARD_REQUEST, detailsAsync);
}

/**
 * exporting sagas after they are initialised
 */
export default function* rootSaga() {
  yield all([
    detailsWatcher(),
  ]);
};
