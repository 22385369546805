import React, {useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {clearStorage} from '../../config/localStorage.config';
import TickIcon from '../../icons/tick.icon';
import {updateSubscriptionApi} from '../../services/plan.service';
import Heading from '../heading';

/**
 * Payment success page
 *
 * @return {JSX}
 */
const PaymentSuccess = ()=>{
  const navigate = useNavigate();

  // Update Subscription on success
  const updateSubscription = async ()=>{
    try {
      await updateSubscriptionApi({});
    } catch (e) {
      toast(e?.response?.data?.message, {
        type: 'error',
      });
    }
    setTimeout(()=>{
      clearStorage();
      navigate('/');
    }, 3000);
  };

  useEffect(()=>{
    updateSubscription();
  }, []);

  return (
    <div className='flex h-full justify-center items-center flex-col'>
      <div className='flex'>
        <TickIcon
          height={'120px'}
          width={'120px'}
          className='text-green-500'
        />
      </div>
      <Heading className='font-semibold text-xl mt-4 flex items-center text-slate-800'>Success! Payment received.</Heading>
      <p className='text-sm text-slate-600'>
        You need to login again to use the new plan&apos;s features
      </p>
      <p className='text-sm text-slate-600'>Redirecting to <Link to={'/'}className='underline text-blue-400 cursor-pointer'>login</Link></p>
    </div>
  );
};

export default PaymentSuccess;
