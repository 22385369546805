import {yupResolver} from '@hookform/resolvers/yup';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {getEmailSchema} from '../../validationSchemas/auth.validation';
import InputComponent from '../../components/input/input.component';
import ErrorText from '../../components/errorComponents/errorText.component';
import Button from '../../components/button/button.component';
import {forgotPasswordRequest} from '../../services/user.service';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';

/**
 * Get email for forgot password
 *
 * @return {JSX}
 */
const GetEmailForgotPassword = ()=>{
  const [loader, setLoader] = useState(false);
  const {register, handleSubmit, formState: {errors}} = useForm({
    resolver: yupResolver(getEmailSchema),
  });
  const navigate = useNavigate();

  const sendEmail = async (value)=>{
    try {
      setLoader(true);
      const response = await forgotPasswordRequest({
        email: value?.email,
      });
      setLoader(false);
      toast(response?.message, {
        type: 'success',
      });
      navigate('/');
    } catch (e) {
      setLoader(false);
      toast(e?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  return (
    <form className="flex justify-center items-center h-screen" onSubmit={handleSubmit(sendEmail)} noValidate>
      <div
        className="w-96 mx-6 overflow-hidden bg-white rounded-lg shadow-xl ddark:bg-gray-800"
      >
        <div className="flex-1 flex-col overflow-y-auto md:flex-row">
          <div className="flex-1 items-center justify-center p-6 sm:p-12">
            <div className="w-full">
              <h1
                className="mb-4 text-xl font-semibold text-gray-700 ddark:text-gray-200"
              >
              Enter the email to reset your password
              </h1>
              <label className="block mt-4 text-sm">
                <span className="text-gray-700 ddark:text-gray-400">Email</span>
                <InputComponent
                  name="email"
                  placeholder={'Enter email'}
                  type={'email'}
                  {...register('email')}
                  errorStatus={Boolean(errors.email?.message)}
                />
                <ErrorText displayStatus={true}>{errors.email?.message}</ErrorText>
              </label>
              <Button
                loader={loader}
                buttonText='Change password'
                className="w-full mt-4"
                type={'submit'}
                disabled={loader}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default GetEmailForgotPassword;
