import {ADD_DEMO_USER_CLEAR, ADD_DEMO_USER_FAILED, ADD_DEMO_USER_SUCCESS, DOWNGRADE_REDEMPTION_CLEAR, DOWNGRADE_REDEMPTION_FAILED, DOWNGRADE_REDEMPTION_SUCCESS, GET_SMTP_CONFIG_CLEAR, GET_SMTP_CONFIG_FAILED, GET_SMTP_CONFIG_SUCCESS, UPDATE_INTEGRATION_CONFIG_ADMIN_CLEAR, UPDATE_INTEGRATION_CONFIG_ADMIN_FAILED, UPDATE_INTEGRATION_CONFIG_ADMIN_SUCCESS, UPDATE_SMTP_CONFIG_CLEAR, UPDATE_SMTP_CONFIG_FAILED, UPDATE_SMTP_CONFIG_SUCCESS} from '../constants/reducer.const';

const initialState = {};

/**
 * Admin reducer reducer function
 *
 * @param {any} state
 * @param {any} action
 * @return {any}
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SMTP_CONFIG_SUCCESS: return {...state, smtpConfig: {...action}};
    case GET_SMTP_CONFIG_FAILED: return {...state, smtpConfig: {...action}};
    case GET_SMTP_CONFIG_CLEAR: return {...state, smtpConfig: {}};

    case UPDATE_SMTP_CONFIG_SUCCESS: return {...state, smtpConfigUpdate: {...action}};
    case UPDATE_SMTP_CONFIG_FAILED: return {...state, smtpConfigUpdate: {...action}};
    case UPDATE_SMTP_CONFIG_CLEAR: return {...state, smtpConfigUpdate: {}};

    // Add demo user
    case ADD_DEMO_USER_SUCCESS: return {...state, addDemoUser: {...action}};
    case ADD_DEMO_USER_FAILED: return {...state, addDemoUser: {...action}};
    case ADD_DEMO_USER_CLEAR: return {...state, addDemoUser: {}};

    // Integration
    case UPDATE_INTEGRATION_CONFIG_ADMIN_SUCCESS: return {...state, adminIntegration: {...action}};
    case UPDATE_INTEGRATION_CONFIG_ADMIN_FAILED: return {...state, adminIntegration: {...action}};
    case UPDATE_INTEGRATION_CONFIG_ADMIN_CLEAR: return {...state, adminIntegration: {}};

    // Downgrade redemption
    case DOWNGRADE_REDEMPTION_SUCCESS: return {...state, downgradeRedemption: {...action}};
    case DOWNGRADE_REDEMPTION_FAILED: return {...state, downgradeRedemption: {...action}};
    case DOWNGRADE_REDEMPTION_CLEAR: return {...state, downgradeRedemption: {}};

    default: return {...state};
  }
}
