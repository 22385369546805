import React from 'react';
import PropTypes from 'prop-types';

/**
 * Heading
 *
 * @return {JSX}
 */
const Heading = ({children, ...props})=>{
  return (
    <h2 {...props}>{children}</h2>
  );
};

Heading.propTypes = {
  children: PropTypes.any,
};

export default Heading;
