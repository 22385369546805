import {all, call, put, takeLatest} from 'redux-saga/effects';
import {setValue} from '../config/localStorage.config';
import {JWT_AUTH_KEY, JWT_AUTH_KEY_ADMIN, USER_DETAILS, USER_DETAILS_ADMIN} from '../constants/application.const';
import {GET_USERS_ADMIN_FAILED, GET_USERS_ADMIN_REQUEST, GET_USERS_ADMIN_SUCCESS, GET_USERS_LOGIN_FAILED, GET_USERS_LOGIN_REQUEST, GET_USERS_LOGIN_SUCCESS, LOGIN_USER_REQUESTED, REGISTER_USER_REQUESTED, UPDATE_USERS_PROFILE_FAILED, UPDATE_USERS_PROFILE_PASSWORD_FAILED, UPDATE_USERS_PROFILE_PASSWORD_REQUEST, UPDATE_USERS_PROFILE_PASSWORD_SUCCESS, UPDATE_USERS_PROFILE_REQUEST, UPDATE_USERS_PROFILE_SUCCESS, UPDATE_USERS_STATUS_ADMIN_FAILED, UPDATE_USERS_STATUS_ADMIN_REQUEST, UPDATE_USERS_STATUS_ADMIN_SUCCESS, USER_LOGIN_FAILURE, USER_LOGIN_SUCCESS, USER_REGISTER_FAILURE, USER_REGISTER_SUCCESS} from '../constants/reducer.const';
import {logoutUnauthorized} from '../helpers/common.helper';
import * as userService from '../services/user.service';

/**
 * Register user saga
 *
 * @param {Object} action
 */
function* registerUserAsync(action) {
  try {
    const user = yield call(userService.registerUser, action.payload);
    yield put({type: USER_REGISTER_SUCCESS, payload: user});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: USER_REGISTER_FAILURE, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * Login user saga
 *
 * @param {Object} action
 */
function* loginUserAsync(action) {
  try {
    const user = yield call(userService.loginUser, {
      username: action?.payload?.username,
      password: action?.payload?.password,
    });
    if (action?.payload?.userType === 'A' && user?.usersDetails?.userType === 'A') {
      setValue(JWT_AUTH_KEY_ADMIN, user?.access_token);
      setValue(USER_DETAILS_ADMIN, JSON.stringify(user?.usersDetails));
    } else {
      setValue(JWT_AUTH_KEY, user?.access_token);
      setValue(USER_DETAILS, JSON.stringify(user?.usersDetails));
    }
    yield put({type: USER_LOGIN_SUCCESS, payload: {
      message: 'Logged in Successfully',
      data: user,
    }});
  } catch (e) {
    yield put({type: USER_LOGIN_FAILURE, payload: e?.response?.data?{message: e?.response?.data?.message} : {message: 'Server Error(please try again)'}});
  }
}

/**
 * register user saga watcher
 */
function* registerUserWatcher() {
  yield takeLatest(REGISTER_USER_REQUESTED, registerUserAsync);
}

/**
 * login user saga watcher
 */
function* loginUserWatcher() {
  yield takeLatest(LOGIN_USER_REQUESTED, loginUserAsync);
}

/**
 * get widget analytics saga
 *
 * @param {Object} action
 */
function* getAdminUsers(action) {
  try {
    const response = yield call(userService.getAllUsersAdmin, action.payload);
    yield put({type: GET_USERS_ADMIN_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: GET_USERS_ADMIN_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * get widget watcher
 */
function* getAdminUsersWatchers() {
  yield takeLatest(GET_USERS_ADMIN_REQUEST, getAdminUsers);
}

/**
 * get widget analytics saga
 *
 * @param {Object} action
 */
function* updateAdminUsersStatus(action) {
  try {
    const response = yield call(userService.updateUserStatus, action.payload);
    yield put({type: UPDATE_USERS_STATUS_ADMIN_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: UPDATE_USERS_STATUS_ADMIN_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * update user admin status
 */
function* updateAdminUsersStatusWatchers() {
  yield takeLatest(UPDATE_USERS_STATUS_ADMIN_REQUEST, updateAdminUsersStatus);
}

/**
 * Update user profile
 *
 * @param {Object} action
 */
function* updateUserProfile(action) {
  try {
    const response = yield call(userService.updateProfile, action.payload);
    yield put({type: UPDATE_USERS_PROFILE_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: UPDATE_USERS_PROFILE_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * update user profile status
 */
function* updateUserProfileWatchers() {
  yield takeLatest(UPDATE_USERS_PROFILE_REQUEST, updateUserProfile);
}

/**
 * Update user profile password
 *
 * @param {Object} action
 */
function* updateUserProfilePassword(action) {
  try {
    const response = yield call(userService.updateProfilePassword, action.payload);
    yield put({type: UPDATE_USERS_PROFILE_PASSWORD_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: UPDATE_USERS_PROFILE_PASSWORD_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * update user profile status
 */
function* updateUserProfilePasswordWatchers() {
  yield takeLatest(UPDATE_USERS_PROFILE_PASSWORD_REQUEST, updateUserProfilePassword);
}

/**
 * Get login user detail
 *
 * @param {Object} action
 */
function* getLoginUserDetail(action) {
  try {
    const response = yield call(userService.getLoginUserDetails, action.payload);
    yield put({type: GET_USERS_LOGIN_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: GET_USERS_LOGIN_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * Get login user detail watcher
 */
function* getLoginUserDetailWatchers() {
  yield takeLatest(GET_USERS_LOGIN_REQUEST, getLoginUserDetail);
}

/**
 * exporting sagas after they are initialised
 */
export default function* rootSaga() {
  yield all([
    registerUserWatcher(),
    loginUserWatcher(),
    getAdminUsersWatchers(),
    updateAdminUsersStatusWatchers(),
    updateUserProfileWatchers(),
    updateUserProfilePasswordWatchers(),
    getLoginUserDetailWatchers(),
  ]);
};
