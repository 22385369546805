// Widget reducer
export const UPDATE_WIDGET_SETTINGS = 'UPDATE_WIDGET_SETTINGS';
export const VIDEO_DETAILS = 'VIDEO_DETAILS';
export const CLEAR_VIDEO_DETAILS = 'CLEAR_VIDEO_DETAILS';
export const WIDGET_SETTINGS_CLEAR = 'WIDGET_SETTINGS_CLEAR';

// User reducer constants
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE';
export const REGISTER_USER_REQUESTED = 'REGISTER_USER_REQUESTED';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const LOGIN_USER_REQUESTED = 'LOGIN_USER_REQUESTED';
export const CLEAR_USER_REQUEST = 'CLEAR_USER_REQUEST';
export const GET_USERS_ADMIN_REQUEST = 'GET_USERS_ADMIN_REQUEST';
export const GET_USERS_ADMIN_SUCCESS = 'GET_USERS_ADMIN_SUCCESS';
export const GET_USERS_ADMIN_FAILED = 'GET_USERS_ADMIN_FAILED';

export const GET_USERS_LOGIN_REQUEST = 'GET_USERS_LOGIN_REQUEST';
export const GET_USERS_LOGIN_SUCCESS = 'GET_USERS_LOGIN_SUCCESS';
export const GET_USERS_LOGIN_FAILED = 'GET_USERS_LOGIN_FAILED';
export const GET_USERS_LOGIN_CLEAR = 'GET_USERS_LOGIN_CLEAR';

export const UPDATE_USERS_STATUS_ADMIN_SUCCESS = 'UPDATE_USERS_STATUS_ADMIN_SUCCESS';
export const UPDATE_USERS_STATUS_ADMIN_FAILED = 'UPDATE_USERS_STATUS_ADMIN_FAILED';
export const UPDATE_USERS_STATUS_ADMIN_REQUEST = 'UPDATE_USERS_STATUS_ADMIN_REQUEST';
export const UPDATE_USERS_STATUS_ADMIN_CLEAR = 'UPDATE_USERS_STATUS_ADMIN_CLEAR';
export const UPDATE_USERS_PROFILE_SUCCESS = 'UPDATE_USERS_PROFILE_SUCCESS';
export const UPDATE_USERS_PROFILE_FAILED = 'UPDATE_USERS_PROFILE_FAILED';
export const UPDATE_USERS_PROFILE_REQUEST = 'UPDATE_USERS_PROFILE_REQUEST';
export const UPDATE_USERS_PROFILE_CLEAR = 'UPDATE_USERS_PROFILE_CLEAR';
export const UPDATE_USERS_PROFILE_PASSWORD_SUCCESS = 'UPDATE_USERS_PROFILE_PASSWORD_SUCCESS';
export const UPDATE_USERS_PROFILE_PASSWORD_FAILED = 'UPDATE_USERS_PROFILE_PASSWORD_FAILED';
export const UPDATE_USERS_PROFILE_PASSWORD_REQUEST = 'UPDATE_USERS_PROFILE_PASSWORD_REQUEST';
export const UPDATE_USERS_PROFILE_PASSWORD_CLEAR = 'UPDATE_USERS_PROFILE_PASSWORD_CLEAR ';

// Dashboard
export const DETAILS_DASHBOARD_SUCCESS = 'DETAILS_DASHBOARD_SUCCESS';
export const DETAILS_DASHBOARD_FAILURE = 'DETAILS_DASHBOARD_FAILURE';
export const DETAILS_DASHBOARD_REQUEST = 'DETAILS_DASHBOARD_REQUEST';

// Video widget
export const VIDEO_UPLOAD_REQUEST = 'VIDEO_UPLOAD_REQUEST';
export const VIDEO_UPLOAD_FAILURE = 'VIDEO_UPLOAD_FAILURE';
export const VIDEO_UPLOAD_SUCCESS = 'VIDEO_UPLOAD_SUCCESS';
export const VIDEO_UPLOAD_CLEAR_REQUEST = 'VIDEO_UPLOAD_CLEAR_REQUEST';
export const VIDEO_LIST_REQUEST = 'VIDEO_LIST_REQUEST';
export const VIDEO_LIST_SUCCESS = 'VIDEO_LIST_SUCCESS';
export const VIDEO_LIST_FAILURE = 'VIDEO_LIST_FAILURE';
export const VIDEO_LIST_CLEAR_REQUEST = 'VIDEO_LIST_CLEAR_REQUEST';
export const VIDEO_DELETE_REQUEST = 'VIDEO_DELETE_REQUEST';
export const VIDEO_DELETE_SUCCESS = 'VIDEO_DELETE_SUCCESS';
export const VIDEO_DELETE_FAILURE = 'VIDEO_DELETE_FAILURE';
export const VIDEO_DELETE_CLEAR_REQUEST = 'VIDEO_DELETE_CLEAR_REQUEST';
export const VIDEO_UPDATE_REQUEST = 'VIDEO_UPDATE_REQUEST';
export const VIDEO_UPDATE_SUCCESS = 'VIDEO_UPDATE_SUCCESS';
export const VIDEO_UPDATE_FAILURE = 'VIDEO_UPDATE_FAILURE';
export const VIDEO_UPDATE_CLEAR_REQUEST = 'VIDEO_UPDATE_CLEAR_REQUEST';

// Widget
export const ADD_WIDGET_REQUEST = 'ADD_WIDGET_REQUEST';
export const ADD_WIDGET_SUCCESS = 'ADD_WIDGET_SUCCESS';
export const ADD_WIDGET_FAILED = 'ADD_WIDGET_FAILED';
export const ADD_UPDATE_WIDGET_PREVIEW_REQUEST = 'ADD_UPDATE_WIDGET_PREVIEW_REQUEST';
export const ADD_UPDATE_WIDGET_PREVIEW_SUCCESS = 'ADD_UPDATE_WIDGET_PREVIEW_SUCCESS';
export const ADD_UPDATE_WIDGET_PREVIEW_FAILED = 'ADD_UPDATE_WIDGET_PREVIEW_FAILED';
export const ADD_UPDATE_WIDGET_PREVIEW_CLEAR = 'ADD_UPDATE_WIDGET_PREVIEW_CLEAR';
export const UPDATE_WIDGET_REQUEST = 'UPDATE_WIDGET_REQUEST';
export const UPDATE_WIDGET_SUCCESS = 'UPDATE_WIDGET_SUCCESS';
export const UPDATE_WIDGET_FAILED = 'UPDATE_WIDGET_FAILED';
export const UPDATE_WIDGET_LIVE_STATUS_SUCCESS = 'UPDATE_WIDGET_LIVE_STATUS_SUCCESS';
export const UPDATE_WIDGET_LIVE_STATUS_FAILED = 'UPDATE_WIDGET_LIVE_STATUS_FAILED';
export const UPDATE_WIDGET_LIVE_STATUS_REQUEST = 'UPDATE_WIDGET_LIVE_STATUS_REQUEST';
export const UPDATE_WIDGET_LIVE_STATUS_REQUEST_CLEAR = 'UPDATE_WIDGET_LIVE_STATUS_REQUEST_CLEAR';
export const WIDGET_REQUEST_CLEAR = 'WIDGET_REQUEST_CLEAR';
export const GET_WIDGET_REQUEST = 'GET_WIDGET_REQUEST';
export const GET_WIDGET_SUCCESS = 'GET_WIDGET_SUCCESS';
export const GET_WIDGET_FAILED = 'GET_WIDGET_FAILED';
export const WIDGET_LIST_REQUEST_CLEAR = 'WIDGET_LIST_REQUEST_CLEAR';
export const DELETE_WIDGET_SUCCESS = 'DELETE_WIDGET_SUCCESS';
export const DELETE_WIDGET_FAILED = 'DELETE_WIDGET_FAILED';
export const DELETE_WIDGET_REQUEST = 'DELETE_WIDGET_REQUEST';
export const WIDGET_DELETE_REQUEST_CLEAR = 'WIDGET_DELETE_REQUEST_CLEAR';
export const SELECTED_WIDGET = 'SELECTED_WIDGET';
export const COLLAPSE_WIDGET = 'COLLAPSE_WIDGET';

// Inegrations
export const ADD_INTEGRATION_REQUEST = 'ADD_INTEGRATION_REQUEST';
export const ADD_INTEGRATION_SUCCESS = 'ADD_INTEGRATION_SUCCESS';
export const ADD_INTEGRATION_FAILED = 'ADD_INTEGRATION_FAILED';
export const INTEGRATION_REQUEST_CLEAR = 'INTEGRATION_REQUEST_CLEAR';
export const GET_INTEGRATION_REQUEST = 'GET_INTEGRATION_REQUEST';
export const GET_INTEGRATION_SUCCESS = 'GET_INTEGRATION_SUCCESS';
export const GET_INTEGRATION_FAILED = 'GET_INTEGRATION_FAILED';
export const GET_INTEGRATION_REQUEST_CLEAR = 'GET_INTEGRATION_REQUEST_CLEAR';
export const GET_WIDGET_ANALYTICS_SUCCESS = 'GET_WIDGET_ANALYTICS_SUCCESS';
export const GET_WIDGET_ANALYTICS_FAILED = 'GET_WIDGET_ANALYTICS_FAILED';
export const GET_WIDGET_ANALYTICS_REQUEST = 'GET_WIDGET_ANALYTICS_REQUEST';
export const GET_WIDGET_ANALYTICS_REQUEST_CLEAR = 'GET_WIDGET_ANALYTICS_REQUEST_CLEAR';
export const SHOPIFY_OAUTH_REDIRECTION_REQUEST = 'SHOPIFY_OAUTH_REDIRECTION_REQUEST';
export const GET_SHOPIFY_ACCESS_TOKEN_SUCCESS = 'GET_SHOPIFY_ACCESS_TOKEN_SUCCESS';
export const GET_SHOPIFY_ACCESS_TOKEN_FAILED = 'GET_SHOPIFY_ACCESS_TOKEN_FAILED';
export const GET_SHOPIFY_ACCESS_TOKEN_REQUEST = 'GET_SHOPIFY_ACCESS_TOKEN_REQUEST';
export const GET_SHOPIFY_ACCESS_TOKEN_CLEAR = 'GET_SHOPIFY_ACCESS_TOKEN_CLEAR';
export const GET_INTEGRATION_STATUS_SUCCESS = 'GET_INTEGRATION_STATUS_SUCCESS';
export const GET_INTEGRATION_STATUS_FAILED = 'GET_INTEGRATION_STATUS_FAILED';
export const GET_INTEGRATION_STATUS_REQUEST = 'GET_INTEGRATION_STATUS_REQUEST';
export const GET_INTEGRATION_STATUS_CLEAR = 'GET_INTEGRATION_STATUS_CLEAR';

export const GET_SHOPIFY_INSTALL_STATUS_SUCCESS = 'GET_SHOPIFY_INSTALL_STATUS_SUCCESS';
export const GET_SHOPIFY_INSTALL_STATUS_FAILED = 'GET_SHOPIFY_INSTALL_STATUS_FAILED';
export const GET_SHOPIFY_INSTALL_STATUS_REQUEST = 'GET_SHOPIFY_INSTALL_STATUS_REQUEST';
export const GET_SHOPIFY_INSTALL_STATUS_CLEAR = 'GET_SHOPIFY_INSTALL_STATUS_CLEAR';

export const INSTALL_SHOPIFY_SUCCESS = 'INSTALL_SHOPIFY_SUCCESS';
export const INSTALL_SHOPIFY_FAILED = 'INSTALL_SHOPIFY_FAILED';
export const INSTALL_SHOPIFY_REQUEST = 'INSTALL_SHOPIFY_REQUEST';
export const INSTALL_SHOPIFY_CLEAR = 'INSTALL_SHOPIFY_CLEAR';

export const UNINSTALL_SHOPIFY_SUCCESS = 'UNINSTALL_SHOPIFY_SUCCESS';
export const UNINSTALL_SHOPIFY_FAILED = 'UNINSTALL_SHOPIFY_FAILED';
export const UNINSTALL_SHOPIFY_REQUEST = 'UNINSTALL_SHOPIFY_REQUEST';
export const UNINSTALL_SHOPIFY_CLEAR = 'UNINSTALL_SHOPIFY_CLEAR';

export const DELETE_INTEGRATION_REQUEST = 'DELETE_INTEGRATION_REQUEST';
export const DELETE_INTEGRATION_SUCCESS = 'DELETE_INTEGRATION_SUCCESS';
export const DELETE_INTEGRATION_FAILED = 'DELETE_INTEGRATION_FAILED';
export const DELETE_INTEGRATION_CLEAR = 'DELETE_INTEGRATION_CLEAR';

export const UPDATE_INTEGRATION_SUCCESS = 'UPDATE_INTEGRATION_SUCCESS';
export const UPDATE_INTEGRATION_FAILED = 'UPDATE_INTEGRATION_FAILED';
export const UPDATE_INTEGRATION_REQUEST = 'UPDATE_INTEGRATION_REQUEST';

export const GET_PABBLY_LIST_SUCCESS = 'GET_PABBLY_LIST_SUCCESS';
export const GET_PABBLY_LIST_FAILED = 'GET_PABBLY_LIST_FAILED';
export const GET_PABBLY_LIST_REQUEST = 'GET_PABBLY_LIST_REQUEST';
export const GET_PABBLY_LIST_CLEAR = 'GET_PABBLY_LIST_CLEAR';

export const UPDATE_SHOPIDY_EMAIL_REQUEST = 'UPDATE_SHOPIDY_EMAIL_REQUEST';
export const UPDATE_SHOPIDY_EMAIL_SUCCESS = 'UPDATE_SHOPIDY_EMAIL_SUCCESS';
export const UPDATE_SHOPIDY_EMAIL_FAILED = 'UPDATE_SHOPIDY_EMAIL_FAILED';
export const UPDATE_SHOPIDY_EMAIL_CLEAR = 'UPDATE_SHOPIDY_EMAIL_CLEAR';

// Customers
export const ADD_CUSTOMER_REQUEST = 'ADD_CUSTOMER_REQUEST';
export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';
export const ADD_CUSTOMER_FAILED = 'ADD_CUSTOMER_FAILED';
export const CUSTOMER_REQUEST_CLEAR = 'CUSTOMER_REQUEST_CLEAR';
export const GET_CUSTOMER_REQUEST = 'GET_CUSTOMER_REQUEST';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_FAILED = 'GET_CUSTOMER_FAILED';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILED = 'DELETE_CUSTOMER_FAILED';
export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const GET_SURVEY_STATS_SUCCESS = 'GET_SURVEY_STATS_SUCCESS';
export const GET_SURVEY_STATS_FAILED = 'GET_SURVEY_STATS_FAILED';
export const GET_SURVEY_STATS_REQUEST = 'GET_SURVEY_STATS_REQUEST';
export const GET_SURVEY_STATS_CLEAR = 'GET_SURVEY_STATS_CLEAR';

// Plans
export const PLAN_REQUEST_CLEAR = 'PLAN_REQUEST_CLEAR';
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';
export const GET_PLAN_FAILED = 'GET_PLAN_FAILED';
export const GET_PLAN_REQUEST = 'GET_PLAN_REQUEST';

export const GET_ALL_PLANS_ADMIN_REQUEST = 'GET_ALL_PLANS_ADMIN_REQUEST';
export const GET_ALL_PLANS_ADMIN_SUCCESS = 'GET_ALL_PLANS_ADMIN_SUCCESS';
export const GET_ALL_PLANS_ADMIN_FAILED = 'GET_ALL_PLANS_ADMIN_FAILED';
export const GET_ALL_PLANS_ADMIN_CLEAR = 'GET_ALL_PLANS_ADMIN_CLEAR';
export const ADD_PLAN_ADMIN_REQUEST = 'ADD_PLAN_ADMIN_REQUEST';
export const ADD_PLAN_ADMIN_SUCCESS = 'ADD_PLAN_ADMIN_SUCCESS';
export const ADD_PLAN_ADMIN_CLEAR = 'ADD_PLAN_ADMIN_CLEAR';
export const ADD_PLAN_ADMIN_FAILED = 'ADD_PLAN_ADMIN_FAILED';
export const EDIT_PLAN_ADMIN_REQUEST = 'EDIT_PLAN_ADMIN_REQUEST';
export const EDIT_PLAN_ADMIN_SUCCESS = 'EDIT_PLAN_ADMIN_SUCCESS';
export const EDIT_PLAN_ADMIN_CLEAR = 'EDIT_PLAN_ADMIN_CLEAR';
export const EDIT_PLAN_ADMIN_FAILED = 'EDIT_PLAN_ADMIN_FAILED';

export const INITIATE_PAYMENT_STRIPE_REQUEST = 'INITIATE_PAYMENT_STRIPE_REQUEST';
export const INITIATE_PAYMENT_STRIPE_SUCCESS = 'INITIATE_PAYMENT_STRIPE_SUCCESS';
export const INITIATE_PAYMENT_STRIPE_FAILED = 'INITIATE_PAYMENT_STRIPE_FAILED';
export const INITIATE_PAYMENT_STRIPE_CLEAR = 'INITIATE_PAYMENT_STRIPE_CLEAR';
export const UPDATE_PAYMENT_STATUS_REQUEST = 'UPDATE_PAYMENT_STATUS_REQUEST';
export const UPDATE_PAYMENT_STATUS_SUCCESS = 'UPDATE_PAYMENT_STATUS_SUCCESS';
export const UPDATE_PAYMENT_STATUS_FAILED = 'UPDATE_PAYMENT_STATUS_FAILED';
export const UPDATE_PAYMENT_STATUS_CLEAR = 'UPDATE_PAYMENT_STATUS_CLEAR';

// Admin reducers
export const GET_SMTP_CONFIG_SUCCESS = 'GET_SMTP_CONFIG_SUCCESS';
export const GET_SMTP_CONFIG_FAILED = 'GET_SMTP_CONFIG_FAILED';
export const GET_SMTP_CONFIG_REQUEST = 'GET_SMTP_CONFIG_REQUEST';
export const GET_SMTP_CONFIG_CLEAR = 'GET_SMTP_CONFIG_CLEAR';
export const UPDATE_SMTP_CONFIG_SUCCESS = 'UPDATE_SMTP_CONFIG_SUCCESS';
export const UPDATE_SMTP_CONFIG_FAILED = 'UPDATE_SMTP_CONFIG_FAILED';
export const UPDATE_SMTP_CONFIG_REQUEST = 'UPDATE_SMTP_CONFIG_REQUEST';
export const UPDATE_SMTP_CONFIG_CLEAR = 'UPDATE_SMTP_CONFIG_CLEAR';
export const ADD_DEMO_USER_REQUEST = 'ADD_DEMO_USER_REQUEST';
export const ADD_DEMO_USER_SUCCESS = 'ADD_DEMO_USER_SUCCESS';
export const ADD_DEMO_USER_CLEAR = 'ADD_DEMO_USER_CLEAR';
export const ADD_DEMO_USER_FAILED = 'ADD_DEMO_USER_FAILED';
export const UPDATE_INTEGRATION_CONFIG_ADMIN_SUCCESS = 'UPDATE_INTEGRATION_CONFIG_ADMIN_SUCCESS';
export const UPDATE_INTEGRATION_CONFIG_ADMIN_FAILED = 'UPDATE_INTEGRATION_CONFIG_ADMIN_FAILED';
export const UPDATE_INTEGRATION_CONFIG_ADMIN_REQUEST = 'UPDATE_INTEGRATION_CONFIG_ADMIN_REQUEST';
export const UPDATE_INTEGRATION_CONFIG_ADMIN_CLEAR = 'UPDATE_INTEGRATION_CONFIG_ADMIN_CLEAR';

// Redemption reducers
export const UPGRADE_REDEMPTION_SUCCESS = 'UPGRADE_REDEMPTION_SUCCESS';
export const UPGRADE_REDEMPTION_FAILED = 'UPGRADE_REDEMPTION_FAILED';
export const UPGRADE_REDEMPTION_REQUEST = 'UPGRADE_REDEMPTION_REQUEST';
export const UPGRADE_REDEMPTION_CLEAR = 'UPGRADE_REDEMPTION_CLEAR';
export const DOWNGRADE_REDEMPTION_SUCCESS = 'DOWNGRADE_REDEMPTION_SUCCESS';
export const DOWNGRADE_REDEMPTION_FAILED = 'DOWNGRADE_REDEMPTION_FAILED';
export const DOWNGRADE_REDEMPTION_REQUEST = 'DOWNGRADE_REDEMPTION_REQUEST';
export const DOWNGRADE_REDEMPTION_CLEAR = 'DOWNGRADE_REDEMPTION_CLEAR';
export const GET_REDEMPTION_CODE_SUCCESS = 'GET_REDEMPTION_CODE_SUCCESS';
export const GET_REDEMPTION_CODE_FAILED = 'GET_REDEMPTION_CODE_FAILED';
export const GET_REDEMPTION_CODE_REQUEST = 'GET_REDEMPTION_CODE_REQUEST';
export const GET_REDEMPTION_CODE_CLEAR = 'GET_REDEMPTION_CODE_CLEAR';
