import React from 'react';
import PropTypes from 'prop-types';

/**
 * Error Text component
 *
 * @return {JSX}
 */
const ErrorText = ({children, displayStatus, additionalClass})=>{
  if (displayStatus) {
    return (
      <span className={`text-red-700 text-xs ${additionalClass}`}>{children}</span>
    );
  } else {
    return (<></>);
  }
};

ErrorText.propTypes = {
  children: PropTypes.any,
  displayStatus: PropTypes.bool,
  additionalClass: PropTypes.string,
};

export default ErrorText;
