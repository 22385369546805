import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import UserIcon from '../../icons/users.icon';
import PlansIcon from '../../icons/plans.icon';
import ProfileMenuWrapper from './profileMenu.component';
import CogIcon from '../../icons/cog.icon';
import CodeIcon from '../../icons/codes.icon';

/**
 * Side nav admin
 *
 * @return {JSX}
 */
const SideNavAdmin = ()=>{
  const location = useLocation();

  return (
    <div className='bg-blue-500 w-20 flex items-center py-4 rounded-r-xl flex-col justify-between'>
      <div className='flex items-center flex-col'>
        <Link to={'/admin/customers'} className={`mt-2 p-2 hover:bg-white hover:rounded-md text-white hover:text-blue-500 cursor-pointer ${location?.pathname === '/admin/customers'?'bg-white rounded-md text-blue-500':''}`}>
          <UserIcon
            height={'30px'}
            width={'30px'}
          />
        </Link>
        <Link to={'/admin/plans'} className={`mt-2 p-2 hover:bg-white hover:rounded-md text-white hover:text-blue-500 cursor-pointer ${location?.pathname === '/admin/plans'?'bg-white rounded-md text-blue-500':''}`}>
          <PlansIcon
            height={'30px'}
            width={'30px'}
          />
        </Link>
        <Link to={'/admin/saaszillacodes'} className={`mt-2 p-2 hover:bg-white hover:rounded-md text-white hover:text-blue-500 cursor-pointer ${location?.pathname === '/admin/saaszillacodes'?'bg-white rounded-md text-blue-500':''}`}>
          <CodeIcon
            height={'30px'}
            width={'30px'}
          />
        </Link>
        <Link to={'/admin/settings'} className={`mt-2 p-2 hover:bg-white hover:rounded-md text-white hover:text-blue-500 cursor-pointer ${location?.pathname === '/admin/settings'?'bg-white rounded-md text-blue-500':''}`}>
          <CogIcon
            height={'30px'}
            width={'30px'}
          />
        </Link>
      </div>
      <div className='flex items-center flex-col'>
        <ProfileMenuWrapper
          additionalClasses='mt-4 rounded-full bg-green-400 p-2 w-10 h-10 text-center text-white cursor-pointer hover:bg-green-500'
          name={'Admin'}
          redirectionUrl={'/admin'}
        >
          <span className='capitalize'>{'A'}</span>
        </ProfileMenuWrapper>
      </div>
    </div>
  );
};

export default SideNavAdmin;
