import React from 'react';
import ErrorText from '../../../../components/errorComponents/errorText.component';
import Input from '../../../../components/input/input.component';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {updateWidgetSettings} from '../../../../actions/widget.actions';

/**
 * Widget name component
 *
 * @return {JSX}
 */
const WidgetName = ({register, errors})=>{
  const widgetStore = useSelector((state)=>state.widget);
  const dispatch = useDispatch();

  return (
    <>
      <div className='flex items-center mt-20'>
        <label className='font-bold mr-4'>Widget Name</label>
        <Input
          placeholder="Ex: Offer popup"
          parentAdditionalClass={'flex-2'}
          className="mt-2"
          errorStatus={errors?.widgetName}
          {...register('widgetName', {
            onChange: (event)=>{
              dispatch(updateWidgetSettings({
                widgetName: event.target.value,
              }));
            },
          })}
          defaultValue={widgetStore?.widgetSettings?.widgetName}
        />
      </div>
      <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errors?.widgetName?.message}</ErrorText>
    </>
  );
};


WidgetName.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
};

export default WidgetName;
