import React from 'react';
import PropTypes from 'prop-types';

/**
 * Save Icon
 *
 * @return {JSX}
 */
const SaveIcon = ({height, width, ...props})=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height || '24px'} width={width || '24px'} viewBox="0 0 48 48" {...props} fill={'currentColor'}><path d="M0 0h48v48h-48z" fill="none"/><path d="M34 6h-24c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4v-24l-8-8zm-10 32c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6zm6-20h-20v-8h20v8z"/></svg>
  );
};

SaveIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default SaveIcon;
