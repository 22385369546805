import React from 'react';
import PropTypes from 'prop-types';

/**
 * Settings icon for whole project
 *
 * @return {JSX}
 */
const SettingsIcon = ({height, width, title, ...props})=>{
  return (
    <svg width={width || '25'} height={height || '25'} xmlns="http://www.w3.org/2000/svg" {...props} fill="currentColor" viewBox="0 0 50 50"><path d="M9 47.4q-1.2 0-2.1-.9-.9-.9-.9-2.1v-30q0-1.2.9-2.1.9-.9 2.1-.9h20.25l-3 3H9v30h30V27l3-3v20.4q0 1.2-.9 2.1-.9.9-2.1.9Zm15-18Zm9.1-17.6 2.15 2.1L21 28.1v4.3h4.25l14.3-14.3 2.1 2.1L26.5 35.4H18v-8.5Zm8.55 8.4-8.55-8.4 5-5q.85-.85 2.125-.85t2.125.9l4.2 4.25q.85.9.85 2.125t-.9 2.075Z"/>
      <title>{title || 'Customize / modify'}</title>
    </svg>
  );
};

SettingsIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  title: PropTypes.string,
};

export default SettingsIcon;
