import React, {useEffect, useState} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import PropTypes from 'prop-types';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

export const options = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: 'Monthly statistics of widget views',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const WidgetBarChart = ({graphData})=>{
  const [graphDataLocal, setGraphDataLocal] = useState({
    labels,
    datasets: [
      {
        label: 'Views',
        data: [],
        backgroundColor: 'rgb(192 132 252)',
      },
    ],
  });

  const setGraphData = (data)=>{
    const temp = {...graphDataLocal};
    temp.datasets[0].data = data;
    setGraphDataLocal(JSON.parse(JSON.stringify(temp)));
  };

  useEffect(()=>{
    if (graphData) {
      setGraphData(graphData);
    }
  }, [graphData]);

  return (
    <div className='p-2 h-96 overflow-auto'>
      <Bar options={options} data={{...graphDataLocal}} />
    </div>
  );
};

WidgetBarChart.propTypes = {
  graphData: PropTypes.any,
};

export default WidgetBarChart;
