import React, {useState} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import Button from '../button/button.component';
import ErrorText from '../errorComponents/errorText.component';
import {BASE_REACT_URL} from '../../constants/application.const';

/**
 * Stripe checkout form
 *
 * @return {JSX}
 */
const StripeCheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${BASE_REACT_URL}payment-status`,
      },
    });


    if (error) {
      setErrorMessage(error.message);
    }
  };
  return (
    <form onSubmit={handleSubmit} className="border-2 border-purple-500 rounded-md p-4">
      <PaymentElement />
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      <Button
        type={'submit'}
        className="mt-4 w-full"
      >
        Pay
      </Button>
    </form>
  );
};

export default StripeCheckoutForm;
