import React from 'react';
import {Outlet, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import Heading from '../../../components/heading';
import LiWrapper from '../../../components/liWrapper';
import IntegrationIcon from '../../../icons/integration.icon';
import SmtpIcon from '../../../icons/smtp.icon';
import IntegrationConfig from '../integrationConfig';
import SmtpConfig from '../smtpConfig';

/**
 * Setings page
 *
 * @return {JSXs}
 */
const SettingsAdminPage = ()=>{
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className='h-full'>
      <div className='bg-white grid grid-cols-4 gap-2 h-screen'>
        <div className='col-span-1 px-4 pt-8'>
          <Heading className={'font-semibold text-xl'}>Settings</Heading>
          <ul className='mt-4'>
            <LiWrapper
              active={location?.pathname === '/admin/settings'}
              onClick={()=>navigate('/admin/settings')}
            >
              <SmtpIcon
                height="24"
                width="24"
                className="mr-2"
              />
              <span className='text-sm'>SMTP settings</span>
            </LiWrapper>
            <LiWrapper
              active={location?.pathname === '/admin/settings/integrations'}
              onClick={()=>navigate('/admin/settings/integrations')}
            >
              <IntegrationIcon
                height="24"
                width="24"
                className="mr-2"
              />
              <span className='text-sm'>Integration</span>
            </LiWrapper>
          </ul>
        </div>
        <div className='col-span-3 bg-gray-50 rounded-l-xl overflow-y-auto'>
          <Outlet/>
          <Routes>
            <Route index element={<SmtpConfig />} />
            <Route path="integrations/*" element={<IntegrationConfig />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default SettingsAdminPage;
