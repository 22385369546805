import React from 'react';
import PropTypes from 'prop-types';

/**
 * Play icon
 *
 * @return {JSX}
 */
const PlayIcon = ({height, width, color, ...props})=>{
  return (
    <svg width={width || '24px'} height={height || '24px'} viewBox="0 0 36 42" fill={color || 'currentColor'} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M34.5 20.134C35.1667 20.5189 35.1667 21.4811 34.5 21.866L3 40.0526C2.33333 40.4375 1.5 39.9563 1.5 39.1865V2.81347C1.5 2.04367 2.33333 1.56254 3 1.94744L34.5 20.134Z" fill="currentColor" stroke="currentColor" strokeWidth="2"/>
    </svg>

  );
};

PlayIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default PlayIcon;
