
import {applyMiddleware, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {persistReducer} from 'redux-persist';
import reducer from '../reducers';
import sagas from '../sagas';
import storage from 'redux-persist/lib/storage';

const sagaMiddleware = createSagaMiddleware();

// Persist reducer store
const persistConfig = {
  key: 'rootPersistReducer',
  storage,
};
const persistedReducer = persistReducer(persistConfig, reducer);

// mount it on the Store
const store = createStore(
    persistedReducer,
    applyMiddleware(sagaMiddleware),
);

// run saga middleware function
sagaMiddleware.run(sagas);

export default store;
