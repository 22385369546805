import React from 'react';
import PropTypes from 'prop-types';
import LoaderIcon from '../../icons/loader.icon';

/**
 * Common button for the whole project
 *
 * @return {JSX}
 */
function Button({loader, buttonText, type, className, buttonIcon, classOverride, children, ...props}) {
  return (
    <button type={type || 'button'} className={classOverride || `flex items-center justify-center px-3 py-2 text-sm font-medium text-white duration-150 bg-primary border border-transparent rounded-lg hover:bg-purple-700 active:bg-purple-500 focus:outline-none focus:shadow-outline-purple ${className} `} {...props}>
      {loader && <LoaderIcon />}
      {buttonIcon && <span className={`${buttonIcon?'pr-2':''}`}>{buttonIcon}</span>}
      {buttonText || children}
    </button>
  );
};

Button.propTypes = {
  buttonText: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  classOverride: PropTypes.string,
  buttonIcon: PropTypes.any,
  onClick: PropTypes.func,
  children: PropTypes.any,
  loader: PropTypes.bool,
};

// bg-slate-600 bg-slate-700 bg-slate-500  shadow-outline-slate

export default Button;
