import React from 'react';
import PropTypes from 'prop-types';

/**
 * InfoNew icon for whole project
 *
 * @return {JSX}
 */
const InfoNewIcon = ({height, width, ...props})=>{
  return (
    <svg width={width || '32px'} height={height || '32px'} fill={'currentColor'} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M21.787 2.66602C26.307 2.66602 29.3337 5.83935 29.3337 10.5593V21.454C29.3337 26.1607 26.307 29.3327 21.787 29.3327H10.227C5.70699 29.3327 2.66699 26.1607 2.66699 21.454V10.5593C2.66699 5.83935 5.70699 2.66602 10.227 2.66602H21.787ZM15.987 13.974C15.347 13.974 14.827 14.506 14.827 15.1473V21.0407C14.827 21.6807 15.347 22.2007 15.987 22.2007C16.6403 22.2007 17.1603 21.6807 17.1603 21.0407V15.1473C17.1603 14.506 16.6403 13.974 15.987 13.974ZM16.0137 9.74735C15.3603 9.74735 14.8403 10.2673 14.8403 10.9207C14.8403 11.5593 15.3603 12.0807 15.987 12.0807C16.6537 12.0807 17.1737 11.5593 17.1737 10.9207C17.1737 10.2673 16.6537 9.74735 16.0137 9.74735Z" fill="#50545E"/>
    </svg>
  );
};

InfoNewIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default InfoNewIcon;
