import React, {useEffect, useState} from 'react';
import ButtonGroup from '../../../../components/button/buttonGroup.component';
import Input from '../../../../components/input/input.component';
import InfoWrapper from '../../../../components/wrappers/infoWrapper.component';
import Heading from '../../../../components/heading';
import {useDispatch, useSelector} from 'react-redux';
import {updateCollapseAction, updateWidgetSettings} from '../../../../actions/widget.actions';
import PropTypes from 'prop-types';
import ColorInput from '../../../../components/input/colorInput.component';
import {PROTOCOL_TYPE_MAPPER, STYLE_TYPE_MAPPER} from '../../../../constants/widget.const';
import {getValue} from '../../../../config/localStorage.config';
import {USER_DETAILS} from '../../../../constants/application.const';
import {convertToMilliSeonds} from '../../../../helpers/common.helper';
import ErrorText from '../../../../components/errorComponents/errorText.component';
import ChipInput from '../../../../components/input/chipInput.component';
import Select from '../../../../components/select/select.component';
import QuestionIcon from '../../../../icons/question.icon';
import ReactTooltip from 'react-tooltip';
import {useNavigate} from 'react-router-dom';
import CollapsibleComponent from './collapsible.component';

/**
 * Popup settings
 *
 * @return {JSX}
 */
const PopupSettings = ({register, errors, setValue, widgetType, ...props})=>{
  const widgetStore = useSelector((state)=>state.widget);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [curretUserPlan, setCurrentUserPlan] = useState({});
  const [protocol, setProtocol] = useState(PROTOCOL_TYPE_MAPPER.HTTPS.value);
  const [displayPreferenceDelay, setDisplayPreferenceDelay] = useState({
    HH: 0,
    MM: 0,
    SS: 0,
  });
  const [toggleCollapsible, setToggleCollapsible] = useState(true);

  // Get user data from local storage
  const getLocalStorageUserData = ()=>{
    const userData = getValue(USER_DETAILS);
    if (userData) {
      setCurrentUserPlan(JSON.parse(userData)?.plan);
    }
  };

  // Called initially
  useEffect(()=>{
    getLocalStorageUserData();
  }, []);

  useEffect(()=>{
    console.log(widgetStore?.collapse_widget);
    setToggleCollapsible(false);
    if (widgetStore?.collapse_widget?.payload?.GS) {
      setToggleCollapsible(true);
    }
  }, [widgetStore?.collapse_widget]);

  useEffect(()=>{
    setValue('popupSize', 'LG');
    dispatch(updateWidgetSettings({
      popupSize: widgetStore?.widgetSettings?.popupSize || 'LG',
      bubbleBorderColor: widgetStore?.widgetSettings?.bubbleBorderColor || '#00000',
      bubbleType: widgetStore?.widgetSettings?.bubbleType || 'C',
      branding: widgetStore?.widgetSettings?.branding || 'Y',
    }));
  }, []);

  // Update current display preference config (For custom timing)
  const updateDispalyPreferenceConfig = (key, value, allValues)=>{
    const currentValue = {...widgetStore?.widgetSettings?.displayPreferenceConfig};
    setDisplayPreferenceDelay({...allValues});
    const delayTime = convertToMilliSeonds(allValues?.HH, allValues?.MM, allValues?.SS);
    dispatch(updateWidgetSettings({
      displayPreferenceConfig: {
        ...currentValue,
        [key]: value,
        delayTime,
      },
    }));
  };

  return (
    <div {...props}>
      <CollapsibleComponent
        type={'GS'}
        heading={((widgetType === 'B' || widgetType === 'BS'))?'General Bubble Settings':'General Popup Settings'}
        defaultValue={toggleCollapsible}
        toggleCallback={(toggle)=>{
          setToggleCollapsible(toggle);
          const tempCollapse = {...widgetStore?.collapse_widget};
          dispatch(updateWidgetSettings({
            toggleStatusMessageSuccess: false,
          }));
          Object.keys(tempCollapse).map((key)=>tempCollapse[key] = false);
          tempCollapse['GS'] = toggle;
          dispatch(updateCollapseAction({...tempCollapse}));
        }}
      />

      <div className={toggleCollapsible?'px-2':'hidden'}>

        <div className={`flex items-center ${widgetType === 'PS'?'':'mt-4'}`}>
          {widgetType !== 'PS' &&<div className='single-line-left-component'><label className='text-sm font-normal flex items-center'>Size</label>
            <ButtonGroup
              labelMarginEnable={true}
              planBlock={!curretUserPlan?.modules?.P?.WIDGETS?.BUBBLE_SETTINGS?.SIZE || !curretUserPlan?.modules?.P?.WIDGETS?.POPUP_SETTINGS?.SIZE}
              defaultSelect={widgetStore?.widgetSettings?.popupSize}
              onSelect={(value)=>{
                if (!curretUserPlan?.modules?.P?.WIDGETS?.BUBBLE_SETTINGS?.SIZE || !curretUserPlan?.modules?.P?.WIDGETS?.POPUP_SETTINGS?.SIZE) return;
                setValue('popupSize', value);
                dispatch(updateWidgetSettings({
                  popupSize: value,
                }));
              }}
              buttons={[
                {
                  name: 'Small',
                  value: 'SM',
                },
                {
                  name: 'Medium',
                  value: 'LG',
                },
                {
                  name: 'Large',
                  value: 'XL',
                },
              ]}
            />
          </div>}
          {/* Choose border color */}
          {(widgetType === 'B' || widgetType === 'BS') && <div id="bubble-border-color">
            <label className='text-sm font-normal flex items-center'>Bubble Color</label>
            <ColorInput
              defaultValue={widgetStore?.widgetSettings?.bubbleBorderColor}
              onChangeValue={(value)=>{
                dispatch(updateWidgetSettings({
                  bubbleBorderColor: value,
                }));
              }}
            />
          </div>}
        </div>

        <div className={`flex items-center ${widgetType === 'PS'?'':'mt-4'}`}>
          {/* Widget type */}
          <div className="single-line-left-component">
            {(widgetType === 'B' || widgetType === 'BS') && <ButtonGroup
              className={''}
              label={'Shape'}
              planBlock={!curretUserPlan?.modules?.P?.WIDGETS?.BUBBLE_SETTINGS?.TYPE}
              defaultSelect={widgetStore?.widgetSettings?.bubbleType}
              onSelect={(value)=>{
                if (!curretUserPlan?.modules?.P?.WIDGETS?.BUBBLE_SETTINGS?.TYPE) return;
                dispatch(updateWidgetSettings({
                  bubbleType: value,
                }));
              }}
              buttons={[...STYLE_TYPE_MAPPER]}
            />}
          </div>
          <div>
            {((widgetType === 'B' || widgetType === 'BS') || widgetType === 'R') && <ButtonGroup
              label={'Position'}
              defaultSelect={widgetStore?.widgetSettings?.bubblePosition || 'R'}
              onSelect={(value)=>{
                dispatch(updateWidgetSettings({
                  bubblePosition: value,
                }));
              }}
              buttons={[
                {
                  name: 'Left',
                  value: 'L',
                },
                {
                  name: 'Right',
                  value: 'R',
                },
              ]}
            />}
          </div>
        </div>
        {/* Review widget background color */}
        {widgetType === 'R' && <div id="text-size-container">
          <label className='text-sm font-normal mt-4 flex items-center'>Choose background color</label>
          <ColorInput
            defaultValue={widgetStore?.widgetSettings?.reviewPopupBackgroundColor}
            onChangeValue={(value)=>{
              dispatch(updateWidgetSettings({
                reviewPopupBackgroundColor: value,
              }));
            }}
          />
        </div>}
        {/* Review widget star color */}
        {widgetType === 'R' && <div id="text-size-container">
          <label className='text-sm font-normal mt-4 flex items-center'>Choose star color</label>
          <ColorInput
            defaultValue={widgetStore?.widgetSettings?.starColor}
            onChangeValue={(value)=>{
              dispatch(updateWidgetSettings({
                starColor: value,
              }));
            }}
          />
        </div>}
        {(widgetType !== 'B' && widgetType !== 'E') && <ButtonGroup
          className={'mt-4'}
          label={'When to display?'}
          planBlock={!curretUserPlan?.modules?.P?.WIDGETS?.POPUP_SETTINGS?.WHEN_TO_DISPLAY}
          onSelect={(value)=>{
            dispatch(updateWidgetSettings({
              displayPreference: value,
            }));
          }}
          defaultSelect={widgetStore?.widgetSettings?.displayPreference}
          buttons={[
            {
              name: 'Start',
              value: 'S',
            },
            {
              name: 'Set timer',
              value: 'C',
            },
            {
              name: 'Exit intent',
              value: 'EI',
            },
          ]}
        />}

        {widgetStore?.widgetSettings?.displayPreference === 'EI' &&
        <ButtonGroup
          className={'mt-4'}
          label={'Display once?'}
          defaultSelect={widgetStore?.widgetSettings?.displayOnce || 'N'}
          onSelect={(value)=>{
            dispatch(updateWidgetSettings({
              displayOnce: value,
            }));
          }}
          buttons={[
            {
              name: 'Yes',
              value: 'Y',
            },
            {
              name: 'No',
              value: 'N',
            },
          ]}
        />
        }
        {(widgetStore?.widgetSettings?.displayPreference === 'C') && <InfoWrapper
          description={widgetStore?.widgetSettings?.displayPreference === 'EI'?(
          <div>Mention time(delay) after which the popup need to be displayed <span className='font-bold'>by default eveything will be set to 0 and the popup is displayed immediately</span></div>
          ):'Mention time(delay) after which the popup need to be displayed'}
          additionalClass='mt-2'
        >
          <div className='flex justify-around mt-2'>
            <Input type='number' placeholder='HH' parentAdditionalClass="mr-2"
              onChange={(event)=>{
                updateDispalyPreferenceConfig('HH', event?.target?.value, {
                  ...displayPreferenceDelay,
                  HH: event?.target?.value,
                });
              }}
              defaultValue={widgetStore?.widgetSettings?.displayPreferenceConfig?.HH}
            />
            <Input type='number' placeholder='MM' parentAdditionalClass="mr-2"
              onChange={(event)=>{
                updateDispalyPreferenceConfig('MM', event?.target?.value, {
                  ...displayPreferenceDelay,
                  MM: event?.target?.value,
                });
              }}
              defaultValue={widgetStore?.widgetSettings?.displayPreferenceConfig?.MM}
            />
            <Input type='number' placeholder='SS'
              onChange={(event)=>{
                updateDispalyPreferenceConfig('SS', event?.target?.value, {
                  ...displayPreferenceDelay,
                  SS: event?.target?.value,
                });
              }}
              defaultValue={widgetStore?.widgetSettings?.displayPreferenceConfig?.SS}
            />
          </div>
        </InfoWrapper>}
        <div className='mt-4'>
          <Heading className="text-sm font-normal flex items-center">
          Which pages to display?
            <QuestionIcon
              height={20}
              width={20}
              className={'ml-1 cursor-pointer'}
              data-tip
              data-for='page-to-display'
            />
            <ReactTooltip
              id="page-to-display"
              place="right"
              effect="solid"
            >
              <span>Press enter or spacebar after you enter the url</span>
            </ReactTooltip>
          </Heading>
          <div className='grid grid-cols-12 mt-1'>
            <div className='col-span-3'>
              <Select
                options={[
                  {
                    text: PROTOCOL_TYPE_MAPPER.HTTPS.name,
                    value: PROTOCOL_TYPE_MAPPER.HTTPS.value,
                  },
                  {
                    text: PROTOCOL_TYPE_MAPPER.HTTP.name,
                    value: PROTOCOL_TYPE_MAPPER.HTTP.value,
                  },
                ]}
                onChange={(value)=>{
                  setProtocol(value);
                }}
              />
            </div>
            <div className="mt-1 col-span-9">
              <ChipInput
                misc={{
                  protocol,
                }}
                defaultValues={widgetStore?.widgetSettings?.pages}
                placeholder='www.example.com'
                chipsLength={curretUserPlan?.modules?.P?.WIDGETS?.BUBBLE_SETTINGS?.DOMAIN_LENGTH}
                chipsLengthErrorMessage={`Current plan supports only ${curretUserPlan?.modules?.P?.WIDGETS?.BUBBLE_SETTINGS?.DOMAIN_LENGTH} domains`}
                valuesOnChange={(value)=>{
                  setValue('pages', value?.join(','));
                  dispatch(updateWidgetSettings({
                    pages: value?.join(','),
                  }));
                }}
              />
            </div>
          </div>
          <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errors?.pages?.message}</ErrorText>
        </div>
        {(widgetType === 'P' || widgetType === 'PS') && <ButtonGroup
          className={'mt-4'}
          label={'Position'}
          defaultSelect={widgetStore?.widgetSettings?.popupPosition || 'C'}
          onSelect={(value)=>{
            dispatch(updateWidgetSettings({
              popupPosition: value,
            }));
          }}
          buttons={[
            {
              name: 'Left',
              value: 'L',
            },
            {
              name: 'Center',
              value: 'C',
            },
            {
              name: 'Right',
              value: 'R',
            },
          ]}
        />}
        <div className="flex items-end mt-4">
          <ButtonGroup
            className={'single-line-left-component'}
            label={'Branding'}
            planBlock={curretUserPlan?.modules?.P?.WIDGETS?.WIDGET_BRANDING}
            onSelect={(value)=>{
              dispatch(updateWidgetSettings({
                branding: value,
              }));
            }}
            defaultSelect={widgetStore?.widgetSettings?.branding}
            buttons={[
              {
                name: 'Yes',
                value: 'Y',
              },
              {
                name: 'No',
                value: 'N',
              },
            ]}
          />
          <a className='text-xs underline text-primary cursor-pointer mb-4' onClick={()=>{
            navigate('/upgrade');
          }}>Upgrade your plan for remove branding?</a>
        </div>
      </div>
    </div>
  );
};

PopupSettings.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
  setValue: PropTypes.any,
  widgetType: PropTypes.string,
};

export default PopupSettings;
