import React, {useState} from 'react';
import Button from '../../../components/button/button.component';
import Heading from '../../../components/heading';
import PropTypes from 'prop-types';
import UpgradeButton from '../../../components/button/upgradeButton.component';
import CloseIcon from '../../../icons/close.icon';
import DisconnectModal from '../modals/disconnect.modal';

/**
 * Integrations page
 *
 * @return {JSX}
 */
const IntegrationCard = ({icon, heading, description, planBlock, connectButtonClickHandler, additionalActionComponents, connected, disconnectCallback, howToIntegrateLink})=>{
  const [disconnectModal, setDisconnectModal] = useState(false);

  return (
    <>
      <div className='rounded-md bg-white p-4 border-2 border-slate-200 grid grid-rows-4 gap-1 relative'>
        {planBlock && <div className="absolute inset-0 bg-stone-700 bg-opacity-75 transition-opacity z-50 rounded-lg flex justify-center items-center" aria-hidden="true">
          <UpgradeButton />
        </div>}
        <div className='flex justify-between items-center'>
          {icon}
          <div id="action-conponent-holders" className="flex items-center">
            {additionalActionComponents}
            {!connected && <Button
              classOverride={'flex items-center justify-center px-8 py-0 text-xs text-white duration-150 bg-primary border border-transparent rounded-full hover:bg-purple-700 active:bg-purple-500 focus:outline-none focus:shadow-outline-purple h-8'}
              onClick={()=>{
                if (!planBlock) {
                  connectButtonClickHandler();
                }
              }}
            >Connect</Button>}
            {connected && <Button
              onClick={()=>{
                setDisconnectModal(true);
              }}
              buttonIcon={<CloseIcon
                height={18}
                width={18}
                className='text-white'
              />}
              classOverride={'flex items-center justify-center px-2 text-xs text-white duration-150 bg-red-600 border border-transparent rounded-full mr-2 h-8'}
            >Disconnect</Button>}
            {connected && <Button
              classOverride={'flex items-center justify-center px-8 py-0 text-xs text-white duration-150 bg-green-600 border border-transparent rounded-full  h-8'}
              disabled
            >Connected</Button>}
          </div>
        </div>
        <Heading className='font-bold text-lg mt-2'>{heading}</Heading>
        <p className='text-sm'>
          {description}
        </p>
        <div className='flex items-center'>
          <a className='text-xs underline text-blue-400 cursor-pointer' href={howToIntegrateLink} target="_blank" rel="noreferrer">How to integrate?</a>
        </div>
      </div>
      {disconnectModal && <DisconnectModal
        onClose={()=>{
          setDisconnectModal(false);
        }}
        onDisconnect={()=>{
          disconnectCallback();
        }}
      />}
    </>
  );
};

IntegrationCard.propTypes = {
  icon: PropTypes.any,
  heading: PropTypes.string,
  description: PropTypes.string,
  planBlock: PropTypes.bool,
  connectButtonClickHandler: PropTypes.func,
  disconnectCallback: PropTypes.func,
  additionalActionComponents: PropTypes.any,
  connected: PropTypes.bool,
  misc: PropTypes.any,
  howToIntegrateLink: PropTypes.string,
};

export default IntegrationCard;
