import {PRIMARY_COLOR} from './application.const';
import {FIELD_REQUIRED_MAPPER, FIELD_TYPE_MAPPER, FIELD_VALIDATION_MAPPER, FONT_SIZE_CONST, FONT_TYPE_CONST} from './widget.const';

// Heading default values
export const HEADING_DEFAULT_VALUES = {
  fontSize: {
    value: FONT_SIZE_CONST.SM,
    displayName: 'Font Size',
    chipColor: '',
  },
  fontType: {
    value: FONT_TYPE_CONST.T,
    displayName: 'Font Type',
    chipColor: '',
  },
  textColor: {
    value: '#000000',
    displayName: 'Text Color',
    chipColor: '#000000',
  },
  textAlignment: {
    value: 'L',
    displayName: 'Left',
    chipColor: '',
  },
};

// Coupon default values
export const COUPON_DEFAULT_VALUES = {
  fontSize: {
    value: FONT_SIZE_CONST.SM,
    displayName: 'Font Size',
    chipColor: '',
  },
  fontType: {
    value: FONT_TYPE_CONST.T,
    displayName: 'Font Type',
    chipColor: '',
  },
  textColor: {
    value: '#000000',
    displayName: 'Text Color',
    chipColor: '#000000',
  },
  backgroundColor: {
    value: '#8e9dec',
    displayName: 'Background Color',
    chipColor: '#8e9dec',
  },
};

// Sub Heading default values
export const SUB_HEADING_DEFAULT_VALUES = {
  fontSize: {
    value: FONT_SIZE_CONST.SM,
    displayName: 'Font Size',
    chipColor: '',
  },
  fontType: {
    value: FONT_TYPE_CONST.T,
    displayName: 'Font Type',
    chipColor: '',
  },
  textColor: {
    value: '#000000',
    displayName: 'Text Color',
    chipColor: '#000000',
  },
  textAlignment: {
    value: 'L',
    displayName: 'Left',
    chipColor: '',
  },
};

// Bubble inner heading
export const BUBBLE_ACTION_HEADING = {
  fontSize: {
    value: FONT_SIZE_CONST.SM,
    displayName: 'Font Size',
    chipColor: '',
  },
  fontType: {
    value: FONT_TYPE_CONST.T,
    displayName: 'Font Type',
    chipColor: '',
  },
  textColor: {
    value: '#000000',
    displayName: 'Text Color',
    chipColor: '#000000',
  },
  textAlignment: {
    value: 'L',
    displayName: 'Left',
    chipColor: '',
  },
};

// Counter default values
export const COUNTER_DEFAULT_VALUES = {
  counterDate: '',
  direction: 'RB',
  fieldColor: {
    value: '#000000',
    displayName: 'Field color',
    chipColor: '#000000',
  },
  backgroundColor: {
    value: '#000000',
    displayName: 'Background Color',
    chipColor: '#000000',
  },
};

// Counter default values
export const ACTION_BUTTON_DEFAULT_VALUES = {
  actionButtonColor: {
    value: '#000000',
    displayName: 'Button color',
    chipColor: '#000000',
  },
};

// Gradiant default values
export const GRADIANT_DEFAULT_VALUES = {
  backgroundColor: {
    value: '#000000',
    displayName: 'Background color',
    chipColor: '#000000',
  },
  opacity: {
    value: 0.3,
    displayName: 'Opacity',
    chipColor: '#000000',
  },
};

// Field config defaultvalues
export const FIELD_CONFIG_VALUES = {
  enabledStatus: {
    value: true,
    displayName: 'Enabled',
  },
  fieldPlaceholder: {
    value: '',
    displayName: 'Field Placeholder',
  },
  fieldType: {
    value: FIELD_TYPE_MAPPER.T.value,
    displayName: 'Field Type',
  },
  requiredStatus: {
    value: FIELD_REQUIRED_MAPPER.T.value,
    displayName: 'Required',
  },
  validationType: {
    value: FIELD_VALIDATION_MAPPER.N.value,
    displayName: 'Validation type',
  },
  customValidationPattern: {
    value: '',
    displayName: 'Validation pattern',
  },
};

// Review component
export const REVIEW_FIELD_CONFIG_VALUES = {
  enabledStatus: {
    value: true,
    displayName: 'Enabled',
  },
  fieldPlaceholder: {
    value: '',
    displayName: 'Field Placeholder',
  },
  requiredStatus: {
    value: FIELD_REQUIRED_MAPPER.T.value,
    displayName: 'Required',
  },
};

export const ENTRY_NOTIFICATION_VALUES = {
  backgroundColor: {
    value: PRIMARY_COLOR,
    displayName: 'Background color',
    chipColor: PRIMARY_COLOR,
  },
  textColor: {
    value: '#fff',
    displayName: 'Text color',
    chipColor: '#000',
  },
};
