/**
 * Set value
 *
 * @param {string} key
 * @param {string} value
 * @return {bool}
 */
export const setValue = (key, value)=>{
  if (!key || !value) return;
  return localStorage.setItem(key, value);
};

/**
 * Get value
 *
 * @param {string} key
 * @return {bool}
 */
export const getValue = (key) =>{
  if (!key) return;
  return localStorage.getItem(key);
};

/**
 * Remove item
 *
 * @param {string} key
 * @return {bool}
 */
export const removeItem = (key) => {
  if (!key) return;
  return localStorage.removeItem(key);
};

/**
 * Clear all local storage value
 *
 * @param {string} key
 * @return {bool}
 */
export const clearStorage = ()=>{
  return localStorage.clear();
};
