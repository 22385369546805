import React from 'react';
import PropTypes from 'prop-types';

/**
 * Logo icon
 *
 * @return {JSX}
 */
function LogoIcon({color, height, width, ...props}) {
  return (
    <svg viewBox="0 0 164 147" width={width || '24px'} fill={color || 'currentColor'} {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M97.2237 80.9536C90.5654 77.4609 85.3781 72.4934 81.5844 65.9737C77.7908 59.4539 75.9326 51.8475 75.9326 42.9993C75.9326 34.3063 77.8682 26.6999 81.6619 20.1802C85.533 13.6604 90.7203 8.693 97.3786 5.20028C103.959 1.78517 111.469 0 119.908 0C128.27 0 135.78 1.78517 142.438 5.27789C149.097 8.77062 154.284 13.738 158.155 20.2578C162.026 26.7775 163.884 34.3839 163.884 43.0769C163.884 51.7699 161.949 59.3763 158.155 65.9737C154.284 72.4934 149.019 77.5385 142.361 81.0312C135.703 84.5239 128.115 86.3091 119.754 86.3091C111.315 86.2315 103.882 84.4463 97.2237 80.9536ZM129.973 56.1164C132.683 53.0894 134.077 48.6653 134.077 42.9993C134.077 37.3333 132.683 32.9868 129.973 29.9598C127.264 26.9328 123.857 25.4581 119.908 25.4581C115.882 25.4581 112.553 26.9328 109.921 29.9598C107.211 32.9868 105.895 37.3333 105.895 42.9993C105.895 48.8205 107.211 53.167 109.766 56.194C112.321 59.2211 115.728 60.6958 119.754 60.6958C123.78 60.6958 127.186 59.2211 129.973 56.1164Z" fill="currentColor"/>
      <path d="M16.7033 83.2022C11.4798 79.722 7.41021 74.7724 4.43398 68.276C1.45775 61.7796 0 54.2004 0 45.3839C0 36.722 1.51849 29.1429 4.49472 22.6465C7.5317 16.1501 11.6012 11.2004 16.8248 7.72023C21.9877 4.31735 27.8794 2.53857 34.5 2.53857C41.0599 2.53857 46.9516 4.31735 52.1752 7.79757C57.3988 11.2778 61.4683 16.2274 64.5053 22.7238C67.5423 29.2202 69 36.7994 69 45.4612C69 54.1231 67.4815 61.7022 64.5053 68.276C61.4683 74.7724 57.338 79.7994 52.1144 83.2796C46.8908 86.7598 40.9384 88.5386 34.3785 88.5386C27.7579 88.4612 21.9269 86.6825 16.7033 83.2022ZM35.3961 58.454C37.522 55.4379 38.6153 51.0296 38.6153 45.3839C38.6153 39.7382 37.522 35.4073 35.3961 32.3911C33.2702 29.3749 30.5977 27.9055 27.5 27.9055C24.3415 27.9055 21.7298 29.3749 19.6646 32.3911C17.5387 35.4073 16.5062 39.7382 16.5062 45.3839C16.5062 51.1843 17.5387 55.5152 19.5431 58.5314C21.5475 61.5476 24.2201 63.017 27.3785 63.017C30.537 63.017 33.2095 61.5476 35.3961 58.454Z" fill="currentColor"/>
      <path d="M81.5068 146.461C48.0604 146.461 22.2014 122.4 21.04 121.314L38.6149 102.531C38.6923 102.608 50.1508 113.164 65.9449 118.132C85.3779 124.263 102.256 119.373 117.508 103.074L136.244 120.693C120.837 137.148 103.108 145.763 83.5972 146.306C82.9004 146.461 82.2036 146.461 81.5068 146.461Z" fill="currentColor"/>
    </svg>

  );
}

LogoIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default LogoIcon;
