import React from 'react';
import PropTypes from 'prop-types';

/**
 * Upload icon
 *
 * @return {JSX}
 */
function UploadIcon({color, height, width, ...props}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height={height || '24px'} viewBox="0 0 24 24" width="24px" fill={color || '#ffff'} {...props}><g><rect fill="none" width={ width || '24px'}/></g><g><path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M7,9l1.41,1.41L11,7.83V16h2V7.83l2.59,2.58L17,9l-5-5L7,9z"/></g></svg>
  );
}

UploadIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default UploadIcon;
