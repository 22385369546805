import React, {useRef} from 'react';
import './assets/css/tailwind-main-output.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import 'tw-elements';
import DashboardPage from './pages/dashboard/dashboard.component';
import WidgetsPage from './pages/widgets/widgets.page';
import VideoLibrary from './pages/videoLibrary';
import Contacts from './pages/contacts';
import LoginPage from './pages/auth/login.page';
import RegisterPage from './pages/auth/register.page';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './config/axios.config';
import ProtectedRoute from './components/protectedRouter';
import ReactTooltip from 'react-tooltip';
import MediaLibrary from './pages/mediaLibrary';
import Integrations from './pages/integrations';
import IntegrationProcess from './pages/integrations/integrationProcess.page';
import PaymentCheckout from './pages/paymentCheckout';
import StripePaymentStatus from './components/stripePaymentStatus';
import AdminPages from './pages/admin';
import SettingsPage from './pages/settings';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import ForgotPassword from './pages/auth/forgotPassword.page';
import GetEmailForgotPassword from './pages/auth/getEmailForgotPassword.page';
import UpgradePage from './pages/upgrade';
import PaymentSuccess from './components/stripePaymentStatus/paymentSuccess';
import PaymentFailed from './components/stripePaymentStatus/paymentFailed';
import PreviewPage from './pages/widgets/previewPage';
import ShopifyRedirectionPage from './pages/common/shopifyRedirection.page';

localStorage.theme = 'light';
/**
 * App entry point
 *
 * @return {JSX}
 */
function App() {
  const tawkMessengerRef = useRef();

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<LoginPage />}/>
        <Route path="admin/*" element={<AdminPages />}/>
        <Route path="shopify-redirection-page" element={<ShopifyRedirectionPage />}/>
        <Route path="register/:type/:planId" element={<RegisterPage />}/>
        <Route path="register/:type" element={<RegisterPage />}/>
        <Route path="register" element={<RegisterPage />}/>
        <Route path="forgot-password" element={<ForgotPassword />}/>
        <Route path="password-reset" element={<GetEmailForgotPassword />}/>
        <Route path="dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>}/>
        <Route path="widgets/*" element={<ProtectedRoute><WidgetsPage /></ProtectedRoute>}/>
        <Route path="contacts/*" element={<ProtectedRoute><Contacts /></ProtectedRoute>}/>
        <Route path="video-library/*" element={<ProtectedRoute><VideoLibrary /></ProtectedRoute>}/>
        <Route path="media-library/*" element={<ProtectedRoute><MediaLibrary /></ProtectedRoute>}/>
        <Route path="integrations/*" element={<ProtectedRoute><Integrations /></ProtectedRoute>}/>
        <Route path="integrations-process/:type" element={<IntegrationProcess />}/>
        <Route path="payment-process/M/:clientSecret" element={<PaymentCheckout />}/>
        <Route path="payment-status" element={<StripePaymentStatus />}/>
        <Route path="payment-success" element={<PaymentSuccess />}/>
        <Route path="payment-failed" element={<PaymentFailed />}/>
        <Route path="settings/*" element={<SettingsPage />}/>
        <Route path="upgrade" element={<UpgradePage/>}/>
        <Route path="preview-widget" element={<ProtectedRoute><PreviewPage/></ProtectedRoute>}/>
      </Routes>
      <ToastContainer />
      <ReactTooltip id={'common'} />
      <TawkMessengerReact
        propertyId={process.env.REACT_APP_TAWK_PID}
        widgetId={process.env.REACT_APP_TAWK_WID}
        onLoad={() => {
          console.log(location?.pathname);
          if (location?.pathname === '/preview-widget') {
            tawkMessengerRef.current.hideWidget();
          }
        }}
        ref={tawkMessengerRef}
      />
    </BrowserRouter>
  );
}

export default App;
