import {all, call, put, takeLatest} from 'redux-saga/effects';
import {GET_PLAN_SUCCESS, GET_PLAN_FAILED, GET_PLAN_REQUEST, INITIATE_PAYMENT_STRIPE_SUCCESS, INITIATE_PAYMENT_STRIPE_FAILED, INITIATE_PAYMENT_STRIPE_REQUEST, UPDATE_PAYMENT_STATUS_SUCCESS, UPDATE_PAYMENT_STATUS_FAILED, UPDATE_PAYMENT_STATUS_REQUEST, GET_ALL_PLANS_ADMIN_SUCCESS, GET_ALL_PLANS_ADMIN_FAILED, GET_ALL_PLANS_ADMIN_REQUEST, ADD_PLAN_ADMIN_SUCCESS, ADD_PLAN_ADMIN_FAILED, ADD_PLAN_ADMIN_REQUEST, EDIT_PLAN_ADMIN_SUCCESS, EDIT_PLAN_ADMIN_FAILED, EDIT_PLAN_ADMIN_REQUEST} from '../constants/reducer.const';
import {logoutUnauthorized} from '../helpers/common.helper';
import * as planService from '../services/plan.service';

/**
 * get all plan list saga
 *
 * @param {Object} action
 */
function* getPlanDetails(action) {
  try {
    const response = yield call(planService.getPlanDetails, action.payload);
    yield put({type: GET_PLAN_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: GET_PLAN_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * plan list saga watcher
 */
function* getPlanListWatcher() {
  yield takeLatest(GET_PLAN_REQUEST, getPlanDetails);
}

/**
 * get all plan list saga for admin
 *
 * @param {Object} action
 */
function* getAllPlanAdmin(action) {
  try {
    const response = yield call(planService.getAllPlanAdmin, action.payload);
    yield put({type: GET_ALL_PLANS_ADMIN_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: GET_ALL_PLANS_ADMIN_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * plan list admin saga watcher
 */
function* getAllPlanAdminWatcher() {
  yield takeLatest(GET_ALL_PLANS_ADMIN_REQUEST, getAllPlanAdmin);
}

/**
 * payment Initiation
 *
 * @param {Object} action
 */
function* paymentInit(action) {
  try {
    const response = yield call(planService.initiateStripePaymentProcess, action.payload);
    yield put({type: INITIATE_PAYMENT_STRIPE_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: INITIATE_PAYMENT_STRIPE_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * payment Initiation saga watcher
 */
function* paymentInitWatcher() {
  yield takeLatest(INITIATE_PAYMENT_STRIPE_REQUEST, paymentInit);
}

/**
 * update payment status
 *
 * @param {Object} action
 */
function* updatePaymentStatus(action) {
  try {
    const response = yield call(planService.updatePaymentStatusApi, action.payload);
    yield put({type: UPDATE_PAYMENT_STATUS_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: UPDATE_PAYMENT_STATUS_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * update payment status
 */
function* updatePaymentStatusWatcher() {
  yield takeLatest(UPDATE_PAYMENT_STATUS_REQUEST, updatePaymentStatus);
}

/**
 * add plan service (admin)
 *
 * @param {Object} action
 */
function* addPlanAdmin(action) {
  try {
    const response = yield call(planService.addPlans, action.payload);
    yield put({type: ADD_PLAN_ADMIN_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: ADD_PLAN_ADMIN_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * add plan admin watcher
 */
function* addPlanAdminWatcher() {
  yield takeLatest(ADD_PLAN_ADMIN_REQUEST, addPlanAdmin);
}

/**
 * edit plan service (admin)
 *
 * @param {Object} action
 */
function* editPlanAdmin(action) {
  try {
    const response = yield call(planService.editPlans, action.payload);
    yield put({type: EDIT_PLAN_ADMIN_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: EDIT_PLAN_ADMIN_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * edit plan admin watcher
 */
function* editPlanAdminWatcher() {
  yield takeLatest(EDIT_PLAN_ADMIN_REQUEST, editPlanAdmin);
}

/**
 * exporting sagas after they are initialised
 */
export default function* rootSaga() {
  yield all([
    getPlanListWatcher(),
    paymentInitWatcher(),
    updatePaymentStatusWatcher(),
    getAllPlanAdminWatcher(),
    addPlanAdminWatcher(),
    editPlanAdminWatcher(),
  ]);
};

