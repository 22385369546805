import {all, call, put, takeLatest} from 'redux-saga/effects';
import {VIDEO_DELETE_FAILURE, VIDEO_DELETE_REQUEST, VIDEO_DELETE_SUCCESS, VIDEO_LIST_FAILURE, VIDEO_LIST_REQUEST, VIDEO_LIST_SUCCESS, VIDEO_UPDATE_FAILURE, VIDEO_UPDATE_REQUEST, VIDEO_UPDATE_SUCCESS, VIDEO_UPLOAD_FAILURE, VIDEO_UPLOAD_REQUEST, VIDEO_UPLOAD_SUCCESS} from '../constants/reducer.const';
import {logoutUnauthorized} from '../helpers/common.helper';
import * as videoLibraryService from '../services/videoLibrary.service';

/**
 * video upload saga
 *
 * @param {Object} action
 */
function* storeVideoAsync(action) {
  try {
    const response = yield call(videoLibraryService.storeVideo, action.payload);
    yield put({type: VIDEO_UPLOAD_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: VIDEO_UPLOAD_FAILURE, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * video upload saga watcher
 */
function* videoUploadWatcher() {
  yield takeLatest(VIDEO_UPLOAD_REQUEST, storeVideoAsync);
}

/**
 * get all video list saga
 *
 * @param {Object} action
 */
function* getVideoList(action) {
  try {
    const response = yield call(videoLibraryService.getAllVideoList, action.payload);
    yield put({type: VIDEO_LIST_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: VIDEO_LIST_FAILURE, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * video list saga watcher
 */
function* getVideoListWatcher() {
  yield takeLatest(VIDEO_LIST_REQUEST, getVideoList);
}

/**
 * delete video list saga
 *
 * @param {Object} action
 */
function* deleteVideo(action) {
  try {
    const response = yield call(videoLibraryService.deleteVideo, action.payload);
    yield put({type: VIDEO_DELETE_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: VIDEO_DELETE_FAILURE, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * delete video saga watcher
 */
function* deleteVideoWatcher() {
  yield takeLatest(VIDEO_DELETE_REQUEST, deleteVideo);
}

/**
 * video details update saga
 *
 * @param {Object} action
 */
function* videoUpdateDetails(action) {
  try {
    const response = yield call(videoLibraryService.updateVideoDetails, action.payload);
    yield put({type: VIDEO_UPDATE_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: VIDEO_UPDATE_FAILURE, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * video details saga watcher
 */
function* videoUpdateDetailsWatcher() {
  yield takeLatest(VIDEO_UPDATE_REQUEST, videoUpdateDetails);
}

/**
 * exporting sagas after they are initialised
 */
export default function* rootSaga() {
  yield all([
    videoUploadWatcher(),
    getVideoListWatcher(),
    deleteVideoWatcher(),
    videoUpdateDetailsWatcher(),
  ]);
};
