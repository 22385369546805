import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import PropTypes from 'prop-types';

ChartJS.register(ArcElement, Tooltip, Legend);

/**
 * Survey Doughnut Chart
 * (For showing survey answers)
 *
 * @return {JSX}
 */
const SurveyDoughnutChart = ({data})=>{
  return (
    <>
      {data?.labels && (
        <Doughnut data={data} />
      )}
      {!data?.labels && (
        <div className='h-24 flex justify-center items-center break-word text-slate-400'>
          No Data Yet
        </div>
      )}
    </>
  );
};

SurveyDoughnutChart.propTypes = {
  data: PropTypes.any,
};

export default SurveyDoughnutChart;
