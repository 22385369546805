import React, {useState} from 'react';
import PropTypes from 'prop-types';

// Play Icon
const PlayIcon = ({...props})=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props} fill="currentColor"><path d="M19.15 32.5 32.5 24 19.15 15.5ZM24 44Q19.9 44 16.25 42.425Q12.6 40.85 9.875 38.125Q7.15 35.4 5.575 31.75Q4 28.1 4 24Q4 19.9 5.575 16.25Q7.15 12.6 9.875 9.875Q12.6 7.15 16.25 5.575Q19.9 4 24 4Q28.1 4 31.75 5.575Q35.4 7.15 38.125 9.875Q40.85 12.6 42.425 16.25Q44 19.9 44 24Q44 28.1 42.425 31.75Q40.85 35.4 38.125 38.125Q35.4 40.85 31.75 42.425Q28.1 44 24 44ZM24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24ZM24 41Q31 41 36 36Q41 31 41 24Q41 17 36 12Q31 7 24 7Q17 7 12 12Q7 17 7 24Q7 31 12 36Q17 41 24 41Z"/></svg>
  );
};

// Pause icon
const PauseIcon = ({...props})=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props} fill="currentColor"><path d="M18.5 32H21.5V16H18.5ZM26.5 32H29.5V16H26.5ZM24 44Q19.9 44 16.25 42.425Q12.6 40.85 9.875 38.125Q7.15 35.4 5.575 31.75Q4 28.1 4 24Q4 19.9 5.575 16.25Q7.15 12.6 9.875 9.875Q12.6 7.15 16.25 5.575Q19.9 4 24 4Q28.1 4 31.75 5.575Q35.4 7.15 38.125 9.875Q40.85 12.6 42.425 16.25Q44 19.9 44 24Q44 28.1 42.425 31.75Q40.85 35.4 38.125 38.125Q35.4 40.85 31.75 42.425Q28.1 44 24 44ZM24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24ZM24 41Q31 41 36 36Q41 31 41 24Q41 17 36 12Q31 7 24 7Q17 7 12 12Q7 17 7 24Q7 31 12 36Q17 41 24 41Z"/></svg>
  );
};

// Volume icon
const VolumeIcon = ({...props})=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props} fill="currentColor"><path d="M28 41.45V38.35Q32.85 36.95 35.925 32.975Q39 29 39 23.95Q39 18.9 35.95 14.9Q32.9 10.9 28 9.55V6.45Q34.2 7.85 38.1 12.725Q42 17.6 42 23.95Q42 30.3 38.1 35.175Q34.2 40.05 28 41.45ZM6 30V18H14L24 8V40L14 30ZM27 32.4V15.55Q29.7 16.4 31.35 18.75Q33 21.1 33 24Q33 26.95 31.35 29.25Q29.7 31.55 27 32.4ZM21 15.6 15.35 21H9V27H15.35L21 32.45ZM16.3 24Z"/></svg>
  );
};

// Mute icon
const MuteIcon = ({...props})=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props} fill="currentColor"><path d="M40.65 45.2 34.05 38.6Q32.65 39.6 31.025 40.325Q29.4 41.05 27.65 41.45V38.35Q28.8 38 29.875 37.575Q30.95 37.15 31.9 36.45L23.65 28.15V40L13.65 30H5.65V18H13.45L2.45 7L4.6 4.85L42.8 43ZM38.85 33.6 36.7 31.45Q37.7 29.75 38.175 27.85Q38.65 25.95 38.65 23.95Q38.65 18.8 35.65 14.725Q32.65 10.65 27.65 9.55V6.45Q33.85 7.85 37.75 12.725Q41.65 17.6 41.65 23.95Q41.65 26.5 40.95 28.95Q40.25 31.4 38.85 33.6ZM18.6 23.15ZM32.15 26.9 27.65 22.4V15.9Q30 17 31.325 19.2Q32.65 21.4 32.65 24Q32.65 24.75 32.525 25.475Q32.4 26.2 32.15 26.9ZM23.65 18.4 18.45 13.2 23.65 8ZM20.65 32.7V25.2L16.45 21H8.65V27H14.95Z"/></svg>
  );
};

/**
 * Video controls
 *
 * @return {JSX}
 */
const VideoControls = ({callback, controlColors})=>{
  const [play, setPlay] = useState('P');
  const [mute, setMute] = useState('M');

  return (
    <div style={{
      position: 'absolute',
      display: 'flex',
      flexDirection: 'row',
      top: '8px',
      left: '8px',
      zIndex: 10,
    }}>
      {play === 'PA' && <PlayIcon
        height={24}
        width={24}
        style={{
          color: controlColors || '#ffff',
          cursor: 'pointer',
          marginRight: '2px',
        }}
        onClick={()=>{
          callback('P');
          setPlay('P');
        }}
      />}
      {play === 'P' && <PauseIcon
        height={24}
        width={24}
        style={{
          color: controlColors || '#ffff',
          cursor: 'pointer',
          marginRight: '2px',
        }}
        onClick={()=>{
          callback('PA');
          setPlay('PA');
        }}
      />}
      {mute === 'M' && <VolumeIcon
        height={24}
        width={24}
        style={{
          color: controlColors || '#ffff',
          cursor: 'pointer',
          marginRight: '2px',
        }}
        onClick={()=>{
          callback('UM');
          setMute('UM');
        }}
      />}
      {mute === 'UM' && <MuteIcon
        height={24}
        width={24}
        style={{
          color: controlColors || '#ffff',
          cursor: 'pointer',
          marginRight: '2px',
        }}
        onClick={()=>{
          callback('M');
          setMute('M');
        }}
      />}
    </div>
  );
};

VideoControls.propTypes = {
  callback: PropTypes.func,
  controlColors: PropTypes.string,
};

export default VideoControls;
