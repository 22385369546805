import React from 'react';
import PropTypes from 'prop-types';

/**
 * Chip container
 *
 * @return {JSX}
 */
const ChipContainer = ({children, additionalClass, ...props})=>{
  return (
    <div className={`flex ${additionalClass} cursor-pointer`} {...props}>
      {children}
    </div>
  );
};

ChipContainer.propTypes = {
  children: PropTypes.any,
  additionalClass: PropTypes.string,
};

export default ChipContainer;
