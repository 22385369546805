import React from 'react';
import Heading from '../../../components/heading';
import CloseIcon from '../../../icons/close.icon';
import PropTypes from 'prop-types';
import Input from '../../../components/input/input.component';
import Button from '../../../components/button/button.component';
import {useForm} from 'react-hook-form';
import ErrorText from '../../../components/errorComponents/errorText.component';
import {shopifyIntegrationDetails} from '../../../validationSchemas/integration.validation';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch} from 'react-redux';
import {shopifyOauthRedirectionAction} from '../../../actions/integrations.actions';
import QuestionIcon from '../../../icons/question.icon';
import ReactTooltip from 'react-tooltip';

/**
 * Shopify integration modal
 *
 * @return {JSX}
 */
const ShopifyIntegrationModal = ({onClose})=> {
  const {register, handleSubmit, formState: {errors}} = useForm({
    resolver: yupResolver(shopifyIntegrationDetails),
  });
  const dispatch = useDispatch();

  // handling on connect
  const onConnect = (values)=>{
    dispatch(shopifyOauthRedirectionAction(values));
  };

  return (
    <>
      <div className="fixed inset-0 bg-stone-900 bg-opacity-75 transition-opacity z-50" aria-hidden="true"></div>
      <form onSubmit={handleSubmit(onConnect)} className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white w-1/4 p-4 rounded-lg'>
        <Heading className='font-semibold text-lg flex justify-between  mt-4'>
          Integrate Shopify
          <CloseIcon className='cursor-pointer w-4 h-4' onClick={()=>{
            onClose();
          }}/>
        </Heading>
        <div id="input-container">
          <label className='text-sm font-normal mt-4 flex items-center mb-1'>Enter your shop name</label>
          <div className='flex items-center'>
            <Input
              type="text"
              placeholder="Ex: popsuite-shop"
              errorStatus={errors?.storeName?.message}
              {...register('storeName')}
            /><span className="text-sm">.myshopify.com</span>
          </div>
          <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errors?.storeName?.message}</ErrorText>
        </div>
        <div id="input-container" className='w-full'>
          <label className='text-sm font-normal mt-4 flex items-center mb-1'>
            Enter your shop email
            <QuestionIcon
              height={20}
              width={20}
              className={'ml-1'}
              data-tip
              data-for="store-email-instructions"
            />
            <ReactTooltip
              id="store-email-instructions"
              place="top"
              effect="solid"
            >
              <span>This is the email associated with your shopify account</span>
            </ReactTooltip>
          </label>
          <div className='flex items-center w-full'>
            <Input
              type="email"
              placeholder="Ex: example@mail.com"
              errorStatus={errors?.storeEmail?.message}
              parentAdditionalClass={'w-full'}
              {...register('storeEmail')}
            />
          </div>
          <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errors?.storeEmail?.message}</ErrorText>
        </div>
        <div className='flex mt-8 justify-end'>
          <Button type="submit">Connect</Button>
        </div>
      </form>
    </>
  );
};

ShopifyIntegrationModal.propTypes = {
  onClose: PropTypes.func,
};

export default ShopifyIntegrationModal;
