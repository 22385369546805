import React, {useEffect, useState} from 'react';
import Chips from '../../../../components/chips';
import ChipContainer from '../../../../components/chips/chipContainer.component';
import Input from '../../../../components/input/input.component';
import SettingsIcon from '../../../../icons/settings.icon';
import HeadingModal from '../modals/heading.modal';
import {useDispatch, useSelector} from 'react-redux';
import {updateCollapseAction, updateWidgetSettings} from '../../../../actions/widget.actions';
import PropTypes from 'prop-types';
import ErrorText from '../../../../components/errorComponents/errorText.component';
import {COUPON_DEFAULT_VALUES, ACTION_BUTTON_DEFAULT_VALUES, ENTRY_NOTIFICATION_VALUES} from '../../../../constants/defaultValues.const';
import CheckBox from '../../../../components/checkbox';
import CouponConfigModal from '../modals/couponConfig.modal';
import VisibilityIcon from '../../../../icons/visibility.icon';
import HideVisibilityIcon from '../../../../icons/visibility.icon copy';
import InfoWrapper from '../../../../components/wrappers/infoWrapper.component';
import ButtonLinkModal from '../modals/buttonLink.modal';
import CollapsibleComponent from './collapsible.component';
import ColorInput from '../../../../components/input/colorInput.component';
import Heading from '../../../../components/heading';

/**
 * Toggle visibility(for messages)
 *
 * @return {JSX}
 */
const ToggleVisible = ({toggleCallback})=>{
  const [visible, setVisible] = useState(false);
  if (visible) {
    return (<VisibilityIcon
      height={'20'} width={'20'} className={'cursor-pointer mt-1'}
      onClick={()=>{
        setVisible(false);
        toggleCallback(false);
      }}
    />);
  } else {
    return (<HideVisibilityIcon
      height={'20'} width={'20'} className={'cursor-pointer mt-1'}
      onClick={()=>{
        setVisible(true);
        toggleCallback(true);
      }}
    />);
  }
};

ToggleVisible.propTypes = {
  toggleCallback: PropTypes.func,
};

/**
 * Entry Notification Settings component
 *
 * @return {JSX}
 */
const EntryNotificationSettings = ({register, errors, setValue, widgetType, ...props})=>{
  const [entrySuccessModalToggle, setEntrySuccessModalToggle] = useState(false);
  const [entryFailModalToggle, setEntryFailToggle] = useState(false);
  const widgetStore = useSelector((state)=>state.widget);
  const [couponConfigNotificationModal, setCouponConfigNotificationModal] = useState(false);
  const [entrySuccessConfig, setEntrySuccessConfig] = useState({...ENTRY_NOTIFICATION_VALUES});
  const [entryFailConfig, setEntryFailConfig] = useState({...ENTRY_NOTIFICATION_VALUES});
  const [couponConfigNotification, setCouponNotificationConfig] = useState({...COUPON_DEFAULT_VALUES});
  const [ctaButtonNotificationConfig, setCtaButtonNotificationConfig] = useState({...ACTION_BUTTON_DEFAULT_VALUES});
  const [ctaButtonLinkModal, setCtaButtonLinkModal] = useState(false);
  const [toggleCollapsible, setToggleCollapsible] = useState(false);
  const dispatch = useDispatch();

  // Close success modal
  const closeSuccessMessageModal = ()=>{
    setEntrySuccessModalToggle(false);
  };

  // Close fail modal
  const closeFailMessageModal = ()=>{
    setEntryFailToggle(false);
  };

  // Close coupon modal
  const closeCouponModal = ()=>{
    setCouponConfigNotificationModal(false);
  };

  useEffect(()=>{
    setToggleCollapsible(false);
    if (widgetStore?.collapse_widget?.payload?.M) {
      setToggleCollapsible(true);
    }
  }, [widgetStore?.collapse_widget]);

  useEffect(()=>{
    if (widgetStore?.widgetSettings) {
      setEntrySuccessConfig(widgetStore?.widgetSettings?.entrySuccessConfig?{...widgetStore?.widgetSettings?.entrySuccessConfig}:{...ENTRY_NOTIFICATION_VALUES});
      setEntryFailConfig({...widgetStore?.widgetSettings?.entryFailConfig});
      setCouponNotificationConfig({...widgetStore?.widgetSettings?.couponConfigNotification});
      setCtaButtonNotificationConfig({...widgetStore?.widgetSettings?.ctaButtonNotificationConfig});
    }
  }, [widgetStore?.widgetSettings]);

  return (
    <div {...props}>
      <CollapsibleComponent
        type={'M'}
        heading={'Message Settings'}
        defaultValue={toggleCollapsible}
        toggleCallback={(toggle)=>{
          setToggleCollapsible(toggle);
          const tempCollapse = {...widgetStore?.collapse_widget};
          Object.keys(tempCollapse).map((key)=>tempCollapse[key] = false);
          dispatch(updateWidgetSettings({
            toggleStatusMessageSuccess: true,
          }));
          tempCollapse['M'] = toggle;
          dispatch(updateCollapseAction({...tempCollapse}));
        }}
      />

      <div className={toggleCollapsible?'px-2':'hidden'}>
        {/*  Succsess Message */}
        <div id="success-message-container" className='mt-4'>
          {/* title */}
          {(widgetType === 'B' || widgetType === 'BS' || widgetType === 'PS') && (<>
            <label className="text-sm font-normal flex items-center">
              <span className="mr-1">Succsess Message</span><SettingsIcon className={'cursor-pointer mr-1'} height={'20'} width={'20'} onClick={()=>{
                setEntrySuccessModalToggle(true);
              }}/>
              {false && <ToggleVisible
                toggleCallback={(toggleStatus)=>{
                  dispatch(updateWidgetSettings({
                    toggleStatusMessageSuccess: toggleStatus,
                  }));
                }}
              />}
            </label>
            <Input
              parentAdditionalClass={'mt-2'}
              placeholder={'Ex: Thank you for registering'}
              {...register('entryPopupSuccessText', {
                onChange: (event)=>{
                  dispatch(updateWidgetSettings({
                    entryPopupSuccessText: event?.target?.value,
                  }));
                },
              })}
              defaultValue={widgetStore?.widgetSettings?.entryPopupSuccessText}
              errorStatus={errors?.entryPopupSuccessText}
            />
            <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errors?.entryPopupSuccessText?.message}</ErrorText>
          </>)}
          <ChipContainer additionalClass='mt-2' onClick={()=>{
            setEntrySuccessModalToggle(true);
          }}>
            {Object.keys(entrySuccessConfig)?.map((configKey, index)=>(
              <Chips key={index} classOverride={`text-xs p-1 ${entrySuccessConfig[configKey]?.chipColor?'text-white':'bg-slate-200'} rounded-lg mr-2`} style={{backgroundColor: entrySuccessConfig[configKey]?.chipColor}}>{entrySuccessConfig[configKey]?.displayName} : {entrySuccessConfig[configKey]?.value}</Chips>
            ))}
          </ChipContainer>
        </div>

        {/*  Fail Message */}
        <div id="heading-container" className="hidden">
          {/* title */}
          {(widgetType === 'B' || widgetType === 'BS') && (<>
            <label className="text-sm font-normal mt-4 flex items-center">
              <span className="mr-1">Failure Message</span>
              {/* <SettingsIcon className={'cursor-pointer'} height={'20'} width={'20'} onClick={()=>{
                setEntryFailToggle(true);
              }}/> */}
              <ToggleVisible
                toggleCallback={(toggleStatus)=>{
                  dispatch(updateWidgetSettings({
                    toggleStatusMessageFail: toggleStatus,
                  }));
                }}
              />
            </label>
            <Input
              placeholder={'Ex: Please try again later'}
              {...register('entryPopupFailText', {
                onChange: (event)=>{
                  dispatch(updateWidgetSettings({
                    entryPopupFailText: event?.target?.value,
                  }));
                },
              })}
              defaultValue={widgetStore?.widgetSettings?.entryPopupFailText}
              errorStatus={errors?.entryPopupFailText}
            />
            <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errors?.entryPopupFailText?.message}</ErrorText>
          </>)}
          <ChipContainer additionalClass='mt-2'>
            {Object.keys(entryFailConfig)?.map((configKey, index)=>(
              <Chips key={index} classOverride={`text-xs p-1 ${entryFailConfig[configKey]?.chipColor?'text-white':'bg-slate-200'} rounded-lg mr-2`} style={{backgroundColor: entryFailConfig[configKey]?.chipColor}}>{entryFailConfig[configKey]?.displayName} : {entryFailConfig[configKey]?.value}</Chips>
            ))}
          </ChipContainer>
        </div>

        {/* Coupon config */}
        <label className="text-sm font-normal mt-4 flex items-center">
          Dislay Coupon?
        </label>
        <CheckBox
          label='Enable'
          parentAdditionalClass='mt-2'
          defaultValue={widgetStore?.widgetSettings?.couponDisplayNotification}
          onChange={(value)=>{
            dispatch(updateWidgetSettings({
              couponDisplayNotification: value,
            }));
          }}
        />
        {widgetStore?.widgetSettings?.couponDisplayNotification && <div className='mt-4'>
          <label className="text-sm font-normal flex items-center">
            <span className="mr-1">Coupon code</span><SettingsIcon className={'cursor-pointer'} height={'20'} width={'20'} onClick={()=>{
              setCouponConfigNotificationModal(true);
            }}/>
          </label>
          <div className='flex items-center'>
            <Input
              placeholder={'Ex: POP876'}
              parentAdditionalClass={'mr-2 w-full'}
              {...register('couponCodeNotification', {
                onChange: (event)=>{
                  dispatch(updateWidgetSettings({
                    couponCodeNotification: event?.target?.value,
                  }));
                },
              })}
              errorStatus={Boolean(errors?.couponCodeNotification)}
              defaultValue={widgetStore?.widgetSettings?.couponCodeNotification}
            />
          </div>
          <ErrorText displayStatus={true}>{errors?.couponCodeNotification?.message}</ErrorText>
          <ChipContainer additionalClass='mt-2 w-full' onClick={()=>{
            setCouponConfigNotificationModal(true);
          }}>
            {Object.keys(couponConfigNotification)?.map((configKey, index)=>(
              <Chips key={index} classOverride={`text-xs p-1 ${couponConfigNotification[configKey]?.chipColor?'text-white':'bg-slate-200'} rounded-lg mr-2`} style={{backgroundColor: couponConfigNotification[configKey]?.chipColor}}>{couponConfigNotification[configKey]?.displayName} : {couponConfigNotification[configKey]?.value}</Chips>
            ))}
          </ChipContainer>
        </div>}

        {/* CTA button */}
        <label className="text-sm font-normal mt-4 flex items-center">
          Display CTA button?
        </label>
        <CheckBox
          label='Enable'
          parentAdditionalClass='mt-2'
          defaultValue={widgetStore?.widgetSettings?.ctaButtonNotificationEnable}
          onChange={(value)=>{
            dispatch(updateWidgetSettings({
              ctaButtonNotificationEnable: value,
            }));
          }}
        />
        {widgetStore?.widgetSettings?.ctaButtonNotificationEnable && <div id="form-container">
          <div id="action-button-container">
            <div className="flex items-center mt-4">
              <div className='flex-2 mr-2'>
                <Heading className="text-sm font-normal flex items-center">
                  Button Name
                </Heading>
                <Input
                  placeholder={'Ex: Button name'}
                  parentAdditionalClass={'mr-2 w-full mt-1'}
                  {...register('ctaButtonNotificationName', {
                    onChange: (event)=>{
                      dispatch(updateWidgetSettings({
                        ctaButtonNotificationName: event?.target?.value,
                      }));
                    },
                  })}
                  errorStatus={errors?.ctaButtonNotificationName}
                  defaultValue={widgetStore?.widgetSettings?.ctaButtonNotificationName}
                />
                {/* <SettingsIcon className={'cursor-pointer'} height={'20'} width={'20'} onClick={()=>{
                  setCtaButtonLinkModal(true);
                }}/> */}
              </div>
              <div id="button-color" className='flex-1'>
                <label className='text-sm font-normal flex items-center'>Button Color</label>
                <ColorInput
                  defaultValue={widgetStore?.widgetSettings?.ctaButtonNotificationConfig?.actionButtonColor?.value}
                  onChangeValue={(value)=>{
                    const configTemp = {...ctaButtonNotificationConfig, actionButtonColor: {
                      value: value,
                      displayName: 'Button color',
                      chipColor: value,
                    }};
                    setCtaButtonNotificationConfig({...configTemp});
                    dispatch(updateWidgetSettings({
                      ctaButtonNotificationConfig: configTemp,
                    }));
                  }}
                />
              </div>
            </div>
            <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errors?.ctaButtonNotificationName?.message}</ErrorText>
            {widgetType !== 'R' && <div>
              <InfoWrapper
                description='Only https:// links are allowed'
                additionalClass='mt-2'
              />
              <Input
                placeholder={'Ex: https://example.com'}
                parentAdditionalClass={'mr-2 w-full'}
                {...register('ctaButtonNotificationLink', {
                  onChange: (event)=>{
                    dispatch(updateWidgetSettings({
                      ctaButtonNotificationLink: event?.target?.value,
                    }));
                  },
                })}
                errorStatus={errors?.ctaButtonNotificationLink}
                defaultValue={widgetStore?.widgetSettings?.ctaButtonNotificationLink}
              />
              <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errors?.ctaButtonNotificationLink?.message}</ErrorText>
            </div>}
            <ChipContainer additionalClass='mt-2'>
              <Chips classOverride='text-sm text-white font-thin p-1 bg-slate-200 rounded-lg' style={{backgroundColor: ctaButtonNotificationConfig?.actionButtonColor?.value}}>Button color : {ctaButtonNotificationConfig?.actionButtonColor?.value}</Chips>
            </ChipContainer>
          </div>
        </div>}
      </div>

      {/* Success message modal */}
      {entrySuccessModalToggle && <HeadingModal
        hideFontSize={true}
        widgetType={widgetType}
        configValues={{...entrySuccessConfig}}
        className={`transition-all`}
        onClose={()=>closeSuccessMessageModal()}
        backgroundColorToggle={true}
        modalTitle={'Success Message Modal Config'}
        hideFontType={true}
        onConfigChange={(config)=>{
          setEntrySuccessConfig({...config});
          dispatch(updateWidgetSettings({
            entrySuccessConfig: config,
          }));
        }}
      />}

      {/* Fail message modal */}
      {entryFailModalToggle && <HeadingModal
        hideFontSize={true}
        widgetType={widgetType}
        configValues={{...entryFailConfig}}
        className={`transition-all`}
        onClose={()=>closeFailMessageModal()}
        backgroundColorToggle={true}
        modalTitle={'Failure Message Modal Config'}
        hideFontType={true}
        onConfigChange={(config)=>{
          setEntryFailConfig({...config});
          dispatch(updateWidgetSettings({
            entryFailConfig: config,
          }));
        }}
      />}

      {/* Coupon config modal */}
      {couponConfigNotificationModal && <CouponConfigModal
        widgetType={widgetType}
        configValues={{...couponConfigNotification}}
        className={`transition-all`}
        onClose={()=>closeCouponModal()}
        onConfigChange={(config)=>{
          setCouponNotificationConfig({...config});
          dispatch(updateWidgetSettings({
            couponConfigNotification: config,
          }));
        }}
      />}

      {/* Button link modal */}
      {ctaButtonLinkModal && <ButtonLinkModal
        onClose={()=>{
          setCtaButtonLinkModal(false);
        }}
        configValues={{...ctaButtonNotificationConfig}}
        className={`transition-all`}
        onConfigChange={(config)=>{
          setCtaButtonNotificationConfig({...config});
          dispatch(updateWidgetSettings({
            ctaButtonNotificationConfig: config,
          }));
        }}
      />}
    </div>
  );
};

EntryNotificationSettings.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
  setValue: PropTypes.any,
  widgetType: PropTypes.string,
};

export default EntryNotificationSettings;
