import React from 'react';
import PropTypes from 'prop-types';
import SideNav from '../components/sidenav/sidenav.component';

/**
 * Top and side bar template
 *
 * @return {JSX}
 */
const TopSideBarTemplate = ({children, noPadding, hideSideNav})=>{
  return (
    <div className="flex h-screen bg-gray-50 ddark:bg-gray-900">
      {!hideSideNav && <SideNav/>}
      <div className="flex flex-col flex-1 w-full">
        <main className={`h-full overflow-hidden ${noPadding?'':'p-4'} relative`}>
          {children}
        </main>
      </div>
    </div>
  );
};

TopSideBarTemplate.propTypes = {
  children: PropTypes.any,
  noPadding: PropTypes.bool,
  hideSideNav: PropTypes.bool,
};

export default TopSideBarTemplate;
