import {yupResolver} from '@hookform/resolvers/yup';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {updateProfileAction, updateProfileActionClear, updateProfilePasswordAction} from '../../../actions/users.actions';
import Button from '../../../components/button/button.component';
import ErrorText from '../../../components/errorComponents/errorText.component';
import Heading from '../../../components/heading';
import Input from '../../../components/input/input.component';
import InfoWrapper from '../../../components/wrappers/infoWrapper.component';
import {getValue, setValue as setValueLocalStorage} from '../../../config/localStorage.config';
import {MAX_PASSWORD_FIELD_LENGTH, USER_DETAILS} from '../../../constants/application.const';
import {UPDATE_USERS_PROFILE_FAILED, UPDATE_USERS_PROFILE_PASSWORD_FAILED, UPDATE_USERS_PROFILE_PASSWORD_SUCCESS, UPDATE_USERS_PROFILE_SUCCESS} from '../../../constants/reducer.const';
import {profileUserSchema, updatePasswordSchema} from '../../../validationSchemas/profile.validation';

/**
 * Change password
 *
 * @return {JSX}
 */
const ChangePassword = ()=>{
  const dispatch = useDispatch();
  const {register, handleSubmit, formState: {errors}, reset} = useForm({
    resolver: yupResolver(updatePasswordSchema),
  });
  const userSelector = useSelector((data)=>data.user);

  /**
   * Update password
   *
   * @param {any} value
   */
  const updatePassword = (value)=>{
    dispatch(updateProfilePasswordAction({
      oldPassword: value?.oldPassword,
      password: value?.password,
    }));
  };

  useEffect(()=>{
    if (userSelector?.profilePasswordUpdateStatus?.type === UPDATE_USERS_PROFILE_PASSWORD_SUCCESS) {
      toast(userSelector?.profilePasswordUpdateStatus?.payload?.message, {
        type: 'success',
      });
      reset();
    }
    if (userSelector?.profilePasswordUpdateStatus?.type === UPDATE_USERS_PROFILE_PASSWORD_FAILED) {
      toast(userSelector?.profilePasswordUpdateStatus?.payload?.message, {
        type: 'error',
      });
    }
  }, [userSelector?.profilePasswordUpdateStatus]);

  return (
    <form onSubmit={handleSubmit(updatePassword)}>
      <InfoWrapper
        description='Change your password'
        additionalClass='mt-2'
      >
        <div>
          <label className="block mt-4 text-sm">
            <span className="text-gray-700 ddark:text-gray-400">Password</span>
            <Input
              name="oldPassword"
              placeholder={'Enter Old Password'}
              type={'password'}
              {...register('oldPassword')}
              maxLength={MAX_PASSWORD_FIELD_LENGTH}
            />
            <ErrorText displayStatus={true}>{errors.oldPassword?.message}</ErrorText>
          </label>
          <label className="block mt-4 text-sm">
            <span className="text-gray-700 ddark:text-gray-400">Password</span>
            <Input
              name="password"
              placeholder={'Enter New Password'}
              type={'password'}
              {...register('password')}
              maxLength={MAX_PASSWORD_FIELD_LENGTH}
            />
            <ErrorText displayStatus={true}>{errors.password?.message}</ErrorText>
          </label>
          <label className="block mt-4 text-sm">
            <span className="text-gray-700 ddark:text-gray-400">Confirm Password</span>
            <Input
              placeholder={'Confirm Password'}
              type={'password'}
              name="cpassword"
              {...register('cpassword')}
              maxLength={MAX_PASSWORD_FIELD_LENGTH}
            />
            <ErrorText displayStatus={true}>{errors.cpassword?.message}</ErrorText>
          </label>
        </div>
        <div className='flex mt-8 justify-end'>
          <Button type="submit">Update</Button>
        </div>
      </InfoWrapper>
    </form>
  );
};

/**
 * Profile page
 *
 * @return {JSX}
 */
const ProfileComponent = ()=>{
  const {register, handleSubmit, setValue, formState: {errors}} = useForm({
    resolver: yupResolver(profileUserSchema),
  });
  const dispatch = useDispatch();
  const userSelector = useSelector((data)=>data.user);
  const [currentUserId, setCurretUserId] = useState('');
  const [updatedValue, setUpdatedValue] = useState({});

  /**
   * Update profile
   *
   * @param {any} value
   */
  const updateProfile = (value)=>{
    setUpdatedValue({...value});
    dispatch(updateProfileAction({
      updateId: currentUserId,
      ...value,
    }));
  };

  /**
   * Load profile details
   */
  const loadProfileDetails = ()=>{
    const userDataRaw = getValue(USER_DETAILS);
    if (userDataRaw) {
      const userData = JSON.parse(userDataRaw);
      setValue('name', userData?.name);
      setValue('company', userData?.company);
      setValue('email', userData?.email);
      setValue('phone', userData?.phone);
      setValue('industry', userData?.industry);
      setValue('country', userData?.country);
      setCurretUserId(userData?.id);
    }
  };

  useEffect(()=>{
    loadProfileDetails();
  }, []);

  /**
   * Update new values in local storage
   */
  const updateLocalStorageData = ()=>{
    const userDataRaw = getValue(USER_DETAILS);
    if (userDataRaw) {
      const userData = JSON.parse(userDataRaw);
      userData.name = updatedValue?.name;
      userData.email = updatedValue?.email;
      userData.company = updatedValue?.company;
      userData.phone = updatedValue?.phone;
      userData.country = updatedValue?.country;
      userData.industry = updatedValue?.industry;
      setValueLocalStorage(USER_DETAILS, JSON.stringify(userData));
    }
  };

  useEffect(()=>{
    if (userSelector?.profileUpdateStatus?.type === UPDATE_USERS_PROFILE_SUCCESS) {
      toast(userSelector?.profileUpdateStatus?.payload?.message, {
        type: 'success',
      });
      updateLocalStorageData();
      dispatch(updateProfileActionClear());
    }
    if (userSelector?.profileUpdateStatus?.type === UPDATE_USERS_PROFILE_FAILED) {
      toast(userSelector?.profileUpdateStatus?.payload?.message, {
        type: 'error',
      });
    }
  }, [userSelector?.profileUpdateStatus]);

  return (
    <div className="p-6 w-1/2">
      <div className='mt-4'>
        <Heading className={'font-semibold text-xl'}>Profile</Heading>
      </div>
      <form onSubmit={handleSubmit(updateProfile)} className="mt-4 mb-2" noValidate>
        <label className="col-span-4 block text-sm">
          <span className="text-gray-700 ddark:text-gray-400">Name</span>
          <Input
            type='text'
            placeholder="Enter your Name"
            name="name"
            {...register('name')}
          />
          <ErrorText displayStatus={true}>{errors?.name?.message}</ErrorText>
        </label>
        <label className="col-span-4 block text-sm mt-4">
          <span className="text-gray-700 ddark:text-gray-400">Company Name</span>
          <Input
            type='text'
            placeholder="Enter your Company name"
            name="company"
            {...register('company')}
          />
          <ErrorText displayStatus={true}>{errors?.company?.message}</ErrorText>
        </label>
        <label className="col-span-4 block text-sm mt-4">
          <span className="text-gray-700 ddark:text-gray-400">Email</span>
          <Input
            type='email'
            placeholder="Enter your Email"
            name="email"
            {...register('email')}
          />
          <ErrorText displayStatus={true}>{errors?.email?.message}</ErrorText>
        </label>
        <label className="col-span-4 block text-sm mt-4">
          <span className="text-gray-700 ddark:text-gray-400">Phone</span>
          <Input
            type='text'
            placeholder="Enter your Phone"
            name="phone"
            {...register('phone')}
            errorStatus={Boolean(errors?.phone?.message)}
          />
          <ErrorText displayStatus={true}>{errors?.phone?.message}</ErrorText>
        </label>
        <label className="col-span-4 block text-sm mt-4">
          <span className="text-gray-700 ddark:text-gray-400">Country</span>
          <Input
            type='text'
            placeholder="Enter your Country"
            name="country"
            {...register('country')}
          />
          <ErrorText displayStatus={true}>{errors?.country?.message}</ErrorText>
        </label>
        <label className="col-span-4 block text-sm mt-4">
          <span className="text-gray-700 ddark:text-gray-400">Industry</span>
          <Input
            type='text'
            placeholder="Enter your Industry"
            name="industry"
            {...register('industry')}
          />
          <ErrorText displayStatus={true}>{errors?.industry?.message}</ErrorText>
        </label>
        <div className='flex mt-8 justify-end'>
          <Button type="button" classOverride={'flex items-center justify-center px-3 py-2 mr-2 text-sm font-medium text-purple-600 duration-150 bg-transparent border-2 border-purple-600 rounded-lg hover:border-purple-700 active:border-purple-500 focus:outline-none focus:shadow-outline-purple'} onClick={loadProfileDetails}>Reset</Button>
          <Button type="submit">Update</Button>
        </div>
      </form>
      <ChangePassword/>
    </div>
  );
};

export default ProfileComponent;
