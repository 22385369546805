import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {MEDIA_TYPE_MAPPER} from '../../constants/widget.const';
import Button from '../button/button.component';
import {useDispatch} from 'react-redux';
import {updateWidgetSettings} from '../../actions/widget.actions';
import {useNavigate} from 'react-router-dom';
import EllipsisIcon from '../../icons/ellipsis.icon';
import DropDownWrapper from '../dropDownWrapper/DropDownWrapper.component';
import {PRIMARY_COLOR} from '../../constants/application.const';
import WidgetIcon from '../../icons/widget.icon';
import PencilIcon from '../../icons/pencil.icon';
import DownloadIcon from '../../icons/download.icon';
import BinIcon from '../../icons/bin.icon';
import {deleteVideoAction} from '../../actions/videoUpload.action';
import UpdateVideoDetailsModalComponent from '../modal/updateVideoDetailsModal.component';
import {downloadFileBrowser, limitTextForDisplay} from '../../helpers/common.helper';
import ReactTooltip from 'react-tooltip';

/**
 * Component to display media
 *
 * @return {JSX}
 */
const MediaRenderer = ({type, src, additionalClass, heading, selectionButton, videoDetails, manageMode, uploadModule})=>{
  const [dropDownToggle, setDropDownToggle] = useState(false);
  const [updateNameModal, setUpdateNameModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Toggle dropdown
  const toggleDropDown = ()=>{
    setDropDownToggle((prev)=>!prev);
  };

  // Updates the video in widget
  const useMediaForWidget = (preventNavigation = false)=>{
    if (uploadModule === 'LOGO') {
      dispatch(updateWidgetSettings({
        logoDetails: {
          ...videoDetails,
          mediaPath: src,
        }}));
      if (!preventNavigation) {
        navigate(-1);
      }
    } else {
      dispatch(updateWidgetSettings({
        videoDetails: {
          ...videoDetails,
          mediaPath: src,
        }}));
      if (!preventNavigation) {
        navigate(-1);
      }
    }
  };

  // Delete video
  const onOptionSelect = (value)=>{
    toggleDropDown();
    if (value === 'DE' && videoDetails?.id) {
      dispatch(deleteVideoAction(videoDetails?.id));
    }
    if (value === 'R' && videoDetails?.id) {
      setUpdateNameModal(true);
    }
    if (value === 'D' && videoDetails?.id) {
      downloadFileBrowser(src);
    }
    if (value === 'W' && videoDetails?.id) {
      useMediaForWidget(true);
      navigate('/widgets/create');
    }
  };

  return (<>
    <div className={`rounded-md border-2 border-slate-300 bg-white p-2 relative object-contain ${additionalClass} w-60 max-h-96`}>
      {type === MEDIA_TYPE_MAPPER.V.value && (<video className='h-56 w-56 object-cover' controls>
        <source src={src}></source>
      </video>
      )}
      {type === MEDIA_TYPE_MAPPER.I.value && (<img className='h-56 w-56 object-cover' src={src} />
      )}
      <div className='flex justify-between py-2 items-center'>
        <p className='text-sm font-bold break-all' data-tip data-for={'heading'+videoDetails?.id}>
          <span>{limitTextForDisplay(heading)}</span>
        </p>
        <ReactTooltip
          id={'heading'+videoDetails?.id}
          place="bottom"
          effect="solid"
        >
          <span>{heading}</span>
        </ReactTooltip>
        {manageMode && <DropDownWrapper
          open={dropDownToggle}
          onClose={()=>setDropDownToggle(false)}
          buttonComponent={
            <Button
              onClick={()=>toggleDropDown()}
              classOverride='flex items-center justify-center px-3 py-2 text-sm font-medium text-white rounded-lg focus:outline-none focus:shadow-outline-purple'
              buttonIcon={<EllipsisIcon color={PRIMARY_COLOR}/>}
            />
          }
          onOptionClick={onOptionSelect}
          buttonOptions={[
            {text: 'Widgets', icon: <WidgetIcon color={'#000000'}/>, value: 'W'},
            {text: 'Rename', icon: <PencilIcon/>, value: 'R'},
            {text: 'Download', icon: <DownloadIcon />, value: 'D'},
            {text: 'Delete', icon: <BinIcon color="#ef4444"/>, class: 'text-red-500', value: 'DE'},
          ]}
        />}
      </div>
      {selectionButton && !manageMode && <Button className={'mt-2 w-full'}
        onClick={()=>{
          useMediaForWidget();
        }}
      >{selectionButton?.buttonName}</Button>}
    </div>
    {updateNameModal && <UpdateVideoDetailsModalComponent
      onClose={()=>{
        setUpdateNameModal(false);
      }}
      videoDetails={{...videoDetails}}
    />}
  </>);
};

MediaRenderer.propTypes = {
  type: PropTypes.string,
  src: PropTypes.string,
  additionalClass: PropTypes.string,
  heading: PropTypes.string,
  selectionButton: PropTypes.any,
  videoDetails: PropTypes.any,
  manageMode: PropTypes.bool,
  uploadModule: PropTypes.string,
};

export default MediaRenderer;
