import axios from 'axios';
import {BASE_URL} from '../constants/application.const';
import {objectToQueryString} from '../helpers/common.helper';

/**
 * Get customer
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const getCustomers = (payload)=>{
  return axios.get(`${BASE_URL}customers/get-customers/${payload?.widgetId}`)
      .then((response) => (response?.data));
};

/**
 * Add Customer
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const addCustomers = (payload)=>{
  return axios.post(`${BASE_URL}customers/add-customer-admin`, payload)
      .then((response) => (response?.data));
};

/**
 * Delete customer
 *
 * @param {any} data
 * @return {Promise<any>}
 */
export const deleteCustomer = (data)=>{
  return axios.delete(`${BASE_URL}customers/delete-customers/${data?.id}`)
      .then((response) => (response?.data));
};

/**
 * Get customer
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const getCustomersForExport = (payload)=>{
  return axios.get(`${BASE_URL}export/export-customers-widget/${payload?.widgetId}`)
      .then((response) => (response?.data));
};

/**
 * Get survey
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const getSurveyForExport = (payload)=>{
  return axios.get(`${BASE_URL}export/export-survey-stats/${payload?.widgetId}`)
      .then((response) => (response?.data));
};

/**
 * Get survey stats
 *
 * @param {any} query
 * @return {Promise<any>}
 */
export const getSurveyStats = (query)=>{
  return axios.get(`${BASE_URL}customers/get-survey-data${objectToQueryString(query)}`)
      .then((response) => (response?.data));
};

