import React from 'react';
import {Outlet, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import Heading from '../../components/heading';
import ProfileIcon from '../../icons/profile.icon';
import TopSideBarTemplate from '../../templates/topSideBar.template';
import ProfileComponent from './components/profile.component';
import LiWrapper from '../../components/liWrapper';
import CurrentPlan from './components/currentPlan.component';
import PlansIcon from '../../icons/plans.icon';
import Analytics from '../analytics';
import AnalyticsIcon from '../../icons/analytics.icon';

/**
 * Settings page
 *
 * @return {JSX}
 */
const SettingsPage = ()=>{
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <TopSideBarTemplate
      noPadding={true}
    >
      <div className='bg-white grid grid-cols-4 gap-2 h-screen'>
        <div className='col-span-1 px-4 pt-8 bg-gray-50'>
          <Heading className={'font-semibold text-xl'}>Settings</Heading>
          <ul className='mt-4'>
            <LiWrapper
              active={location?.pathname === '/settings'}
              onClick={()=>navigate('/settings')}
            >
              <ProfileIcon
                height="24"
                width="24"
                className="mr-2"
              />
              <span className='text-sm'>Profile</span>
            </LiWrapper>
            <LiWrapper
              active={location?.pathname === '/settings/current-plan'}
              onClick={()=>navigate('/settings/current-plan')}
            >
              <PlansIcon
                height="24"
                width="24"
                className="mr-2"
              />
              <span className='text-sm'>Plan and pricing</span>
            </LiWrapper>
            <LiWrapper
              active={location?.pathname === '/settings/analytics'}
              onClick={()=>navigate('/settings/analytics')}
            >
              <AnalyticsIcon
                height="24"
                width="24"
                className="mr-2"
              />
              <span className='text-sm'>Analytics</span>
            </LiWrapper>
          </ul>
        </div>
        <div className='col-span-3 rounded-l-xl overflow-y-auto'>
          <Outlet/>
          <Routes>
            <Route index element={<ProfileComponent />} />
            <Route path="current-plan" element={<CurrentPlan />} />
            <Route path="analytics" element={<Analytics />} />
          </Routes>
        </div>
      </div>
    </TopSideBarTemplate>
  );
};

export default SettingsPage;
