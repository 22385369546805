import React from 'react';
import PropTypes from 'prop-types';

/**
 * Media Settings icon for whole project
 *
 * @return {JSX}
 */
const MediaSettingsIcon = ({height, width, title, ...props})=>{
  return (
    <svg width={width || '25'} height={height || '25'} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}><path fill="#ffff" d="M0 0h32v32H0z"></path><path d="M8 6v6L0 6v20l8-6v6h6.523c1.236 3.493 4.558 5.998 8.477 6 3.917-.002 7.239-2.507 8.477-6H32V6H8zm15 23.883A6.898 6.898 0 0 1 16.115 23 6.898 6.898 0 0 1 23 16.115 6.898 6.898 0 0 1 29.883 23 6.898 6.898 0 0 1 23 29.883z" fill="currentColor"></path><path d="M28 24v-2.001h-1.663a3.446 3.446 0 0 0-.245-.606l1.187-1.187-1.416-1.415-1.165 1.166a3.43 3.43 0 0 0-.697-.294V18h-2v1.662a3.366 3.366 0 0 0-.652.27l-1.141-1.14-1.415 1.415 1.14 1.14a3.47 3.47 0 0 0-.271.653H18v2h1.662c.073.246.172.479.295.698l-1.165 1.163 1.413 1.416 1.188-1.187c.192.101.394.182.605.245V28H24v-1.665c.229-.068.445-.158.651-.27l1.212 1.212 1.414-1.416-1.212-1.21c.111-.206.201-.423.27-.651H28zm-5.001.499a1.503 1.503 0 0 1-1.501-1.5 1.505 1.505 0 0 1 1.501-1.501 1.503 1.503 0 0 1 1.5 1.501 1.502 1.502 0 0 1-1.5 1.5z" fill="currentColor"></path></svg>
  );
};

MediaSettingsIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  title: PropTypes.string,
};

export default MediaSettingsIcon;
