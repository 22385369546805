import React, {useEffect, useState} from 'react';
import Heading from '../../components/heading';
import PlayIcon from '../../icons/play.icon';
import ClickThroughRate from '../../icons/analytics/clickThroughRate.icon';
import Impessions from '../../icons/analytics/impresssion.icon';
import {useDispatch, useSelector} from 'react-redux';
import {getWidgetAnalyticsAction} from '../../actions/widget.actions';
import {GET_WIDGET_ANALYTICS_SUCCESS} from '../../constants/reducer.const';
import {generateRandom} from '../../helpers/common.helper';
import PageLoader from '../../components/pageloader';

/**
 * Overall analytics
 *
 * @return {JSX}
 */
const Analytics = ()=>{
  const dispatch = useDispatch();
  const [loader, setLoader] = useState();
  const widgetStore = useSelector((state)=>state.widget);
  const [analyticsData, setAnalyticsData] = useState({
    countStats: [],
    graphData: [],
  });

  useEffect(()=>{
    setLoader(true);
    setAnalyticsData({
      countStats: [],
      graphData: [],
    });
    dispatch(getWidgetAnalyticsAction({widgetId: 'EMPTY'}));
  }, []);

  useEffect(()=>{
    if (widgetStore?.widgetAnalytics?.type === GET_WIDGET_ANALYTICS_SUCCESS) {
      setTimeout(()=>{
        setLoader(false);
        setAnalyticsData(widgetStore?.widgetAnalytics?.payload?.data);
      }, generateRandom(4, 8)*1000);
    }
  }, [widgetStore?.widgetAnalytics]);

  return (
    <div className="p-6">
      <Heading className={'font-semibold text-xl mt-4'}>Analytics(Overall)</Heading>
      <div className='grid grid-cols-3 gap-4 mt-4'>
        <div className='grid grid-cols-2 gap-2 bg-white bg-gray-50 rounded-md p-4 py-6'>
          <div className=''>
            <p className='font-semibold text-2xl'>{analyticsData?.countStats[0]?.mediaViews || 0}</p>
            <Heading className='text-md text-gray-700'>Media views(Image/Video)</Heading>
          </div>
          <div className='flex items-center justify-center'>
            <PlayIcon
              height="48"
              width="48"
              className="text-blue-500"
            />
          </div>
          <p className='text-sm font-sm text-gray-400 col-span-2'>
              Media view count includes Image/Video views.
          </p>
        </div>
        <div className='grid grid-cols-2 gap-2 bg-white bg-gray-50 rounded-md p-4 py-6'>
          <div className=''>
            <p className='font-semibold text-2xl'>{analyticsData?.countStats[0]?.mediaViewsUnique || 0}</p>
            <Heading className='text-md text-gray-700'>Media views(Image/Video) - Unique</Heading>
          </div>
          <div className='flex items-center justify-center'>
            <PlayIcon
              height="48"
              width="48"
              className="text-blue-500"
            />
          </div>
          <p className='text-sm font-sm text-gray-400 col-span-2'>
              Media view count includes Image/Video views.
          </p>
        </div>
        <div className='grid grid-cols-2 gap-2 bg-white bg-gray-50 rounded-md p-4 py-6'>
          <div className=''>
            <p className='font-semibold text-2xl'>{analyticsData?.countStats[0]?.ctr || 0}</p>
            <Heading className='text-md text-gray-700'>Click Through Rate</Heading>
          </div>
          <div className='flex items-center justify-center'>
            <ClickThroughRate
              height="48"
              width="48"
              className="text-purple-500"
            />
          </div>
          <p className='text-sm font-sm text-gray-400 col-span-2'>
              Click through rate count includes successful button clicks(Only counted if the intended action is completed).
          </p>
        </div>
        <div className='grid grid-cols-2 gap-2 bg-white bg-gray-50 rounded-md p-4 py-6'>
          <div className=''>
            <p className='font-semibold text-2xl'>{analyticsData?.countStats[0]?.ctrUnique || 0}</p>
            <Heading className='text-md text-gray-700'>Click Through Rate(Unique)</Heading>
          </div>
          <div className='flex items-center justify-center'>
            <ClickThroughRate
              height="48"
              width="48"
              className="text-purple-500"
            />
          </div>
          <p className='text-sm font-sm text-gray-400 col-span-2'>
              Click through rate count includes successful button clicks(Only counted if the intended action is completed).
          </p>
        </div>
        <div className='grid grid-cols-2 gap-2 bg-white bg-gray-50 rounded-md p-4 py-6'>
          <div className=''>
            <p className='font-semibold text-2xl'>{analyticsData?.countStats[0]?.impressions || 0}</p>
            <Heading className='text-md text-gray-700'>Impressions/Page Views</Heading>
          </div>
          <div className='flex items-center justify-center'>
            <Impessions
              height="48"
              width="48"
              className="text-orange-500"
            />
          </div>
          <p className='text-sm font-sm text-gray-400 col-span-2'>
              Impressions include foucs on input component(Even if the user did not complete the action). Widget state change(Example: Bubble opens up) are also included in Impressions.
          </p>
        </div>
        <div className='grid grid-cols-2 gap-2 bg-white bg-gray-50 rounded-md p-4 py-6'>
          <div className=''>
            <p className='font-semibold text-2xl'>{analyticsData?.countStats[0]?.impressionsUnique || 0}</p>
            <Heading className='text-md text-gray-700'>Impressions/Page Views(Unique)</Heading>
          </div>
          <div className='flex items-center justify-center'>
            <Impessions
              height="48"
              width="48"
              className="text-orange-500"
            />
          </div>
          <p className='text-sm font-sm text-gray-400 col-span-2'>
              Impressions include foucs on input component(Even if the user did not complete the action). Widget state change(Example: Bubble opens up) are also included in Impressions.
          </p>
        </div>
      </div>
      {loader && <PageLoader
        text={'Fetching Analyics...'}
      />}
    </div>
  );
};

export default Analytics;
