import {CLEAR_USER_REQUEST, GET_USERS_ADMIN_FAILED, GET_USERS_ADMIN_SUCCESS, GET_USERS_LOGIN_CLEAR, GET_USERS_LOGIN_FAILED, GET_USERS_LOGIN_SUCCESS, UPDATE_USERS_PROFILE_CLEAR, UPDATE_USERS_PROFILE_FAILED, UPDATE_USERS_PROFILE_PASSWORD_CLEAR, UPDATE_USERS_PROFILE_PASSWORD_FAILED, UPDATE_USERS_PROFILE_PASSWORD_SUCCESS, UPDATE_USERS_PROFILE_SUCCESS, UPDATE_USERS_STATUS_ADMIN_CLEAR, UPDATE_USERS_STATUS_ADMIN_FAILED, UPDATE_USERS_STATUS_ADMIN_SUCCESS, USER_LOGIN_FAILURE, USER_LOGIN_SUCCESS, USER_REGISTER_FAILURE, USER_REGISTER_SUCCESS} from '../constants/reducer.const';

const initialState = {};

/**
 * Widget settings reducer function
 *
 * @param {any} state
 * @param {any} action
 * @return {any}
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case USER_REGISTER_SUCCESS: return {...state, ...action};
    case USER_REGISTER_FAILURE: return {...state, ...action};
    case USER_LOGIN_SUCCESS: return {...state, ...action};
    case USER_LOGIN_FAILURE: return {...state, ...action};
    case CLEAR_USER_REQUEST: return {};

    case GET_USERS_ADMIN_SUCCESS: return {...state, users: {...action}};
    case GET_USERS_ADMIN_FAILED: return {...state, users: {...action}};

    case GET_USERS_LOGIN_SUCCESS: return {...state, userLoginDetails: {...action}};
    case GET_USERS_LOGIN_FAILED: return {...state, userLoginDetails: {...action}};
    case GET_USERS_LOGIN_CLEAR: return {...state, userLoginDetails: {}};

    case UPDATE_USERS_STATUS_ADMIN_SUCCESS: return {...state, userStatus: {...action}};
    case UPDATE_USERS_STATUS_ADMIN_FAILED: return {...state, userStatus: {...action}};
    case UPDATE_USERS_STATUS_ADMIN_CLEAR: return {...state, userStatus: {}};

    case UPDATE_USERS_PROFILE_SUCCESS: return {...state, profileUpdateStatus: {...action}};
    case UPDATE_USERS_PROFILE_FAILED: return {...state, profileUpdateStatus: {...action}};
    case UPDATE_USERS_PROFILE_CLEAR: return {...state, profileUpdateStatus: {}};

    case UPDATE_USERS_PROFILE_PASSWORD_SUCCESS: return {...state, profilePasswordUpdateStatus: {...action}};
    case UPDATE_USERS_PROFILE_PASSWORD_FAILED: return {...state, profilePasswordUpdateStatus: {...action}};
    case UPDATE_USERS_PROFILE_PASSWORD_CLEAR: return {...state, profilePasswordUpdateStatus: {}};

    default: return {...state};
  }
}
