import {all, call, put, takeLatest} from 'redux-saga/effects';
import {ADD_CUSTOMER_FAILED, ADD_CUSTOMER_REQUEST, ADD_CUSTOMER_SUCCESS, GET_CUSTOMER_FAILED, GET_CUSTOMER_REQUEST, GET_CUSTOMER_SUCCESS, DELETE_CUSTOMER_SUCCESS, DELETE_CUSTOMER_FAILED, DELETE_CUSTOMER_REQUEST, GET_SURVEY_STATS_SUCCESS, GET_SURVEY_STATS_FAILED, GET_SURVEY_STATS_REQUEST} from '../constants/reducer.const';
import {logoutUnauthorized} from '../helpers/common.helper';
import * as customerService from '../services/customer.service';

/**
 * video upload saga
 *
 * @param {Object} action
 */
function* addCustomer(action) {
  try {
    const response = yield call(customerService.addCustomers, action.payload);
    yield put({type: ADD_CUSTOMER_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: ADD_CUSTOMER_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * add customer saga watcher
 */
function* addCustomerWatcher() {
  yield takeLatest(ADD_CUSTOMER_REQUEST, addCustomer);
}

/**
 * get all customer list saga
 *
 * @param {Object} action
 */
function* getCustomerList(action) {
  try {
    const response = yield call(customerService.getCustomers, action.payload);
    yield put({type: GET_CUSTOMER_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: GET_CUSTOMER_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * customer list saga watcher
 */
function* getCustomerListWatcher() {
  yield takeLatest(GET_CUSTOMER_REQUEST, getCustomerList);
}

/**
 * delete customer
 *
 * @param {Object} action
 */
function* deleteCustomer(action) {
  try {
    const response = yield call(customerService.deleteCustomer, action.payload);
    yield put({type: DELETE_CUSTOMER_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: DELETE_CUSTOMER_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * delete customer saga watcher
 */
function* deleteCustomerWatcher() {
  yield takeLatest(DELETE_CUSTOMER_REQUEST, deleteCustomer);
}

/**
 * Get Survey stats
 *
 * @param {Object} action
 */
function* getSurveyStats(action) {
  try {
    const response = yield call(customerService.getSurveyStats, action.payload);
    yield put({type: GET_SURVEY_STATS_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: GET_SURVEY_STATS_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * Get Survey stats saga watcher
 */
function* getSurveyStatsWatcher() {
  yield takeLatest(GET_SURVEY_STATS_REQUEST, getSurveyStats);
}

/**
 * exporting sagas after they are initialised
 */
export default function* rootSaga() {
  yield all([
    addCustomerWatcher(),
    getCustomerListWatcher(),
    deleteCustomerWatcher(),
    getSurveyStatsWatcher(),
  ]);
};
