import React from 'react';
import PropTypes from 'prop-types';
import Search from '../../icons/search.icon';
import Enter from '../../icons/enter.icon';

/**
 * Input component
 *
 * @param {any} ref
 * @return {JSX}
 */
const Input = React.forwardRef(({className, classReplace, searchEnable, parentAdditionalClass, errorStatus, enterEnable, onEnterPressed, prefix, ...props}, ref) => {
  return (
    <div className={`relative ${parentAdditionalClass} flex items-center`}>
      {prefix && <div className='rounded-l-lg bg-slate-200 mt-1 text-sm' style={{padding: '11px'}}>
        {prefix?.text}
      </div>}
      <input ref={ref} className={classReplace?classReplace:`mt-1 input-component bg-white ${errorStatus?'border-2 border-red-700 focus:outline-none focus:border-red-500':'border border-slate-200 focus:outline-none focus:border-purple-500 focus:ring-purple-500 focus:ring-1'} placeholder-slate-400 block w-full rounded-md ${prefix?'rounded-l-none':''} sm:text-sm ${className}`} {...props}>
      </input>
      {searchEnable && <Search className={'absolute right-3 text-slate-400 bg-white'}/>}
      {enterEnable && <Enter
        className={'absolute right-3 text-slate-400 bg-white cursor-pointer'}
        onClick={onEnterPressed}
      />}
    </div>
  );
});
Input.displayName = 'Input';

Input.propTypes = {
  className: PropTypes.string,
  classReplace: PropTypes.string,
  searchEnable: PropTypes.bool,
  parentAdditionalClass: PropTypes.string,
  errorStatus: PropTypes.bool,
  enterEnable: PropTypes.bool,
  onEnterPressed: PropTypes.func,
  prefix: PropTypes.any,
};

export default Input;
