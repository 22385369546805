import axios from 'axios';
import {BASE_URL} from '../constants/application.const';

/**
 * Integration store
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const addIntegration = (payload)=>{
  return axios.post(`${BASE_URL}integrations/add-integration/${payload?.type}`, payload)
      .then((response) => (response?.data));
};

/**
 * Get integration
 *
 * @return {Promise<any>}
 */
export const getIntegrations = ()=>{
  return axios.get(`${BASE_URL}integrations/get-integrations`)
      .then((response) => (response?.data));
};

/**
 * Update integration
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const updateIntegrations = (payload)=>{
  return axios.put(`${BASE_URL}integrations/update-integration/${payload?.type}`, payload)
      .then((response) => (response?.data));
};

/**
 * Get integration
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const shopifyOauthRedirection = (payload)=>{
  return axios.post(`${BASE_URL}integrations/shopify-oauth-redirect`, payload)
      .then((response) => (response?.data));
};

/**
 * Get integration
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const shopifyGetAccessToken = (payload)=>{
  return axios.post(`${BASE_URL}integrations/shopify-get-access-token`, payload)
      .then((response) => (response?.data));
};

/**
 * Install shopify app
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const installShopifyApplication = (payload)=>{
  return axios.post(`${BASE_URL}integrations/install-shopify-app`, payload)
      .then((response) => (response?.data));
};

/**
 * Uninstall shopify app
 *
 * @return {Promise<any>}
 */
export const uninstallShopifyApplication = ()=>{
  return axios.delete(`${BASE_URL}integrations/uninstall-shopify-app`, {})
      .then((response) => (response?.data));
};

/**
 * Get integration status
 *
 * @return {Promise<any>}
 */
export const getIntegrationStatus = ()=>{
  return axios.get(`${BASE_URL}integrations/get-integration-status`)
      .then((response) => (response?.data));
};

/**
 * Get shopify installation status
 *
 * @return {Promise<any>}
 */
export const getShopifyInstallationStatus = ()=>{
  return axios.get(`${BASE_URL}integrations/check-shopify-install-status`)
      .then((response) => (response?.data));
};

/**
 * Uninstall shopify app
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const deleteIntegration = (payload)=>{
  return axios.delete(`${BASE_URL}integrations/delete-integration/${payload?.id}`)
      .then((response) => (response?.data));
};

/**
 * Get pabbly list
 *
 * @return {Promise<any>}
 */
export const getPabblyList = ()=>{
  return axios.get(`${BASE_URL}integrations/get-customers-webhook`)
      .then((response) => (response?.data));
};

/**
 * Update email shopify
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const updateShopifyEmail = (payload)=>{
  return axios.post(`${BASE_URL}integrations/update-shopify-email`, payload)
      .then((response) => (response?.data));
};

