import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useStopwatch, useTimer} from 'react-timer-hook';
import {COUNTER_DIRECTION_MAPPER} from './application.const';

/**
 * Timer component
 *
 * @return {JSX}
 */
function Timer({counterConfig}) {
  const {
    seconds,
    minutes,
    hours,
    days,
    restart,
  } = useTimer({expiryTimestamp: new Date()});
  const timerObj = useStopwatch({autoStart: true});

  useEffect(()=>{
    if (counterConfig?.counterDate) {
      restart(new Date(counterConfig?.counterDate));
    }
    if (counterConfig?.direction === COUNTER_DIRECTION_MAPPER.RF.value) {
      timerObj.reset();
      timerObj.start();
    }
  }, [counterConfig?.counterDate, counterConfig?.direction]);

  return (
    <div style={{display: 'flex', justifyContent: 'space-evenly', margin: '1rem 0rem 1rem 0rem', width: '100%'}}>
      { counterConfig?.direction === COUNTER_DIRECTION_MAPPER.RB.value && <>
        <div style={{textAlign: 'center', fontSize: '14px', marginRight: '10px', width: '100%'}}>
          <div style={{color: '#fff', backgroundColor: counterConfig?.fieldColor?.value || '#0000', padding: '8px', borderRadius: '10px'}}>{days}</div>
          <span style={{color: counterConfig?.fieldColor?.value || '#0000'}}>Days</span>
        </div>
        <div style={{textAlign: 'center', fontSize: '14px', marginRight: '10px', width: '100%'}}>
          <div style={{color: '#fff', backgroundColor: counterConfig?.fieldColor?.value || '#0000', padding: '8px', borderRadius: '10px'}}>{hours}</div>
          <span style={{color: counterConfig?.fieldColor?.value || '#0000'}}>Hours</span>
        </div>
        <div style={{textAlign: 'center', fontSize: '14px', marginRight: '10px', width: '100%'}}>
          <div style={{color: '#fff', backgroundColor: counterConfig?.fieldColor?.value || '#0000', padding: '8px', borderRadius: '10px'}}>{minutes}</div>
          <span style={{color: counterConfig?.fieldColor?.value || '#0000'}}>Minutes</span>
        </div>
        <div style={{textAlign: 'center', fontSize: '14px', width: '100%'}}>
          <div style={{color: '#fff', backgroundColor: counterConfig?.fieldColor?.value || '#0000', padding: '8px', borderRadius: '10px'}}>{seconds}</div>
          <span style={{color: counterConfig?.fieldColor?.value || '#0000'}}>Seconds</span>
        </div>
      </>}
      { counterConfig?.direction === COUNTER_DIRECTION_MAPPER.RF.value && <>
        <div style={{textAlign: 'center', fontSize: '14px', marginRight: '10px', width: '100%'}}>
          <div style={{color: '#fff', backgroundColor: counterConfig?.fieldColor?.value || '#0000', padding: '8px', borderRadius: '10px'}}>{timerObj?.days}</div>
          <span style={{color: counterConfig?.fieldColor?.value || '#0000'}}>Days</span>
        </div>
        <div style={{textAlign: 'center', fontSize: '14px', marginRight: '10px', width: '100%'}}>
          <div style={{color: '#fff', backgroundColor: counterConfig?.fieldColor?.value || '#0000', padding: '8px', borderRadius: '10px'}}>{timerObj?.hours}</div>
          <span style={{color: counterConfig?.fieldColor?.value || '#0000'}}>Hours</span>
        </div>
        <div style={{textAlign: 'center', fontSize: '14px', marginRight: '10px', width: '100%'}}>
          <div style={{color: '#fff', backgroundColor: counterConfig?.fieldColor?.value || '#0000', padding: '8px', borderRadius: '10px'}}>{timerObj?.minutes}</div>
          <span style={{color: counterConfig?.fieldColor?.value || '#0000'}}>Minutes</span>
        </div>
        <div style={{textAlign: 'center', fontSize: '14px', width: '100%'}}>
          <div style={{color: '#fff', backgroundColor: counterConfig?.fieldColor?.value || '#0000', padding: '8px', borderRadius: '10px'}}>{timerObj?.seconds}</div>
          <span style={{color: counterConfig?.fieldColor?.value || '#0000'}}>Seconds</span>
        </div>
      </>}
    </div>
  );
}

Timer.propTypes = {
  counterConfig: PropTypes.any,
};

export default Timer;
