import React from 'react';
import PropTypes from 'prop-types';
import KlaviyoIcon from '../../assets/images/klaviyo.jpeg';

/**
 * Klaviyo logo
 *
 * @return {JSX}
 */
const Klaviyo = ({height, width})=>{
  return (
    <img src={KlaviyoIcon} width={width || '60'} height={height || '60'}/>
  );
};

Klaviyo.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Klaviyo;
