import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {dashboardDetailsAction} from '../../actions/dashboard.actions';
import TopSideBarTemplate from '../../templates/topSideBar.template';
import WidgetIcon from '../../icons/widget.icon';
import UploadIcon from '../../icons/upload.icon';
import Chips from '../../components/chips';
import {getCustomerList} from '../../actions/customers.action';
import LoaderIcon from '../../icons/loader.icon';
import {GET_CUSTOMER_SUCCESS} from '../../constants/reducer.const';
import moment from 'moment';
import {LEAD} from '../../constants/application.const';

/**
 * Dashboard page
 *
 * @return {JSX}
 */
const DashboardPage = () => {
  const [contactsList, setContactsList] = useState([]);
  const customersStore = useSelector((state)=>state.customer);
  const dispatch = useDispatch();
  const [customerLoader, setCustomerLoader] = useState(true);

  const getDashboardDetails = () => {
    dispatch(dashboardDetailsAction());
  };

  // get customers
  const getCustomers = ()=>{
    dispatch(getCustomerList());
  };

  useEffect(() => {
    getDashboardDetails();
    getCustomers();
  }, []);

  useEffect(()=>{
    if (customersStore?.customer?.type === GET_CUSTOMER_SUCCESS) {
      setCustomerLoader(false);
      setContactsList(customersStore?.customer?.payload?.data);
    }
  }, [customersStore?.customer]);

  return (
    <TopSideBarTemplate>
      <h2 className="my-4 text-2xl font-semibold text-gray-700 ddark:text-gray-200">
        Dashboard
      </h2>
      <div>
        <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
          <div className="flex items-center p-4 bg-white rounded-lg shadow-xs ddark:bg-gray-800">
            <div className="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full ddark:text-orange-100 ddark:bg-orange-500">
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
              </svg>
            </div>
            <div>
              <p className="mb-2 text-sm font-medium text-gray-600 ddark:text-gray-400">
                Total clients
              </p>
              <p className="text-lg font-semibold text-gray-700 ddark:text-gray-200">
                6389
              </p>
            </div>
          </div>
          <div className="flex items-center p-4 bg-white rounded-lg shadow-xs ddark:bg-gray-800">
            <div className="p-3 mr-4 text-green-500 bg-green-100 rounded-full ddark:text-green-100 ddark:bg-green-500">
              <WidgetIcon
                color={'currentColor'}
              />
            </div>
            <div>
              <p className="mb-2 text-sm font-medium text-gray-600 ddark:text-gray-400">
                Widgets
              </p>
              <p className="text-lg font-semibold text-gray-700 ddark:text-gray-200">
                46
              </p>
            </div>
          </div>
          <div className="flex items-center p-4 bg-white rounded-lg shadow-xs ddark:bg-gray-800">
            <div className="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full ddark:text-blue-100 ddark:bg-blue-500">
              <UploadIcon color={'currentColor'}/>
            </div>
            <div>
              <p className="mb-2 text-sm font-medium text-gray-600 ddark:text-gray-400">
                Videos
              </p>
              <p className="text-lg font-semibold text-gray-700 ddark:text-gray-200">
                2
              </p>
            </div>
          </div>
          <div className="flex items-center p-4 bg-white rounded-lg shadow-xs ddark:bg-gray-800">
            <div className="p-3 mr-4 text-teal-500 bg-teal-100 rounded-full ddark:text-teal-100 ddark:bg-teal-500">
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clipRule="evenodd"></path>
              </svg>
            </div>
            <div>
              <p className="mb-2 text-sm font-medium text-gray-600 ddark:text-gray-400">
                Conversations
              </p>
              <p className="text-lg font-semibold text-gray-700 ddark:text-gray-200">
                35
              </p>
            </div>
          </div>
        </div>
        {customerLoader &&
          <LoaderIcon
            color="primary"
            additionalClass='absolute inset-1/2 h-10 w-10'
          />
        }
        {!customerLoader &&
          <div className="mt-2">
            <div className="flex flex-col w-full overflow-hidden">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="border-separate min-w-full border-solid border-2 rounded-md">
                      <thead className="rounded-md">
                        <tr className='text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b ddark:border-gray-700 bg-gray-50 ddark:text-gray-400 ddark:bg-gray-800 rounded-md'>
                          <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-4 text-left capitalize">Name</th>
                          <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-4 text-left capitalize">Email</th>
                          <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-4 text-left capitalize">Date</th>
                          <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-4 text-left capitalize">Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {contactsList.map((data, index)=>(
                          <tr key={index} className="border-b bg-white">
                            <td className="text-sm text-gray-900 font-light px-2 py-4 whitespace-nowrap">{data?.name}</td>
                            <td className="text-sm text-gray-900 font-light px-2 py-4 whitespace-nowrap">{data?.email}</td>
                            <td className="text-sm text-gray-900 font-light px-2 py-4 whitespace-nowrap">{moment(data?.auditFields?.createdDate).format('YYYY-MM-DD')}</td>
                            <td className="text-sm text-gray-900 font-light px-2 py-4 whitespace-nowrap capitalize">
                              <Chips
                                text={data?.type}
                                color={LEAD === data?.type ? 'green':'yellow'}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </TopSideBarTemplate>
  );
};

export default DashboardPage;
