import React, {useEffect, useState} from 'react';
import TopSideBarTemplate from '../../templates/topSideBar.template';
import ContactsTopBar from './contactsTopBar';
import {useDispatch, useSelector} from 'react-redux';
import {ADD_CUSTOMER_SUCCESS, DELETE_CUSTOMER_FAILED, DELETE_CUSTOMER_SUCCESS, GET_CUSTOMER_SUCCESS} from '../../constants/reducer.const';
import {deleteCutsomerData, getCustomerList} from '../../actions/customers.action';
import Chips from '../../components/chips';
import * as moment from 'moment';
import LoaderIcon from '../../icons/loader.icon';
import BinIcon from '../../icons/bin.icon';
import {toast} from 'react-toastify';
import {LEAD} from '../../constants/application.const';
import Button from '../../components/button/button.component';

/**
 * Contacts page
 *
 * @return {JSX}
 */
function Contacts() {
  const [contactsList, setContactsList] = useState([]);
  const dispatch = useDispatch();
  const customersStore = useSelector((state)=>state.customer);
  const [pageLoader, setPageLoader] = useState(true);

  useEffect(()=>{
    dispatch(getCustomerList());
  }, []);

  useEffect(()=>{
    if (customersStore?.customer?.type === DELETE_CUSTOMER_SUCCESS) {
      toast(customersStore?.customer?.payload?.message, {
        type: 'success',
      });
      setPageLoader(true);
      dispatch(getCustomerList());
    }
    if (customersStore?.customer?.type === DELETE_CUSTOMER_FAILED) {
      toast(customersStore?.customer?.payload?.message, {
        type: 'error',
      });
    }
    if (customersStore?.customer?.type === GET_CUSTOMER_SUCCESS) {
      setPageLoader(false);
      setContactsList(customersStore?.customer?.payload?.data);
    }
    if (customersStore?.customer?.type === ADD_CUSTOMER_SUCCESS) {
      setPageLoader(true);
      dispatch(getCustomerList());
    }
  }, [customersStore?.customer]);

  // Delete customer
  const deleteCustomer = (id)=>{
    dispatch(deleteCutsomerData({id}));
  };

  return (
    <TopSideBarTemplate>
      <h2 className="my-6 text-2xl font-semibold text-gray-700 ddark:text-gray-200">
          Customers
      </h2>
      {pageLoader &&
        <LoaderIcon
          color="primary"
          additionalClass='absolute inset-1/2 h-10 w-10'
        />
      }
      {!pageLoader && <>
        <ContactsTopBar />
        <div className="flex flex-col w-full overflow-hidden">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="border-separate min-w-full border-solid border-2 rounded-md">
                  <thead className="rounded-md">
                    <tr className='text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b ddark:border-gray-700 bg-gray-50 ddark:text-gray-400 ddark:bg-gray-800 rounded-md'>
                      <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize">Name</th>
                      <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize">Email</th>
                      <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize">Date</th>
                      <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize">Type</th>
                      <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contactsList.map((data, index)=>(
                      <tr key={index} className="border-b bg-white">
                        <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap">{data?.name}</td>
                        <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap">{data?.email}</td>
                        <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap">{moment(data?.auditFields?.createdDate).format('YYYY-MM-DD')}</td>
                        <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap capitalize">
                          <Chips
                            text={data?.type}
                            color={LEAD === data?.type ? 'green':'yellow'}
                          />
                        </td>
                        <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap capitalize flex justify-center">
                          <Button classOverride={'flex items-center justify-center px-4 py-1 text-md font-medium leading-5 text-white transition-colors duration-150 bg-red-600 border border-transparent rounded-lg active:bg-red-600 hover:bg-red-700 focus:outline-none focus:shadow-outline-red mt-2'} onClick={()=>deleteCustomer(data?.id)}>
                            <BinIcon color='#fff' />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>}
    </TopSideBarTemplate>
  );
}

export default Contacts;
