import React, {useEffect, useRef} from 'react';
import Button from '../../components/button/button.component';
import UploadIcon from '../../icons/upload.icon';
import {ALLOWED_MEDIA_TYPES, MAX_FILE_SIZE} from '../../constants/application.const';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import {storeVideoAction, videoStoreClear} from '../../actions/videoUpload.action';
import {VIDEO_UPLOAD_FAILURE, VIDEO_UPLOAD_SUCCESS} from '../../constants/reducer.const';

/**
 * Video widget
 *
 * @return {JSX}
 */
function VideoTopBar() {
  const inputFileRef = useRef();
  const dispatch = useDispatch();
  const videoLibrarySelector = useSelector((state)=>state.videoLibrary);

  // on File change capture
  const onFileChangeCapture = (event)=>{
    if (!event.target.files) return;
    const currentFile = event.target.files[0];
    if (ALLOWED_MEDIA_TYPES.indexOf(currentFile?.type) === -1) {
      return toast('Only videos and gifs are allowed', {
        type: 'error',
      });
    }
    if (currentFile?.size > MAX_FILE_SIZE * 1024 * 1024) {
      return toast('Maximum 35mb allowed', {
        type: 'error',
      });
    }

    const formData = new FormData();
    formData.append('video', currentFile);
    // Upload video to server
    dispatch(storeVideoAction(formData));

    // clearing field after the file is uploaded
    event.target.value=null;
  };

  useEffect(()=>{
    if (videoLibrarySelector.type === VIDEO_UPLOAD_SUCCESS) {
      dispatch(videoStoreClear());
      toast(videoLibrarySelector?.payload?.message, {
        type: 'success',
      });
    }
    if (videoLibrarySelector.type === VIDEO_UPLOAD_FAILURE) {
      dispatch(videoStoreClear());
      toast(videoLibrarySelector?.payload?.message, {
        type: 'error',
      });
    }
  }, [videoLibrarySelector]);

  return (
    <div className='flex justify-between mb-4'>
      <h2 className="my-6 text-2xl font-semibold text-gray-700 ddark:text-gray-200">
          Video Library
      </h2>
      <div className='flex items-center'>
        <Button
          buttonText='Upload'
          onClick={()=>{
            inputFileRef.current.click();
          }}
          buttonIcon={<UploadIcon/>}
        />
      </div>
      <input type="file" accept="video/mp4,image/gif" className="hidden" ref={inputFileRef} onChange={onFileChangeCapture}/>
    </div>
  );
}

export default VideoTopBar;
