import React from 'react';
import PropTypes from 'prop-types';

/**
 * Bubble icons
 *
 * @return {JSX}
 */
function BubbleIcon({
  height,
  width,
  ...props
}) {
  return (
    <svg width={width || '128'} height={height || '128'} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="14.5" y="14.5" width="99" height="99" rx="9.5" fill="#8573F2" stroke="#8573F2"/>
      <circle cx="88.5" cy="87.5" r="16.5" fill="white"/>
    </svg>
  );
}

BubbleIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default BubbleIcon;
