import React from 'react';
import PropTypes from 'prop-types';

/**
 * FeatureNew icon for whole project
 *
 * @return {JSX}
 */
const FeatureNewIcon = ({height, width, ...props})=>{
  return (
    <svg width={width || '32px'} height={height || '32px'} fill={'currentColor'} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M23.8083 4C26.8537 4 29.3323 6.45602 29.3323 9.47352L29.3337 13.0433C29.3337 13.3049 29.2283 13.5585 29.0417 13.7435C28.8537 13.9298 28.6003 14.0341 28.3337 14.0341C27.239 14.0341 26.3483 14.9167 26.3483 16.0013C26.3483 17.086 27.239 17.9685 28.3337 17.9685C28.8857 17.9685 29.3337 18.4124 29.3337 18.9594V22.5278C29.3337 25.5453 26.8563 28 23.811 28H8.19099C5.14566 28 2.66699 25.5453 2.66699 22.5278V18.9594C2.66699 18.4124 3.11499 17.9685 3.66699 17.9685C4.76299 17.9685 5.65366 17.086 5.65366 16.0013C5.65366 14.9444 4.79899 14.1491 3.66699 14.1491C3.40166 14.1491 3.14833 14.0447 2.96033 13.8584C2.77233 13.6721 2.66699 13.4198 2.66699 13.1582L2.66966 9.47352C2.66966 6.45602 5.14699 4 8.19233 4H23.8083ZM16.003 10.8713C15.6243 10.8713 15.2857 11.08 15.1163 11.4156L14.1443 13.3669L11.9763 13.6801C11.6017 13.7329 11.295 13.9866 11.1763 14.3433C11.059 14.7 11.1537 15.0858 11.4257 15.3474L12.9977 16.864L12.627 19.0083C12.563 19.3782 12.7137 19.7455 13.0203 19.9661C13.1937 20.089 13.395 20.1524 13.599 20.1524C13.755 20.1524 13.9123 20.1141 14.0563 20.0388L16.0003 19.0268L17.9403 20.0361C18.2763 20.2145 18.675 20.1867 18.9803 19.9648C19.2883 19.7455 19.439 19.3782 19.375 19.0083L19.003 16.864L20.575 15.3474C20.8483 15.0858 20.943 14.7 20.8243 14.3433C20.707 13.9866 20.4003 13.7329 20.0297 13.6814L17.8577 13.3669L16.8857 11.4169C16.719 11.0814 16.3803 10.8726 16.003 10.8713Z" fill="#50545E"/>
    </svg>
  );
};

FeatureNewIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default FeatureNewIcon;
