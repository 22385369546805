import React, {useEffect, useState} from 'react';
import Heading from '../../../../components/heading';
import CloseIcon from '../../../../icons/close.icon';
import PropTypes from 'prop-types';
import Button from '../../../../components/button/button.component';
import {previewInputPopup} from '../../../../validationSchemas/widget.validation';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {WIDGET_PREVIEW_URL, WIDGET_URL} from '../../../../constants/application.const';

/**
 * Preview Input Modal
 *
 * @return {JSX}
 */
const PreviewInputModal = ({
  onClose,
  widgetType,
  widgetDetails,
})=>{
  const {handleSubmit} = useForm({
    resolver: yupResolver(previewInputPopup),
  });
  const [linkId, setLinkId] = useState('');

  // Preview submit
  const previewSubmit = ()=>{
    const widgetUrl = `${WIDGET_URL[widgetType]}deployer.js?linkId=${linkId}&preview=1`;
    window.open(`${WIDGET_PREVIEW_URL}?widget_url=${widgetUrl}`, '_newtab');
  };

  // Called initially
  useEffect(()=>{
    if (widgetDetails) {
      console.log(widgetDetails);
      setLinkId(widgetDetails?.linkId);
    }
  }, [widgetDetails?.linkId]);

  return (
    <>
      <div className="fixed inset-0 bg-stone-900 bg-opacity-75 transition-opacity z-50" aria-hidden="true"></div>
      <form className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white w-1/3 p-4 rounded-lg' style={{zIndex: 60}} onSubmit={handleSubmit(previewSubmit)}>
        <Heading className='font-semibold text-lg flex justify-between  mt-4'>
          Preview The Widget
          <CloseIcon className='cursor-pointer w-4 h-4' onClick={()=>{
            onClose();
          }}/>
        </Heading>
        <div id="input-container">
          <label className='text-sm font-normal mt-4 flex items-center mb-1'>Preview the widget on our sample site.</label>
          {/* <label className='text-sm font-normal mt-4 flex items-center mb-1'>Enter the website URL to preview the widget(Only HTTPS URLs allowed)</label>
          <div className='flex items-center w-full'>
            <Input
              type="text"
              parentAdditionalClass="w-full"
              {...register('url')}
              placeholder="Ex: https://getpopsuite.com"
              errorStatus={Boolean(errors?.url?.message)}
            />
          </div>
          <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errors?.url?.message}</ErrorText> */}
        </div>
        <div className='flex mt-4 justify-end'>
          <Button type="button" onClick={()=>previewSubmit()}>Preview</Button>
        </div>
      </form>
    </>
  );
};

PreviewInputModal.propTypes = {
  onClose: PropTypes.func,
  widgetType: PropTypes.string,
  widgetDetails: PropTypes.any,
};

export default PreviewInputModal;
