import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {ACTION_TYPE_MAPPER, ALIGNMENT_MAPPER, COUNTER_DIRECTION_MAPPER, FONT_SIZE_MAPPER, FONT_TYPE_MAPPER, MEDIA_POSITION_MAPPER, VALIDATION_MAPPER} from './application.const';
import {useStopwatch, useTimer} from 'react-timer-hook';
import {useForm} from 'react-hook-form';
import {POPUP_SIZE_MAPPER, POPUP_SIZE_MAPPER_VALUE} from '../constants/widget.const';

/**
 * Embed widget component
 *
 * @return {JSX}
 */
const EmbedWidgetComponent = ({
  mode,
  popupSize,
  headingText,
  headingConfig,
  subHeadingText,
  subHeadingConfig,
  mediaDetails,
  counterStatus,
  counterConfig,
  mediaPosition,
  actionButtonName,
  actionButtonLink,
  actionButtonConfig,
  actionType,
  fieldConfig,
  displayPreference,
  displayPreferenceConfig,
  pages,
})=>{
  const {
    seconds,
    minutes,
    hours,
    days,
    restart,
  } = useTimer({expiryTimestamp: new Date()});
  const timerObj = useStopwatch({autoStart: true});
  const {register, handleSubmit, formState: {errors}} = useForm();
  const [currentWindowWidth, setCurrentWindowWidth] = useState(window.innerWidth);

  // On form submit
  const onFormSubmit = (value)=>{
    console.log(value);
  };

  // Listen for width change (For responsiveness)
  const updateDimensions = ()=>{
    setCurrentWindowWidth(window.innerWidth);
  };

  // For responsive designs
  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () =>
      window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(()=>{
    if (counterConfig?.counterDate) {
      if (counterConfig?.direction === COUNTER_DIRECTION_MAPPER.RB.value) {
        restart(new Date(counterConfig?.counterDate));
      }
      if (counterConfig?.direction === COUNTER_DIRECTION_MAPPER.RF.value) {
        timerObj.reset();
        timerObj.start();
      }
    }
    if (displayPreference && (mode !== 'dev' && pages?.split(',').indexOf(window?.location?.pathname) !== -1)) {
      setTimeout(()=>{
        // alert('done');
      }, displayPreferenceConfig?.delayTime);
    }
  }, [counterConfig, displayPreference, displayPreferenceConfig?.delayTime, window?.location?.pathname]);

  // get styles for images
  const getImageStyles = ()=>{
    if (mediaPosition === MEDIA_POSITION_MAPPER.L.value) {
      return (
        {objectFit: 'cover', height: '100%', width: '100%', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px'}
      );
    }
    if (mediaPosition === MEDIA_POSITION_MAPPER.R.value) {
      return (
        {objectFit: 'cover', height: '100%', width: '100%', borderTopRightRadius: '10px', borderBottomRightRadius: '10px'}
      );
    }
    if (mediaDetails?.type === 'V' && mediaPosition === MEDIA_POSITION_MAPPER.B.value) {
      return (
        {objectFit: 'cover', height: '100%', width: '100%', borderTopRightRadius: '10px', borderBottomRightRadius: '10px', position: 'absolute', zIndex: 99}
      );
    }
    return (
      {objectFit: 'cover', height: '100%', width: '100%', borderTopRightRadius: '10px', borderBottomRightRadius: '10px'}
    );
  };

  return (
    <div style={{
      boxSizing: 'border-box',
      position: mode === 'dev'?'absolute':'fixed',
      borderWidth: '1px',
      borderRadius: '10px',
      backgroundColor: '#fff',
      width: mode === 'dev' ?'90%':POPUP_SIZE_MAPPER_VALUE[popupSize || 'XL'].width,
      minHeight: '200px',
      maxHeight: '800px',
      overflowY: 'auto',
      margin: 'auto',
      boxShadow: mode === 'dev'?'':'2px 2px 6px gray',
      zIndex: mode === 'dev'?'40':'99',
      display: 'flex',
      top: mode === 'dev' ? '10%' : '50%',
      left: mode === 'dev' ? '10%' : '50%',
      transform: mode === 'dev' ? '':'translate(-50%, -50%)',
      zoom: POPUP_SIZE_MAPPER[popupSize || 'XL'],
      background: mediaPosition === MEDIA_POSITION_MAPPER.B.value?`url(${mediaDetails?.mediaPath || 'https://picsum.photos/500'}) no-repeat`:'',
      backgroundSize: mediaPosition === MEDIA_POSITION_MAPPER.B.value?'cover':'',
      flexDirection: (currentWindowWidth<800)?'column':(mediaPosition === MEDIA_POSITION_MAPPER.R.value?'row-reverse':''),
    }}>
      { mediaDetails?.type === 'V' && <div style={{flex: (mediaPosition === MEDIA_POSITION_MAPPER.B.value)?0:1}}>
        <video style={getImageStyles()} autoPlay loop controls controlsList='nodownload nofullscreen noremoteplayback' muted>
          <source src={mediaDetails?.mediaPath}></source>
        </video>
      </div>}
      {(mediaPosition !== MEDIA_POSITION_MAPPER.B.value && mediaDetails?.type !== 'V') &&<div style={{flex: 1}}>
        { mediaDetails?.type === 'I' && mediaPosition !== MEDIA_POSITION_MAPPER.B.value && <img src={mediaDetails?.mediaPath} style={getImageStyles()}/>}
        {/* Default image */}
        { !mediaDetails?.type && mediaPosition !== MEDIA_POSITION_MAPPER.B.value && <img src={'https://picsum.photos/500'} style={getImageStyles()}/>}
      </div>}
      <div style={{flex: 1, padding: '3rem', paddingLeft: '2rem', display: 'flex', justifyContent: 'center', flexDirection: 'column', backgroundColor: mediaPosition !== MEDIA_POSITION_MAPPER.B.value?'#fff':''}}>
        <h3 style={{fontWeight: FONT_TYPE_MAPPER[headingConfig?.fontType?.value], fontSize: FONT_SIZE_MAPPER[headingConfig?.fontSize?.value], color: headingConfig?.textColor?.value, textAlign: ALIGNMENT_MAPPER[headingConfig?.textAlignment?.value], zIndex: 100}}>{ headingText || 'Heading' }</h3>
        <p style={{marginTop: '10px', fontWeight: FONT_TYPE_MAPPER[subHeadingConfig?.fontType?.value], fontSize: FONT_SIZE_MAPPER[subHeadingConfig?.fontSize?.value], color: subHeadingConfig?.textColor?.value, textAlign: ALIGNMENT_MAPPER[subHeadingConfig?.textAlignment?.value], zIndex: 100}}>
          {subHeadingText || 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores a totam repellendus vitae cumque amet commodi est velit. Quisquam, quas. Enim iure aliquid provident ipsam doloremque, molestiae temporibus veritatis cupiditate.'}
        </p>
        {counterStatus && counterConfig?.direction === COUNTER_DIRECTION_MAPPER.RB.value && <div style={{display: 'flex', justifyContent: 'center', margin: '1rem 0rem 1rem 0rem', flexDirection: currentWindowWidth<800?'column':'', zIndex: 100}}>
          <p style={{color: '#fff', marginRight: '10px', backgroundColor: counterConfig?.fieldColor?.value, padding: '12px', borderRadius: '10px', textAlign: 'center', marginBottom: currentWindowWidth<800?'10px':''}}>{days} Days</p>
          <p style={{color: '#fff', marginRight: '10px', backgroundColor: counterConfig?.fieldColor?.value, padding: '12px', borderRadius: '10px', textAlign: 'center', marginBottom: currentWindowWidth<800?'10px':''}}>{hours} Hours</p>
          <p style={{color: '#fff', marginRight: '10px', backgroundColor: counterConfig?.fieldColor?.value, padding: '12px', borderRadius: '10px', textAlign: 'center', marginBottom: currentWindowWidth<800?'10px':''}}>{minutes} Minutes</p>
          <p style={{color: '#fff', marginRight: '10px', backgroundColor: counterConfig?.fieldColor?.value, padding: '12px', borderRadius: '10px', textAlign: 'center', marginBottom: currentWindowWidth<800?'10px':''}}>{seconds} Secons</p>
        </div>}
        {counterStatus && counterConfig?.direction === COUNTER_DIRECTION_MAPPER.RF.value && <div style={{display: 'flex', justifyContent: 'center', margin: '1rem 0rem 1rem 0rem', flexDirection: currentWindowWidth<800?'column':'', zIndex: 100}}>
          <p style={{color: '#fff', marginRight: '10px', backgroundColor: counterConfig?.fieldColor?.value, padding: '12px', borderRadius: '10px', textAlign: 'center', marginBottom: currentWindowWidth<800?'10px':''}}>{timerObj?.days} Days</p>
          <p style={{color: '#fff', marginRight: '10px', backgroundColor: counterConfig?.fieldColor?.value, padding: '12px', borderRadius: '10px', textAlign: 'center', marginBottom: currentWindowWidth<800?'10px':''}}>{timerObj?.hours} Hours</p>
          <p style={{color: '#fff', marginRight: '10px', backgroundColor: counterConfig?.fieldColor?.value, padding: '12px', borderRadius: '10px', textAlign: 'center', marginBottom: currentWindowWidth<800?'10px':''}}>{timerObj?.minutes} Minutes</p>
          <p style={{color: '#fff', marginRight: '10px', backgroundColor: counterConfig?.fieldColor?.value, padding: '12px', borderRadius: '10px', textAlign: 'center', marginBottom: currentWindowWidth<800?'10px':''}}>{timerObj?.seconds} Secons</p>
        </div>}
        <form onSubmit={handleSubmit(onFormSubmit)} style={{marginTop: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 100}}>
          {actionType === ACTION_TYPE_MAPPER.F.value && <>
            {fieldConfig?.fieldOne?.enabledStatus?.value && <input
              type={fieldConfig?.fieldOne?.fieldType?.value}
              placeholder={fieldConfig?.fieldOne?.fieldPlaceholder?.value || 'Field one'}
              {...register('fieldOne', {
                required: (fieldConfig?.fieldOne?.requiredStatus?.value).toString() === 'true',
                pattern: (fieldConfig?.fieldOne?.validationType?.value === 'C'?fieldConfig?.fieldOne?.customValidationPattern:VALIDATION_MAPPER[fieldConfig?.fieldOne?.validationType?.value]) || VALIDATION_MAPPER['ALL'],
              })}
              style={{
                border: '1px',
                padding: '8px',
                borderColor: 'gray',
                borderStyle: 'solid',
                borderRadius: '5px',
                width: '20rem',
              }}
            />}
            {errors.fieldOne?.type === 'required' && <p style={{color: 'red', fontSize: '12px'}}>Field required</p>}
            {errors.fieldOne?.type === 'pattern' && <p style={{color: 'red', fontSize: '12px'}}>Invalid value</p>}
            {fieldConfig?.fieldTwo?.enabledStatus?.value && <input
              type={fieldConfig?.fieldTwo?.fieldType?.value}
              placeholder={fieldConfig?.fieldTwo?.fieldPlaceholder?.value || 'Field two'}
              {...register('fieldTwo', {
                required: (fieldConfig?.fieldTwo?.requiredStatus?.value).toString() === 'true',
                pattern: (fieldConfig?.fieldTwo?.validationType?.value === 'C'?fieldConfig?.fieldTwo?.customValidationPattern:VALIDATION_MAPPER[fieldConfig?.fieldTwo?.validationType?.value]) || VALIDATION_MAPPER['ALL'],
              })}
              style={{
                border: '1px',
                padding: '8px',
                borderColor: 'gray',
                borderStyle: 'solid',
                borderRadius: '5px',
                marginTop: '10px',
                width: '20rem',
              }}
            />}
          </>}
          {errors.fieldTwo?.type === 'required' && <p style={{color: 'red', fontSize: '12px'}}>Field required</p>}
          {errors.fieldTwo?.type === 'pattern' && <p style={{color: 'red', fontSize: '12px'}}>Invalid value</p>}
          <div style={{display: 'flex', justifyContent: 'center', marginTop: '1rem'}}>
            <button
              style={{
                padding: '8px 20px',
                backgroundColor: actionButtonConfig?.actionButtonColor?.value || 'purple',
                borderRadius: '5px',
                marginTop: '10px',
                color: '#fff',
                fontSize: '2rem',
              }}
              onClick={()=>{
                if (actionType === ACTION_TYPE_MAPPER.AB.value && actionButtonLink) {
                  window.open(
                      actionButtonLink,
                      '_blank',
                  );
                }
              }}
              type={actionType === ACTION_TYPE_MAPPER.AB.value?'button':'submit'}
            >{actionButtonName || 'Submit'}</button>
          </div>
        </form>
        {/* Registeration message reference */}
        {false && <>
          <div style={{backgroundColor: 'green', padding: '2rem', borderRadius: '10px', textAlign: 'center', fontSize: '20px', color: '#fff', fontWeight: 'bold'}}>
          Registration success
          </div>
          <div style={{backgroundColor: 'red', padding: '2rem', borderRadius: '10px', textAlign: 'center', fontSize: '20px', color: '#fff', fontWeight: 'bold'}}>
          Registration failed(Try Again later)
          </div>
        </>}
      </div>
    </div>
  );
};

EmbedWidgetComponent.propTypes = {
  mode: PropTypes.string,
  popupSize: PropTypes.string,
  headingText: PropTypes.string,
  headingConfig: PropTypes.any,
  subHeadingText: PropTypes.string,
  subHeadingConfig: PropTypes.any,
  mediaDetails: PropTypes.any,
  counterStatus: PropTypes.bool,
  counterConfig: PropTypes.any,
  mediaPosition: PropTypes.string,
  actionButtonName: PropTypes.string,
  actionButtonLink: PropTypes.string,
  actionButtonConfig: PropTypes.any,
  actionType: PropTypes.string,
  fieldConfig: PropTypes.any,
  displayPreference: PropTypes.string,
  displayPreferenceConfig: PropTypes.any,
  pages: PropTypes.string,
};

export default EmbedWidgetComponent;
