import React, {useEffect, useState} from 'react';
import ButtonGroup from '../../../../components/button/buttonGroup.component';
import Heading from '../../../../components/heading';
import ColorInput from '../../../../components/input/colorInput.component';
import CloseIcon from '../../../../icons/close.icon';
import PropTypes from 'prop-types';
import {FONT_SIZE_CONST, FONT_TYPE_CONST, TEXT_ALIGNMENT_CONST} from '../../../../constants/widget.const';
import Button from '../../../../components/button/button.component';
import {HEADING_DEFAULT_VALUES} from '../../../../constants/defaultValues.const';

/**
 * Heading modal
 *
 * @return {JSX}
 */
const HeadingModal = ({onClose,
  onConfigChange,
  configValues,
  widgetType,
  backgroundColorToggle,
  modalTitle,
  hideFontSize,
  hideFontType,
})=>{
  const [config, setConfig] = useState({...configValues});

  useEffect(()=>{
    if (configValues) {
      setConfig({...configValues});
    }
  }, [configValues]);

  // Reset form
  const resetForm = ()=>{
    setConfig({...HEADING_DEFAULT_VALUES});
    onConfigChange({...HEADING_DEFAULT_VALUES});
  };

  return (
    <>
      <div className="fixed inset-0 bg-stone-900 bg-opacity-75 transition-opacity z-50" aria-hidden="true"></div>
      <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white w-1/2 p-4 rounded-lg'>
        <Heading className='font-semibold text-lg flex justify-between  mt-4'>
          {modalTitle?modalTitle:'Heading settings'}
          <CloseIcon className='cursor-pointer w-4 h-4' onClick={()=>{
            onClose();
          }}/>
        </Heading>
        <div className='grid grid-cols-2 gap-4 my-4'>
          {!hideFontSize && <div id="text-size-container">
            <ButtonGroup
              className={'mt-3'}
              label={'Font Size'}
              defaultSelect={config?.fontSize?.value}
              onSelect={(value)=>{
                setConfig({...config, fontSize: {
                  value: value,
                  displayName: 'Font Size',
                  chipColor: '',
                }});
                onConfigChange({...config, fontSize: {
                  value: value,
                  displayName: 'Font Size',
                  chipColor: '',
                }});
              }}
              buttons={[
                {
                  name: 'Small',
                  value: FONT_SIZE_CONST.SM,
                },
                {
                  name: 'Large',
                  value: FONT_SIZE_CONST.LG,
                },
                {
                  name: 'Extra Large',
                  value: FONT_SIZE_CONST.XL,
                },
              ]}
            />
          </div>}
          {widgetType !== 'B' && <div id="text-alignment-container">
            <ButtonGroup
              className={'mt-3'}
              label={'Text alignment'}
              defaultSelect={config?.textAlignment?.value}
              onSelect={(value)=>{
                setConfig({...config, textAlignment: {
                  value: value,
                  displayName: 'Text alignment',
                  chipColor: '',
                }});
                onConfigChange({...config, textAlignment: {
                  value: value,
                  displayName: 'Text alignment',
                  chipColor: '',
                }});
              }}
              buttons={[
                {
                  name: 'Left',
                  value: TEXT_ALIGNMENT_CONST.L,
                },
                {
                  name: 'Center',
                  value: TEXT_ALIGNMENT_CONST.C,
                },
                {
                  name: 'Right',
                  value: TEXT_ALIGNMENT_CONST.R,
                },
              ]}
            />
          </div>}
          <div id="text-size-container">
            <label className='text-sm font-normal mt-4 flex items-center mb-1'>Choose text color</label>
            <ColorInput
              defaultValue={config?.textColor?.value}
              onChangeValue={(value)=>{
                setConfig({...config, textColor: {
                  value: value,
                  displayName: 'Text color',
                  chipColor: value,
                }});
                onConfigChange({...config, textColor: {
                  value: value,
                  displayName: 'Text color',
                  chipColor: value,
                }});
              }}
            />
          </div>
          { backgroundColorToggle && <div id="text-size-container">
            <label className='text-sm font-normal mt-4 flex items-center mb-1'>Choose background color</label>
            <ColorInput
              defaultValue={config?.backgroundColor?.value}
              onChangeValue={(value)=>{
                setConfig({...config, backgroundColor: {
                  value: value,
                  displayName: 'Background color',
                  chipColor: value,
                }});
                onConfigChange({...config, backgroundColor: {
                  value: value,
                  displayName: 'Background color',
                  chipColor: value,
                }});
              }}
            />
          </div>}
          {!hideFontType && <div id="text-size-container" className=' mt-3'>
            <ButtonGroup
              label={'Font Type'}
              defaultSelect={config?.fontType?.value}
              onSelect={(value)=>{
                setConfig({...config, fontType: {
                  value,
                  displayName: 'Font Type',
                  chipColor: '',
                }});
                onConfigChange({...config, fontType: {
                  value,
                  displayName: 'Font Type',
                  chipColor: '',
                }});
              }}
              buttons={[
                {
                  name: 'Thin',
                  value: FONT_TYPE_CONST.T,
                },
                {
                  name: 'Bold',
                  value: FONT_TYPE_CONST.B,
                },
                {
                  name: 'Extra Bold',
                  value: FONT_TYPE_CONST.EB,
                },
              ]}
            />
          </div>}
        </div>
        <div className='flex mt-8 justify-end'>
          <Button classOverride={'flex items-center justify-center px-3 py-2 mr-2 text-sm font-medium text-purple-600 duration-150 bg-transparent border-2 border-purple-600 rounded-lg hover:border-purple-700 active:border-purple-500 focus:outline-none focus:shadow-outline-purple'} type={'button'} onClick={()=>resetForm()}>Reset</Button>
          <Button onClick={()=>onClose()}>Confirm</Button>
        </div>
      </div>
    </>
  );
};

HeadingModal.propTypes = {
  onClose: PropTypes.func,
  onConfigChange: PropTypes.func,
  configValues: PropTypes.any,
  widgetType: PropTypes.string,
  backgroundColorToggle: PropTypes.bool,
  modalTitle: PropTypes.string,
  hideFontSize: PropTypes.bool,
  hideFontType: PropTypes.bool,
};

export default HeadingModal;
