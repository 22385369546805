// Size mapper
export const WIDGET_SIZE_MAPPER = {
  '0': 'SM',
  '1': 'MD',
  '2': 'LG',
};

// Style types
export const STYLE_TYPES = {
  C: 'C',
  S: 'S',
  R: 'R',
};

// Style type mapper
export const STYLE_TYPE_MAPPER = [
  {
    name: 'Circle',
    value: 'C',
  },
  {
    name: 'Square',
    value: 'S',
  },
  {
    name: 'Rectangle',
    value: 'R',
  },
];

// Position types
export const POSITION_TYPES = {
  R: 'right',
  L: 'left',
};

// Widget types
export const WIDGET_TYPES = {
  VT: 'VT',
  VTA: 'VTA',
  VTFA: 'VTFA',
  VTFAC: 'VTFAC',
};

// Widget Components
export const WIDGET_COMPONENT_TYPE = {
  V: 'V', // Video
  T: 'T', // Text
  F: 'F', // Form
  AB: 'AB', // Action button
  C: 'C', // Counter
};

export const FONT_SIZE_CONST = {
  SM: 'SM',
  LG: 'LG',
  XL: 'XL',
};

export const TEXT_ALIGNMENT_CONST = {
  L: 'L',
  C: 'C',
  R: 'R',
};

export const FONT_TYPE_CONST = {
  T: 'T',
  B: 'B',
  EB: 'EB',
};

// media type mapper
export const MEDIA_TYPE_MAPPER = {
  V: {
    value: 'V',
    name: 'Video',
    fileType: 'video/mp4',
  },
  I: {
    value: 'I',
    name: 'Image/Gif',
    fileType: 'image/gif,image/png',
  },
  NM: {
    value: 'NM',
    name: 'No Media',
    fileType: '',
  },
  U: {
    value: 'U',
    name: 'Upload',
    fileType: '',
  },
};

// counter direction mapper
export const COUNTER_DIRECTION_MAPPER = {
  RF: {
    value: 'RF',
    name: 'Run time forward',
  },
  RB: {
    value: 'RB',
    name: 'Run time backward',
  },
};

// counter direction mapper
export const MEDIA_POSITION_MAPPER = {
  L: {
    value: 'L',
    name: 'Left',
  },
  B: {
    value: 'B',
    name: 'Background',
  },
  R: {
    value: 'R',
    name: 'Right',
  },
};

// action types form / CTA button
export const ACTION_TYPE_MAPPER = {
  AB: {
    name: 'Action Button',
    value: 'AB',
  },
  F: {
    name: 'Form',
    value: 'F',
  },
  FB: {
    name: 'FB Messenger',
    value: 'FB',
  },
  CAL: {
    name: 'Calendly',
    value: 'CAL',
  },
};

// field validation mapper
export const FIELD_VALIDATION_MAPPER = {
  N: {
    name: 'No validate',
    value: 'N',
  },
  E: {
    name: 'Email',
    value: 'E',
  },
  P: {
    name: 'Phone Number',
    value: 'P',
  },
  C: {
    name: 'Custom',
    value: 'C',
  },
};

// field required mapper
export const FIELD_REQUIRED_MAPPER = {
  T: {
    name: 'True',
    value: true,
  },
  F: {
    name: 'False',
    value: false,
  },
};

// field type mapper
export const FIELD_TYPE_MAPPER = {
  T: {
    name: 'Text',
    value: 'text',
  },
  E: {
    name: 'Email',
    value: 'email',
  },
  N: {
    name: 'Number',
    value: 'number',
  },
};

// Select http protocol field
export const PROTOCOL_TYPE_MAPPER = {
  HTTP: {
    name: 'http://',
    value: 'http://',
  },
  HTTPS: {
    name: 'https://',
    value: 'https://',
  },
};

// Answering types for survey widget
export const ANSWERING_TYPE = {
  SINGLE_CHOICE: {
    name: 'Single Choice',
    value: 'SC',
  },
  MULTIPLE_CHOICE: {
    name: 'Multiple Choice',
    value: 'MC',
  },
  MULTIPLE_CHOICE_DESIGN_TWO: {
    name: 'Multiple Choice(Design two)',
    value: 'MC2',
  },
};

// Question types for survey widget
export const QUESTION_TYPE = {
  SIMPLE_FLOW: {
    name: 'Simple Flow',
    value: 'SF',
  },
  HIERARCHY: {
    name: 'Hierarchy Flow',
    value: 'HF',
  },
};

// Widget type name mapper
/**
 * Tailwind colors text-gray-500, text-teal-500, text-pink-500, text-amber-500
 */
export const WIDGET_TYPE_NAME_MAPPER = {
  B: {
    value: 'Bubble',
    color: 'gray',
  },
  P: {
    value: 'Popup',
    color: 'teal',
  },
  PS: {
    value: 'Survey(Popup)',
    color: 'amber',
  },
  E: {
    value: 'Embed',
    color: 'pink',
  },
  R: {
    value: 'Review',
    color: 'orange',
  },
};

// Popup size mapper
export const POPUP_SIZE_MAPPER = {
  'SM': '60%',
  'LG': '70%',
  'XL': '100%',
};


// Popup size mapper
export const POPUP_SIZE_MAPPER_VALUE = {
  'SM': {
    height: '200px',
    width: '40%',
  },
  'LG': {
    height: '400px',
    width: '60%',
  },
  'XL': {
    height: '600px',
    width: '80%',
  },
};
