import React, {useEffect, useState} from 'react';
import Heading from '../../components/heading';
import {useDispatch, useSelector} from 'react-redux';
import {GET_REDEMPTION_CODE_FAILED, GET_REDEMPTION_CODE_SUCCESS} from '../../constants/reducer.const';
import {toast} from 'react-toastify';
import {getRedemptionCodeAction, getRedemptionCodeClearAction} from '../../actions/redemption.actions';
import Chips from '../../components/chips';
import Input from '../../components/input/input.component';
import ButtonGroup from '../../components/button/buttonGroup.component';

const statusMapper = {
  'N': 'NO',
  'Y': 'YES',
};

// For admin ( RedemptionAdminPage )
const RedemptionAdminPage = ()=>{
  const [codeList, setCodeList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState('');
  const redemptionSelector = useSelector((state)=>state?.redemption);
  const dispatch = useDispatch();

  const getAllCodes = ()=>{
    dispatch(getRedemptionCodeAction({}));
  };

  useEffect(()=>{
    getAllCodes();
  }, []);

  useEffect(()=>{
    if (redemptionSelector?.redemptionCodes?.type === GET_REDEMPTION_CODE_SUCCESS) {
      setCodeList(redemptionSelector?.redemptionCodes?.payload?.data);
      dispatch(getRedemptionCodeClearAction());
    }
    if (redemptionSelector?.redemptionCodes?.type === GET_REDEMPTION_CODE_FAILED) {
      toast(redemptionSelector?.redemptionCodes?.payload?.message, {
        type: 'error',
      });
      dispatch(getRedemptionCodeClearAction());
    }
  }, [redemptionSelector?.redemptionCodes]);

  return (
    <div className='px-8 pt-8 h-full'>
      <Heading className={'font-semibold text-xl'}>Redemption</Heading>
      <div className="my-4 w-1/2">
        <div id="field-one-container">
          <label className="text-sm font-normal mt-4 flex items-center">
            Search Codes
          </label>
          <Input
            type="text"
            placeholder="Search codes"
            onChange={(event)=>{
              setSearchText(event?.target?.value);
            }}
          />
        </div>
        <ButtonGroup
          className={'mt-2'}
          label={'Filter'}
          onSelect={(value)=>{
            setFilter(value);
          }}
          defaultSelect={filter}
          buttons={[
            {
              name: 'Yes',
              value: 'Y',
            },
            {
              name: 'All',
              value: '',
            },
            {
              name: 'No',
              value: 'N',
            },
          ]}
        />
      </div>
      <div className="flex flex-col w-full overflow-hidden mt-4 border border-slate-300 rounded-lg ">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-y-auto max-h-96">
              <table className="border-collapse min-w-full rounded-md bg-white">
                <thead className="rounded-md">
                  <tr className='text-xs font-semibold tracking-wide text-left text-gray-500 uppercase ddark:border-gray-700 bg-gray-50 ddark:text-gray-400 ddark:bg-gray-800 rounded-md border-b mb-4'>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">S.no</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">Code</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {codeList?.filter((d)=>d?.code?.match(new RegExp(searchText, 'i')))?.filter((d)=>(filter?(d?.usedStatus === filter):true))?.map((data, index)=>(
                    <tr key={index}>
                      <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap text-center">{index+1}</td>
                      <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap text-center">{data?.code}</td>
                      <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap text-center">
                        <div className='flex justify-center'>
                          <Chips
                            text={statusMapper[data?.usedStatus]}
                            color={data?.usedStatus === 'Y'?'green':'red'}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedemptionAdminPage;
