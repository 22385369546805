import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

/**
 * Drop down wrapper for all kinds of buttons
 *
 * !Do not override the button area
 * @return {JSX}
 */
function DropDownWrapper({buttonComponent, buttonOptions, open, onOptionClick, onClose}) {
  const ref = useRef();
  useEffect(()=>{
    /**
     * handle click outside the div
     *
     * @param {*} event
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current?.contains(event.target)) {
        onClose(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left" ref={ref}>
      <div>
        {buttonComponent}
      </div>
      {open && <div className="origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bg-white z-50" role="menu" aria-orientation="vertical" aria-labelledby="menu-button">
        <div className="py-1 z-50" role="none">
          {buttonOptions?.map((option, index)=>(
            <a className="cursor-pointer text-gray-700 hover:bg-gray-200 block px-4 py-2 text-sm flex justify-between" key={index} onClick={()=>onOptionClick(option?.value)}>
              {option?.icon}
              <span className={option?.class || ''}>{option?.text}</span>
            </a>
          ))}
        </div>
      </div>}
    </div>
  );
}

DropDownWrapper.propTypes = {
  buttonComponent: PropTypes.any,
  buttonOptions: PropTypes.array,
  open: PropTypes.bool,
  onOptionClick: PropTypes.func,
  onClose: PropTypes.func,
};

export default DropDownWrapper;
