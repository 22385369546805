import {yupResolver} from '@hookform/resolvers/yup';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import InputComponent from '../../components/input/input.component';
import Button from '../../components/button/button.component';
import ErrorText from '../../components/errorComponents/errorText.component';
import {MAX_PASSWORD_FIELD_LENGTH} from '../../constants/application.const';
import {loginSchema} from '../../validationSchemas/auth.validation';
import {clearUserAction, loginUserAction} from '../../actions/users.actions';
import {USER_LOGIN_FAILURE, USER_LOGIN_SUCCESS} from '../../constants/reducer.const';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';

/**
 * Admin Login page
 *
 * @return {JSX}
 */
function AdminLoginPage() {
  const {register, handleSubmit, formState: {errors}} = useForm({
    resolver: yupResolver(loginSchema),
  });
  const dispatch = useDispatch();
  const selector = useSelector((state)=>state?.user);
  const navigate = useNavigate();
  const [loginLoader, setLoginLoader] = useState(false);

  const loginSubmit = (value)=>{
    setLoginLoader(true);
    dispatch(loginUserAction({
      ...value,
      userType: 'A',
    }));
  };

  useEffect(()=>{
    if (selector.type === USER_LOGIN_SUCCESS) {
      setLoginLoader(false);
      dispatch(clearUserAction());
      toast(selector?.payload?.message, {
        type: 'success',
      });
      navigate('/admin/customers');
    }
    if (selector.type === USER_LOGIN_FAILURE) {
      setLoginLoader(false);
      dispatch(clearUserAction());
      toast(selector?.payload?.message, {
        type: 'error',
      });
    }
  }, [selector]);

  return (
    <div className="flex justify-center items-center h-screen">
      <div
        className="w-96 mx-6 overflow-hidden bg-white rounded-lg shadow-xl ddark:bg-gray-800"
      >
        <div className="flex-1 flex-col overflow-y-auto md:flex-row">
          <div className="flex-1 items-center justify-center p-6 sm:p-12">
            <form className="w-full" onSubmit={handleSubmit(loginSubmit)} noValidate>
              <h1
                className="mb-4 text-xl font-semibold text-gray-700 ddark:text-gray-200"
              >
              Admin Login
              </h1>
              <label className="block text-sm">
                <span className="text-gray-700 ddark:text-gray-400">Email</span>
                <InputComponent
                  name="username"
                  placeholder={'Enter email'}
                  type={'email'}
                  {...register('username')}
                />
                <ErrorText displayStatus={true}>{errors.username?.message}</ErrorText>
              </label>
              <label className="block mt-4 text-sm">
                <span className="text-gray-700 ddark:text-gray-400">Password</span>
                <InputComponent
                  name="password"
                  placeholder={'Enter Password'}
                  type={'password'}
                  {...register('password')}
                  maxLength={MAX_PASSWORD_FIELD_LENGTH}
                />
                <ErrorText displayStatus={true}>{errors.password?.message}</ErrorText>
              </label>
              <Button
                loader={loginLoader}
                buttonText='Log In'
                type={'submit'}
                disabled={loginLoader}
                classOverride={`flex items-center justify-center px-3 py-2 text-sm font-medium text-white duration-150 bg-blue-600 border border-transparent rounded-lg hover:bg-blue-700 active:bg-blue-500 focus:outline-none focus:shadow-outline-blue w-full mt-4`}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLoginPage;
