import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '../../icons/close.icon';
import Button from '../button/button.component';
import Heading from '../heading';
import Input from '../input/input.component';
import ErrorText from '../errorComponents/errorText.component';
import {redemptionSchema} from '../../validationSchemas/redemption.validation';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {UPGRADE_REDEMPTION_FAILED, UPGRADE_REDEMPTION_SUCCESS} from '../../constants/reducer.const';
import {useDispatch, useSelector} from 'react-redux';
import {upgradeRedemptionAction, upgradeRedemptionClearAction} from '../../actions/redemption.actions';
import {toast} from 'react-toastify';
import {useState} from 'react';
import {clearStorage} from '../../config/localStorage.config';
import {useNavigate} from 'react-router-dom';

/**
 * Redemption modal
 *
 * @return {JSX}
 */
const RedemptionModalComponent = ({
  onClose,
}) => {
  const redemptionSelector = useSelector((state)=>state.redemption);
  const dispatch = useDispatch();
  const [upgradeLoader, setUpgradeLoader] = useState(false);
  const navigate = useNavigate();
  const {register, handleSubmit, formState: {errors}} = useForm({
    resolver: yupResolver(redemptionSchema),
  });

  // Upgrade plan submit
  const upgradePlan = (value)=>{
    setUpgradeLoader(true);
    dispatch(upgradeRedemptionAction(value));
  };

  useEffect(()=>{
    if (redemptionSelector?.redemptionUpgrade?.type === UPGRADE_REDEMPTION_SUCCESS) {
      setUpgradeLoader(false);
      dispatch(upgradeRedemptionClearAction());
      toast(redemptionSelector?.redemptionUpgrade?.payload?.message, {
        type: 'success',
      });
      clearStorage();
      navigate('/');
    }
    if (redemptionSelector?.redemptionUpgrade?.type === UPGRADE_REDEMPTION_FAILED) {
      setUpgradeLoader(false);
      toast(redemptionSelector?.redemptionUpgrade?.payload?.message, {
        type: 'error',
      });
      dispatch(upgradeRedemptionClearAction());
    }
  }, [redemptionSelector?.redemptionUpgrade]);

  return (
    <div
      className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
    >
      <form
        onSubmit={handleSubmit(upgradePlan)}
        className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg sm:rounded-lg sm:m-4 sm:max-w-xl"
        id="modal"
      >
        <header className="flex justify-between">
          <Heading>Upgrade Plan</Heading>
          <Button
            classOverride="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded hover: hover:text-gray-700"
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </Button>
        </header>
        <p className="text-gray-500 text-xs">Have a Redemption code? Enter in the below field to upgrade your plan</p>
        <div id="input-container">
          <label className='text-sm font-normal mt-4 flex items-center mb-1'>Enter your Redemption code</label>
          <Input
            type="text"
            placeholder="Ex: POPSUITE**********"
            errorStatus={Boolean(errors?.saaszillacode?.message)}
            {...register('saaszillacode')}
          />
          <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errors?.saaszillacode?.message}</ErrorText>
        </div>
        <footer
          className="flex flex-row items-center justify-end mt-4"
        >
          <Button
            className={'w-full'}
            type="submit"
            loader={upgradeLoader}
          >
              Submit
          </Button>
        </footer>
      </form>
    </div>
  );
};

RedemptionModalComponent.propTypes = {
  onClose: PropTypes.func,
};

export default RedemptionModalComponent;
