import {yupResolver} from '@hookform/resolvers/yup';
import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {getSmtpConfigAction, getSmtpConfigClearAction, updateSmtpConfigAction, updateSmtpConfigClearAction} from '../../../actions/admin.actions';
import Button from '../../../components/button/button.component';
import ErrorText from '../../../components/errorComponents/errorText.component';
import Heading from '../../../components/heading';
import Input from '../../../components/input/input.component';
import Select from '../../../components/select/select.component';
import InfoWrapper from '../../../components/wrappers/infoWrapper.component';
import {GET_SMTP_CONFIG_SUCCESS, UPDATE_SMTP_CONFIG_FAILED, UPDATE_SMTP_CONFIG_SUCCESS} from '../../../constants/reducer.const';
import {smtpConfig} from '../../../validationSchemas/adminSettings.validation';

/**
 * Smtp config page
 *
 * @return {JSX}
 */
const SmtpConfig = ()=>{
  const dispatch = useDispatch();
  const [currentConfig, setCurrentConfig] = useState({});
  const [configUpdateLoader, setConfigUpdateLoader] = useState(false);
  const adminStore = useSelector((state)=>state.admin);
  const {register, handleSubmit, formState: {errors}, setValue, getValues} = useForm({
    resolver: yupResolver(smtpConfig),
  });

  const getSmtpConfig = ()=>{
    dispatch(getSmtpConfigAction());
  };

  useEffect(()=>{
    setValue('secure', false);
    getSmtpConfig();
  }, []);

  const setFormValue = useCallback((data)=>{
    setCurrentConfig({...data});
    setValue('host', data?.host);
    setValue('port', data?.port);
    setValue('secure', data?.secure);
    setValue('email', data?.email);
  }, []);

  useEffect(()=>{
    if (adminStore?.smtpConfig?.type === GET_SMTP_CONFIG_SUCCESS) {
      setFormValue(adminStore?.smtpConfig?.payload?.data);
      dispatch(getSmtpConfigClearAction());
    }
    if (adminStore?.smtpConfigUpdate?.type === UPDATE_SMTP_CONFIG_SUCCESS) {
      setConfigUpdateLoader(false);
      dispatch(updateSmtpConfigClearAction());
      toast(adminStore?.smtpConfigUpdate?.payload?.message, {
        type: 'success',
      });
    }
    if (adminStore?.smtpConfigUpdate?.type === UPDATE_SMTP_CONFIG_FAILED) {
      setConfigUpdateLoader(false);
      dispatch(updateSmtpConfigClearAction());
      toast(adminStore?.smtpConfigUpdate?.payload?.message, {
        type: 'error',
      });
    }
  }, [adminStore?.smtpConfig, adminStore?.smtpConfigUpdate]);

  const submitConfig = (value)=>{
    setConfigUpdateLoader(true);
    dispatch(updateSmtpConfigAction({
      updateId: currentConfig?.id,
      ...value,
    }));
  };

  return (
    <div className="p-6 w-1/2">
      <div className='mt-4'>
        <Heading className={'font-semibold text-xl'}>SMTP Config</Heading>
      </div>
      <form onSubmit={handleSubmit(submitConfig)} className="mt-4 mb-2" noValidate autoComplete='off'>
        <label className="col-span-4 block text-sm">
          <span className="text-gray-700 ddark:text-gray-400">Host</span>
          <Input
            type='text'
            placeholder="Ex: Gmail"
            name="host"
            {...register('host')}
            errorStatus={Boolean(errors?.host?.message)}
          />
          <ErrorText displayStatus={true}>{errors?.host?.message}</ErrorText>
        </label>
        <label className="col-span-4 block text-sm mt-4">
          <span className="text-gray-700 ddark:text-gray-400">Port</span>
          <Input
            defaultValue={0}
            type='number'
            placeholder="Example: 587"
            name="port"
            errorStatus={Boolean(errors?.port?.message)}
            {...register('port')}
          />
          <ErrorText displayStatus={true}>{errors?.port?.message}</ErrorText>
        </label>
        <InfoWrapper
          description='For Gmail 465 should be provided'
          additionalClass='mt-2'
        />
        <label className="col-span-4 block text-sm mt-4">
          <span className="text-gray-700 ddark:text-gray-400">Secure</span>
          <Select
            defaultSelected={getValues('secure')}
            onChange={(event)=>{
              setValue('secure', event);
            }}
            options={[
              {
                value: false,
                text: 'False',
              },
              {
                value: true,
                text: 'True',
              },
            ]}
          />
          <ErrorText displayStatus={true}>{errors?.secure?.message}</ErrorText>
        </label>
        <label className="col-span-4 block text-sm mt-4">
          <span className="text-gray-700 ddark:text-gray-400">Email</span>
          <Input
            type='email'
            placeholder="Enter Email"
            name="email"
            errorStatus={Boolean(errors?.email?.message)}
            {...register('email')}
          />
          <ErrorText displayStatus={true}>{errors?.email?.message}</ErrorText>
        </label>
        <label className="block mt-4 text-sm">
          <span className="text-gray-700 ddark:text-gray-400">Password</span>
          <Input
            name="password"
            placeholder={'Enter Password'}
            type={'password'}
            {...register('password')}
            errorStatus={Boolean(errors?.password?.message)}
          />
          <ErrorText displayStatus={true}>{errors.password?.message}</ErrorText>
        </label>
        <div className='flex mt-8 justify-end'>
          <Button type="button" classOverride={'flex items-center justify-center px-3 py-2 mr-2 text-sm font-medium text-purple-600 duration-150 bg-transparent border-2 border-purple-600 rounded-lg hover:border-purple-700 active:border-purple-500 focus:outline-none focus:shadow-outline-purple'} onClick={getSmtpConfig}>Reset</Button>
          <Button type="submit" loader={configUpdateLoader}>Submit</Button>
        </div>
      </form>
    </div>
  );
};

export default SmtpConfig;
