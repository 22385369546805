import {ADD_DEMO_USER_CLEAR, ADD_DEMO_USER_REQUEST, DOWNGRADE_REDEMPTION_CLEAR, DOWNGRADE_REDEMPTION_REQUEST, GET_SMTP_CONFIG_CLEAR, GET_SMTP_CONFIG_REQUEST, UPDATE_INTEGRATION_CONFIG_ADMIN_CLEAR, UPDATE_INTEGRATION_CONFIG_ADMIN_REQUEST, UPDATE_SMTP_CONFIG_CLEAR, UPDATE_SMTP_CONFIG_REQUEST} from '../constants/reducer.const';

export const getSmtpConfigAction = ()=>({type: GET_SMTP_CONFIG_REQUEST, payload: {}});
export const updateSmtpConfigAction = (payload)=>({type: UPDATE_SMTP_CONFIG_REQUEST, payload});
export const getSmtpConfigClearAction = ()=>({type: GET_SMTP_CONFIG_CLEAR, payload: {}});
export const updateSmtpConfigClearAction = ()=>({type: UPDATE_SMTP_CONFIG_CLEAR, payload: {}});
export const addDemoUserAction = (payload)=>({type: ADD_DEMO_USER_REQUEST, payload});
export const addDemoUserClear = ()=>({type: ADD_DEMO_USER_CLEAR, payload: {}});

export const updateIntegrationConfigAdminAction = (payload)=>({type: UPDATE_INTEGRATION_CONFIG_ADMIN_REQUEST, payload});
export const updateIntegrationConfigAdminClear = ()=>({type: UPDATE_INTEGRATION_CONFIG_ADMIN_CLEAR, payload: {}});

// Downgrade Redemption
export const adminDowngradeRedemptionAction = (payload)=>({type: DOWNGRADE_REDEMPTION_REQUEST, payload});
export const adminDowngradeRedemptionClearAction = ()=>({type: DOWNGRADE_REDEMPTION_CLEAR, payload: {}});
