import {ADD_CUSTOMER_FAILED, ADD_CUSTOMER_SUCCESS, CUSTOMER_REQUEST_CLEAR, GET_CUSTOMER_SUCCESS, GET_CUSTOMER_FAILED, GET_CUSTOMER_REQUEST, ADD_CUSTOMER_REQUEST, DELETE_CUSTOMER_REQUEST, DELETE_CUSTOMER_SUCCESS, DELETE_CUSTOMER_FAILED, GET_SURVEY_STATS_SUCCESS, GET_SURVEY_STATS_FAILED, GET_SURVEY_STATS_CLEAR, GET_SURVEY_STATS_REQUEST} from '../constants/reducer.const';

const initialState = {};

/**
 * Customer settings reducer function
 *
 * @param {any} state
 * @param {any} action
 * @return {any}
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_CUSTOMER_REQUEST: return {};
    case ADD_CUSTOMER_SUCCESS: return {...state, customer: {...action}};
    case ADD_CUSTOMER_FAILED: return {...state, customer: {...action}};
    case CUSTOMER_REQUEST_CLEAR: return {...state, customer: {}};

    case GET_CUSTOMER_REQUEST: return {};
    case GET_CUSTOMER_SUCCESS: return {...state, customer: {...action}};
    case GET_CUSTOMER_FAILED: return {...state, customer: {...action}};

    case DELETE_CUSTOMER_REQUEST: return {};
    case DELETE_CUSTOMER_SUCCESS: return {...state, customer: {...action}};
    case DELETE_CUSTOMER_FAILED: return {...state, customer: {...action}};

    case GET_SURVEY_STATS_REQUEST: return {};
    case GET_SURVEY_STATS_SUCCESS: return {...state, surveyStats: {...action}};
    case GET_SURVEY_STATS_FAILED: return {...state, surveyStats: {...action}};
    case GET_SURVEY_STATS_CLEAR: return {...state, surveyStats: {}};

    default: return {...state};
  }
}
