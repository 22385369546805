import React from 'react';
import PropTypes from 'prop-types';

/**
 * Export icon
 *
 * @return {JSX}
 */
function ExportIcon({color, height, width, ...props}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height || '24px'} width={width || '24px'} fill='currentColor' viewBox="0 0 48 48" {...props}><path d="M11 40Q9.8 40 8.9 39.1Q8 38.2 8 37V29.85H11V37Q11 37 11 37Q11 37 11 37H37Q37 37 37 37Q37 37 37 37V29.85H40V37Q40 38.2 39.1 39.1Q38.2 40 37 40ZM24 32.35 14.35 22.7 16.5 20.55 22.5 26.55V8H25.5V26.55L31.5 20.55L33.65 22.7Z"/></svg>

  );
}

ExportIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default ExportIcon;
