import {combineReducers} from 'redux';
import WidgetReducers from './widget.reducer';
import UserReducers from './user.reducer';
import DashboardReducers from './dashboard.reducer';
import VideoLibraryreducers from './videoLibrary.reducer';
import Customer from './customers.reducer';
import Integration from './integrations.reducer';
import Plan from './plan.reducer';
import adminReducer from './admin.reducer';
import redemptionReducer from './redemption.reducer';

const rootReducer = combineReducers({
  widget: WidgetReducers,
  user: UserReducers,
  dashboard: DashboardReducers,
  videoLibrary: VideoLibraryreducers,
  customer: Customer,
  integration: Integration,
  plan: Plan,
  admin: adminReducer,
  redemption: redemptionReducer,
});

export default rootReducer;
