import React, {useState} from 'react';
import Heading from '../../../../components/heading';
import Select from '../../../../components/select/select.component';
import CloseIcon from '../../../../icons/close.icon';
import PropTypes from 'prop-types';
import Input from '../../../../components/input/input.component';
import {FIELD_REQUIRED_MAPPER, FIELD_TYPE_MAPPER, FIELD_VALIDATION_MAPPER} from '../../../../constants/widget.const';
import Button from '../../../../components/button/button.component';
import {FIELD_CONFIG_VALUES} from '../../../../constants/defaultValues.const';

/**
 * Field config modal
 *
 * @return {JSX}
 */
const FieldConfigModal = ({onClose, onConfigChange, configValues, currentField, widgetType})=>{
  const [config, setConfig] = useState({...configValues});

  // to field config (nested objects)
  const updateFieldConfig = (fieldConfigKey, fieldConfigValue)=>{
    const fieldValueTemp = {...config};
    fieldValueTemp[currentField][fieldConfigKey].value = fieldConfigValue;
    setConfig(fieldValueTemp);
    onConfigChange(fieldValueTemp);
  };

  // Reset form
  const resetForm = ()=>{
    setConfig({
      fieldOne: {...FIELD_CONFIG_VALUES},
      fieldTwo: {...FIELD_CONFIG_VALUES},
      reviewDescription: {...REVIEW_FIELD_CONFIG_VALUES},
    });
    onConfigChange({
      fieldOne: {...FIELD_CONFIG_VALUES},
      fieldTwo: {...FIELD_CONFIG_VALUES},
      reviewDescription: {...REVIEW_FIELD_CONFIG_VALUES},
    });
  };

  return (
    <>
      <div className="fixed inset-0 bg-stone-900 bg-opacity-75 transition-opacity z-50" aria-hidden="true"></div>
      <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white w-1/2 p-4 rounded-lg'>
        <Heading className='font-semibold text-lg flex justify-between  mt-4'>
            Field Config
          <CloseIcon className='cursor-pointer w-4 h-4' onClick={()=>{
            onClose();
          }}/>
        </Heading>
        {/* config fields */}
        <div className='grid grid-cols-2 gap-2'>
          {widgetType !== 'R' && <div id="config-fields">
            <label className='text-sm font-normal mt-4 flex items-center'>Choose input type</label>
            <Select
              defaultSelected={config[currentField]?.fieldType?.value}
              options={[
                {
                  text: FIELD_TYPE_MAPPER.T.value,
                  value: FIELD_TYPE_MAPPER.T.name,
                },
                {
                  text: FIELD_TYPE_MAPPER.E.name,
                  value: FIELD_TYPE_MAPPER.E.value,
                },
                {
                  text: FIELD_TYPE_MAPPER.N.name,
                  value: FIELD_TYPE_MAPPER.N.value,
                },
              ]}
              onChange={(value)=>{
                updateFieldConfig('fieldType', value);
              }}
            />
          </div>}
          <div id="config-fields">
            <label className='text-sm font-normal mt-4 flex items-center'>Requried</label>
            <Select
              defaultSelected={config[currentField]?.requiredStatus?.value}
              options={[
                {
                  text: FIELD_REQUIRED_MAPPER.T.name,
                  value: FIELD_REQUIRED_MAPPER.T.value,
                },
                {
                  text: FIELD_REQUIRED_MAPPER.F.name,
                  value: FIELD_REQUIRED_MAPPER.F.value,
                },
              ]}
              onChange={(value)=>{
                updateFieldConfig('requiredStatus', value);
              }}
            />
          </div>
          {widgetType !== 'R' && <div id="config-fields">
            <label className='text-sm font-normal mt-2 flex items-center'>Validation</label>
            <Select
              defaultSelected={config[currentField]?.validationType?.value}
              options={[
                {
                  text: FIELD_VALIDATION_MAPPER.N.name,
                  value: FIELD_VALIDATION_MAPPER.N.value,
                },
                {
                  text: FIELD_VALIDATION_MAPPER.E.name,
                  value: FIELD_VALIDATION_MAPPER.E.value,
                },
                {
                  text: FIELD_VALIDATION_MAPPER.P.name,
                  value: FIELD_VALIDATION_MAPPER.P.value,
                },
                {
                  text: FIELD_VALIDATION_MAPPER.C.name,
                  value: FIELD_VALIDATION_MAPPER.C.value,
                },
              ]}
              onChange={(value)=>{
                updateFieldConfig('validationType', value);
              }}
            />
          </div>}
          {config[currentField]?.validationType?.value === 'C' && <div id="config-fields">
            <label className='text-sm font-normal mt-2 flex items-center'>Custom validation(Regular expression)</label>
            <Input placeholder={'Ex: /^d{11}$/'} parentAdditionalClass={'mt-2'}
              onChange={(event)=>{
                updateFieldConfig('customValidationPattern', event.target.value);
              }} value={config[currentField]?.customValidationPattern?.value}/>
          </div>}
        </div>
        <div className='flex mt-8 justify-end'>
          <Button classOverride={'flex items-center justify-center px-3 py-2 mr-2 text-sm font-medium text-purple-600 duration-150 bg-transparent border-2 border-purple-600 rounded-lg hover:border-purple-700 active:border-purple-500 focus:outline-none focus:shadow-outline-purple'} type={'button'} onClick={()=>resetForm()}>Reset</Button>
          <Button onClick={()=>onClose()}>Confirm</Button>
        </div>
      </div>
    </>
  );
};

FieldConfigModal.propTypes = {
  onClose: PropTypes.func,
  onConfigChange: PropTypes.func,
  configValues: PropTypes.any,
  currentField: PropTypes.string,
  widgetType: PropTypes.string,
};

export default FieldConfigModal;
