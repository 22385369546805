import React, {useCallback, useEffect, useState} from 'react';
import Button from '../../../../components/button/button.component';
import PropTypes from 'prop-types';
import Popover from '../../../../components/popover';
import Heading from '../../../../components/heading';
import {getValue} from '../../../../config/localStorage.config';
import {USER_DETAILS} from '../../../../constants/application.const';
import UpgradeButton from '../../../../components/button/upgradeButton.component';
import PublishModal from '../modals/publish.modal';
import PublishIcon from '../../../../icons/publish.icon';

// Popover message
const PopoverPlanMessage = ({heading})=>{
  return (
    <div className='w-60 p-4'>
      <Heading className="text-md font-bold text-black">{heading}</Heading>
      <Heading className="text-sm mt-2 text-black">Upgrade to access this feature and many more!!!</Heading>
      <div className='flex justify-end'>
        <UpgradeButton
          additionalClass='mt-4'
        />
      </div>
    </div>
  );
};

PopoverPlanMessage.propTypes = {
  heading: PropTypes.string,
};

/**
 * Widget top bar
 *
 * @return {JSX}
 */
const WidgetTopBar = ({onTabChange, widgetDetails, publishOpen})=>{
  const [selected, setSelected] = useState(2);
  const [curretUserPlan, setCurrentUserPlan] = useState({});
  const [publishModal, setPublishModal] = useState(false);

  // Local storage
  const getLocalStorageUserData = ()=>{
    const userData = getValue(USER_DETAILS);
    if (userData) {
      setCurrentUserPlan(JSON.parse(userData)?.plan);
    }
  };

  // Called initially
  useEffect(()=>{
    getLocalStorageUserData();
  }, []);

  // open the publish modal by default
  useEffect(()=>{
    if (publishOpen == 'o') {
      setPublishModal(true);
    }
  }, [publishOpen]);

  const PublishModalWrapper = useCallback(()=>(
    <>
      {publishModal && <PublishModal
        onClose={()=>{
          setPublishModal(false);
        }}
      />}
    </>
  ), [widgetDetails?.liveStatus, publishModal]);

  return (
    <>
      <div className='flex justify-between'>
        <div className='flex py-6 px-6 items-center'>
          <p className={`mr-6 p-2 ${selected === 2?'bg-primary text-white rounded-lg':''} cursor-pointer ${curretUserPlan?.modules?.P?.WIDGETS?.TABS?.CUSTOMERS ?'hover:bg-primary hover:text-white hover:rounded-lg':'rounded-lg bg-slate-300'} p-2`} onClick={()=>{
            setSelected(2); onTabChange(2);
          }}>Customers</p>
          <Popover
            additionalPopoverClass='mt-2'
            popupOverComponent={!(curretUserPlan?.modules?.P?.WIDGETS?.TABS?.ANALYTICS) && <PopoverPlanMessage
              heading='Need Analytics?'
            />}
          >
            <p className={`mr-6 p-2 ${selected === 3?'bg-primary text-white rounded-lg':''} cursor-pointer ${curretUserPlan?.modules?.P?.WIDGETS?.TABS?.ANALYTICS ?'hover:bg-primary hover:text-white hover:rounded-lg':'rounded-lg bg-slate-300'} p-2`} onClick={()=>{
              if (curretUserPlan?.modules?.P?.WIDGETS?.TABS?.ANALYTICS) {
                setSelected(3);
                onTabChange(3);
              }
            }}>Analytics</p>
          </Popover>
          {(widgetDetails?.widgetSettings?.widgetType === 'PS' || widgetDetails?.widgetSettings?.widgetType === 'BS') && <p className={`mr-6 p-2 ${selected === 4?'bg-primary text-white rounded-lg':''} cursor-pointer ${curretUserPlan?.modules?.P?.WIDGETS?.TABS?.ANALYTICS ?'hover:bg-primary hover:text-white hover:rounded-lg':'rounded-lg bg-slate-300'} p-2`} onClick={()=>{
            if (curretUserPlan?.modules?.P?.WIDGETS?.TABS?.ANALYTICS) {
              setSelected(4);
              onTabChange(4);
            }
          }}>Survey Stats</p>}
        </div>
        <div className='flex justify-between items-center pr-6'>
          <Button
            buttonIcon={<PublishIcon />}
            classOverride={'flex items-center justify-center px-3 py-2 text-sm font-medium text-white duration-150 bg-green-500 border-2 border-green-500 rounded-lg hover:bg-green-600 active:bg-green-400 focus:outline-none'}
            onClick={()=>{
              setPublishModal(true);
            }}
          >Publish</Button>
        </div>
      </div>
      {PublishModalWrapper()}
    </>
  );
};

WidgetTopBar.propTypes = {
  onTabChange: PropTypes.func,
  widgetDetails: PropTypes.any,
  publishOpen: PropTypes.string,
  surveyEnable: PropTypes.bool,
};

export default WidgetTopBar;

/**
 * Tailwind widget classes
 *
 * text-green-600 bg-green-200 border-green-500 bg-green-300 bg-green-100
 * text-red-600 bg-red-200 border-red-500 bg-red-300 bg-red-100
 */
