import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

/**
 * Popover component
 *
 * @return {JSx}
 */
const Popover = ({children, popupOverComponent, additionalPopoverClass, position}) => {
  const [showPopover, setShowPopover] = useState(false);
  const ref = useRef();

  useEffect(()=>{
    /**
     * handle click outside the div
     *
     * @param {*} event
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current?.contains(event.target)) {
        setShowPopover(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='relative'
      onMouseEnter={()=>setShowPopover(true)}
      ref={ref}
    >
      {children}
      {showPopover && <div className={`absolute bg-white border border-2 border-slate-200 ${position || 'top'}-10 rounded-lg ${additionalPopoverClass} z-50`}>
        {popupOverComponent}
      </div>}
    </div>
  );
};

Popover.propTypes = {
  children: PropTypes.any,
  popupOverComponent: PropTypes.any,
  additionalPopoverClass: PropTypes.string,
  position: PropTypes.string,
};

export default Popover;
