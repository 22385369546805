import {yupResolver} from '@hookform/resolvers/yup';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {updateIntegrationConfigAdminAction, updateIntegrationConfigAdminClear} from '../../../actions/admin.actions';
import Button from '../../../components/button/button.component';
import ErrorText from '../../../components/errorComponents/errorText.component';
import Heading from '../../../components/heading';
import Input from '../../../components/input/input.component';
import {UPDATE_INTEGRATION_CONFIG_ADMIN_FAILED, UPDATE_INTEGRATION_CONFIG_ADMIN_SUCCESS} from '../../../constants/reducer.const';
import {integrationConfig} from '../../../validationSchemas/adminSettings.validation';

/**
 * Integration config for admin
 *
 * @return {JSX}
 */
const IntegrationConfig = () => {
  const dispatch = useDispatch();
  const {register, handleSubmit, formState: {errors}} = useForm({
    resolver: yupResolver(integrationConfig),
  });
  const [configUpdateLoader, setConfigUpdateLoader] = useState(false);
  const adminStore = useSelector((state)=>state.admin);

  // Update integration
  const updateIntegration = (value) => {
    setConfigUpdateLoader(true);
    dispatch(updateIntegrationConfigAdminAction(value));
  };

  useEffect(()=>{
    if (adminStore?.adminIntegration?.type === UPDATE_INTEGRATION_CONFIG_ADMIN_SUCCESS) {
      setConfigUpdateLoader(false);
      dispatch(updateIntegrationConfigAdminClear());
      toast(adminStore?.adminIntegration?.payload?.message, {
        type: 'success',
      });
    }
    if (adminStore?.adminIntegration?.type === UPDATE_INTEGRATION_CONFIG_ADMIN_FAILED) {
      setConfigUpdateLoader(false);
      dispatch(updateIntegrationConfigAdminClear());
      toast(adminStore?.adminIntegration?.payload?.message, {
        type: 'error',
      });
    }
  }, [adminStore?.adminIntegration]);

  return (
    <div className="p-6 w-1/2">
      <div className='mt-4'>
        <Heading className={'font-semibold text-xl'}>Integration Config</Heading>
      </div>

      {/* Webhook URL config */}
      <form onSubmit={handleSubmit(updateIntegration)}>
        <div className='font-semibold mt-8 flex items-center'>
          Webhook Configurations
        </div>
        <div className='mt-4'>
          <Heading className="text-sm font-normal flex items-center">
            Pabbly Webhook URL
          </Heading>
          <Input
            placeholder={'Ex: https://connect.pabbly.com/workflow/sendwebhookdata/IHUEDHUEDhiuwed823...'}
            parentAdditionalClass={'mr-2 w-full'}
            errorStatus={Boolean(errors?.pabblyWebHookURL?.message)}
            {...register('pabblyWebHookURL')}
            defaultValue={''}
          />
          <ErrorText displayStatus={true}>{errors?.pabblyWebHookURL?.message}</ErrorText>
        </div>
        <div className='flex mt-8 justify-end'>
          <Button type="button" classOverride={'flex items-center justify-center px-3 py-2 mr-2 text-sm font-medium text-purple-600 duration-150 bg-transparent border-2 border-purple-600 rounded-lg hover:border-purple-700 active:border-purple-500 focus:outline-none focus:shadow-outline-purple'} onClick={() => { }}>Reset</Button>
          <Button type="submit" loader={configUpdateLoader}>Submit</Button>
        </div>
      </form>
    </div>
  );
};

export default IntegrationConfig;
