import React, {useRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Button from '../button/button.component';
import LogoutIcon from '../../icons/logout.icon';
import {useNavigate} from 'react-router-dom';
import {clearStorage} from '../../config/localStorage.config';
import ProfileIcon from '../../icons/profile.icon';
import ReactTooltip from 'react-tooltip';

// profile menu
const ProfileMenuWrapper = ({children, name, additionalClasses, redirectionUrl, isAdmin})=>{
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const ref = useRef();

  // Logout functionality
  const logout = ()=>{
    clearStorage();
    navigate(redirectionUrl || '/');
  };

  useEffect(()=>{
    /**
     * handle click outside the div
     *
     * @param {*} event
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current?.contains(event.target)) {
        setToggle(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`relative ${additionalClasses}`} ref={ref} data-tip data-for="profile">
      <ReactTooltip
        id="profile"
        place="right"
        effect="solid"
      >
        <span>Profile</span>
      </ReactTooltip>
      <div
        onClick={()=>setToggle((prev)=>!prev)}
      >{children}</div>
      {toggle && <div className='absolute z-50 flex items-center flex-col bottom-1 left-16 bg-white border border-slate-300 rounded-lg p-2 w-40 drop-shadow-md'>
        <>
          <p className='text-sm my-2 text-slate-900'>Welcome <span className='text-primary'>{name || 'user'}</span></p>
          <hr className='border border-slate-300 w-full'/>
          {!isAdmin && <Button classOverride={'flex items-center p-1 text-sm font-medium duration-150 border border-transparent rounded-lg focus:outline-none my-2 w-full'}
            onClick={()=>{
              navigate('/settings');
            }}
          >
            <ProfileIcon
              height="30"
              width="30"
              className="mr-2 text-primary"
            />
            <span className='font-semibold text-sm text-primary'>Profile</span>
          </Button>}
          <Button classOverride={'flex items-center justify-center p-1 text-sm font-medium text-white duration-150 bg-red-500 border border-transparent rounded-lg active:bg-red-500 hover:bg-red-600 focus:outline-none focus:shadow-outline-red my-2 w-full'}
            onClick={()=>{
              logout();
            }}
          >
            <LogoutIcon
              height="30"
              width="30"
              className="mr-2"
            />
            <span className='font-semibold text-sm'>Logout</span>
          </Button>
        </>
      </div>}
    </div>
  );
};

ProfileMenuWrapper.propTypes = {
  children: PropTypes.any,
  name: PropTypes.string,
  additionalClasses: PropTypes.string,
  redirectionUrl: PropTypes.string,
  isAdmin: PropTypes.any,
};

export default ProfileMenuWrapper;
