import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {deleteCutsomerData, getCustomerList} from '../../../../actions/customers.action';
import Button from '../../../../components/button/button.component';
import Chips from '../../../../components/chips';
import BinIcon from '../../../../icons/bin.icon';
import PropTypes from 'prop-types';
import {DELETE_CUSTOMER_FAILED, DELETE_CUSTOMER_SUCCESS, GET_CUSTOMER_FAILED, GET_CUSTOMER_SUCCESS} from '../../../../constants/reducer.const';
import {toast} from 'react-toastify';
import moment from 'moment';
import ExportIcon from '../../../../icons/export.icon';
import {getCustomersForExport} from '../../../../services/customer.service';
import {downloadFile} from '../../../../helpers/common.helper';
import {getWidgetAnalyticsAction} from '../../../../actions/widget.actions';

/**
 * Customer Listing
 *
 * @return {JSX}
 */
const CustomerListing = ({widgetId, widgetType}) => {
  const [contactsList, setContactsList] = useState([]);
  const widgetStore = useSelector((state)=>state.widget);
  const customersStore = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const [customerLoader, setCustomerLoader] = useState(false);

  // get customers
  const getCustomers = () => {
    dispatch(getCustomerList({widgetId}));
  };

  useEffect(() => {
    if (widgetId) {
      setCustomerLoader(true);
      dispatch(getWidgetAnalyticsAction({widgetId}));
      getCustomers();
    }
  }, [widgetId]);

  // Delete customer
  const deleteCustomer = (id) => {
    dispatch(deleteCutsomerData({id}));
  };

  // Export customers
  const exportCustomers = async ()=>{
    const rawData = await getCustomersForExport({widgetId});
    downloadFile(rawData, 'text/csv', 'registered_customers.csv');
  };


  useEffect(() => {
    if (customersStore?.customer?.type === DELETE_CUSTOMER_SUCCESS) {
      toast(customersStore?.customer?.payload?.message, {
        type: 'success',
      });
      setCustomerLoader(true);
      getCustomers();
    }
    if (customersStore?.customer?.type === DELETE_CUSTOMER_FAILED) {
      toast(customersStore?.customer?.payload?.message, {
        type: 'error',
      });
    }
    if (customersStore?.customer?.type === GET_CUSTOMER_SUCCESS) {
      setCustomerLoader(false);
      setContactsList(customersStore?.customer?.payload?.data);
    }
    if (customersStore?.customer?.type === GET_CUSTOMER_FAILED) {
      setCustomerLoader(false);
    }
  }, [customersStore?.customer, widgetStore?.widgetAnalytics]);

  return (
    <div className="flex flex-col w-full overflow-hidden">
      <div className='flex justify-between items-center'>
        <div className='flex mt-4 justify-end font-bold items-center flex'>
          Customer Count: <span className='bg-primary text-white rounded-lg px-2 py-1 ml-2'>{contactsList?.length}</span>
        </div>
        <div className='flex mt-4 justify-end'>
          <Button
            buttonIcon={<ExportIcon />}
            buttonText='Export'
            type={'button'}
            onClick={()=>exportCustomers()}
            classOverride={`flex items-center justify-center px-3 py-2 text-sm font-medium text-white duration-150 bg-primary hover:bg-purple-700 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-blue`}
          />
        </div>
      </div>
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8 mt-4">
        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="border-collapse min-w-full rounded-md">
              <thead className="rounded-md">
                <tr className='text-xs font-semibold tracking-wide text-left text-gray-500 uppercase bg-gray-50 rounded-md border-b mb-4'>
                  <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize">{widgetType === 'R'?'Rating Comments':'Field One'}</th>
                  {widgetType !== 'R' &&
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize">Field Two</th>}
                  {widgetType === 'R' &&
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize">Rating</th>}
                  <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize">Date</th>
                  <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize">Type</th>
                  <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize">Action</th>
                </tr>
              </thead>
              {!customerLoader && <tbody>
                {contactsList?.map((data, index) => (
                  <tr key={index}>
                    <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap">{data?.fieldOne}</td>
                    {widgetType !== 'R' &&
                      <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap">{data?.fieldTwo || '---'}</td>
                    }
                    {widgetType === 'R' &&
                      <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap">{data?.rating || 0}</td>
                    }
                    <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap">{
                      moment(data?.auditFields?.createdDate).format('lll')
                    }</td>
                    <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap capitalize items-center">
                      <div className='flex justify-start items-center'>
                        <Chips
                          text={'Lead'}
                          color={'green'}
                        />
                      </div>
                    </td>
                    <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap capitalize flex justify-start items-center">
                      <Button classOverride={'flex items-center justify-center px-2 py-1 text-md font-medium leading-5 transition-colors duration-150 bg-red-200 border border-transparent rounded-lg hover:bg-red-300 focus:outline-none focus:shadow-outline-red text-red-600 border-2'}
                        onClick={() => {
                          deleteCustomer(data?.id);
                        }}
                      >
                        <BinIcon />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>}
              {customerLoader && <tbody>
                <tr>
                  <td colSpan={6}>
                    <div className="p-4 w-full mx-auto">
                      <div className="animate-pulse flex space-x-4">
                        <div className="flex-1 space-y-6 py-1">
                          <div className="h-2 bg-slate-200 rounded"></div>
                          <div className="h-2 bg-slate-200 rounded"></div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>}
              {!customerLoader && !contactsList?.length && (
                <tbody>
                  <tr>
                    <td colSpan={5} className="text-center text-gray-500">
                      <div className='mt-4'>Leads from your widget will be displayed here</div>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

CustomerListing.propTypes = {
  widgetId: PropTypes.string,
  widgetType: PropTypes.string,
};

export default CustomerListing;
