import {UPDATE_WIDGET_SETTINGS, VIDEO_DETAILS, CLEAR_VIDEO_DETAILS, ADD_WIDGET_REQUEST, WIDGET_REQUEST_CLEAR, GET_WIDGET_REQUEST, WIDGET_LIST_REQUEST_CLEAR, DELETE_WIDGET_REQUEST, WIDGET_DELETE_REQUEST_CLEAR, WIDGET_SETTINGS_CLEAR, UPDATE_WIDGET_REQUEST, UPDATE_WIDGET_LIVE_STATUS_REQUEST, UPDATE_WIDGET_LIVE_STATUS_REQUEST_CLEAR, GET_WIDGET_ANALYTICS_REQUEST, GET_WIDGET_ANALYTICS_REQUEST_CLEAR, SELECTED_WIDGET, ADD_UPDATE_WIDGET_PREVIEW_REQUEST, ADD_UPDATE_WIDGET_PREVIEW_CLEAR, COLLAPSE_WIDGET} from '../constants/reducer.const';

export const updateWidgetSettings = (payload)=>({type: UPDATE_WIDGET_SETTINGS, payload});
export const updateVideoDetails = (payload)=>({type: VIDEO_DETAILS, payload});
export const clearVideoDetails = ()=>({type: CLEAR_VIDEO_DETAILS, patload: {}});

// widget operations
export const addWidgetAction = (payload)=>({type: ADD_WIDGET_REQUEST, payload});
export const addUpdateWidgetPreviewAction = (payload)=>({type: ADD_UPDATE_WIDGET_PREVIEW_REQUEST, payload});
export const updateWidgetAction = (payload)=>({type: UPDATE_WIDGET_REQUEST, payload});
export const updateWidgetLiveStatusAction = (payload)=>({type: UPDATE_WIDGET_LIVE_STATUS_REQUEST, payload});
export const updateWidgetLiveStatusClearAction = (payload)=>({type: UPDATE_WIDGET_LIVE_STATUS_REQUEST_CLEAR, payload});
export const clearWidget = ()=>({type: WIDGET_REQUEST_CLEAR, payload: {}});
export const deleteWidgetAction = (payload)=>({type: DELETE_WIDGET_REQUEST, payload});
export const clearDeleteWidgetAction = ()=>({type: WIDGET_DELETE_REQUEST_CLEAR, payload: {}});
export const clearWidgetSettings = ()=>({type: WIDGET_SETTINGS_CLEAR, payload: {}});
export const clearWidgetPreviewSettings = ()=>({type: ADD_UPDATE_WIDGET_PREVIEW_CLEAR, payload: {}});

// Get all widgets
export const getAllWidgets = (payload)=>({type: GET_WIDGET_REQUEST, payload});
export const setSelectedWidget = (payload)=>({type: SELECTED_WIDGET, payload});
export const clearWidgetList = ()=>({type: WIDGET_LIST_REQUEST_CLEAR, payload: {}});

// Analytics
export const getWidgetAnalyticsAction = (payload)=>({type: GET_WIDGET_ANALYTICS_REQUEST, payload});
export const clearWidgetAnalyticsAction = ()=>({type: GET_WIDGET_ANALYTICS_REQUEST_CLEAR, payload: {}});

// Collapsable
export const updateCollapseAction = (payload)=>({type: COLLAPSE_WIDGET, payload});
