import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getAllPlansAdmin} from '../../actions/plans.actions';
import Button from '../../components/button/button.component';
import Chips from '../../components/chips';
import SettingsIcon from '../../icons/settings.icon';
import {GET_ALL_PLANS_ADMIN_SUCCESS} from '../../constants/reducer.const';
import {toMb} from '../../helpers/common.helper';
import {PLAN_TYPE_MAPPER} from '../../constants/application.const';
import Heading from '../../components/heading';
import {useNavigate} from 'react-router-dom';

/**
 * Admin plans page
 *
 * @return {JSX}
 */
const AdminPlansPage = ()=>{
  const dispatch = useDispatch();
  const planSelector = useSelector((state)=>state?.plan);
  const [plans, setPlans] = useState([]);
  const navigate = useNavigate();

  // called initially
  useEffect(()=>{
    dispatch(getAllPlansAdmin());
  }, []);

  useEffect(()=>{
    if (planSelector?.planAdminDetails?.type === GET_ALL_PLANS_ADMIN_SUCCESS) {
      setPlans(planSelector?.planAdminDetails?.payload?.data);
    }
  }, [planSelector?.planAdminDetails]);

  return (
    <div className='px-8 pt-8 h-full'>
      <Heading className={'font-semibold text-xl'}>Plan</Heading>
      <div className='flex mt-4 justify-end'>
        <Button
          buttonText='Add Plan'
          type={'button'}
          onClick={()=>navigate('/admin/add-plans')}
          classOverride={`flex items-center justify-center px-3 py-2 text-sm font-medium text-white duration-150 bg-blue-600 border border-transparent rounded-lg hover:bg-blue-700 active:bg-blue-500 focus:outline-none focus:shadow-outline-blue`}
        />
      </div>
      <div className="flex flex-col w-full overflow-hidden mt-4 border border-slate-300 rounded-lg ">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-y-auto max-h-96">
              <table className="border-collapse min-w-full rounded-md bg-white">
                <thead className="rounded-md">
                  <tr className='text-xs font-semibold tracking-wide text-left text-gray-500 uppercase ddark:border-gray-700 bg-gray-50 ddark:text-gray-400 ddark:bg-gray-800 rounded-md border-b mb-4'>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">S.no</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">Plan Name</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">Plan Description</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">Price(in $)</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">Order</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">Type</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">Storage Cap</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">Shopify exclusive</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {plans?.map((data, index)=>(
                    <tr key={index}>
                      <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap text-center">{index+1}</td>
                      <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap text-center">{data?.name}</td>
                      <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap text-center">{data?.description}</td>
                      <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap text-center">{data?.price}</td>
                      <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap text-center">{data?.orderPrecedence}</td>
                      <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap text-center ">
                        <div className='flex justify-center'>
                          <Chips
                            text={PLAN_TYPE_MAPPER[data?.type]}
                            color={PLAN_TYPE_MAPPER[data?.type] ===PLAN_TYPE_MAPPER.R?'teal':'pink'}
                          />
                        </div>
                      </td>
                      <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap text-center ">
                        {toMb(data?.storageCap)}mb
                      </td>
                      <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap text-center ">
                        {data?.shopifyExclusive?'Yes':'No'}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-2 py-1 text-center ">
                        <div className='flex justify-center'>
                          <Button classOverride={'flex items-center justify-center px-2 py-1 text-md font-medium leading-5 transition-colors duration-150 bg-blue-200 border border-transparent rounded-lg hover:bg-blue-300 focus:outline-none focus:shadow-outline-blue text-blue-600 border-2'}
                            onClick={()=>{
                              navigate(`/admin/edit-plan/${data?.id}`);
                            }}
                          >
                            <SettingsIcon
                              height={'20px'}
                              width={'20px'}
                            />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPlansPage;
