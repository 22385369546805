import axios from 'axios';
import {BASE_URL} from '../constants/application.const';

/**
 * Get decrypted text
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const getDcryptedTextApi = (payload)=>{
  return axios.post(`${BASE_URL}common/decrypt-text`, payload)
      .then((response) => (response?.data));
};
