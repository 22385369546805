import React, {useState} from 'react';
import Button from './button.component';
import UploadIcon from '../../icons/upload.icon';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import RedemptionModalComponent from '../modal/redemptionModal.component';
import {useEffect} from 'react';
import {getValue} from '../../config/localStorage.config';
import {USER_DETAILS} from '../../constants/application.const';

/**
 * Upgrade button
 *
 * @return {JSX}
 */
const UpgradeButton = ({additionalClass})=>{
  const navigate = useNavigate();
  const [redemptionModalToggle, setRedemptionModalToggle] = useState(false);
  const [currentUserData, setCurrentUserData] = useState({});

  // Get user data from local storage
  const getLocalStorageUserData = ()=>{
    const userData = getValue(USER_DETAILS);
    if (userData) {
      setCurrentUserData(JSON.parse(userData));
    }
  };

  useEffect(()=>{
    getLocalStorageUserData();
  }, []);

  return (
    <>
      <Button classOverride={`flex items-center justify-center p-2 text-sm font-medium text-white duration-150 bg-primary border border-transparent rounded-lg active:bg-blue-500 hover:bg-purple-700 focus:outline-none focus:shadow-outline-blue ${additionalClass}`}
        onClick={()=>{
          if (currentUserData?.saaszillacode) {
            setRedemptionModalToggle(true);
          } else {
            navigate('/upgrade');
          }
        }}
      >
        <UploadIcon color={'#fff'} className={'mr-2'}/>
            Upgrade
      </Button>
      {redemptionModalToggle && <RedemptionModalComponent
        onClose={()=>{
          setRedemptionModalToggle(false);
        }}
      />}
    </>
  );
};

UpgradeButton.propTypes = {
  additionalClass: PropTypes.string,
};

export default UpgradeButton;
