import React, {useState, useRef} from 'react';
import sampleVideo from '../assets/videos/sample.mp4';
import CloseIcon from './closeIcon.component';
import Timer from './timer.component';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import {ACTION_TYPE_MAPPER, FONT_SIZE_MAPPER, FONT_TYPE_MAPPER, VALIDATION_MAPPER} from './application.const';
import VideoControls from './videoControls.component';
import fbIcon from '../assets/images/fb.png';
import CalendlyIcon from '../assets/images/calendly.png';
import Watermark from '../assets/images/watermark.png';
import CopyToClipboard from 'react-copy-to-clipboard';
import SissorIcon from '../icons/sissor.icon';
import BubbleQuestionComponent from './bubbleQuestion.component';

/**
 * Widget component
 *
 * @return {JSX}
 */
function Widget({closeCallback, backgroundColor, title, headerText, headerTextConfig, actionType, fieldConfig, counterConfig, actionButtonConfig, actionButtonName, actionButtonLink, counterStatus, mediaDetails, borderColor, mode, couponConfig, gradiantConfig, couponCode, couponDisplay, branding, couponCodeNotification, couponDisplayNotification, couponCodeNotificationCode, entrySuccessConfig, entryFailConfig, entryPopupSuccessText, entryPopupFailText, toggleStatusMessageSuccess, toggleStatusMessageFail, ctaButtonNotificationEnable, ctaButtonNotificationName, ctaButtonNotificationLink, ctaButtonNotificationConfig, surveyQuestionSettings, surveyQuestionAnswerType}) {
  const {register, handleSubmit, formState: {errors}} = useForm();
  const [videoMute, setVideoMute] = useState(false);
  const [copied, setCopied] = useState(false);
  const [copiedSuccess, setCopiedSuccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [surveyQuestionsData, setSurveyQuestionsData] = useState({});
  const videoRef = useRef();

  // Change copied status
  const changeCopiedStatus = (type = 1) => {
    if (type == 1) {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    } else if (type == 2) {
      setCopiedSuccess(true);
      setTimeout(() => {
        setCopiedSuccess(false);
      }, 2000);
    }
  };

  // On form submit
  const onFormSubmit = (value)=>{
    console.log(value);
    changePage(1);
  };

  // Question answer submit(callback function)
  const questionAnswerSubmit = (questionAnswerData)=>{
    console.log(questionAnswerData);
  };

  // Change page
  const changePage = (page)=>{
    setCurrentPage(page);
  };

  return (
    <>
      <div style={{
        position: 'relative',
        height: '500px',
        width: '350px',
        borderRadius: '10px',
        borderWidth: '4px',
        borderColor: (borderColor || '#cccc'),
        bottom: mode === 'dev'?'30px':'',
      }}>
        {(mediaDetails?.type === 'V') && <VideoControls
          controlColors={borderColor}
          callback={(status)=>{
            if (status === 'M' || status === 'UM') {
              setVideoMute(status === 'UM');
            }
            if (status === 'P') {
              videoRef?.current?.play();
            }
            if (status === 'PA') {
              videoRef?.current?.pause();
            }
          }}
        />}
        <button
          style={{
            position: 'absolute',
            zIndex: 10,
            top: '.5rem',
            right: '.5rem',
            cursor: 'pointer',
          }}
          onClick={()=>closeCallback()}>
          <CloseIcon color={borderColor || '#ffff'}/>
        </button>
        {(mediaDetails?.type === 'V') && <video muted={videoMute} ref={videoRef} loop autoPlay style={{
          height: '100%',
          width: '100%',
          objectFit: 'cover',
          // borderRadius: '5%',
          position: 'relative',
          zIndex: '-1',
        }}>
          <source src={mediaDetails?.mediaPath || sampleVideo}/>
          Your browser does not support HTML video.
        </video>}
        {(mediaDetails?.type === 'I') && <img src={mediaDetails?.mediaPath || 'https://picsum.photos/500'} style={{
          height: '100%',
          width: '100%',
          objectFit: 'cover',
          // borderRadius: '5%',
          position: 'relative',
          zIndex: '-1',
        }}/>}
        {!mediaDetails?.type && <img src={'https://picsum.photos/500'} style={{
          height: '100%',
          width: '100%',
          objectFit: 'cover',
          // borderRadius: '5%',
          position: 'relative',
          zIndex: '-1',
        }}/>}
        {(mediaDetails?.type === 'P') && <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            // borderRadius: '5%',
          }}
        >
          <h1>{title}</h1>
        </div>}

        <div
          style={{
            position: 'absolute',
            bottom: '1rem',
            width: '100%',
            padding: '1rem',
          }}
        >
          {/* Question component */}
          {(currentPage === 1 || surveyQuestionSettings?.length === 1) &&
          <div
            className='flex flex-col p-2'
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '1rem',
              width: '100%',
              backgroundColor: gradiantConfig?.hlsaValue || 'hsla(0, 0%, 0%, 0.3)',
            }}
          >
            {/* Header text */}
            {headerText && <p style={{
              fontWeight: FONT_TYPE_MAPPER[headerTextConfig?.fontType?.value],
              fontSize: FONT_SIZE_MAPPER[headerTextConfig?.fontSize?.value],
              color: headerTextConfig?.textColor?.value,
              textAlign: 'center',
            }}>{headerText}</p>}
            <BubbleQuestionComponent
              surveyQuestionSettings={surveyQuestionSettings}
              questTextColor={headerTextConfig?.textColor?.value}
              surveyQuestionAnswerType={surveyQuestionAnswerType || 'SC'}
              submitFunctionCallback={questionAnswerSubmit}
              changePage={changePage}
              buttonDesignStyle={{
                padding: '10px',
                backgroundColor: actionButtonConfig?.actionButtonColor?.value || 'purple',
                borderRadius: '5px',
                color: '#fff',
                fontSize: '12px',
              }}
              optionColor={'#ffff'}
              oneQuestionStatus={surveyQuestionSettings?.length === 1}
              onSurveyQuestionUpdate={(question)=>{
                setSurveyQuestionsData(question);
              }}
            />
          </div>}

          {/* Form component */}
          {currentPage === 0 && <>
            <form
              onSubmit={handleSubmit(onFormSubmit)}
              className='flex flex-col p-2'
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '1rem',
                width: '100%',
                backgroundColor: gradiantConfig?.hlsaValue || 'hsla(0, 0%, 0%, 0.3)',
              }}
              noValidate>

              {/* Coupon code */}
              {couponCode && couponDisplay && <>
                {!copied && <CopyToClipboard
                  options={{
                    message: 'Copied',
                  }}
                  text={couponCode}
                  onCopy={()=>changeCopiedStatus()}
                >
                  <p
                    title="Copy to clipboard?"
                    style={{
                      fontWeight: FONT_TYPE_MAPPER[couponConfig?.fontType?.value],
                      fontSize: FONT_SIZE_MAPPER[couponConfig?.fontSize?.value],
                      color: couponConfig?.textColor?.value,
                      backgroundColor: couponConfig?.backgroundColor?.value || '#fffaa3',
                      padding: '4px',
                      textAlign: 'center',
                      marginBottom: counterStatus?'':'10px',
                      border: `dashed 3px ${couponConfig?.textColor?.value || '#000000'}`,
                      padding: '5px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      position: 'relative',
                    }}
                  >
                    <SissorIcon
                      height={'25px'}
                      width={'25px'}
                      style={{
                        marginLeft: '-18px',
                        color: couponConfig?.textColor?.value || '#000000',
                        position: 'absolute',
                      }}
                    />
                    {couponCode}
                  </p></CopyToClipboard>}
                {copied && <p
                  style={{
                    fontWeight: FONT_TYPE_MAPPER[couponConfig?.fontType?.value],
                    fontSize: FONT_SIZE_MAPPER[couponConfig?.fontSize?.value],
                    color: couponConfig?.textColor?.value,
                    backgroundColor: couponConfig?.backgroundColor?.value,
                    padding: '4px',
                    textAlign: 'center',
                    marginBottom: counterStatus?'':'10px',
                    border: `dashed 3px ${couponConfig?.textColor?.value || '#000000'}`,
                    borderRadius: '5px',
                    cursor: 'pointer',
                    position: 'relative',
                  }}
                >
                  <SissorIcon
                    height={'25px'}
                    width={'25px'}
                    style={{
                      marginLeft: '-18px',
                      color: couponConfig?.textColor?.value || '#000000',
                      position: 'absolute',
                    }}
                  />
                Copied!!!
                </p>}
              </>}

              {/* Timer component */}
              { counterStatus && <Timer counterConfig={counterConfig} />}

              {/* Form and button config */}
              {actionType === ACTION_TYPE_MAPPER.F.value && <>
                {fieldConfig?.fieldOne?.enabledStatus?.value && <input
                  type={fieldConfig?.fieldOne?.fieldType?.value}
                  placeholder={fieldConfig?.fieldOne?.fieldPlaceholder?.value || 'Field one'}
                  {...register('fieldOne', {
                    required: (fieldConfig?.fieldOne?.requiredStatus?.value).toString() === 'true',
                    pattern: (fieldConfig?.fieldOne?.validationType?.value === 'C'?fieldConfig?.fieldOne?.customValidationPattern:VALIDATION_MAPPER[fieldConfig?.fieldOne?.validationType?.value]) || VALIDATION_MAPPER['ALL'],
                  })}
                  style={{
                    border: '2px',
                    padding: '8px',
                    borderStyle: 'solid',
                    borderColor: errors.fieldOne?.type?'red':'gray',
                    borderRadius: '5px',
                    width: '100%',
                  }}
                />}
                {errors.fieldOne?.type === 'required' && <p style={{color: 'red', fontSize: '14px', fontWeight: 'bold'}}>Field required</p>}
                {errors.fieldOne?.type === 'pattern' && <p style={{color: 'red', fontSize: '14px', fontWeight: 'bold'}}>Invalid value</p>}
                {fieldConfig?.fieldTwo?.enabledStatus?.value && <input
                  type={fieldConfig?.fieldTwo?.fieldType?.value}
                  placeholder={fieldConfig?.fieldTwo?.fieldPlaceholder?.value || 'Field two'}
                  {...register('fieldTwo', {
                    required: (fieldConfig?.fieldTwo?.requiredStatus?.value).toString() === 'true',
                    pattern: (fieldConfig?.fieldTwo?.validationType?.value === 'C'?fieldConfig?.fieldTwo?.customValidationPattern:VALIDATION_MAPPER[fieldConfig?.fieldTwo?.validationType?.value]) || VALIDATION_MAPPER['ALL'],
                  })}
                  style={{
                    border: '2px',
                    padding: '8px',
                    borderStyle: 'solid',
                    borderColor: errors.fieldTwo?.type?'red':'gray',
                    borderRadius: '5px',
                    marginTop: '10px',
                    width: '100%',
                  }}
                />}
              </>}
              {errors.fieldTwo?.type === 'required' && <p style={{color: 'red', fontSize: '14px', fontWeight: 'bold'}}>Field required</p>}
              {errors.fieldTwo?.type === 'pattern' && <p style={{color: 'red', fontSize: '14px', fontWeight: 'bold'}}>Invalid value</p>}
              {(actionType !== ACTION_TYPE_MAPPER.FB.value && actionType !== ACTION_TYPE_MAPPER.CAL.value) && <div style={{display: 'flex', justifyContent: 'center'}}>
                <button
                  style={{
                    padding: '8px 20px',
                    backgroundColor: actionButtonConfig?.actionButtonColor?.value || 'purple',
                    borderRadius: '5px',
                    marginTop: '10px',
                    color: '#fff',
                    flex: 1,
                  }}
                  onClick={()=>{
                    if (actionType === ACTION_TYPE_MAPPER.AB.value && surveyQuestionSettings?.length === 1) {
                      questionAnswerSubmit(surveyQuestionsData);
                    } else if (actionType === ACTION_TYPE_MAPPER.AB.value) {
                      changePage(1);
                    }
                  }}
                  type={actionType === ACTION_TYPE_MAPPER.AB.value?'button':'submit'}
                >{actionButtonName || 'Submit'}</button>
              </div>}
              {actionType === ACTION_TYPE_MAPPER.FB.value && <button style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{backgroundColor: '#1F82F6', color: '#ffff', display: 'flex', fontSize: '14px', width: '140px', display: 'flex', justifyContent: 'center', borderRadius: '5px'}}>
                  <img src={fbIcon} height="24" width="24"/>
                  <span>Message Us</span>
                </div>
              </button>}
              {actionType === ACTION_TYPE_MAPPER.CAL.value && <button style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{backgroundColor: '#ffff', color: '#0669F6', display: 'flex', fontSize: '14px', width: '200px', display: 'flex', justifyContent: 'center', borderRadius: '5px', paddingTop: '4px', paddingBottom: '4px', border: '2px', borderStyle: 'solid', borderColor: '#0669F6'}}>
                  <img src={CalendlyIcon} height="24" width="24" style={{marginRight: '2px'}}/>
                  <span>Schedule a meeting</span>
                </div>
              </button>}
            </form>
          </>}
        </div>

        {toggleStatusMessageSuccess && <div style={{position: 'absolute', bottom: '10px', width: '100%', padding: '10px'}}>
          <div style={{backgroundColor: entrySuccessConfig?.backgroundColor?.value || '#a955f7', padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', wordBreak: 'break-word'}}>
            <h3 style={{'color': entrySuccessConfig?.textColor?.value || '#ffff', 'fontWeight': 'bold', 'marginBottom': '1rem', 'marginTop': '1rem'}}>{entryPopupSuccessText || 'Thank you'}</h3>

            {/* Coupon code for message */}
            {couponCodeNotificationCode && couponDisplayNotification && <>
              {!copiedSuccess && <CopyToClipboard
                options={{
                  message: 'Copied',
                }}
                text={couponCodeNotificationCode}
                onCopy={()=>changeCopiedStatus(2)}
              >
                <p
                  title="Copy to clipboard?"
                  style={{
                    fontWeight: FONT_TYPE_MAPPER[couponCodeNotification?.fontType?.value],
                    fontSize: FONT_SIZE_MAPPER[couponCodeNotification?.fontSize?.value],
                    color: couponCodeNotification?.textColor?.value,
                    backgroundColor: couponCodeNotification?.backgroundColor?.value,
                    padding: '4px',
                    textAlign: 'center',
                    marginBottom: counterStatus?'':'10px',
                    border: `dashed 3px ${couponCodeNotification?.textColor?.value || '#000000'}`,
                    padding: '5px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    position: 'relative',
                  }}
                >
                  <SissorIcon
                    height={'25px'}
                    width={'25px'}
                    style={{
                      marginLeft: '-18px',
                      color: couponCodeNotification?.textColor?.value || '#000000',
                      position: 'absolute',
                    }}
                  />
                  {couponCodeNotificationCode}
                </p></CopyToClipboard>}
              {copiedSuccess && <p
                style={{
                  fontWeight: FONT_TYPE_MAPPER[couponCodeNotification?.fontType?.value],
                  fontSize: FONT_SIZE_MAPPER[couponCodeNotification?.fontSize?.value],
                  color: couponCodeNotification?.textColor?.value,
                  backgroundColor: couponCodeNotification?.backgroundColor?.value,
                  padding: '4px',
                  textAlign: 'center',
                  marginBottom: counterStatus?'':'10px',
                  border: `dashed 3px ${couponCodeNotification?.textColor?.value || '#000000'}`,
                  borderRadius: '5px',
                  cursor: 'pointer',
                  position: 'relative',
                }}
              >
                <SissorIcon
                  height={'25px'}
                  width={'25px'}
                  style={{
                    marginLeft: '-18px',
                    color: couponCodeNotification?.textColor?.value || '#000000',
                    position: 'absolute',
                  }}
                />
                Copied!!!
              </p>}
            </>}

            {/* Button config for success message */}
            <>
              {ctaButtonNotificationEnable && <div style={{display: 'flex', justifyContent: 'center'}}>
                <button
                  style={{
                    padding: '8px 20px',
                    backgroundColor: ctaButtonNotificationConfig?.actionButtonColor?.value || '#000000',
                    borderRadius: '5px',
                    marginTop: '10px',
                    color: '#fff',
                  }}
                  onClick={()=>{
                    if (ctaButtonNotificationLink) {
                      window.open(
                          ctaButtonNotificationLink,
                          '_blank',
                      );
                    }
                  }}
                  type={'button'}
                >{ctaButtonNotificationName || 'Redirect'}</button>
              </div>}
            </>

          </div>
        </div>}
        {toggleStatusMessageFail && <div style={{position: 'absolute', bottom: '10px', width: '100%', padding: '10px'}}>
          <div style={{backgroundColor: entryFailConfig?.backgroundColor?.value || '#f43f5d', padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', wordBreak: 'break-word'}}>
            <h3 style={{'color': entryFailConfig?.textColor?.value || '#ffff', 'fontWeight': 'bold', 'marginBottom': '1rem', 'marginTop': '1rem'}}>{entryPopupFailText || 'Try again later'}</h3>
          </div>
        </div>}
        {branding === 'Y' && <div style={{backgroundColor: 'white', padding: '1px 0px 1px 0px', fontSize: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', bottom: '-6px', borderBottomLeftRadius: '1rem', borderBottomRightRadius: '1rem', width: '100%'}}><span>Create your free widget </span><a href="http://customer.getpopsuite.com/register" target="_blank" alt="popsuite website" rel="noreferrer" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><img src={Watermark} height="50" width="50" style={{marginLeft: '2px', borderRadius: '2px'}}/></a></div>}
      </div>
    </>
  );
}

Widget.propTypes = {
  closeCallback: PropTypes.func,
  widgetComponents: PropTypes.array,
  backgroundColor: PropTypes.string,
  title: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonText: PropTypes.string,
  headerText: PropTypes.string,
  buttonLink: PropTypes.string,
  headerTextColor: PropTypes.string,
  counterConfig: PropTypes.any,
  actionType: PropTypes.string,
  fieldConfig: PropTypes.any,
  actionButtonConfig: PropTypes.any,
  actionButtonName: PropTypes.string,
  actionButtonLink: PropTypes.string,
  counterStatus: PropTypes.bool,
  mediaDetails: PropTypes.any,
  borderColor: PropTypes.string,
  headerTextConfig: PropTypes.any,
  mode: PropTypes.string,
  couponCode: PropTypes.string,
  couponConfig: PropTypes.any,
  gradiantConfig: PropTypes.any,
  couponDisplay: PropTypes.bool,
  couponCodeNotificationCode: PropTypes.string,
  couponCodeNotification: PropTypes.any,
  couponDisplayNotification: PropTypes.bool,
  branding: PropTypes.string,
  entrySuccessConfig: PropTypes.any,
  entryPopupSuccessText: PropTypes.string,
  entryFailConfig: PropTypes.any,
  entryPopupFailText: PropTypes.string,
  toggleStatusMessageSuccess: PropTypes.bool,
  toggleStatusMessageFail: PropTypes.bool,
  ctaButtonNotificationEnable: PropTypes.bool,
  ctaButtonNotificationName: PropTypes.string,
  ctaButtonNotificationLink: PropTypes.string,
  ctaButtonNotificationConfig: PropTypes.any,
  surveyQuestionSettings: PropTypes.any,
  surveyQuestionAnswerType: PropTypes.string,
};


export default Widget;
