import React, {useEffect} from 'react';
import Heading from '../../../components/heading';
import Watermark from '../../../assets/images/watermark.png';
import {useSearchParams} from 'react-router-dom';
import {WIDGET_URL} from '../../../constants/application.const';

/**
 * Preview page
 *
 * @return {JSX}
 */
const PreviewPage = ()=>{
  const [query] = useSearchParams();

  useEffect(()=>{
    const script = document.createElement('script');
    script.src = `${WIDGET_URL[query?.get('widget_type')]}deployer.js?linkId=preview`;
    document.querySelector('#preview_window_container').appendChild(script);
  }, [query]);

  return (
    <div className="w-full h-full overflow-hidden" id="preview_window_container">
      <Heading className={'font-semibold my-2 justify-center flex'}>
        <div>
          <div style={{backgroundColor: 'white', padding: '1px 0px 1px 0px', fontSize: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottomLeftRadius: '2rem', borderBottomRightRadius: '2rem', width: '100%'}}><a href="https://customer.getpopsuite.com/register" target="_blank" alt="popsuite website" rel="noreferrer" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><img src={Watermark} height="20" width="80" style={{marginLeft: '2px'}}/></a></div>
          <div className='bg-primary p-2 rounded-md text-white flex-1 mt-1'>This is a preview page</div>
        </div>
      </Heading>
      <iframe src={query?.get('site')} height="90%" width="100%" title="description"></iframe>
    </div>
  );
};

export default PreviewPage;
