import React, {useState} from 'react';
import StarIcon from './star.icon';
import PropTypes from 'prop-types';

/**
 * Rating component
 *
 * @return {JSX}
 */
const RatingComponent = ({starColor})=>{
  const [ratingStatus, setRatingStatus] = useState(0);
  const [ratingHoverStatus, setRatingHoverStatus] = useState(0);

  // click start
  const clickStar = (index)=>{
    setRatingStatus(index+1);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '.5rem',
      }}
    >
      <StarIcon
        onClick={()=>{
          clickStar(ratingStatus === 1? -1:0);
        }}
        height={'40'}
        width={'40'}
        style={{
          cursor: 'pointer',
          color: (ratingHoverStatus || ratingStatus) > 0?starColor:'',
        }}
        onMouseEnter={()=>{
          setRatingHoverStatus(1);
        }}
        onMouseLeave={()=>{
          setRatingHoverStatus(0);
        }}
      />
      <StarIcon
        onClick={()=>{
          clickStar(1);
        }}
        height={'40'}
        width={'40'}
        style={{
          cursor: 'pointer',
          color: (ratingHoverStatus || ratingStatus) > 1?starColor:'',
        }}
        onMouseEnter={()=>{
          setRatingHoverStatus(2);
        }}
        onMouseLeave={()=>{
          setRatingHoverStatus(0);
        }}
      />
      <StarIcon
        onClick={()=>{
          clickStar(2);
        }}
        height={'40'}
        width={'40'}
        style={{
          cursor: 'pointer',
          color: (ratingHoverStatus || ratingStatus) > 2?starColor:'',
        }}
        onMouseEnter={()=>{
          setRatingHoverStatus(3);
        }}
        onMouseLeave={()=>{
          setRatingHoverStatus(0);
        }}
      />
      <StarIcon
        onClick={()=>{
          clickStar(3);
        }}
        height={'40'}
        width={'40'}
        style={{
          cursor: 'pointer',
          color: (ratingHoverStatus || ratingStatus) > 3?starColor:'',
        }}
        onMouseEnter={()=>{
          setRatingHoverStatus(4);
        }}
        onMouseLeave={()=>{
          setRatingHoverStatus(0);
        }}
      />
      <StarIcon
        onClick={()=>{
          clickStar(4);
        }}
        height={'40'}
        width={'40'}
        style={{
          cursor: 'pointer',
          color: (ratingHoverStatus || ratingStatus) > 4?starColor:'',
        }}
        onMouseEnter={()=>{
          setRatingHoverStatus(5);
        }}
        onMouseLeave={()=>{
          setRatingHoverStatus(0);
        }}
      />
    </div>
  );
};

RatingComponent.propTypes = {
  starColor: PropTypes.string,
};

export default RatingComponent;
