import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {shopifyOauthRedirectionAction} from '../../actions/integrations.actions';
import SiteLogo from '../../assets/images/widget-image.png';

/**
 * Shopify redirection page
 *
 * @return {JSX}
 */
const ShopifyRedirectionPage = ()=>{
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);

  useEffect(()=>{
    const shop = searchParams.get('shop')?.split('.')[0];
    if (shop) {
      dispatch(shopifyOauthRedirectionAction({
        storeName: shop,
      }));
    } else {
      setShowLoader(true);
      setTimeout(()=>{
        window.open(
            'https://customer.getpopsuite.com',
            '_blank',
        );
      }, 2000);
    }
  }, []);

  return (
    <>
      {showLoader && <div className='h-full flex items-center justify-center flex-col'>
        <div className='flex justify-center'>
          <img
            src={SiteLogo}
            height='400px'
            width='400px'
            className='rounded-lg'
          />
        </div>
        <div className='text-center'>
          <p>Please click below if you are not redirected</p>
          <a href="https://customer.getpopsuite.com" target='_blank' rel="noreferrer" className='text-blue-800 font-bold underline'>redirect</a>
        </div>
      </div>}
    </>
  );
};

export default ShopifyRedirectionPage;
