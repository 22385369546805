import React, {useEffect, useRef, useState} from 'react';
import Input from './input.component';
import Chips from '../chips/index';
import PropTypes from 'prop-types';
import ErrorText from '../errorComponents/errorText.component';
// import {URL_PATTERN_WITH_ROUTES} from '../../constants/application.const';
import {domainCounter} from '../../helpers/common.helper';

/**
 * Chip input component
 *
 * @return {JSX}
 */
const ChipInput = ({placeholder, valuesOnChange, defaultValues, chipsLength, chipsLengthErrorMessage, misc})=>{
  const [textValue, setTextValue] = useState('');
  const [chips, setChips] = useState([]);
  const ref = useRef();
  const [errorStatus, setErrorStatus] = useState({
    status: false,
    message: '',
  });

  useEffect(()=>{
    if (defaultValues) {
      setChips(defaultValues?.split(','));
    }
  }, [defaultValues]);

  // add/remove chips from
  const addRemoveChips = (addValue, removeIndex, event)=>{
    // if (addValue && !URL_PATTERN_WITH_ROUTES?.test(addValue)) {
    //   setErrorStatus({
    //     status: true,
    //     message: 'Enter a valid address(example: www.example.com/<page>)',
    //   });
    //   return;
    // }
    if (addValue && domainCounter([...chips, addValue]) > chipsLength) {
      setErrorStatus({
        status: true,
        message: chipsLengthErrorMessage || `Chips cannot exceed ${chipsLength}`,
      });
      return;
    }
    if (addValue) {
      const tempArray = [...chips, addValue];
      setChips([...tempArray]);
      event.target.value = '';
      valuesOnChange([...tempArray]);
    }
    if (typeof removeIndex !== 'undefined') {
      const tempArray = [...chips];
      tempArray.splice(removeIndex, 1);
      setChips([...tempArray]);
      valuesOnChange([...tempArray]);
    }
    setTextValue('');
  };

  return (
    <div className='relative w-full'>
      <Input
        ref={ref}
        parentAdditionalClass={'w-full'}
        placeholder={placeholder}
        defaultValue={textValue}
        onChange={(event)=>setTextValue(event?.target?.value)}
        onKeyUp={(event)=>{
          setErrorStatus({
            status: false,
            message: '',
          });
          if (event.keyCode === 13) {
            event.preventDefault();
            const temptextValue = textValue.replace(/((https:\/\/)|(http:\/\/))/, '');
            const textValueTemp = textValue?misc?.protocol+temptextValue:'';
            addRemoveChips(textValueTemp, undefined, event);
          }
        }}
        enterEnable={true}
        onEnterPressed={()=>{
          const temptextValue = textValue.replace(/((https:\/\/)|(http:\/\/))/, '');
          const textValueTemp = textValue?misc?.protocol+temptextValue:'';
          addRemoveChips(textValueTemp, undefined, {
            target: ref.current,
          });
        }}
        errorStatus={errorStatus?.status}
      />
      {errorStatus?.status && <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errorStatus?.message}</ErrorText>}
      {chips?.length ? <div className='mt-1 flex flex-wrap break-all'>
        {chips?.map((value, index)=>(<Chips
          color={'purple'}
          additionalClass={'mr-1 mt-1'}
          key={index}
          enableClose={true}
          closeOnClick={()=>{
            addRemoveChips('', index);
          }}
        >{value}</Chips>))}
      </div>:<></>}
    </div>
  );
};

ChipInput.propTypes = {
  placeholder: PropTypes.string,
  valuesOnChange: PropTypes.func,
  defaultValues: PropTypes.string,
  chipLimit: PropTypes.number,
  miscValidation: PropTypes.any,
  chipsLength: PropTypes.number,
  chipsLengthErrorMessage: PropTypes.string,
  misc: PropTypes.any,
};

export default ChipInput;
