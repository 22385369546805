import {PLAN_REQUEST_CLEAR, GET_PLAN_SUCCESS, GET_PLAN_FAILED, INITIATE_PAYMENT_STRIPE_SUCCESS
  , INITIATE_PAYMENT_STRIPE_FAILED
  , INITIATE_PAYMENT_STRIPE_CLEAR,
  UPDATE_PAYMENT_STATUS_SUCCESS,
  UPDATE_PAYMENT_STATUS_FAILED,
  GET_ALL_PLANS_ADMIN_SUCCESS,
  GET_ALL_PLANS_ADMIN_FAILED,
  GET_ALL_PLANS_ADMIN_CLEAR,
  ADD_PLAN_ADMIN_SUCCESS,
  ADD_PLAN_ADMIN_CLEAR,
  ADD_PLAN_ADMIN_FAILED,
  EDIT_PLAN_ADMIN_SUCCESS,
  EDIT_PLAN_ADMIN_CLEAR,
  EDIT_PLAN_ADMIN_FAILED} from '../constants/reducer.const';

const initialState = {};

/**
 * Plan settings reducer function
 *
 * @param {any} state
 * @param {any} action
 * @return {any}
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PLAN_SUCCESS: return {...state, planDetails: {...action}};
    case GET_PLAN_FAILED: return {...state, planDetails: {...action}};

    case GET_ALL_PLANS_ADMIN_SUCCESS: return {...state, planAdminDetails: {...action}};
    case GET_ALL_PLANS_ADMIN_CLEAR: return {...state, planAdminDetails: {}};
    case GET_ALL_PLANS_ADMIN_FAILED: return {...state, planAdminDetails: {...action}};

    case ADD_PLAN_ADMIN_SUCCESS: return {...state, addPlanAdminDetails: {...action}};
    case ADD_PLAN_ADMIN_CLEAR: return {...state, addPlanAdminDetails: {}};
    case ADD_PLAN_ADMIN_FAILED: return {...state, addPlanAdminDetails: {...action}};

    case EDIT_PLAN_ADMIN_SUCCESS: return {...state, editPlanAdminDetails: {...action}};
    case EDIT_PLAN_ADMIN_CLEAR: return {...state, editPlanAdminDetails: {}};
    case EDIT_PLAN_ADMIN_FAILED: return {...state, editPlanAdminDetails: {...action}};

    case INITIATE_PAYMENT_STRIPE_SUCCESS: return {...state, paymentInitResponse: {...action}};
    case INITIATE_PAYMENT_STRIPE_FAILED: return {...state, paymentInitResponse: {...action}};

    case UPDATE_PAYMENT_STATUS_SUCCESS: return {...state, updatePaymentResponse: {...action}};
    case UPDATE_PAYMENT_STATUS_FAILED: return {...state, updatePaymentResponse: {...action}};

    case PLAN_REQUEST_CLEAR: return {...state, plan: {}};
    case INITIATE_PAYMENT_STRIPE_CLEAR: return {...state, paymentInitResponse: {}};

    default: return {...state};
  }
}
