import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Select from '../../../../components/select/select.component';
import {ANSWERING_TYPE} from '../../../../constants/widget.const';
import Heading from '../../../../components/heading';
import Input from '../../../../components/input/input.component';
import ErrorText from '../../../../components/errorComponents/errorText.component';
import Plus from '../../../../icons/plus.icon';
import Button from '../../../../components/button/button.component';
import QuestionIcon from '../../../../icons/question.icon';
import ReactTooltip from 'react-tooltip';
import CloseIcon from '../../../../icons/close.icon';
import {nanoid} from 'nanoid';
import {useDispatch} from 'react-redux';
import {updateCollapseAction, updateWidgetSettings} from '../../../../actions/widget.actions';
import {useSelector} from 'react-redux';
import CollapsibleComponent from './collapsible.component';

/**
 * Question settings
 *
 * @return {JSX}
 */
const QuestionSettings = ({register, errors, setValue, widgetType, ...props})=>{
  const widgetStore = useSelector((state)=>state.widget);
  const dispatch = useDispatch();
  const [questions, setQuestions] = useState([
    {
      questionText: '',
      questionId: nanoid(),
      options: [
        {
          optionText: '',
          optionId: nanoid(),
        },
      ],
    },
  ]);
  const [toggleCollapsible, setToggleCollapsible] = useState(false);


  /**
   * Update questions settings
   *
   * @param {*} questionArrayTemp
   */
  const updateQuestionSettings = (questionArrayTemp)=>{
    dispatch(updateWidgetSettings({
      surveyQuestionSettings: [...questionArrayTemp],
    }));
    setQuestions([...questionArrayTemp]);
  };

  // Add questions
  const addQuestion = (index)=>{
    const questionArrayTemp = [...questions];
    questionArrayTemp.splice(index+1, 0, {
      questionText: '',
      questionId: nanoid(),
      options: [
        {
          optionText: '',
          optionId: nanoid(),
        },
      ],
    });
    updateQuestionSettings(questionArrayTemp);
  };

  // Add options
  const addOptions = (questionIndex)=>{
    const questionArrayTemp = [...questions];
    questionArrayTemp[questionIndex]?.options?.push({
      optionText: '',
      optionId: nanoid(),
    });
    updateQuestionSettings(questionArrayTemp);
  };

  // Update question text
  const updateQuestionText = (questionText, index)=>{
    const questionArrayTemp = [...questions];
    questionArrayTemp[index].questionText = questionText;
    updateQuestionSettings(questionArrayTemp);
  };

  // Update options text
  const updateOptionsText = (optionText, optionIndex, questionIndex)=>{
    const questionArrayTemp = [...questions];
    questionArrayTemp[questionIndex].options[optionIndex].optionText = optionText;
    updateQuestionSettings(questionArrayTemp);
  };

  // Delete options
  const deleteOptions = (optionIndex, questionIndex)=>{
    const questionArrayTemp = [...questions];
    questionArrayTemp[questionIndex]?.options?.splice(optionIndex, 1);
    updateQuestionSettings(questionArrayTemp);
  };

  // Delete questions
  const deleteQuestions = (questionIndex)=>{
    const questionArrayTemp = [...questions];
    questionArrayTemp?.splice(questionIndex, 1);
    updateQuestionSettings(questionArrayTemp);
  };

  // called white store is updated
  useEffect(()=>{
    if (widgetStore?.widgetSettings && widgetStore?.widgetSettings?.surveyQuestionSettings) {
      setQuestions([...widgetStore?.widgetSettings?.surveyQuestionSettings]);
    }
  }, [widgetStore?.widgetSettings]);

  useEffect(()=>{
    setToggleCollapsible(false);
    if (widgetStore?.collapse_widget?.payload?.QS) {
      setToggleCollapsible(true);
    }
  }, [widgetStore?.collapse_widget]);

  return (
    <div {...props}>
      <CollapsibleComponent
        type={'QS'}
        heading={'Question settings'}
        defaultValue={toggleCollapsible}
        toggleCallback={(toggle)=>{
          setToggleCollapsible(toggle);
          const tempCollapse = {...widgetStore?.collapse_widget};
          dispatch(updateWidgetSettings({
            toggleStatusMessageSuccess: false,
          }));
          Object.keys(tempCollapse).map((key)=>tempCollapse[key] = false);
          tempCollapse['QS'] = toggle;
          dispatch(updateCollapseAction({...tempCollapse}));
        }}
      />
      <div className={toggleCollapsible?'px-2':'hidden'}>
        <div className='mt-4'>
          <Heading className="text-sm font-normal flex align-center flex-col">
            <div>Select answering type</div>
            <div className='text-primary'>{`(For changing the question's color, changed widget heading's text color)`}</div>
          </Heading>
          <Select
            defaultSelected={widgetStore?.widgetSettings?.surveyQuestionAnswerType}
            options={[
              {
                text: ANSWERING_TYPE.SINGLE_CHOICE.name,
                value: ANSWERING_TYPE.SINGLE_CHOICE.value,
              },
              {
                text: ANSWERING_TYPE.MULTIPLE_CHOICE.name,
                value: ANSWERING_TYPE.MULTIPLE_CHOICE.value,
              },
              {
                text: ANSWERING_TYPE.MULTIPLE_CHOICE_DESIGN_TWO.name,
                value: ANSWERING_TYPE.MULTIPLE_CHOICE_DESIGN_TWO.value,
              },
            ]}
            onChange={(value)=>{
              dispatch(updateWidgetSettings({
                surveyQuestionAnswerType: value,
              }));
            }}
          />
        </div>
        <div className='mt-4'>
          <Heading className="text-sm font-normal flex items-center">
            Questions
            <QuestionIcon
              height={20}
              width={20}
              className={'ml-1'}
              data-tip
              data-for="question-instructions"
            />
            <ReactTooltip
              id="question-instructions"
              place="right"
              effect="solid"
            >
              <span>Currently maximum of 4 options allowed for a question and total of 5 question allowed</span>
            </ReactTooltip>
          </Heading>

          {/* Question config container */}
          {
            questions?.map((data, key)=>(
              <div id="question-config-container" key={data?.questionId}>
                <div className='flex items-center mb-4'>
                  <Heading className="text-sm font-normal flex items-center mr-1">
                    <div>Q{key+1}:</div>
                  </Heading>
                  <Input
                    placeholder={'Ex: Did you enjoy our product?'}
                    parentAdditionalClass={'mr-2 w-full'}
                    onChange={(event)=>{
                      updateQuestionText(event?.target?.value, key);
                    }}
                    defaultValue={data?.questionText}
                  />
                  {(questions?.length < 5) && <Button className={'px-2 py-1 mr-1'}
                    onClick={()=>{
                      addQuestion(key);
                    }}
                  >
                    <Plus className={'text-white'}/>
                  </Button>}
                  {(key !== 0) && (<Button className={'px-2 py-1 bg-red-600'} onClick={()=>{
                    deleteQuestions(key);
                  }}>
                    <CloseIcon className={'text-white'} height={24} width={24}/>
                  </Button>)}
                </div>
                <ul id="answer-container">
                  {data?.options?.map((optionData, innerKey)=>(
                    <li className="mb-4 text-sm flex items-center justify-between" key={optionData?.optionId}>
                      <Heading className="text-sm font-normal flex items-center mr-1">
                        <div>OP{innerKey+1}:</div>
                      </Heading>
                      <div className='w-full mr-2'>
                        <Input
                          placeholder={'Ex: Option 1'}
                          parentAdditionalClass={'mr-2 w-full'}
                          className={'p-2'}
                          onChange={(event)=>{
                            updateOptionsText(event?.target?.value, innerKey, key);
                          }}
                          defaultValue={optionData?.optionText}
                        />
                      </div>
                      {innerKey!==0 && <div id="close button container">
                        <Button className={'px-2 py-1 bg-red-600'} onClick={()=>{
                          deleteOptions(innerKey, key);
                        }}>
                          <CloseIcon className={'text-white'} height={12} width={12}/>
                        </Button>
                      </div>}
                    </li>
                  ))}
                </ul>
                <Button className={`
                  px-2 py-1 mb-4
                  ${!(data?.options?.length < 4)?'bg-slate-300 hover:bg-slate-300 active:bg-slate-300':''}
                `} onClick={()=>{
                  addOptions(key);
                }}
                disabled={!(data?.options?.length < 4)}
                >
                  Add answers
                </Button>
              </div>
            ))
          }
          <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errors?.actionButtonName?.message}</ErrorText>
        </div>
      </div>
    </div>
  );
};

QuestionSettings.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
  setValue: PropTypes.any,
  widgetType: PropTypes.string,
};

export default QuestionSettings;
