import {yupResolver} from '@hookform/resolvers/yup';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import Button from '../../components/button/button.component';
import ErrorText from '../../components/errorComponents/errorText.component';
import InputComponent from '../../components/input/input.component';
import {MAX_PASSWORD_FIELD_LENGTH} from '../../constants/application.const';
import InfoIcon from '../../icons/info.icon';
import {getDcryptedTextApi} from '../../services/common.service';
import {updatePasswordApi} from '../../services/user.service';
import {forgotPasswordSchema} from '../../validationSchemas/auth.validation';

/**
 * Forgot password
 *
 * @return {JSX}
 */
const ForgotPassword = ()=>{
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const [forgotPasswordLoader, setForgotPasswordLoader] = useState(false);
  const [linkExpired, setLinkExpired] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const {register, handleSubmit, formState: {errors}} = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });

  // Update password
  const updatePassword = async (value)=>{
    try {
      setForgotPasswordLoader(true);
      const response = await updatePasswordApi({
        email: userEmail,
        password: value?.password,
      });
      setForgotPasswordLoader(false);
      toast(response?.message, {
        type: 'success',
      });
      navigate('/');
    } catch (e) {
      setForgotPasswordLoader(false);
      toast(e?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  const getDcryptedText = async (encText)=>{
    console.log(encText);
    const response = await getDcryptedTextApi({
      encryptedText: encText,
    });
    const splitData = response?.data.split('-');
    setUserEmail(splitData[0]);
    setLinkExpired(moment().diff(moment(Number(splitData[1])), 'minutes')>10);
  };

  useEffect(()=>{
    if (query?.get('encText')) {
      getDcryptedText(query?.get('encText'));
    }
  }, [query?.get('encText')]);

  return (
    <div className="flex justify-center items-center h-screen">
      {linkExpired && <div
        className="w-100 mx-6 overflow-hidden bg-white rounded-lg shadow-xl ddark:bg-gray-800"
      >
        <div className="flex-1 flex-col overflow-y-auto md:flex-row">
          <div className="flex-1 items-center justify-center p-6 sm:p-12">
            <h1
              className="mb-4 text-xl text-red-700 font-semibold ddark:text-gray-200 flex items-center"
            >
              <InfoIcon
                height={'30px'}
                width={'30px'}
              />
              <p className='ml-2'>Link Expired</p>
            </h1>
            <p>Password reset link is expired. Click <a href="google.com">here</a> to generate new link</p>
          </div>
        </div>
      </div>}
      {!linkExpired && <div
        className="w-96 mx-6 overflow-hidden bg-white rounded-lg shadow-xl ddark:bg-gray-800"
      >
        <div className="flex-1 flex-col overflow-y-auto md:flex-row">
          <div className="flex-1 items-center justify-center p-6 sm:p-12">
            <form className="w-full" onSubmit={handleSubmit(updatePassword)} noValidate>
              <h1
                className="mb-4 text-xl font-semibold text-gray-700 ddark:text-gray-200"
              >
              Change Password
              </h1>
              <label className="block mt-4 text-sm">
                <span className="text-gray-700 ddark:text-gray-400">Password</span>
                <InputComponent
                  name="password"
                  placeholder={'Enter Password'}
                  type={'password'}
                  {...register('password')}
                  errorStatus={Boolean(errors.password?.message)}
                  maxLength={MAX_PASSWORD_FIELD_LENGTH}
                />
                <ErrorText displayStatus={true}>{errors.password?.message}</ErrorText>
              </label>
              <label className="block mt-4 text-sm">
                <span className="text-gray-700 ddark:text-gray-400">Confirm Password</span>
                <InputComponent
                  placeholder={'Confirm Password'}
                  type={'password'}
                  name="cpassword"
                  {...register('cpassword')}
                  errorStatus={Boolean(errors.cpassword?.message)}
                  maxLength={MAX_PASSWORD_FIELD_LENGTH}
                />
                <ErrorText displayStatus={true}>{errors.cpassword?.message}</ErrorText>
              </label>
              <Button
                loader={forgotPasswordLoader}
                buttonText='Change password'
                className="w-full mt-4"
                type={'submit'}
                disabled={forgotPasswordLoader}
              />
            </form>
          </div>
        </div>
      </div>}
    </div>
  );
};

export default ForgotPassword;
