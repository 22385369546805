import React, {useEffect} from 'react';
import Heading from '../../components/heading';
import MailChimp from '../../icons/integrations/mailChimp.icon';
import SiteLogo from '../../assets/images/siteLogo.jpeg';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {INTEGRATION_TYPE_MAPPER} from '../../constants/application.const';
import {useDispatch, useSelector} from 'react-redux';
import {ADD_INTEGRATION_FAILED, ADD_INTEGRATION_SUCCESS, GET_SHOPIFY_ACCESS_TOKEN_FAILED, GET_SHOPIFY_ACCESS_TOKEN_SUCCESS} from '../../constants/reducer.const';
import {addIntegrationAction, clearIntegrationAction, clearShopifyAccessTokenAction, getShopifyAccessTokenAction} from '../../actions/integrations.actions';
import {toast} from 'react-toastify';
import Shopify from '../../icons/integrations/shopify.icon';

/**
 * Integrations process page
 *
 * @return {JSX}
 */
const IntegrationProcess = ()=>{
  const routerParams = useParams();
  const [searchParams] = useSearchParams();
  const integrationStore = useSelector((state)=>state.integration);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getAccessTokenShopify = (payload)=>{
    dispatch(getShopifyAccessTokenAction(payload));
  };

  useEffect(()=>{
    if (routerParams?.type === 'M') {
      dispatch(addIntegrationAction({
        code: searchParams.get('code'),
        type: routerParams?.type,
      }));
    }
    if (routerParams?.type === 'SH') {
      getAccessTokenShopify({
        code: searchParams.get('code'),
        shop: searchParams.get('shop')?.split('.')[0],
      });
    }
  }, [searchParams?.code, routerParams?.type]);

  useEffect(()=>{
    if (integrationStore?.integration?.type === ADD_INTEGRATION_SUCCESS) {
      dispatch(clearIntegrationAction());
      toast(integrationStore?.integration?.payload?.message, {
        type: 'success',
      });
      navigate('/integrations');
    }

    if (integrationStore?.integration?.type === ADD_INTEGRATION_FAILED) {
      dispatch(clearIntegrationAction());
      toast(integrationStore?.integration?.payload?.message, {
        type: 'error',
      });
    }

    if (integrationStore?.shopifyAccessToken?.type === GET_SHOPIFY_ACCESS_TOKEN_SUCCESS) {
      dispatch(clearShopifyAccessTokenAction());
      toast(integrationStore?.shopifyAccessToken?.payload?.message, {
        type: 'success',
      });
      navigate('/integrations');
    }
    if (integrationStore?.shopifyAccessToken?.type === GET_SHOPIFY_ACCESS_TOKEN_FAILED) {
      dispatch(clearShopifyAccessTokenAction());
      toast(integrationStore?.shopifyAccessToken?.payload?.message, {
        type: 'error',
      });
    }
  }, [integrationStore?.integration, integrationStore?.shopifyAccessToken]);

  return (
    <div className='h-full flex items-center justify-center flex-col'>
      <div className='grid grid-cols-2 items-center mb-4'>
        <div className='flex justify-center mr-2'>
          <img
            src={SiteLogo}
            height='100px'
            width='100px'
            className='rounded-lg'
          />
        </div>
        <div className='flex justify-center'>
          {INTEGRATION_TYPE_MAPPER[routerParams?.type]?.value === 'M' && (<MailChimp
            height='200px'
            width='300px'
          />)}
          {INTEGRATION_TYPE_MAPPER[routerParams?.type]?.value === 'SH' && (<Shopify
            height='100px'
            width='100px'
            viewBoxOverride='0 0 100 100'
          />)}
        </div>
      </div>
      <div className='text-center'>
        <Heading className={'font-semibold text-2xl'}>Integarting <span className="text-purple-500">Popsuit</span> with {INTEGRATION_TYPE_MAPPER[routerParams?.type]?.text}</Heading>
        <p>Do not refresh the page</p>
      </div>
    </div>
  );
};

export default IntegrationProcess;
