import * as yup from 'yup';

// Add edit plan schema for admin
export const addEditPlanSchema = yup.object({
  name: yup.string().required('Plan name required'),
  description: yup.string(),
  price: yup.number().nullable().transform((curr, orig) => orig === '' ? null : curr).required('Plan pricing required'),
  type: yup.string(),
  storageCap: yup.number().nullable().transform((curr, orig) => orig === '' ? null : curr).required('Plan storage cap required'),
  shopifyExclusive: yup.boolean(),
  widgetLimit: yup.number().nullable().transform((curr, orig) => orig === '' ? null : curr).required('Widget limit required'),
  domainLimit: yup.number().nullable().transform((curr, orig) => orig === '' ? null : curr).required('Domain limit required'),
  enableIntegrations: yup.boolean(),
  enableBranding: yup.boolean(),
  status: yup.string(),
  orderPrecedence: yup.number().nullable().transform((curr, orig) => orig === '' ? null : curr).required('Order Precedence required'),
  impressionCount: yup.number().nullable().transform((curr, orig) => orig === '' ? null : curr).required('Impression Count required'),
  planType: yup.string(),
}).required();
