import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import Button from '../../components/button/button.component';
import ErrorText from '../../components/errorComponents/errorText.component';
import Input from '../../components/input/input.component';
import {customerValidation} from '../../validationSchemas/customer.validation';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';
import {ADD_CUSTOMER_FAILED, ADD_CUSTOMER_SUCCESS} from '../../constants/reducer.const';
import {toast} from 'react-toastify';
import {addCustomer, clearCutsomerData} from '../../actions/customers.action';

/**
 * Contats top bar component
 *
 * @return {JSX}
 */
function ContactsTopBar() {
  const {register, handleSubmit, formState: {errors}, reset} = useForm({
    resolver: yupResolver(customerValidation),
  });
  const dispatch = useDispatch();
  const customersStore = useSelector((state)=>state.customer);
  const [loader, setLoader] = useState(false);

  const onSubmit = (value)=>{
    setLoader(true);
    dispatch(addCustomer(value));
  };

  useEffect(()=>{
    if (customersStore?.customer?.type === ADD_CUSTOMER_SUCCESS) {
      toast(customersStore?.customer?.payload?.message, {
        type: 'success',
      });
      reset();
      dispatch(clearCutsomerData());
      setLoader(false);
    }
    if (customersStore?.customer?.type === ADD_CUSTOMER_FAILED) {
      toast(customersStore?.customer?.payload?.message, {
        type: 'error',
      });
      setLoader(false);
    }
  }, [customersStore?.customer]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-row gap-4 w-1/2 mb-4' noValidate>
      <div>
        <Input
          placeholder="Name"
          type="text"
          {...register('name')}
        />
        <ErrorText displayStatus={true}>{errors?.name?.message}</ErrorText>
      </div>
      <div>
        <Input
          placeholder="Email"
          type="email"
          {...register('email')}
        />
        <ErrorText displayStatus={true}>{errors?.email?.message}</ErrorText>
      </div>
      <div>
        <Button buttonText="Add" className={'mt-1'} loader={loader}/>
      </div>
    </form>
  );
}

export default ContactsTopBar;
