import React from 'react';
import {Outlet, Route, Routes, useLocation} from 'react-router-dom';
import TopSideBarTemplate from '../../templates/topSideBar.template';
import AddWidget from './addWidget/addWidget.page';
import CreateWidget from './createWidget';
import MediaSelectionComponent from './mediaSelection';
import WidgetHome from './widgetHome/widgetHome.page';
/**
 * Widget poage
 *
 * @return {JSX}
 */
const WidgetsPage = ()=>{
  const {pathname} = useLocation();

  /**
   * White list url
   *
   * @return {JSX}
   */
  const whiteListRouters = ()=> {
    // const routerPathSplit = pathname.split('/');
    // const widgetsTypesRouterEnabled = ((routerPathSplit[1] === 'widgets' && routerPathSplit[2] === 'add') || (routerPathSplit[1] === 'widgets' && routerPathSplit[2] === 'media-selection') || (routerPathSplit[1] === 'widgets' && routerPathSplit[2] === 'edit'));
    return pathname === '/widgets/create';
  };

  return (
    <TopSideBarTemplate
      noPadding={true}
      hideSideNav={whiteListRouters()}
    >
      <Outlet/>
      <Routes>
        <Route index element={<WidgetHome />} />
        <Route path="add/:type" element={<AddWidget />} />
        <Route path="edit/:type/:id" element={<AddWidget />} />
        <Route path="create" element={<CreateWidget />} />
        <Route path="media-selection/:type" element={<MediaSelectionComponent />} />
      </Routes>
    </TopSideBarTemplate>
  );
};

export default WidgetsPage;
