import React, {useEffect, useState} from 'react';
import {PRIMARY_COLOR} from '../../constants/common.const';
import EllipsisIcon from '../../icons/ellipsis.icon';
import Button from '../button/button.component';
import PropTypes from 'prop-types';
import DropDownWrapper from '../dropDownWrapper/DropDownWrapper.component';
import WidgetIcon from '../../icons/widget.icon';
import PencilIcon from '../../icons/pencil.icon';
import DownloadIcon from '../../icons/download.icon';
import BinIcon from '../../icons/bin.icon';
import {useDispatch, useSelector} from 'react-redux';
import {deleteVideoAction, deleteVideoClearAction} from '../../actions/videoUpload.action';
import {VIDEO_DELETE_FAILURE, VIDEO_DELETE_SUCCESS} from '../../constants/reducer.const';
import {toast} from 'react-toastify';
import UpdateVideoDetailsModalComponent from '../modal/updateVideoDetailsModal.component';
import {BASE_URL} from '../../constants/application.const';
import WidgetSelectionModal from '../modal/widgetSelectionModal.component';
/**
 * Video Widget Card
 *
 * @return {JSX}
 */
function VideoWidgetCard({additionalClasses, cardTitle, videoId, videoDetails, videoBasePath}) {
  const [dropDownToggle, setDropDownToggle] = useState(false);
  const [updateNameModal, setUpdateNameModal] = useState(false);
  const dispatch = useDispatch();
  const videoLibrarySelector = useSelector((state)=>state.videoLibrary);
  const [selectWidgetModal, setSelectWidgetModal] = useState(false);

  // Toggle dropdown
  const toggleDropDown = ()=>{
    setDropDownToggle((prev)=>!prev);
  };

  useEffect(()=>{
    if (videoLibrarySelector.type === VIDEO_DELETE_SUCCESS) {
      dispatch(deleteVideoClearAction());
      toast(videoLibrarySelector?.payload?.message, {
        type: 'success',
      });
    }
    if (videoLibrarySelector.type === VIDEO_DELETE_FAILURE) {
      dispatch(deleteVideoClearAction());
      toast(videoLibrarySelector?.payload?.message, {
        type: 'error',
      });
    }
  }, [videoLibrarySelector]);

  // Delete video
  const onOptionSelect = (value)=>{
    toggleDropDown();
    if (value === 'DE' && videoId) {
      dispatch(deleteVideoAction(videoId));
    }
    if (value === 'R' && videoId) {
      setUpdateNameModal(true);
    }
    if (value === 'W' && videoId) {
      setSelectWidgetModal(true);
    }
  };

  return (
    <div className={`bg-white border-2 rounded-md w-60 ${additionalClasses}`}>
      <div>
        {
          videoDetails?.type === 'video/mp4' && (
            <video width="250" height="250" className="rounded-t-md" controls>
              <source src={`${BASE_URL}${videoBasePath}${videoDetails?.fileName}`} type="video/mp4"></source>
            </video>
          )
        }
        {
          videoDetails?.type === '' && (
            <img src="https://picsum.photos/250/250"/>
          )
        }
      </div>
      <div className='p-2 flex justify-between items-center relative'>
        <p className="break-all text-sm">{cardTitle || 'Test'}</p>
        <DropDownWrapper
          open={dropDownToggle}
          onClose={()=>setDropDownToggle(false)}
          buttonComponent={
            <Button
              onClick={()=>toggleDropDown()}
              classOverride='flex items-center justify-center px-3 py-2 text-sm font-medium text-white rounded-lg focus:outline-none focus:shadow-outline-purple'
              buttonIcon={<EllipsisIcon color={PRIMARY_COLOR}/>}
            />
          }
          onOptionClick={onOptionSelect}
          buttonOptions={[
            {text: 'Widgets', icon: <WidgetIcon/>, value: 'W'},
            {text: 'Rename', icon: <PencilIcon/>, value: 'R'},
            {text: 'Download', icon: <DownloadIcon />, value: 'D'},
            {text: 'Delete', icon: <BinIcon color="#ef4444"/>, class: 'text-red-500', value: 'DE'},
          ]}
        />
      </div>
      {updateNameModal && <UpdateVideoDetailsModalComponent
        onClose={()=>{
          setUpdateNameModal(false);
        }}
        videoDetails={{...videoDetails}}
      />}
      {selectWidgetModal && <WidgetSelectionModal
        videoDetails={{...videoDetails}}
        videoBasePath={videoBasePath}
        onClose={()=>{
          setSelectWidgetModal(false);
        }}
      />}
    </div>
  );
}

VideoWidgetCard.propTypes = {
  additionalClasses: PropTypes.string,
  cardTitle: PropTypes.string,
  videoId: PropTypes.string,
  videoDetails: PropTypes.any,
  videoBasePath: PropTypes.string,
};

export default VideoWidgetCard;
