import {VIDEO_DELETE_CLEAR_REQUEST, VIDEO_DELETE_REQUEST, VIDEO_LIST_CLEAR_REQUEST, VIDEO_LIST_REQUEST, VIDEO_UPDATE_CLEAR_REQUEST, VIDEO_UPDATE_REQUEST, VIDEO_UPLOAD_CLEAR_REQUEST, VIDEO_UPLOAD_REQUEST} from '../constants/reducer.const';

export const storeVideoAction = (payload)=>({type: VIDEO_UPLOAD_REQUEST, payload});
export const videoStoreClear = ()=>({type: VIDEO_UPLOAD_CLEAR_REQUEST, payload: {}});
export const getAllVideoAction = (payload)=>({type: VIDEO_LIST_REQUEST, payload});
export const getAllVideoClear = ()=>({type: VIDEO_LIST_CLEAR_REQUEST, payload: {}});
export const deleteVideoAction = (payload)=>({type: VIDEO_DELETE_REQUEST, payload});
export const deleteVideoClearAction = ()=>({type: VIDEO_DELETE_CLEAR_REQUEST, payload: {}});
export const videoUpdateAction = (payload)=>({type: VIDEO_UPDATE_REQUEST, payload});
export const videoUpdateClearAction = ()=>({type: VIDEO_UPDATE_CLEAR_REQUEST, payload: {}});
