import * as yup from 'yup';
import {EMAIL_PATTERN, MAX_PASSWORD_FIELD_LENGTH, MAX_TEXT_FIELD_LENGTH, PHONE_PATTERN} from '../constants/application.const';

// Profile schema
export const profileUserSchema = yup.object({
  name: yup.string().required('Name required').max(MAX_TEXT_FIELD_LENGTH, `Maximum ${MAX_TEXT_FIELD_LENGTH} allowed`),
  company: yup.string().required('Company Name required').max(MAX_TEXT_FIELD_LENGTH, `Maximum ${MAX_TEXT_FIELD_LENGTH} allowed`),
  email: yup.string().required('Email required').matches(EMAIL_PATTERN, 'Invalid email'),
  phone: yup.string().nullable().transform((v, o) => (o === '' ? null : v)).matches(PHONE_PATTERN, 'Invalid phone number (valid: +(country code)(number))'),
  country: yup.string(),
  industry: yup.string(),
}).required();

// Update password
export const updatePasswordSchema = yup.object({
  oldPassword: yup.string().required('Old Password required').max(MAX_PASSWORD_FIELD_LENGTH, `Maximum ${MAX_PASSWORD_FIELD_LENGTH} allowed`),
  password: yup.string().required('Password required').max(MAX_PASSWORD_FIELD_LENGTH, `Maximum ${MAX_PASSWORD_FIELD_LENGTH} allowed`),
  cpassword: yup.string().required('Confirm password required').oneOf([yup.ref('password')], 'Passwords does not match'),
}).required();
