import {CLEAR_USER_REQUEST, GET_USERS_ADMIN_REQUEST, GET_USERS_LOGIN_CLEAR, GET_USERS_LOGIN_REQUEST, LOGIN_USER_REQUESTED, REGISTER_USER_REQUESTED, UPDATE_USERS_PROFILE_CLEAR, UPDATE_USERS_PROFILE_PASSWORD_REQUEST, UPDATE_USERS_PROFILE_REQUEST, UPDATE_USERS_STATUS_ADMIN_CLEAR, UPDATE_USERS_STATUS_ADMIN_REQUEST} from '../constants/reducer.const';

export const registerUserAction = (payload)=>({type: REGISTER_USER_REQUESTED, payload});
export const loginUserAction = (payload)=>({type: LOGIN_USER_REQUESTED, payload});

export const clearUserAction = ()=>({type: CLEAR_USER_REQUEST, payload: {}});
export const getLoginUserDetail = ()=>({type: GET_USERS_LOGIN_REQUEST, payload: {}});
export const getLoginUserDetailClear = ()=>({type: GET_USERS_LOGIN_CLEAR, payload: {}});

// admin
export const getAllAdminUsersAction = ()=>({type: GET_USERS_ADMIN_REQUEST, payload: {}});
export const updateUserStatusAction = (payload)=>({type: UPDATE_USERS_STATUS_ADMIN_REQUEST, payload});
export const updateUserStatusClear = ()=>({type: UPDATE_USERS_STATUS_ADMIN_CLEAR, payload: {}});
export const updateProfileAction = (payload)=>({type: UPDATE_USERS_PROFILE_REQUEST, payload});
export const updateProfileActionClear = ()=>({type: UPDATE_USERS_PROFILE_CLEAR, payload: {}});
export const updateProfilePasswordAction = (payload)=>({type: UPDATE_USERS_PROFILE_PASSWORD_REQUEST, payload});
