import React from 'react';

/**
 * Click through rate icon
 *
 * @return {JSX}
 */
const ClickThroughRate = ({...props})=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} fill="currentColor" viewBox="0 0 48 48"><path d="M23.4 36Q18.6 35.75 15.3 32.3Q12 28.85 12 24Q12 19 15.5 15.5Q19 12 24 12Q28.85 12 32.3 15.3Q35.75 18.6 36 23.45L32.85 22.45Q32.3 19.25 29.85 17.125Q27.4 15 24 15Q20.25 15 17.625 17.625Q15 20.25 15 24Q15 27.35 17.125 29.825Q19.25 32.3 22.45 32.85ZM24 44Q19.85 44 16.2 42.425Q12.55 40.85 9.85 38.15Q7.15 35.45 5.575 31.8Q4 28.15 4 24Q4 19.85 5.575 16.2Q7.15 12.55 9.85 9.85Q12.55 7.15 16.2 5.575Q19.85 4 24 4Q28.15 4 31.8 5.575Q35.45 7.15 38.15 9.85Q40.85 12.55 42.425 16.2Q44 19.85 44 24Q44 24.45 43.975 24.9Q43.95 25.35 43.9 25.8L41 24.9V24Q41 16.9 36.05 11.95Q31.1 7 24 7Q16.9 7 11.95 11.95Q7 16.9 7 24Q7 31.1 11.95 36.05Q16.9 41 24 41H24.9L25.8 43.9Q25.35 43.95 24.9 43.975Q24.45 44 24 44ZM41.05 45 32.5 36.45 30 44 24 24 44 30 36.45 32.5 45 41.05Z"/></svg>
  );
};

export default ClickThroughRate;
