import {all, call, put, takeLatest} from 'redux-saga/effects';
import {ADD_DEMO_USER_FAILED, ADD_DEMO_USER_REQUEST, ADD_DEMO_USER_SUCCESS, DOWNGRADE_REDEMPTION_FAILED, DOWNGRADE_REDEMPTION_REQUEST, DOWNGRADE_REDEMPTION_SUCCESS, GET_SMTP_CONFIG_FAILED, GET_SMTP_CONFIG_REQUEST, GET_SMTP_CONFIG_SUCCESS, UPDATE_INTEGRATION_CONFIG_ADMIN_FAILED, UPDATE_INTEGRATION_CONFIG_ADMIN_REQUEST, UPDATE_INTEGRATION_CONFIG_ADMIN_SUCCESS, UPDATE_SMTP_CONFIG_FAILED, UPDATE_SMTP_CONFIG_REQUEST, UPDATE_SMTP_CONFIG_SUCCESS} from '../constants/reducer.const';
import * as adminService from '../services/admin.service';

/**
 * get smtp config saga
 *
 * @param {Object} action
 */
function* getSmtpConfig(action) {
  try {
    const response = yield call(adminService.getSmtpConfig, action.payload);
    yield put({type: GET_SMTP_CONFIG_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: GET_SMTP_CONFIG_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * get smtp config
 */
function* getSmtpConfigWatchers() {
  yield takeLatest(GET_SMTP_CONFIG_REQUEST, getSmtpConfig);
}

/**
 * update smtp config saga
 *
 * @param {Object} action
 */
function* updateSmtpConfig(action) {
  try {
    const response = yield call(adminService.updateSmtpConfig, action.payload);
    yield put({type: UPDATE_SMTP_CONFIG_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: UPDATE_SMTP_CONFIG_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * update smtp config
 */
function* updateSmtpConfigWatchers() {
  yield takeLatest(UPDATE_SMTP_CONFIG_REQUEST, updateSmtpConfig);
}

/**
 * add demo user
 *
 * @param {Object} action
 */
function* addDemoUser(action) {
  try {
    const response = yield call(adminService.addDemoUsers, action.payload);
    yield put({type: ADD_DEMO_USER_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: ADD_DEMO_USER_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * add demo user watcher
 */
function* addDemoUserWatchers() {
  yield takeLatest(ADD_DEMO_USER_REQUEST, addDemoUser);
}

/**
 * update integration config
 *
 * @param {Object} action
 */
function* updateIntegrationConfig(action) {
  try {
    const response = yield call(adminService.updateIntegrationConfig, action.payload);
    yield put({type: UPDATE_INTEGRATION_CONFIG_ADMIN_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: UPDATE_INTEGRATION_CONFIG_ADMIN_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * add demo user watcher
 */
function* updateIntegrationConfigWatchers() {
  yield takeLatest(UPDATE_INTEGRATION_CONFIG_ADMIN_REQUEST, updateIntegrationConfig);
}

/**
 * Downgrade Redemption
 *
 * @param {Object} action
 */
function* downgradeRedemption(action) {
  try {
    const response = yield call(adminService.downgradeRedemption, action.payload);
    yield put({type: DOWNGRADE_REDEMPTION_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: DOWNGRADE_REDEMPTION_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * Downgrade Redemption watcher
 */
function* downgradeRedemptionWatchers() {
  yield takeLatest(DOWNGRADE_REDEMPTION_REQUEST, downgradeRedemption);
}

/**
 * exporting sagas after they are initialised
 */
export default function* rootSaga() {
  yield all([
    getSmtpConfigWatchers(),
    updateSmtpConfigWatchers(),
    addDemoUserWatchers(),
    updateIntegrationConfigWatchers(),
    downgradeRedemptionWatchers(),
  ]);
};
