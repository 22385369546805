import React from 'react';
import PropTypes from 'prop-types';
/**
 * Copy icon
 *
 * @return {JSX}
 */
const CopyIcon = ({height, width, color, ...props})=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height || '24px'} viewBox="0 0 24 24" width={width || '24px'} fill={color || '#000000'} {...props}><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>
  );
};

CopyIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default CopyIcon;
