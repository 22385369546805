import React from 'react';
import PropTypes from 'prop-types';

/**
 * Sissor icon for whole project
 *
 * @return {JSX}
 */
const SissorIcon = ({height, width, title, ...props})=>{
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width={width || '25'} height={height || '25'} viewBox="0 0 860.000000 1280.000000"
      preserveAspectRatio="xMidYMid meet" {...props} fill={'currentColor'}>
      <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
        fill={'currentColor'} stroke="none">
        <path d="M5296 12768 c-35 -36 -64 -111 -113 -293 -29 -107 -118 -400 -310
        -1015 -102 -329 -141 -450 -353 -1100 -76 -234 -160 -497 -185 -585 -26 -88
        -80 -263 -121 -390 -41 -126 -97 -302 -124 -390 -51 -167 -85 -255 -99 -255
        -5 0 -15 7 -22 16 -19 23 -242 573 -328 809 -18 50 -62 153 -96 230 -35 77
        -88 205 -118 285 -30 80 -78 198 -106 263 -28 64 -73 169 -101 233 -27 63 -50
        119 -50 125 0 5 -29 76 -64 157 -35 81 -106 253 -159 383 -209 515 -512 1157
        -628 1329 -46 69 -138 157 -173 166 -35 8 -69 -23 -93 -85 -15 -39 -18 -75
        -17 -226 2 -204 23 -381 60 -518 14 -51 45 -166 68 -257 24 -91 77 -266 119
        -390 41 -124 87 -274 101 -335 54 -230 76 -314 115 -425 22 -63 63 -202 91
        -309 28 -107 66 -240 85 -295 19 -56 59 -200 89 -321 150 -594 221 -863 296
        -1110 122 -400 131 -433 251 -900 61 -236 115 -458 119 -493 12 -92 -25 -222
        -196 -697 -46 -131 -98 -298 -119 -385 -20 -85 -59 -222 -86 -305 -26 -82 -65
        -213 -85 -290 -119 -463 -314 -1078 -382 -1210 -42 -82 -192 -238 -285 -299
        -167 -108 -264 -151 -607 -268 -498 -169 -843 -365 -1080 -614 -140 -147 -205
        -247 -301 -464 -25 -58 -73 -164 -106 -236 -75 -162 -127 -320 -155 -472 -29
        -160 -32 -541 -4 -707 52 -314 180 -580 370 -770 139 -138 316 -248 497 -309
        208 -70 586 -56 809 29 384 147 734 601 951 1234 126 367 167 702 115 951
        -100 478 -120 528 -295 729 -89 103 -101 129 -78 165 29 43 121 113 204 155
        73 36 90 51 143 117 201 253 367 574 435 845 32 128 198 592 288 804 39 93 49
        107 147 209 119 123 383 366 419 385 14 8 37 10 60 6 30 -5 70 -41 257 -228
        359 -362 594 -648 654 -798 35 -87 145 -339 247 -564 154 -338 331 -533 620
        -683 77 -39 148 -80 157 -91 25 -27 16 -55 -39 -121 -93 -113 -146 -244 -220
        -542 l-44 -176 20 -154 c54 -421 89 -592 148 -733 21 -49 51 -128 67 -175 16
        -47 58 -132 93 -190 35 -58 82 -137 105 -176 141 -242 413 -537 630 -682 92
        -61 281 -138 371 -151 95 -14 530 -14 597 0 66 14 214 82 308 143 144 93 281
        258 404 487 90 168 124 305 133 534 10 270 -24 598 -82 800 -15 50 -50 135
        -80 191 -29 55 -69 143 -90 195 -53 134 -137 292 -198 374 -84 114 -419 442
        -525 516 -196 136 -250 155 -747 255 -413 83 -555 118 -689 171 -204 80 -270
        127 -348 245 -140 212 -323 558 -384 723 -18 50 -64 171 -102 270 -103 267
        -135 366 -201 625 -33 129 -73 273 -89 320 -70 201 -358 934 -402 1020 -43 85
        -42 114 11 324 27 105 107 423 179 706 71 283 143 567 160 630 16 63 86 340
        155 615 68 275 147 574 175 664 28 90 54 191 59 225 5 33 20 99 34 146 44 146
        149 638 192 890 86 515 79 830 -26 1175 -55 180 -128 303 -195 331 -50 21 -73
        17 -108 -18z m1739 -9533 c193 -34 354 -126 544 -310 164 -159 231 -247 305
        -400 188 -385 251 -617 250 -915 -3 -325 -75 -512 -279 -715 -153 -153 -297
        -207 -510 -191 -304 22 -604 230 -829 576 -49 74 -109 167 -135 205 -57 84
        -75 127 -102 248 -11 51 -35 137 -54 192 -46 136 -59 238 -52 404 9 208 60
        411 141 555 69 123 216 258 364 332 63 32 73 34 168 34 56 0 141 -7 189 -15z
        m-5435 -246 c90 -14 198 -51 310 -106 176 -87 309 -251 384 -475 79 -235 84
        -479 15 -760 -95 -391 -270 -645 -596 -866 -145 -97 -182 -116 -258 -132 -84
        -17 -336 -18 -405 -2 -75 19 -220 90 -306 151 -123 86 -162 148 -238 371 -58
        172 -69 248 -61 425 12 263 38 371 155 645 118 276 214 414 386 551 211 168
        409 232 614 198z"/>
      </g>
    </svg>
  );
};

SissorIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  title: PropTypes.string,
};

export default SissorIcon;
