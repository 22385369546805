import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from '../../../../components/button/button.component';
import {USER_DETAILS, WIDGET_URL} from '../../../../constants/application.const';
import {GET_INTEGRATION_STATUS_SUCCESS, GET_SHOPIFY_INSTALL_STATUS_SUCCESS, GET_USERS_LOGIN_FAILED, GET_USERS_LOGIN_SUCCESS, INSTALL_SHOPIFY_FAILED, INSTALL_SHOPIFY_SUCCESS, SELECTED_WIDGET, UNINSTALL_SHOPIFY_FAILED, UNINSTALL_SHOPIFY_SUCCESS, UPDATE_WIDGET_LIVE_STATUS_SUCCESS} from '../../../../constants/reducer.const';
import CopyIcon from '../../../../icons/copy.icon';
import TickIcon from '../../../../icons/tick.icon';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {updateWidgetLiveStatusAction} from '../../../../actions/widget.actions';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';
import {getIntegrationStatusAction, getShopifyInstallStatusAction, installShopifyAppAction, unInstallShopifyAppAction} from '../../../../actions/integrations.actions';
import {useNavigate} from 'react-router-dom';
import {getValue} from '../../../../config/localStorage.config';
import InfoWrapper from '../../../../components/wrappers/infoWrapper.component';
import {getLoginUserDetail as getLoginUserDetailAction, getLoginUserDetailClear} from '../../../../actions/users.actions';
import LoaderIcon from '../../../../icons/loader.icon';

const shopifyInstallContentMapper = (shop)=>({
  [true]: (<>A widget is already installed on <span className="font-bold">{shop}</span></>),
  [false]: (<>By clicking install the application will be installed on (It may take upto 5 mins to get updated)<span className="font-bold">{shop}</span></>),
});

const PublishTabSwitcher = ({okButtonCallback}) => {
  const [curretUserPlan, setCurrentUserPlan] = useState({});
  const [userData, setUserData] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const widgetStore = useSelector((state)=>state.widget);
  const integrationStore = useSelector((state)=>state.integration);
  const userStore = useSelector((state)=>state.user);
  const [shareableLink, setShareableLink] = useState('');
  const [widgetType, setWidgetType] = useState('');
  const [currentWidgetDetails, setCurrentWidgetDetails] = useState({});
  const [shopifyConfig, setShopifyConfig] = useState({});
  const [installLoader, setInstallLoader] = useState(false);
  const [unInstallLoader, setUnInstallLoader] = useState(false);
  const [integrationStatus, setIntegrationStatus] = useState({});
  const [link, setLink] = useState('');
  const [copied, setCopied] = useState(false);
  const [userDetailsLoader, setUserDetailsLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Get user data from local storage
  const getLocalStorageUserData = ()=>{
    const userData = getValue(USER_DETAILS);
    if (userData) {
      const planData = JSON.parse(userData)?.plan;
      setCurrentUserPlan(planData);
      if (planData?.shopifyExclusive) {
        setCurrentTab(1);
      }
    }
  };

  // Update shareable link
  const updateShareableLink = (widgetDetails)=>{
    const widgetTypeTemp = widgetDetails?.widgetSettings?.widgetType;
    setWidgetType(widgetTypeTemp);
    setShareableLink(`<script id="deployer_script" src="${WIDGET_URL[widgetTypeTemp]}deployer.js?linkId=${widgetDetails?.linkId}"></script>`);
    setLink(`${WIDGET_URL[widgetTypeTemp]}deployer.js?linkId=${widgetDetails?.linkId}`);
  };

  // Get integration status
  const getIntegrationStatus = ()=>{
    dispatch(getIntegrationStatusAction({}));
  };

  // Get Login user details
  const getLoginUserDetails = ()=>{
    setUserDetailsLoader(true);
    dispatch(getLoginUserDetailAction());
  };

  useEffect(()=>{
    getShopifyInstallStatus();
    getIntegrationStatus();
    getLocalStorageUserData();
    getLoginUserDetails();
  }, []);

  useEffect(()=>{
    if (widgetStore?.selectedWidget?.type === SELECTED_WIDGET) {
      setCurrentWidgetDetails({...widgetStore?.selectedWidget?.payload});
      updateShareableLink(widgetStore?.selectedWidget?.payload);
    }

    if (widgetStore?.widgetLiveStatus?.type === UPDATE_WIDGET_LIVE_STATUS_SUCCESS) {
      toast(widgetStore?.widgetLiveStatus?.payload?.message, {
        type: 'success',
      });
    }
    if (integrationStore?.shopifyInstallStatus?.type === GET_SHOPIFY_INSTALL_STATUS_SUCCESS) {
      setShopifyConfig(integrationStore?.shopifyInstallStatus?.payload?.data);
    }

    if (integrationStore?.integrationStatus?.type === GET_INTEGRATION_STATUS_SUCCESS) {
      setIntegrationStatus(integrationStore?.integrationStatus?.payload?.data);
    }

    if (userStore?.userLoginDetails?.type === GET_USERS_LOGIN_SUCCESS) {
      setUserDetailsLoader(false);
      setUserData({...userStore?.userLoginDetails?.payload?.data});
      dispatch(getLoginUserDetailClear());
    }
    if (userStore?.userLoginDetails?.type === GET_USERS_LOGIN_FAILED) {
      setUserDetailsLoader(false);
    }
  }, [widgetStore?.selectedWidget, widgetStore?.widgetLiveStatus, integrationStore?.shopifyInstallStatus, integrationStore?.integrationStatus, userStore?.userLoginDetails]);

  useEffect(()=>{
    if (integrationStore?.shopifyInstallResponse?.type === INSTALL_SHOPIFY_SUCCESS) {
      setInstallLoader(false);
      getShopifyInstallStatus();
      okButtonCallback();
      toast(integrationStore?.shopifyInstallResponse?.payload?.message, {
        type: 'success',
      });
    }
    if (integrationStore?.shopifyInstallResponse?.type === INSTALL_SHOPIFY_FAILED) {
      setInstallLoader(false);
      toast(integrationStore?.shopifyInstallResponse?.payload?.message, {
        type: 'error',
      });
    }
    if (integrationStore?.shopifyUnInstallResponse?.type === UNINSTALL_SHOPIFY_SUCCESS) {
      setUnInstallLoader(false);
      getShopifyInstallStatus();
      okButtonCallback();
      toast(integrationStore?.shopifyUnInstallResponse?.payload?.message, {
        type: 'success',
      });
    }
    if (integrationStore?.shopifyUnInstallResponse?.type === UNINSTALL_SHOPIFY_FAILED) {
      setUnInstallLoader(false);
      toast(integrationStore?.shopifyUnInstallResponse?.payload?.message, {
        type: 'error',
      });
    }
  }, [integrationStore?.shopifyUnInstallResponse, integrationStore?.shopifyInstallResponse]);

  // Change copied status
  const changeCopiedStatus = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  // Update widget live status
  const updateLiveStatus = (status)=>{
    if (currentWidgetDetails?.id) {
      dispatch(updateWidgetLiveStatusAction({
        widgetId: currentWidgetDetails?.id,
        liveStatus: status,
      }));
    }
  };

  // get shopify install status
  const getShopifyInstallStatus = ()=>{
    dispatch(getShopifyInstallStatusAction());
  };

  // install shopify app
  const installShopifyApp = ()=>{
    setInstallLoader(true);
    dispatch(installShopifyAppAction({
      widgetUrl: link,
    }));
  };

  // uninstall shopify app
  const unInstallShopifyApp = ()=>{
    setUnInstallLoader(true);
    dispatch(unInstallShopifyAppAction());
  };

  return (
    <div>
      {userDetailsLoader &&
      <div className="flex justify-center items-center">
        <LoaderIcon
          classOverride='animate-spin -ml-1 h-12 w-12 text-purple-500'
        />
      </div>
      }
      {((Number(userData?.widgetsImpressions) >= Number(curretUserPlan?.impressionCount))) && <InfoWrapper
        description={<div>
          <div className='mb-1'><span>Current plan&#39;s impression count limit :</span><span className='text-primary font-bold'>{curretUserPlan?.impressionCount}</span></div>
          <p className='text-red-800 font-bold'>You have exceeded the impression count for your plan, Kindly upgrade to pushlish your widget</p>
        </div>}
        additionalClass='mt-2 mb-2'
      />}
      {((Number(userData?.widgetsImpressions) < Number(curretUserPlan?.impressionCount))) && <>
        <div className="flex">
          {!curretUserPlan?.shopifyExclusive && <div className={`text-md mr-2 font-bold ${currentTab === 0 ? 'underline text-primary' : ''} underline-offset-2 cursor-pointer`} onClick={() => setCurrentTab(0)}>Link</div>}
          <div className={`text-md cursor-pointer font-bold ${currentTab === 1 ? 'underline text-primary' : ''}`} onClick={() => setCurrentTab(1)}>Shopify</div>
        </div>
        <div className="mt-4">
          {currentTab === 0 && <div>
            <p className="text-sm break-word">Copy the below link and paste it in the sites footer(In the case of embeded widget copy the div component and paste it in place you want to render the component). Click live to make the application live</p>
            <div className={`mt-4 p-2 border-solid border-red border-2 rounded-md flex justify-between ${copied ? 'bg-lime-300' : ''}`}>
              {!copied && <><span>{widgetType === 'E' && <><p>
                {'<div id="deployer_container_embeded"></div>'}
              </p><br/></>}{shareableLink}</span>
              <CopyToClipboard className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded ddark:hover:text-gray-200 hover: hover:text-gray-700 cursor-pointer" text={`${widgetType === 'E'?'<div id="deployer_container_embeded"></div>':''}${shareableLink}`} onCopy={() => changeCopiedStatus()}>
                <CopyIcon color={'#c5c5c7'} className />
              </CopyToClipboard></>}
              {copied && <p>Copied</p>}
            </div>
            <Button
              classOverride={`flex items-center justify-center px-3 py-2 text-sm font-medium text-${currentWidgetDetails?.liveStatus === 'L'?'red':'green'}-600 duration-150 bg-${currentWidgetDetails?.liveStatus === 'L'?'red':'green'}-200 border-2 border-${currentWidgetDetails?.liveStatus === 'L'?'red':'green'}-500 rounded-lg hover:bg-${currentWidgetDetails?.liveStatus === 'L'?'red':'green'}-300 active:bg-${currentWidgetDetails?.liveStatus === 'L'?'red':'green'}-100 focus:outline-none focus:shadow-outline-purple mt-4`}
              buttonIcon={<TickIcon />}
              onClick={()=>{
                updateLiveStatus(currentWidgetDetails?.liveStatus === 'L'?'UL':'L');
              }}
            >
              <span>{currentWidgetDetails?.liveStatus === 'L'?'Disable':'Enable'}</span>
            </Button>
          </div>}
          {currentTab === 1 && <div>
            <p className="text-sm break-word">{Number(integrationStatus?.shopify) >0?shopifyInstallContentMapper(shopifyConfig?.shop)[shopifyConfig?.installed]:(
              <>Shopify store not connected</>
            )}</p>
            {shopifyConfig?.installed && Number(integrationStatus?.shopify) >0 && <Button
              classOverride={`flex items-center justify-center px-3 py-2 text-sm font-medium text-red-600 duration-150 bg-red-200 border-2 border-red-500 rounded-lg hover:bg-red-300 active:bg-red-100 focus:outline-none focus:shadow-outline-purple mt-4`}
              buttonIcon={<TickIcon />}
              onClick={()=>unInstallShopifyApp()}
              loader={unInstallLoader}
            >
              <span>Un Install</span>
            </Button>}
            {!shopifyConfig?.installed && Number(integrationStatus?.shopify) >0 && <Button
              classOverride={`flex items-center justify-center px-3 py-2 text-sm font-medium text-green-600 duration-150 bg-green-200 border-2 border-green-500 rounded-lg hover:bg-green-300 active:bg-green-100 focus:outline-none focus:shadow-outline-purple mt-4`}
              buttonIcon={<TickIcon />}
              onClick={()=>installShopifyApp()}
              loader={installLoader}
            >
              <span>Install</span>
            </Button>}
            {!Number(integrationStatus?.shopify) && <Button
              classOverride={`flex items-center justify-center px-3 py-2 text-sm font-medium text-gray-600 duration-150 bg-gray-200 border-2 border-gray-500 rounded-lg hover:bg-gray-300 active:bg-gray-100 focus:outline-none focus:shadow-outline-purple mt-4`}
              onClick={()=>navigate('/integrations')}
              loader={installLoader}
            >
              <span>Connect shopify</span>
            </Button>}
          </div>}
        </div>
      </>}
      <div className='flex justify-end'>
        <Button
          onClick={()=>{
            okButtonCallback();
          }}
        >
          Ok
        </Button>
      </div>
    </div>
  );
};

PublishTabSwitcher.propTypes = {
  okButtonCallback: PropTypes.func,
};

export default PublishTabSwitcher;
