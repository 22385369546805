import {GET_REDEMPTION_CODE_CLEAR, GET_REDEMPTION_CODE_FAILED, GET_REDEMPTION_CODE_SUCCESS, UPGRADE_REDEMPTION_CLEAR, UPGRADE_REDEMPTION_FAILED, UPGRADE_REDEMPTION_SUCCESS} from '../constants/reducer.const';

const initialState = {};

/**
 * Redemption reducer
 *
 * @param {*} state
 * @param {*} action
 * @return {any}
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case UPGRADE_REDEMPTION_SUCCESS: return {...state, redemptionUpgrade: {...action}};
    case UPGRADE_REDEMPTION_CLEAR: return {...state, redemptionUpgrade: {}};
    case UPGRADE_REDEMPTION_FAILED: return {...state, redemptionUpgrade: {...action}};

    case GET_REDEMPTION_CODE_SUCCESS: return {...state, redemptionCodes: {...action}};
    case GET_REDEMPTION_CODE_CLEAR: return {...state, redemptionCodes: {}};
    case GET_REDEMPTION_CODE_FAILED: return {...state, redemptionCodes: {...action}};

    default: return {...state};
  }
}
