import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '../../icons/close.icon';
import Button from '../button/button.component';
import InputComponent from '../input/input.component';
import {yupResolver} from '@hookform/resolvers/yup';
import {videoUpdateSchema} from '../../validationSchemas/widget.validation';
import ErrorText from '../errorComponents/errorText.component';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {VIDEO_UPDATE_FAILURE, VIDEO_UPDATE_SUCCESS} from '../../constants/reducer.const';
import {videoUpdateAction, videoUpdateClearAction} from '../../actions/videoUpload.action';
import {toast} from 'react-toastify';

/**
 * Rname Modal
 *
 * @return {JSX}
 */
const UpdateVideoDetailsModalComponent = ({
  onClose,
  videoDetails,
})=>{
  const {register, handleSubmit, formState: {errors}, setValue} = useForm({
    resolver: yupResolver(videoUpdateSchema),
  });
  const dispatch = useDispatch();
  const videoLibrarySelector = useSelector((state)=>state.videoLibrary);

  useEffect(()=>{
    if (videoDetails) {
      setValue('originalFileName', videoDetails?.originalFileName);
    }
  }, [videoDetails]);

  useEffect(()=>{
    if (videoLibrarySelector?.type === VIDEO_UPDATE_SUCCESS) {
      dispatch(videoUpdateClearAction());
      toast(videoLibrarySelector?.payload?.message, {
        type: 'success',
      });
      onClose();
    }
    if (videoLibrarySelector?.type === VIDEO_UPDATE_FAILURE) {
      dispatch(videoUpdateClearAction());
      toast(videoLibrarySelector?.payload?.message, {
        type: 'error',
      });
    }
  }, [videoLibrarySelector]);

  // Update video details
  const updateVideoDetails = (value)=>{
    dispatch(videoUpdateAction({
      ...value,
      id: videoDetails?.id,
    }));
  };

  return (
    <div
      className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
    >
      <form
        className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg sm:rounded-lg sm:m-4 sm:max-w-xl"
        id="modal"
        onSubmit={handleSubmit(updateVideoDetails)} noValidate
      >
        <header className="flex justify-end">
          <button
            className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded hover: hover:text-gray-700"
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </button>
        </header>
        <div className="mt-4 mb-6">
          <p
            className="mb-2 text-lg font-semibold text-gray-700"
          >
            Update media name
          </p>
          <p className="text-sm text-gray-700">
            <InputComponent
              type={'text'}
              placeholder={'Enter file name'}
              name="originalFileName"
              {...register('originalFileName')}
            />
            <ErrorText displayStatus={true}>{errors.originalFileName?.message}</ErrorText>
          </p>
        </div>
        <footer
          className="flex flex-row items-center justify-end"
        >
          <Button
            classOverride={'flex-1 px-5 py-3 text-sm font-medium leading-5 text-gray-700 transition-colors duration-150 border border-gray-300 rounded-lg sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray mr-4'}
            type="button"
            onClick={onClose}
          >
              Cancel
          </Button>
          <Button
            classOverride={'flex-1 px-5 py-3 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg sm:w-auto sm:px-4 sm:py-2 active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple'}
            type="submit"
          >
              Submit
          </Button>
        </footer>
      </form>
    </div>
  );
};

UpdateVideoDetailsModalComponent.propTypes = {
  onClose: PropTypes.func,
  videoDetails: PropTypes.any,
};

export default UpdateVideoDetailsModalComponent;
