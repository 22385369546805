import axios from 'axios';
import {BASE_URL} from '../constants/application.const';
import {objectToQueryString} from '../helpers/common.helper';

/**
 * Video library upload
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const storeVideo = (payload)=>{
  return axios.post(`${BASE_URL}video-library/store-video`, payload)
      .then((response) => (response?.data));
};


/**
 * Video library get all list
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const getAllVideoList = (payload)=>{
  return axios.get(`${BASE_URL}video-library/get-all-videos${objectToQueryString(payload)}`)
      .then((response) => (response?.data));
};

/**
 * Video delete
 *
 * @param {any} id
 * @return {Promise<any>}
 */
export const deleteVideo = (id)=>{
  return axios.delete(`${BASE_URL}video-library/delete-video/${id}`)
      .then((response) => (response?.data));
};

/**
 * Video update
 *
 * @param {any} data
 * @return {Promise<any>}
 */
export const updateVideoDetails = (data)=>{
  return axios.put(`${BASE_URL}video-library/update-video-deatils`, data)
      .then((response) => (response?.data));
};
