import React from 'react';

/**
 * Block icon
 *
 * @return {JSX}
 */
const BlockIcon = ({...props})=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} fill="currentColor" viewBox="0 0 48 48"><path d="M7 24Q7 27.05 8.1 29.875Q9.2 32.7 11.05 34.95L34.95 11.05Q32.65 9.1 29.85 8.05Q27.05 7 24 7Q16.9 7 11.95 11.95Q7 16.9 7 24ZM13.05 37Q15.3 38.95 18.125 39.975Q20.95 41 24 41Q31.1 41 36.05 36.05Q41 31.1 41 24Q41 20.95 39.95 18.15Q38.9 15.35 37 13.05ZM4 24Q4 19.85 5.575 16.2Q7.15 12.55 9.85 9.85Q12.55 7.15 16.2 5.575Q19.85 4 24 4Q28.15 4 31.8 5.575Q35.45 7.15 38.15 9.85Q40.85 12.55 42.425 16.2Q44 19.85 44 24Q44 28.15 42.425 31.8Q40.85 35.45 38.15 38.15Q35.45 40.85 31.8 42.425Q28.15 44 24 44Q19.85 44 16.2 42.425Q12.55 40.85 9.85 38.15Q7.15 35.45 5.575 31.8Q4 28.15 4 24Z"/></svg>
  );
};

export default BlockIcon;
