import React, {useEffect, useState} from 'react';
import Heading from '../../../../components/heading';
import ColorInput from '../../../../components/input/colorInput.component';
import CloseIcon from '../../../../icons/close.icon';
import PropTypes from 'prop-types';
import Button from '../../../../components/button/button.component';
import {SUB_HEADING_DEFAULT_VALUES} from '../../../../constants/defaultValues.const';

/**
 * Counter config modal
 *
 * @return {JSX}
 */
const CounterConfigModal = ({onClose, onConfigChange, configValues})=>{
  const [config, setConfig] = useState({...configValues});

  useEffect(()=>{
    if (configValues) {
      setConfig({...configValues});
    }
  }, [configValues]);

  // Reset form
  const resetForm = ()=>{
    setConfig({...SUB_HEADING_DEFAULT_VALUES});
    onConfigChange({...SUB_HEADING_DEFAULT_VALUES});
  };

  return (
    <>
      <div className="fixed inset-0 bg-stone-900 bg-opacity-75 transition-opacity z-50" aria-hidden="true"></div>
      <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white w-1/2 p-4 rounded-lg'>
        <Heading className='font-semibold text-lg flex justify-between  mt-4'>
            Counter settings
          <CloseIcon className='cursor-pointer w-4 h-4' onClick={()=>{
            onClose();
          }}/>
        </Heading>
        <div className='grid grid-cols-2 gap-4 my-4'>
          <div id="text-size-container">
            <label className='text-sm font-normal mt-4 flex items-center'>Choose counter container color</label>
            <ColorInput
              defaultValue={config?.fieldColor?.value}
              onChangeValue={(value)=>{
                setConfig({...config, fieldColor: {
                  value: value,
                  displayName: 'Field color',
                  chipColor: value,
                }});
                onConfigChange({...config, fieldColor: {
                  value: value,
                  displayName: 'Field color',
                  chipColor: value,
                }});
              }}
            />
          </div>
        </div>
        <div className='flex mt-8 justify-end'>
          <Button classOverride={'flex items-center justify-center px-3 py-2 mr-2 text-sm font-medium text-purple-600 duration-150 bg-transparent border-2 border-purple-600 rounded-lg hover:border-purple-700 active:border-purple-500 focus:outline-none focus:shadow-outline-purple'} type={'button'} onClick={()=>resetForm()}>Reset</Button>
          <Button onClick={()=>onClose()}>Confirm</Button>
        </div>
      </div>
    </>
  );
};

CounterConfigModal.propTypes = {
  onClose: PropTypes.func,
  onConfigChange: PropTypes.func,
  configValues: PropTypes.any,
};

export default CounterConfigModal;
