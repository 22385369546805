import React from 'react';
import PropTypes from 'prop-types';

/**
 * Widget icon
 *
 * @return {JSX}
 */
function WidgetIcon({color, height, width, ...props}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height || '24px'} viewBox="0 0 24 24" width={width || '24px'} fill={color || 'currentColor'} {...props}><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16.66 4.52l2.83 2.83-2.83 2.83-2.83-2.83 2.83-2.83M9 5v4H5V5h4m10 10v4h-4v-4h4M9 15v4H5v-4h4m7.66-13.31L11 7.34 16.66 13l5.66-5.66-5.66-5.65zM11 3H3v8h8V3zm10 10h-8v8h8v-8zm-10 0H3v8h8v-8z"/></svg>
  );
}

WidgetIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default WidgetIcon;
