import {all, call, put, takeLatest} from 'redux-saga/effects';
import {ADD_INTEGRATION_SUCCESS, ADD_INTEGRATION_FAILED, ADD_INTEGRATION_REQUEST, GET_INTEGRATION_SUCCESS, GET_INTEGRATION_FAILED, GET_INTEGRATION_REQUEST, SHOPIFY_OAUTH_REDIRECTION_REQUEST, GET_SHOPIFY_ACCESS_TOKEN_SUCCESS, GET_SHOPIFY_ACCESS_TOKEN_FAILED, GET_SHOPIFY_ACCESS_TOKEN_REQUEST, GET_INTEGRATION_STATUS_SUCCESS, GET_INTEGRATION_STATUS_FAILED, GET_INTEGRATION_STATUS_REQUEST, GET_SHOPIFY_INSTALL_STATUS_SUCCESS, GET_SHOPIFY_INSTALL_STATUS_FAILED, GET_SHOPIFY_INSTALL_STATUS_REQUEST, UNINSTALL_SHOPIFY_SUCCESS, UNINSTALL_SHOPIFY_FAILED, UNINSTALL_SHOPIFY_REQUEST, INSTALL_SHOPIFY_SUCCESS, INSTALL_SHOPIFY_FAILED, INSTALL_SHOPIFY_REQUEST, DELETE_INTEGRATION_SUCCESS, DELETE_INTEGRATION_FAILED, DELETE_INTEGRATION_REQUEST, UPDATE_INTEGRATION_SUCCESS, UPDATE_INTEGRATION_FAILED, UPDATE_INTEGRATION_REQUEST, GET_PABBLY_LIST_REQUEST, GET_PABBLY_LIST_SUCCESS, GET_PABBLY_LIST_FAILED, UPDATE_SHOPIDY_EMAIL_REQUEST, UPDATE_SHOPIDY_EMAIL_FAILED, UPDATE_SHOPIDY_EMAIL_SUCCESS} from '../constants/reducer.const';
import {logoutUnauthorized} from '../helpers/common.helper';
import * as integrationService from '../services/integration.service';

/**
 * add integration saga
 *
 * @param {Object} action
 */
function* addIntegrationSaga(action) {
  try {
    const response = yield call(integrationService.addIntegration, action.payload);
    yield put({type: ADD_INTEGRATION_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: ADD_INTEGRATION_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * add integration watcher
 */
function* addIntegrationWatcher() {
  yield takeLatest(ADD_INTEGRATION_REQUEST, addIntegrationSaga);
}

/**
 * get all integration list saga
 *
 * @param {Object} action
 */
function* getIntegrationList(action) {
  try {
    const response = yield call(integrationService.getIntegrations, action.payload);
    yield put({type: GET_INTEGRATION_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: GET_INTEGRATION_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * integration list saga watcher
 */
function* getIntegartionListWatcher() {
  yield takeLatest(GET_INTEGRATION_REQUEST, getIntegrationList);
}

/**
 * Shopify oauth redirection
 *
 * @param {Object} action
 */
function* shopifyOauthRedirection(action) {
  try {
    const response = yield call(integrationService.shopifyOauthRedirection, action.payload);
    window.location.replace(response?.data);
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: GET_INTEGRATION_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * integration list saga watcher
 */
function* shopifyOauthRedirectionWatcher() {
  yield takeLatest(SHOPIFY_OAUTH_REDIRECTION_REQUEST, shopifyOauthRedirection);
}

/**
 * Shopify oauth redirection
 *
 * @param {Object} action
 */
function* shopifyGetAccessToken(action) {
  try {
    const response = yield call(integrationService.shopifyGetAccessToken, action.payload);
    yield put({type: GET_SHOPIFY_ACCESS_TOKEN_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: GET_SHOPIFY_ACCESS_TOKEN_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * integration list saga watcher
 */
function* shopifyGetAccessTokenWatcher() {
  yield takeLatest(GET_SHOPIFY_ACCESS_TOKEN_REQUEST, shopifyGetAccessToken);
}

/**
 * Get integration status
 *
 * @param {Object} action
 */
function* getIntegrationStatus(action) {
  try {
    const response = yield call(integrationService.getIntegrationStatus, action.payload);
    yield put({type: GET_INTEGRATION_STATUS_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: GET_INTEGRATION_STATUS_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * integration status watcher
 */
function* getIntegrationStatusWatcher() {
  yield takeLatest(GET_INTEGRATION_STATUS_REQUEST, getIntegrationStatus);
}

/**
 * Get shopify install status
 *
 * @param {Object} action
 */
function* getShopifyInstallStatus(action) {
  try {
    const response = yield call(integrationService.getShopifyInstallationStatus, action.payload);
    yield put({type: GET_SHOPIFY_INSTALL_STATUS_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: GET_SHOPIFY_INSTALL_STATUS_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * shopify install status watcher
 */
function* getShopifyInstallStatusWatcher() {
  yield takeLatest(GET_SHOPIFY_INSTALL_STATUS_REQUEST, getShopifyInstallStatus);
}

/**
 * install shopify app
 *
 * @param {Object} action
 */
function* installShopifyApp(action) {
  try {
    const response = yield call(integrationService.installShopifyApplication, action.payload);
    yield put({type: INSTALL_SHOPIFY_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: INSTALL_SHOPIFY_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * shopify install status watcher
 */
function* installShopifyAppWatcher() {
  yield takeLatest(INSTALL_SHOPIFY_REQUEST, installShopifyApp);
}

/**
 * Get shopify install status
 *
 * @param {Object} action
 */
function* uninstallShopifyApp(action) {
  try {
    const response = yield call(integrationService.uninstallShopifyApplication, action.payload);
    yield put({type: UNINSTALL_SHOPIFY_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: UNINSTALL_SHOPIFY_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * shopify install status watcher
 */
function* uninstallShopifyAppWatcher() {
  yield takeLatest(UNINSTALL_SHOPIFY_REQUEST, uninstallShopifyApp);
}

/**
 * delete integration
 *
 * @param {Object} action
 */
function* deleteIntegration(action) {
  try {
    const response = yield call(integrationService.deleteIntegration, action.payload);
    yield put({type: DELETE_INTEGRATION_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: DELETE_INTEGRATION_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * integration uninstall status watcher
 */
function* deleteIntegrationWatcher() {
  yield takeLatest(DELETE_INTEGRATION_REQUEST, deleteIntegration);
}

/**
 * update integration
 *
 * @param {Object} action
 */
function* updateIntegration(action) {
  try {
    const response = yield call(integrationService.updateIntegrations, action.payload);
    yield put({type: UPDATE_INTEGRATION_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: UPDATE_INTEGRATION_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * integration update status watcher
 */
function* updateIntegrationWatcher() {
  yield takeLatest(UPDATE_INTEGRATION_REQUEST, updateIntegration);
}

/**
 * Get all pabbly list
 *
 * @param {Object} action
 */
function* getAllPabblyList(action) {
  try {
    const response = yield call(integrationService.getPabblyList, action.payload);
    yield put({type: GET_PABBLY_LIST_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: GET_PABBLY_LIST_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * Get all pabbly list watcher
 */
function* getAllPabblyListWatcher() {
  yield takeLatest(GET_PABBLY_LIST_REQUEST, getAllPabblyList);
}

/**
 * Update shopify email
 *
 * @param {Object} action
 */
function* updateShopifyEmail(action) {
  try {
    const response = yield call(integrationService.updateShopifyEmail, action.payload);
    console.log(response);
    yield put({type: UPDATE_SHOPIDY_EMAIL_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: UPDATE_SHOPIDY_EMAIL_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * Update shopify email watcher
 */
function* updateShopifyEmailWatcher() {
  yield takeLatest(UPDATE_SHOPIDY_EMAIL_REQUEST, updateShopifyEmail);
}

/**
 * exporting sagas after they are initialised
 */
export default function* rootSaga() {
  yield all([
    addIntegrationWatcher(),
    getIntegartionListWatcher(),
    shopifyOauthRedirectionWatcher(),
    shopifyGetAccessTokenWatcher(),
    getIntegrationStatusWatcher(),
    getShopifyInstallStatusWatcher(),
    installShopifyAppWatcher(),
    uninstallShopifyAppWatcher(),
    deleteIntegrationWatcher(),
    updateIntegrationWatcher(),
    getAllPabblyListWatcher(),
    updateShopifyEmailWatcher(),
  ]);
};

