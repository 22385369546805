import React from 'react';
import PropTypes from 'prop-types';

/**
 * Back icon
 *
 * @return {JSX}
 */
const BackIcon = ({height, width})=>{
  return (
    <svg width={width || '30px'} height={height || '30px'} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.3574 13.5792L15.3591 13.9923C15.3591 15.7092 15.2586 17.2745 15.1071 18.2948L14.9745 18.9294C14.9006 19.2654 14.8036 19.6481 14.7024 19.8434C14.332 20.5579 13.6079 21 12.8328 21H12.7653C12.2601 20.9833 11.1986 20.5399 11.1986 20.5245C9.49065 19.8079 6.19553 17.645 4.67366 16.103L4.23142 15.6401C4.11561 15.5146 3.9855 15.3662 3.90472 15.2504C3.63491 14.8932 3.5 14.4511 3.5 14.009C3.5 13.5155 3.65145 13.0567 3.93781 12.6815L4.39218 12.1911L4.49399 12.0865C5.87488 10.5893 9.48047 8.14118 11.3666 7.39196L11.6514 7.28282C11.9941 7.16 12.4742 7.01349 12.7653 7C13.1357 7 13.4895 7.0861 13.8268 7.25574C14.2481 7.49348 14.584 7.86874 14.7699 8.31081C14.8882 8.61667 15.074 9.53552 15.074 9.55223C15.2444 10.4787 15.3432 11.9406 15.3574 13.5792ZM24.5 14.0005C24.5 14.9785 23.7147 15.7714 22.7462 15.7714L18.4304 15.3897C17.6706 15.3897 17.0546 14.7677 17.0546 14.0005C17.0546 13.232 17.6706 12.6113 18.4304 12.6113L22.7462 12.2296C23.7147 12.2296 24.5 13.0225 24.5 14.0005Z" fill="black"/>
    </svg>
  );
};

BackIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};


export default BackIcon;
