import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {getAllPlansAdmin} from '../../actions/plans.actions';
import Button from '../../components/button/button.component';
import Heading from '../../components/heading';
import {clearStorage, getValue} from '../../config/localStorage.config';
import {USER_DETAILS} from '../../constants/application.const';
import {GET_ALL_PLANS_ADMIN_FAILED, GET_ALL_PLANS_ADMIN_SUCCESS} from '../../constants/reducer.const';
import {toDynamicMetric} from '../../helpers/common.helper';
import {upgradePlanApi} from '../../services/plan.service';
import TopSideBarTemplate from '../../templates/topSideBar.template';
import UploadLoader from '../mediaLibrary/components/uploadLoader.component';
import PlanMigrationModal from './components/planMigration.modal';

/**
 * Upgrade page
 *
 * @return {JSX}
 */
const UpgradePage = ()=>{
  const dispatch = useDispatch();
  const planSelector = useSelector((state)=>state?.plan);
  const [plans, setPlans] = useState([]);
  const [curretUserPlan, setCurrentUserPlan] = useState({});
  const [loaderText, setLoaderText] = useState('Loading Plans');
  const [pageLoader, setPageLoader] = useState(false);
  const [planMigrationModal, setPlanMigrationModal] = useState(false);
  const [migrationNoticeDetails, setMigrtaionNoticeDetails] = useState({});
  const navigate = useNavigate();

  // Get user data from local storage
  const getLocalStorageUserData = ()=>{
    const userData = getValue(USER_DETAILS);
    if (userData) {
      setCurrentUserPlan(JSON.parse(userData)?.plan);
    }
  };

  // called initially
  useEffect(()=>{
    getLocalStorageUserData();
    setPageLoader(true);
    dispatch(getAllPlansAdmin({status: 'A'}));
  }, []);

  useEffect(()=>{
    if (planSelector?.planAdminDetails?.type === GET_ALL_PLANS_ADMIN_SUCCESS) {
      setPageLoader(false);
      setPlans(planSelector?.planAdminDetails?.payload?.data);
    }
    if (planSelector?.planAdminDetails?.type === GET_ALL_PLANS_ADMIN_FAILED) {
      setPageLoader(false);
    }
  }, [planSelector?.planAdminDetails]);

  const upgradePlan = async (planId, planData)=>{
    try {
      setLoaderText('Processing');
      setPageLoader(true);
      const response = await upgradePlanApi({planId});
      if (response?.data?.sessionUrl) {
        location.href = response?.data?.sessionUrl;
      }
      // if Beta redirect to login
      if (response?.data?.beta) {
        toast(response?.message, {
          type: 'success',
        });
        clearStorage();
        navigate('/');
      }
    } catch (e) {
      if (e?.response?.data?.data?.planMigrationError) {
        migrationNoticeDetails.planMigrationError = e?.response?.data?.data?.planMigrationError;
        setMigrtaionNoticeDetails({
          ...migrationNoticeDetails,
          ...planData,
        });
        setPlanMigrationModal(true);
      } else {
        toast(e?.response?.data?.message, {
          type: 'error',
        });
      }
    } finally {
      setPageLoader(false);
    }
  };

  return (
    <TopSideBarTemplate
      noPadding={true}
    >
      <div className='bg-white h-screen grid gap-4'>
        <div className='col-span-12 px-4 pt-8'>
          <Heading className={'font-semibold text-xl'}>Plans</Heading>
          <div className='text-sm mt-1'>
            <p className='text-primary col-span-12'>
            By default when you change from a current plan all your widgets will be <span className='font-bold'>Un-published</span>, So you need to publish them again once the plan is changed successfully.
            </p>
            <p className='text-primary col-span-12'>
            By default when you change from a current plan all your widgets will have Popsuite <span className='font-bold'>branding enabled</span>. You can change them if your current plan permits.
            </p>
          </div>
          <div className="flex flex-col w-full overflow-hidden border border-slate-300 rounded-lg mt-4">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full sm:px-6">
                <div className="overflow-y-auto max-h-screen">
                  <table className="border-collapse min-w-full rounded-md bg-white">
                    <thead className="rounded-md">
                      <tr className='text-xs font-semibold tracking-wide text-left text-gray-500 uppercase ddark:border-gray-700 bg-gray-50 ddark:text-gray-400 ddark:bg-gray-800 rounded-md border-b mb-4'>
                        <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">Features</th>
                        {plans?.map((planData, index)=>(
                          <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center" key={index}>
                            <Heading className={'font-semibold text-lg mt-4'}>{planData?.name}</Heading>
                            <div className='font-bold text-2xl my-2 text-primary'>${planData?.price}</div>
                            <div className='font-thin text-sm mb-4'>{planData?.description}</div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='border-b border-slate-300'>
                        <td className='text-sm text-gray-900 font-semibold px-2 py-6 whitespace-nowrap text-center'>Widgets</td>
                        {
                          plans?.map((planData, index)=>(
                            <td key={index} className='text-sm text-gray-900 font-light px-2 py-6 whitespace-nowrap text-center'>
                              {planData?.modules?.P?.WIDGETS?.WIDGET_LIMIT}
                            </td>
                          ))
                        }
                      </tr>
                      <tr className='border-b border-slate-300'>
                        <td className='text-sm text-gray-900 font-semibold px-2 py-6 whitespace-nowrap text-center'>Storage Capacity</td>
                        {
                          plans?.map((planData, index)=>(
                            <td key={index} className='text-sm text-gray-900 font-light px-2 py-6 whitespace-nowrap text-center'>
                              {toDynamicMetric(planData?.storageCap)}
                            </td>
                          ))
                        }
                      </tr>
                      <tr className='border-b border-slate-300'>
                        <td className='text-sm text-gray-900 font-semibold px-2 py-6 whitespace-nowrap text-center'>Integrations</td>
                        {
                          plans?.map((planData, index)=>(
                            <td key={index} className='text-sm text-gray-900 font-light px-2 py-6 whitespace-nowrap text-center'>
                              {planData?.modules?.P?.INTEGRATIONS?.ZAPIER?'Yes':'No'}
                            </td>
                          ))
                        }
                      </tr>
                      <tr className='border-b border-slate-300'>
                        <td className='text-sm text-gray-900 font-semibold px-2 py-6 whitespace-nowrap text-center'>Branding</td>
                        {
                          plans?.map((planData, index)=>(
                            <td key={index} className='text-sm text-gray-900 font-light px-2 py-6 whitespace-nowrap text-center'>
                              {planData?.modules?.P?.WIDGETS?.WIDGET_BRANDING?'Yes':'No'}
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className='text-sm text-gray-900 font-semibold px-2 py-6 whitespace-nowrap text-center'>Total domains</td>
                        {
                          plans?.map((planData, index)=>(
                            <td key={index} className='text-sm text-gray-900 font-light px-2 py-6 whitespace-nowrap text-center'>
                              {planData?.modules?.P?.WIDGETS?.BUBBLE_SETTINGS?.DOMAIN_LENGTH}
                            </td>
                          ))
                        }
                      </tr>
                      <tr>
                        <td className='text-sm text-gray-900 font-semibold px-2 py-6 whitespace-nowrap text-center'> </td>
                        {
                          plans?.map((planData, index)=>(
                            <td key={index} className='text-sm text-gray-900 font-light px-2 py-6 whitespace-nowrap'>
                              <div className='flex flex-row justify-center'>
                                <Button classOverride={`flex items-center justify-center p-2 text-sm font-medium text-white duration-150 bg-${curretUserPlan?.id === planData?.id?'slate':'purple'}-500 border border-transparent rounded-lg active:bg-${curretUserPlan?.id === planData?.id?'slate':'purple'}-500 hover:bg-${curretUserPlan?.id === planData?.id?'':'purple'}-600 focus:outline-none focus:shadow-outline-${curretUserPlan?.id === planData?.id?'slate':'purple'}`}
                                  disabled={curretUserPlan?.id === planData?.id}
                                  onClick={()=>{
                                    upgradePlan(planData?.id, planData);
                                  }}
                                >{curretUserPlan?.id === planData?.id?'Current plan':'Select Plan'}</Button>
                              </div>
                            </td>
                          ))
                        }
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Plan migration modal */}
        {planMigrationModal && <PlanMigrationModal
          contentDetails={{...migrationNoticeDetails}}
          onClose={()=>{
            setPlanMigrationModal(false);
          }}
        />}
      </div>
      {pageLoader && <UploadLoader
        text={loaderText}
      />}
    </TopSideBarTemplate>
  );
};

// bg-purple-500

export default UpgradePage;
