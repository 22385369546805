import React from 'react';
import PropTypes from 'prop-types';

/**
 * IntegrationNew icon
 *
 * @return {JSX}
 */
function IntegrationNewIcon({color, height, width, ...props}) {
  return (
    <svg width={width || '32px'} height={height || '32px'} fill={color || 'currentColor'} xmlns="http://www.w3.org/2000/svg">
      <path d="M29.3337 10.5593C29.3337 5.83935 26.307 2.66602 21.787 2.66602H10.227C5.70699 2.66602 2.66699 5.83935 2.66699 10.5593V21.454C2.66699 26.1607 5.70699 29.3327 10.227 29.3327H21.787C26.307 29.3327 29.3337 26.1607 29.3337 21.454V10.5593ZM24.9995 7.44787C25.0034 7.67132 24.9205 7.88757 24.7681 8.05107L21.9564 10.864C22.5653 11.7576 22.8431 12.8358 22.7419 13.9125C22.6407 14.9891 22.1668 15.9966 21.4021 16.7612L21.0571 17.1062C20.7357 17.4275 20.2999 17.6079 19.8456 17.6079C19.3912 17.6079 18.9554 17.4275 18.634 17.1062L14.494 12.9662C14.3348 12.807 14.2084 12.6181 14.1222 12.4101C14.036 12.2021 13.9917 11.9792 13.9917 11.7541C13.9917 11.5289 14.036 11.306 14.1222 11.098C14.2084 10.8901 14.3348 10.7011 14.494 10.542L14.839 10.197C15.6037 9.43241 16.6113 8.95876 17.688 8.85778C18.7646 8.75679 19.8427 9.0348 20.7362 9.64382L23.5491 6.83207C23.7126 6.67972 23.9289 6.59678 24.1523 6.60072C24.3758 6.60466 24.589 6.69518 24.747 6.85321C24.905 7.01123 24.9955 7.22442 24.9995 7.44787ZM11.7967 13.9502C12.2511 13.9502 12.6869 14.1307 13.0082 14.452L17.1482 18.592C17.3075 18.7511 17.4338 18.9401 17.52 19.148C17.6062 19.356 17.6506 19.5789 17.6506 19.8041C17.6506 20.0292 17.6062 20.2521 17.52 20.4601C17.4338 20.6681 17.3075 20.857 17.1482 21.0162L16.8032 21.3612C16.0384 22.1256 15.0307 22.599 13.9541 22.6998C12.8775 22.8005 11.7994 22.5223 10.906 21.9132L8.09312 24.7261C8.01416 24.8108 7.91894 24.8788 7.81314 24.9259C7.70734 24.9731 7.59313 24.9984 7.47732 25.0005C7.36152 25.0025 7.24648 24.9812 7.13909 24.9378C7.03169 24.8944 6.93413 24.8299 6.85223 24.748C6.77033 24.6661 6.70576 24.5685 6.66238 24.4611C6.619 24.3537 6.5977 24.2387 6.59974 24.1229C6.60179 24.0071 6.62714 23.8929 6.67428 23.7871C6.72142 23.6813 6.78938 23.586 6.87412 23.5071L9.68702 20.6942C9.078 19.8006 8.79999 18.7225 8.90098 17.6459C9.00197 16.5693 9.47561 15.5617 10.2402 14.797L10.5852 14.452C10.9065 14.1307 11.3423 13.9502 11.7967 13.9502Z"/>
    </svg>
  );
}

IntegrationNewIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default IntegrationNewIcon;
