import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '../../icons/close.icon';

/**
 * Chips component
 *
 * @return {JSX}
 */
const Chips = ({text, color, additionalClass, children, classOverride, enableClose, closeOnClick, textSizeClass, height, width, roundClass, ...props}) => {
  return (
    <p className={classOverride || `px-2 py-1 font-medium leading-tight text-${color}-700 bg-${color}-100 ${roundClass || 'rounded-full'} ${textSizeClass || 'text-xs'} flex items-center ${additionalClass}`} {...props}>
      <span>{text || children}</span>
      {enableClose && <CloseIcon
        height={height || '12'}
        width={width || '12'}
        className="ml-1 flex cursor-pointer"
        onClick={closeOnClick}
      />}
    </p>
  );
};

Chips.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  additionalClass: PropTypes.string,
  classOverride: PropTypes.string,
  children: PropTypes.any,
  enableClose: PropTypes.bool,
  closeOnClick: PropTypes.func,
  textSizeClass: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  roundClass: PropTypes.string,
};

export default Chips;

/**
 * Tailwind styles for auto generation(DO NOT REMOVE)
 *
 * text-amber-700 bg-amber-100 rounded-full
 * text-purple-700 bg-purple-100 rounded-full
 * text-green-700 bg-green-100 rounded-full
 * text-red-700 bg-red-100 rounded-full
 * text-yellow-700 bg-yellow-100 rounded-full
 * text-teal-700 bg-teal-100 rounded-full
 * text-pink-700 bg-pink-100 rounded-full
 */
