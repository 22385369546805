import {all} from 'redux-saga/effects';
import usersSaga from './users.saga';
import dashboardSaga from './dashboard.saga';
import videoLibrary from './videoLibrary.saga';
import widgetSaga from './widget.saga';
import customerSaga from './customer.saga';
import integrationSaga from './integrations.saga';
import planSaga from './plan.saga';
import adminSaga from './admin.saga';
import redemptionSaga from './redemption.saga';

/**
 * Initialising all saga in the application
 */
export default function* rootSaga() {
  yield all([
    usersSaga(),
    dashboardSaga(),
    videoLibrary(),
    widgetSaga(),
    customerSaga(),
    integrationSaga(),
    planSaga(),
    adminSaga(),
    redemptionSaga(),
  ]);
}
