import React from 'react';

/**
 * Start icon
 *
 * @return {JSX}
 */
const StarIcon = ({...props})=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 48 48" fill="currentColor"><path d="M11.65 44 16.3 28.8 4 20H19.2L24 4L28.8 20H44L31.7 28.8L36.35 44L24 34.6Z"/></svg>
  );
};

export default StarIcon;
