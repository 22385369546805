export const FONT_SIZE_CONST = {
  SM: 'SM',
  LG: 'LG',
  XL: 'XL',
};

export const FONT_TYPE_CONST = {
  T: 'T',
  B: 'B',
  EB: 'EB',
};


// Popup size mapper
export const POPUP_SIZE_MAPPER = {
  'SM': '50%',
  'LG': '60%',
  'XL': '70%',
};

// Font size mapper
export const FONT_SIZE_MAPPER = {
  [FONT_SIZE_CONST.SM]: '18px',
  [FONT_SIZE_CONST.LG]: '24px',
  [FONT_SIZE_CONST.XL]: '30px',
};

// Font type mapper
export const FONT_TYPE_MAPPER = {
  [FONT_TYPE_CONST.T]: 'normal',
  [FONT_TYPE_CONST.B]: 'bold',
  [FONT_TYPE_CONST.EB]: 'bolder',
};

// counter direction mapper
export const COUNTER_DIRECTION_MAPPER = {
  RF: {
    value: 'RF',
    name: 'Run time forward',
  },
  RB: {
    value: 'RB',
    name: 'Run time backward',
  },
};

// counter direction mapper
export const MEDIA_POSITION_MAPPER = {
  L: {
    value: 'L',
    name: 'Left',
  },
  B: {
    value: 'B',
    name: 'Background',
  },
  R: {
    value: 'R',
    name: 'Right',
  },
};

// action types form / CTA button
export const ACTION_TYPE_MAPPER = {
  AB: {
    name: 'Action Button',
    value: 'AB',
  },
  F: {
    name: 'Form',
    value: 'F',
  },
  FB: {
    name: 'Facebook',
    value: 'FB',
  },
  CAL: {
    name: 'Calendly',
    value: 'CAL',
  },
};

// validation mappers
export const VALIDATION_MAPPER = {
  E: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  P: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
  ALL: /.*/,
};

// Size mapper for widgets
export const SIZE_MAPPER = {
  SM: {
    C: {
      width: '8rem',
      height: '8rem',
    },
    S: {
      width: '8rem',
      height: '8rem',
    },
    R: {
      width: '11rem',
      height: '6rem',
    },
  },
  LG: {
    C: {
      width: '12rem',
      height: '12rem',
    },
    S: {
      width: '12rem',
      height: '12rem',
    },
    R: {
      width: '15rem',
      height: '10rem',
    },
  },
  XL: {
    C: {
      width: '16rem',
      height: '16rem',
    },
    S: {
      width: '16rem',
      height: '16rem',
    },
    R: {
      width: '19rem',
      height: '14rem',
    },
  },
};

// Style type mapper
export const STYLE_TYPE_MAPPER = (size)=>({
  C: {
    radius: '50%',
    width: SIZE_MAPPER[size]?.C?.width,
    height: SIZE_MAPPER[size]?.C?.height,
  },
  S: {
    radius: '10%',
    width: SIZE_MAPPER[size]?.S?.width,
    height: SIZE_MAPPER[size]?.S?.height,
  },
  R: {
    radius: '10%',
    width: SIZE_MAPPER[size]?.R?.width,
    height: SIZE_MAPPER[size]?.R?.height,
  },
});
