import {EMAIL_PATTERN} from '../constants/application.const';
import * as yup from 'yup';

// Admin SMTP
export const smtpConfig = yup.object({
  host: yup.string().required('Host required'),
  port: yup.number().typeError('Not a valid number'),
  secure: yup.boolean(),
  email: yup.string().required('Email required').matches(EMAIL_PATTERN, 'Invalid email'),
  password: yup.string(),
}).required();

// tawk SMTP
export const tawkConfig = yup.object({
  propertyId: yup.string().required('Property id required'),
  widgetId: yup.string().typeError('Widget id required'),
}).required();

// Integration config
export const integrationConfig = yup.object({
  pabblyWebHookURL: yup.string(),
}).required();
