import React from 'react';
import PropType from 'prop-types';

/**
 * Media loader component
 *
 * @return {JSX}
 */
const MediaLoader = ({additionalSettings})=>{
  return (
    <div id="loader-container" className={`flex flex-row justify-center animate-pulse ${additionalSettings}`}>
      <div className='h-36 w-36 bg-slate-200 rounded-md mr-4' id="loader">
      </div>
      <div className='h-36 w-36 bg-slate-200 rounded-md mr-4' id="loader">
      </div>
      <div className='h-36 w-36 bg-slate-200 rounded-md mr-4' id="loader">
      </div>
      <div className='h-36 w-36 bg-slate-200 rounded-md mr-4' id="loader">
      </div>
      <div className='h-36 w-36 bg-slate-200 rounded-md mr-4' id="loader">
      </div>
      <div className='h-36 w-36 bg-slate-200 rounded-md mr-4' id="loader">
      </div>
    </div>
  );
};

MediaLoader.propTypes = {
  additionalSettings: PropType.string,
};

export default MediaLoader;
