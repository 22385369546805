import React from 'react';
import PropTypes from 'prop-types';

/**
 * Smtp icon
 *
 * @return {JSX}
 */
function SmtpIcon({height, width, ...props}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height || '24px'} width={width || '24px'} viewBox="0 0 48 48" {...props} fill={'currentColor'}><path d="M31.3 38.25V42.35Q31.3 43.1 30.7 43.55Q30.1 44 29.3 44H5.65Q4.95 44 4.475 43.525Q4 43.05 4 42.35V27.45Q4 26.75 4.475 26.275Q4.95 25.8 5.65 25.8H12.3V15.5Q12.3 10.65 15.725 7.325Q19.15 4 24 4H32.65Q37.4 4 40.7 7.375Q44 10.75 44 15.5V44H41V38.25ZM31.3 35.25H41V15.5Q41 12 38.575 9.5Q36.15 7 32.65 7H24Q20.4 7 17.85 9.45Q15.3 11.9 15.3 15.5V25.8H29.3Q30.1 25.8 30.7 26.25Q31.3 26.7 31.3 27.45ZM20 19.25V16.25H36.3V19.25ZM17.65 34.9 28.3 28.8H7ZM17.65 37.65 7 31.55V41H28.3V31.55ZM7 28.8V41V28.8Z"/></svg>
  );
}

SmtpIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default SmtpIcon;
