import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

/**
 * Select component
 *
 * @return {JSX}
 */
function Select({options, onChange, defaultSelected}) {
  const [currentSelected, setCurrentSelected] = useState(defaultSelected || '');

  // On change listner
  const onChangeListner = (event)=>{
    setCurrentSelected(event?.target?.value);
    onChange(event?.target?.value);
  };

  useEffect(()=>{
    if (typeof defaultSelected !== 'undefined') {
      setCurrentSelected(defaultSelected);
    }
  }, [defaultSelected]);

  return (
    <div className="flex justify-center mt-2">
      <div className="w-full">
        <select onChange={onChangeListner} className="form-select appearance-none
            block
            w-full
            input-component
            text-sm
            font-normal
            text-gray-700
            bg-white bg-clip-padding bg-no-repeat
            border border-solid border-slate-200
            rounded
            transition
            ease-in-out
            m-0
            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default select example" value={currentSelected}>
          {
            options?.map((option, index)=>(
              <option key={index} value={option?.value}>{option?.text}</option>
            ))
          }
        </select>
      </div>
    </div>
  );
}

Select.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  defaultSelected: PropTypes.any,
};

export default Select;
