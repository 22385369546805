import * as yup from 'yup';
import {CALENDLY_URL_PATTERN, COUNTER_PATTERN, MAX_LINK_FIELD_LENGTH, MAX_TEXT_AREA_FIELD_LENGTH, MAX_TEXT_FIELD_LENGTH, URL_PATTERN} from '../constants/application.const';

// video name validation schema
export const videoUpdateSchema = yup.object({
  originalFileName: yup.string().required('File name required').max(MAX_TEXT_FIELD_LENGTH, `Maximum ${MAX_TEXT_FIELD_LENGTH} allowed`),
}).required();

// Add widget form (Bubble)
export const addWidgetBubble = yup.object({
  widgetName: yup.string().required('Widget name required').max(MAX_TEXT_FIELD_LENGTH, `Maximum ${MAX_TEXT_FIELD_LENGTH} allowed`),
  widgetTitle: yup.string(),
  widgetSize: yup.number(),
  widgetBorderColor: yup.string(),
  widgetTitleColor: yup.string(),
  widgetBackgroundColor: yup.string(),
  widgetButtonColor: yup.string(),
  widgetStyle: yup.string().default(),
  widgetPosition: yup.string(),
  widgetComponentSettingsVideo: yup.bool(),
  widgetComponentSettingsButton: yup.bool(),
  widgetComponentSettingsButtonText: yup.string().when('widgetComponentSettingsButton', {
    is: true,
    then: yup.string().required('Button text required'),
  }),
  widgetComponentSettingsButtonLink: yup.string().when('widgetComponentSettingsButton', {
    is: true,
    then: yup.string().required('Button link required'),
  }),
  widgetComponentSettingsCounter: yup.bool(),
  widgetComponentSettingsCounterText: yup.string().when('widgetComponentSettingsCounter', {
    is: true,
    then: yup.string().required('Counter text required').matches(COUNTER_PATTERN, 'Invalid pattern( valid format 12:34)'),
  }),
  widgetComponentSettingsHeader: yup.bool(),
  widgetComponentSettingsHeaderText: yup.string().when('widgetComponentSettingsHeader', {
    is: true,
    then: yup.string().required('Header text required'),
  }),
  widgetComponentSettingsHeaderColor: yup.string().when('widgetComponentSettingsHeader', {
    is: true,
    then: yup.string().required('Header color required'),
  }),
  widgetComponentSettingsFormFields: yup.bool(),
  widgetPages: yup.string().required('Pages required'),
});

// Add widget popup
export const addWidgetPopup = yup.object({
  widgetName: yup.string().required('Widget name required').max(MAX_TEXT_FIELD_LENGTH, `Maximum ${MAX_TEXT_FIELD_LENGTH} allowed`),
  pages: yup.string().max(MAX_LINK_FIELD_LENGTH, `Maximum ${MAX_LINK_FIELD_LENGTH} allowed`),
  headingText: yup.string().max(MAX_TEXT_FIELD_LENGTH, `Maximum ${MAX_TEXT_FIELD_LENGTH} allowed`),
  bubbleActionHeading: yup.string().max(MAX_TEXT_FIELD_LENGTH, `Maximum ${MAX_TEXT_FIELD_LENGTH} allowed`),
  titleText: yup.string().max(MAX_TEXT_FIELD_LENGTH, `Maximum ${MAX_TEXT_FIELD_LENGTH} allowed`),
  subHeadingText: yup.string().max(MAX_TEXT_AREA_FIELD_LENGTH, `Maximum ${MAX_TEXT_AREA_FIELD_LENGTH} allowed`),
  popupSize: yup.string(),
  counterDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
  actionButtonName: yup.string().max(MAX_TEXT_FIELD_LENGTH, `Maximum ${MAX_TEXT_FIELD_LENGTH} allowed`),
  actionButtonLink: yup.string().nullable().transform((v, o) => (o === '' ? null : v)).matches(URL_PATTERN, 'Invalid URL'),
  facebookUserName: yup.string(),
  mailChimpAudienceId: yup.string(),
  klaviyoListId: yup.string(),
  calendlyMeetingLink: yup.string().nullable().transform((v, o) => (o === '' ? null : v)).matches(CALENDLY_URL_PATTERN, 'Invalid pattern( valid format username/meetingid)'),
});

// Preview Input Popup validation
export const previewInputPopup = yup.object({
  url: yup.string().required('Preview URL required').matches(URL_PATTERN, 'Invalid URL'),
});
