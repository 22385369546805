import React from 'react';
import LoaderIcon from '../../../icons/loader.icon';
import PropTypes from 'prop-types';

// Loader icon while uploading video
const UploadLoader = ({text})=>{
  return (
    <>
      <div className="fixed inset-0 bg-stone-900 bg-opacity-75 transition-opacity z-50" aria-hidden="true"></div>
      <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white p-4 rounded-lg flex items-center justify-center flex-col px-8'>
        <LoaderIcon
          classOverride='animate-spin -ml-1 h-12 w-12 text-purple-500'
        />
        <p className='mt-4 font-semibold text-lg'>{text || 'Uploading file...'}</p>
      </div>
    </>
  );
};

UploadLoader.propTypes = {
  text: PropTypes.string,
};

export default UploadLoader;
