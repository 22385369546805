import axios from 'axios';
import {BASE_URL} from '../constants/application.const';

/**
 * Plan store
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const getPlanDetails = (payload)=>{
  return axios.get(`${BASE_URL}plans/get-plan/${payload?.planId}`)
      .then((response) => (response?.data));
};

/**
 * Get all plans
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const getAllPlanAdmin = (payload)=>{
  let query = `${BASE_URL}admin-plans/get-all-plans`;
  if (payload?.id) {
    query += `?id=${payload?.id}`;
  }
  if (payload?.status) {
    query += `?status=${payload?.status}`;
  }
  return axios.get(query)
      .then((response) => (response?.data));
};

/**
 * initiate payment process (Stripe)
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const initiateStripePaymentProcess = (payload)=>{
  return axios.post(`${BASE_URL}payments/initiate-payment-stripe`, payload)
      .then((response) => (response?.data));
};

/**
 * update payment status
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const updatePaymentStatusApi = (payload)=>{
  return axios.post(`${BASE_URL}users/update-payment-staus`, payload)
      .then((response) => (response?.data));
};

/**
 * add plans (for admin)
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const addPlans = (payload)=>{
  return axios.post(`${BASE_URL}admin-plans/add-plan`, payload)
      .then((response) => (response?.data));
};

/**
 * edit plans (for admin)
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const editPlans = (payload)=>{
  return axios.post(`${BASE_URL}admin-plans/edit-plan`, payload)
      .then((response) => (response?.data));
};

/**
 * upgrade plans
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const upgradePlanApi = (payload)=>{
  return axios.post(`${BASE_URL}plans/upgrade`, payload)
      .then((response) => (response?.data));
};

/**
 * update subscription
 *
 * @param {any} payload
 * @return {Promise<any>}
 */
export const updateSubscriptionApi = (payload)=>{
  return axios.post(`${BASE_URL}plans/update-subscription`, payload)
      .then((response) => (response?.data));
};
