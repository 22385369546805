import React, {useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import CancelIcon from '../../icons/cancel.icon';
import Heading from '../heading';

/**
 * Payment failed page
 *
 * @return {JSX}
 */
const PaymentFailed = ()=>{
  const navigate = useNavigate();

  useEffect(()=>{
    setTimeout(()=>{
      navigate('/upgrade');
    }, 3000);
  }, []);

  return (
    <div className='flex h-full justify-center items-center flex-col'>
      <div className='flex'>
        <CancelIcon
          height={'120px'}
          width={'120px'}
          className='text-red-500'
        />
      </div>
      <Heading className='font-semibold text-xl mt-4 flex items-center text-slate-800'>Payment failed. Please try again later.</Heading>
      <p className='text-sm text-slate-600'>Redirecting to <Link to={'/upgrade'}className='underline text-blue-400 cursor-pointer'>Plans</Link></p>
    </div>
  );
};

export default PaymentFailed;
