import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

/**
 * Check box component
 *
 * @return {JSX}
 */
const CheckBox = ({label, parentAdditionalClass, onChange, defaultValue})=>{
  const [checked, setChecked] = useState(defaultValue || false);

  useEffect(()=>{
    setChecked(defaultValue);
  }, [defaultValue]);

  return (
    <div className={`flex items-center mt-2 ${parentAdditionalClass}`}>
      <input value={checked} type={'checkbox'} onChange={()=>{
        const currentValue = checked;
        setChecked((value)=>!currentValue);
        onChange(!currentValue);
      }}
      checked={checked}
      className={label?'mr-2':''}
      />
      {label && <span className='text-sm'>{label}</span>}
    </div>
  );
};

CheckBox.propTypes = {
  label: PropTypes.string,
  parentAdditionalClass: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.bool,
};

export default CheckBox;
