import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import UpgradeButton from './upgradeButton.component';
import Popover from '../../components/popover';
import Heading from '../heading';

/**
 * Button group
 *
 * @return {JSX}
 */
const ButtonGroup = ({buttons, label, labelMarginEnable, onSelect, defaultSelect, planBlock, planPopupPosition, ...props})=>{
  const [selected, setSelected] = useState(defaultSelect || '');

  /**
   * On value change
   *
   * @param {Number} index
   */
  const onValueChange = (index)=>{
    setSelected(buttons[index]?.value);
    onSelect(buttons[index]?.value);
  };

  useEffect(()=>{
    if (defaultSelect) {
      setSelected(defaultSelect);
    }
  }, [defaultSelect]);

  return (<div className={`relative ${props?.className} ${planBlock?'p-4':''}`}>
    {planBlock && <div className="absolute inset-0 bg-stone-700 bg-opacity-75 transition-opacity z-50 rounded-lg flex justify-center items-center" aria-hidden="true">
      <Popover
        additionalPopoverClass={planPopupPosition === 'bottom'?'mb-2':'mt-2'}
        position={planPopupPosition}
        popupOverComponent={planBlock &&
            <div className='w-60 p-4'>
              <Heading className="text-md font-bold">Need this feature?</Heading>
              <p className={'mt-2'}>
                Upgrade to unlock more features!!!
              </p>
            </div>
        }
      >
        <UpgradeButton />
      </Popover>
    </div>}
    {label && <label className='text-sm font-normal'>{label}</label>}
    {
      (buttons?.length === 4) && (
        <div className={`flex  ${(labelMarginEnable || label)?'mt-2':''} bg-white border rounded-lg border-slate-200 transition-all`}>
          <button type={'button'} className={`rounded-l-lg button-group-button text-center text-xs ${selected === buttons[0]?.value ?'border bg-primary rounded-lg text-white border-primary':'bg-white'} flex-1`}
            onClick={()=>{
              onValueChange(0);
            }}
          >
            {buttons[0]?.name}
          </button>
          <button type={'button'} className={`button-group-button text-center text-xs ${selected === buttons[1]?.value ?'border bg-primary rounded-lg text-white border-primary':'bg-white'} flex-1`}
            onClick={()=>{
              onValueChange(1);
            }}
          >
            {buttons[1]?.name}
          </button>
          <button type={'button'} className={`button-group-button text-center text-xs ${selected === buttons[2]?.value ?'border bg-primary rounded-lg text-white border-primary':'bg-white'} flex-1`}
            onClick={()=>{
              onValueChange(2);
            }}
          >
            {buttons[2]?.name}
          </button>
          <button type={'button'} className={`rounded-r-lg button-group-button text-center text-xs ${selected === buttons[3]?.value ?'border bg-primary rounded-lg text-white border-primary':'bg-white'}`}
            onClick={()=>{
              onValueChange(3);
            }}
          >
            {buttons[3]?.name}
          </button>
        </div>
      )
    }
    {
      (buttons?.length === 3) && (
        <div className={`flex  ${(labelMarginEnable || label)?'mt-2':''} bg-white border rounded-lg border-slate-200 transition-all`}>
          <button type={'button'} className={`rounded-l-lg button-group-button text-center text-xs ${selected === buttons[0]?.value ?'border bg-primary rounded-lg text-white border-primary':'bg-white'} flex-1`}
            onClick={()=>{
              onValueChange(0);
            }}
          >
            {buttons[0]?.name}
          </button>
          <button type={'button'} className={`button-group-button text-center text-xs ${selected === buttons[1]?.value ?'border bg-primary rounded-lg text-white border-primary':'bg-white'} flex-1`}
            onClick={()=>{
              onValueChange(1);
            }}
          >
            {buttons[1]?.name}
          </button>
          <button type={'button'} className={`rounded-r-lg button-group-button text-center text-xs ${selected === buttons[2]?.value ?'border bg-primary rounded-lg text-white border-primary':'bg-white'} flex-1`}
            onClick={()=>{
              onValueChange(2);
            }}
          >
            {buttons[2]?.name}
          </button>
        </div>
      )
    }
    {
      (buttons?.length === 2) && (
        <div className={`flex  ${(labelMarginEnable || label)?'mt-2':''} bg-white border rounded-lg border-slate-200`}>
          <button type={'button'} className={`rounded-l-lg button-group-button text-center border-slate-200 text-xs ${selected === buttons[0]?.value ?'border bg-primary rounded-lg text-white':'bg-white'} flex-1`}
            onClick={()=>{
              onValueChange(0);
            }}
          >
            {buttons[0]?.name}
          </button>
          <button type={'button'} className={`rounded-r-lg button-group-button text-center border-slate-200 text-xs ${selected === buttons[1]?.value ?' border bg-primary rounded-lg text-white':'bg-white'} flex-1`}
            onClick={()=>{
              onValueChange(1);
            }}
          >
            {buttons[1]?.name}
          </button>
        </div>
      )
    }
  </div>);
};

ButtonGroup.propTypes = {
  labelMarginEnable: PropTypes.bool,
  buttons: PropTypes.array,
  label: PropTypes.string,
  defaultSelect: PropTypes.string,
  onSelect: PropTypes.func,
  className: PropTypes.string,
  planBlock: PropTypes.bool,
  planPopupPosition: PropTypes.string,
};

export default ButtonGroup;
