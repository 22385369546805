import React, {useEffect, useRef, useState} from 'react';
import Button from '../../../components/button/button.component';
import ButtonGroup from '../../../components/button/buttonGroup.component';
import Input from '../../../components/input/input.component';
import {MEDIA_TYPE_MAPPER} from '../../../constants/widget.const';
import PropTypes from 'prop-types';
import {storeVideoAction, videoStoreClear} from '../../../actions/videoUpload.action';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import {ALLOWED_MEDIA_TYPES, MAX_FILE_SIZE, MEDIA_FILE_TYPE_MAPPER} from '../../../constants/application.const';
import {VIDEO_UPLOAD_FAILURE, VIDEO_UPLOAD_SUCCESS} from '../../../constants/reducer.const';
import UploadIcon from '../../../icons/upload.icon';
import UploadLoader from './uploadLoader.component';
import {toDynamicMetric} from '../../../helpers/common.helper';
import UploadInstructionsModal from '../modals/uploadInstructions.modal';

/**
 * Top bar for media library
 *
 * @return {JSX}
 */
const TopBar = ({onMediaFilterChange, onSearch, storageConsumedUser, storageCap})=>{
  const [mediaFilter, setMediaFilter] = useState('');
  const [uploadLoader, setUploadLoader] = useState(false);
  const [uploadInstrcutionsModal, setUploadInstrcutionsModal] = useState(false);
  const inputFileRef = useRef();
  const dispatch = useDispatch();
  const videoLibrarySelector = useSelector((state)=>state.videoLibrary);
  const [searchKeyword, setSearchKeyword] = useState('');

  // on File change capture
  const onFileChangeCapture = (event)=>{
    if (!event.target.files) return;
    const currentFile = event.target.files[0];
    if (ALLOWED_MEDIA_TYPES.indexOf(currentFile?.type) === -1) {
      return toast('Only videos, images and gifs are allowed', {
        type: 'error',
      });
    }
    if (currentFile?.size > MAX_FILE_SIZE * 1024 * 1024) {
      return toast('Maximum 35mb allowed', {
        type: 'error',
      });
    }

    const formData = new FormData();
    formData.append('video', currentFile);
    formData.append('type', MEDIA_FILE_TYPE_MAPPER[currentFile?.type]);
    // Upload video to server
    setUploadLoader(true);
    dispatch(storeVideoAction(formData));

    // clearing field after the file is uploaded
    event.target.value=null;
  };

  useEffect(()=>{
    if (videoLibrarySelector.type === VIDEO_UPLOAD_SUCCESS) {
      setUploadLoader(false);
      dispatch(videoStoreClear());
      toast(videoLibrarySelector?.payload?.message, {
        type: 'success',
      });
    }
    if (videoLibrarySelector.type === VIDEO_UPLOAD_FAILURE) {
      setUploadLoader(false);
      dispatch(videoStoreClear());
      toast(videoLibrarySelector?.payload?.message, {
        type: 'error',
      });
    }
  }, [videoLibrarySelector]);

  return (
    <div className='grid grid-cols-12 gap-4 mt-4'>
      <div className='col-span-4'>
        <Input
          placeholder={'serach media'}
          searchEnable={true}
          type={'text'}
          defaultValue={searchKeyword}
          onChange={(event)=>{
            setSearchKeyword(event?.target?.value);
            onSearch(event?.target?.value);
          }}
        />
      </div>
      <div className={'col-span-8 flex items-center justify-end'}>
        <div className="rounded-full bg-slate-300 p-2 mr-2">
          <span className='text-primary'>Storage:</span> {toDynamicMetric(storageConsumedUser)}/{toDynamicMetric(storageCap)}
        </div>
        <ButtonGroup
          label={''}
          onSelect={(value)=>{
            setMediaFilter(value);
            onMediaFilterChange(value);
          }}
          className={'mr-4'}
          defaultSelect={mediaFilter}
          buttons={[
            {
              name: MEDIA_TYPE_MAPPER.I.name,
              value: MEDIA_TYPE_MAPPER.I.value,
            },
            {
              name: 'All',
              value: '',
            },
            {
              name: MEDIA_TYPE_MAPPER.V.name,
              value: MEDIA_TYPE_MAPPER.V.value,
            },
          ]}
        />
        <Button
          buttonText='Upload'
          onClick={()=>{
            setUploadInstrcutionsModal(true);
          }}
          buttonIcon={<UploadIcon/>}
        />
      </div>
      <input type="file" accept="video/mp4,image/gif,image/png" className="hidden" ref={inputFileRef} onChange={onFileChangeCapture}/>
      {uploadLoader && <UploadLoader />}
      {uploadInstrcutionsModal && <UploadInstructionsModal
        onClose={()=>{
          setUploadInstrcutionsModal(false);
        }}
        onOk={()=>{
          inputFileRef.current.click();
          setUploadInstrcutionsModal(false);
        }}
      />}
    </div>
  );
};

TopBar.propTypes = {
  onMediaFilterChange: PropTypes.func,
  onSearch: PropTypes.func,
  storageConsumedUser: PropTypes.any,
  storageCap: PropTypes.any,
};

export default TopBar;
