import React, {useEffect, useState} from 'react';
// import {useDispatch, useSelector} from 'react-redux';
import {PLAN_TYPE_MAPPER} from '../../constants/application.const';
import Heading from '../../components/heading';
import ErrorText from '../../components/errorComponents/errorText.component';
import Input from '../../components/input/input.component';
import Select from '../../components/select/select.component';
import {addEditPlanSchema} from '../../validationSchemas/plan.validation';
import ButtonGroup from '../../components/button/buttonGroup.component';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Button from '../../components/button/button.component';
import {useNavigate, useParams} from 'react-router-dom';
import {EDIT_PLAN_ADMIN_FAILED, EDIT_PLAN_ADMIN_SUCCESS, GET_ALL_PLANS_ADMIN_SUCCESS} from '../../constants/reducer.const';
import {clearEditPlanAdmin, editPlanAdmin, getAllPlansAdmin} from '../../actions/plans.actions';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import TextArea from '../../components/textarea';

/**
 * Admin edit plans page
 *
 * @return {JSX}
 */
const AdminEditPlansPage = ()=>{
  const dispatch = useDispatch();
  const routerParams = useParams();
  const planSelector = useSelector((state)=>state?.plan);
  const {register, handleSubmit, setValue, formState: {errors}} = useForm({
    resolver: yupResolver(addEditPlanSchema),
  });
  const [planData, setPlanData] = useState({});
  const navigate = useNavigate();

  useEffect(()=>{
    if (routerParams?.id) {
      dispatch(getAllPlansAdmin({id: routerParams?.id}));
    }
  }, []);

  useEffect(()=>{
    if (planSelector?.editPlanAdminDetails?.type === EDIT_PLAN_ADMIN_SUCCESS) {
      toast(planSelector?.editPlanAdminDetails?.payload?.message, {
        type: 'success',
      });
      dispatch(clearEditPlanAdmin());
      navigate('/admin/plans');
    }
    if (planSelector?.editPlanAdminDetails?.type === EDIT_PLAN_ADMIN_FAILED) {
      toast(planSelector?.editPlanAdminDetails?.payload?.message, {
        type: 'error',
      });
      dispatch(clearEditPlanAdmin());
    }
    if (planSelector?.planAdminDetails?.type === GET_ALL_PLANS_ADMIN_SUCCESS) {
      const planDataTemp = planSelector?.planAdminDetails?.payload?.data[0]?{...planSelector?.planAdminDetails?.payload?.data[0]}:{};
      planDataTemp.storageCap = Number(planDataTemp?.storageCap)/1000000;
      planDataTemp.widgetLimit = planDataTemp?.modules.P.WIDGETS.WIDGET_LIMIT;
      planDataTemp.enableBranding = planDataTemp?.modules.P.WIDGETS.WIDGET_BRANDING;
      planDataTemp.domainLimit = planDataTemp?.modules.P.WIDGETS.BUBBLE_SETTINGS.DOMAIN_LENGTH;
      planDataTemp.enableIntegrations = planDataTemp?.modules.P.INTEGRATIONS.ZAPIER;
      setPlanData(planDataTemp);
      setValue('name', planDataTemp.name);
      setValue('description', planDataTemp.description);
      setValue('price', planDataTemp.price);
      setValue('type', planDataTemp.type);
      setValue('shopifyExclusive', planDataTemp.shopifyExclusive);
      setValue('status', planDataTemp?.auditFields?.status);
      setValue('storageCap', planDataTemp.storageCap);
      setValue('orderPrecedence', planDataTemp.orderPrecedence);
      setValue('widgetLimit', planDataTemp.widgetLimit);
      setValue('enableBranding', planDataTemp.enableBranding);
      setValue('domainLimit', planDataTemp.domainLimit);
      setValue('enableIntegrations', planDataTemp.enableIntegrations);
      setValue('impressionCount', planDataTemp.impressionCount);
    }
  }, [planSelector?.editPlanAdminDetails, planSelector?.planAdminDetails]);

  const editPlan = (value)=>{
    value.id = routerParams?.id;
    // converting mb to bytes for storing in db
    value.storageCap = value.storageCap*1000000;
    dispatch(editPlanAdmin({...value}));
  };

  return (
    <div className='px-8 pt-8 h-full overflow-y-auto'>
      <Heading className={'font-semibold text-xl'}>Edit Plan</Heading>
      <form onSubmit={handleSubmit(editPlan)} className="grid grid-cols-12 gap-4 min-w-0 p-4 bg-white rounded-lg drop-shadow-md mt-4 mb-4">
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Plan Name</span>
          <Input
            parentAdditionalClass={'mt-1'}
            type='text'
            placeholder="Plan Name"
            name="name"
            defaultValue={planData?.name}
            {...register('name')}
          />
          <ErrorText displayStatus={true}>{errors?.name?.message}</ErrorText>
        </label>
        <label className="block text-sm col-span-6 opacity-50">
          <span className="text-gray-700 ddark:text-gray-400">Plan Price($)</span>
          <Input
            parentAdditionalClass={'mt-1'}
            type='number'
            placeholder="Example: 10"
            name="price"
            defaultValue={planData?.price}
            disabled={true}
            {...register('price')}
          />
          <ErrorText displayStatus={true}>{errors?.price?.message}</ErrorText>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Storage Cap(in MB)</span>
          <Input
            parentAdditionalClass={'mt-1'}
            type='number'
            placeholder="Storage cap"
            name="storageCap"
            defaultValue={planData?.storageCap}
            {...register('storageCap')}
          />
          <ErrorText displayStatus={true}>{errors?.storageCap?.message}</ErrorText>
        </label>
        <label className="block text-sm col-span-6 pointer-events-none opacity-50">
          <span className="text-gray-700 ddark:text-gray-400">Plan Type</span>
          <Select
            defaultSelected={planData?.type}
            disabled={true}
            onChange={(event)=>{
              setValue('type', event);
            }}
            options={[
              {
                value: 'R',
                text: PLAN_TYPE_MAPPER.R,
              },
              {
                value: 'UL',
                text: PLAN_TYPE_MAPPER.UL,
              },
            ]}
          />
          <ErrorText displayStatus={true}>{errors?.type?.message}</ErrorText>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Shopify Exclusive</span>
          <div className='mt-2'>
            <ButtonGroup
              defaultSelect={planData?.shopifyExclusive?'Y':'N'}
              onSelect={(value)=>{
                setValue('shopifyExclusive', value==='Y');
              }}
              buttons={[
                {
                  name: 'Yes',
                  value: 'Y',
                },
                {
                  name: 'No',
                  value: 'N',
                },
              ]}
            />
          </div>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Active Status</span>
          <div className='mt-2'>
            <ButtonGroup
              defaultSelect={planData?.auditFields?.status || 'A'}
              onSelect={(value)=>{
                setValue('status', value);
              }}
              buttons={[
                {
                  name: 'Active',
                  value: 'A',
                },
                {
                  name: 'Block',
                  value: 'B',
                },
              ]}
            />
          </div>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Order Precedence</span>
          <Input
            parentAdditionalClass={'mt-1'}
            type='number'
            placeholder="Ex: 99"
            name="orderPrecedence"
            defaultValue={planData?.orderPrecedence}
            {...register('orderPrecedence')}
          />
          <ErrorText displayStatus={true}>{errors?.orderPrecedence?.message}</ErrorText>
        </label>

        <span className="text-gray-700 ddark:text-gray-400 font-semibold col-span-12">Module Configuration</span>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Widget limit</span>
          <Input
            parentAdditionalClass={'mt-1'}
            type='number'
            placeholder="Example: 10"
            name="widgetLimit"
            defaultValue={planData?.widgetLimit}
            {...register('widgetLimit')}
          />
          <ErrorText displayStatus={true}>{errors?.widgetLimit?.message}</ErrorText>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Domain limit</span>
          <Input
            parentAdditionalClass={'mt-1'}
            type='number'
            placeholder="Example: 10"
            name="domainLimit"
            defaultValue={planData?.domainLimit}
            {...register('domainLimit')}
          />
          <ErrorText displayStatus={true}>{errors?.domainLimit?.message}</ErrorText>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Enable Integrations</span>
          <div className='mt-2'>
            <ButtonGroup
              defaultSelect={planData?.enableIntegrations?'Y':'N'}
              onSelect={(value)=>{
                setValue('enableIntegrations', value==='Y');
              }}
              buttons={[
                {
                  name: 'Yes',
                  value: 'Y',
                },
                {
                  name: 'No',
                  value: 'N',
                },
              ]}
            />
          </div>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Enable Branding</span>
          <div className='mt-2'>
            <ButtonGroup
              defaultSelect={planData?.enableBranding?'Y':'N'}
              onSelect={(value)=>{
                setValue('enableBranding', value==='Y');
              }}
              buttons={[
                {
                  name: 'Yes',
                  value: 'Y',
                },
                {
                  name: 'No',
                  value: 'N',
                },
              ]}
            />
          </div>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Plan Type</span>
          <div className='mt-2'>
            <ButtonGroup
              defaultSelect={planData?.planType}
              onSelect={(value)=>{
                setValue('planType', value);
              }}
              buttons={[
                {
                  name: 'Normal',
                  value: 'N',
                },
                {
                  name: 'SaasZilla',
                  value: 'SZ',
                },
              ]}
            />
          </div>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Impression count</span>
          <Input
            parentAdditionalClass={'mt-1'}
            type='number'
            placeholder="Impression count"
            name="impressionCount"
            {...register('impressionCount')}
          />
          <ErrorText displayStatus={true}>{errors?.impressionCount?.message}</ErrorText>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Plan Description</span>
          <TextArea
            resize={true}
            parentAdditionalClass={'mt-1'}
            type='text'
            placeholder="Plan Description"
            name="description"
            defaultValue={planData?.description}
            {...register('description')}
          />
          <ErrorText displayStatus={true}>{errors?.description?.message}</ErrorText>
        </label>
        <div className='flex mt-8 justify-end col-span-12'>
          <Button classOverride={'flex items-center justify-center px-3 py-2 mr-2 text-sm font-medium text-purple-600 duration-150 bg-transparent border-2 border-purple-600 rounded-lg hover:border-purple-700 active:border-purple-500 focus:outline-none focus:shadow-outline-purple'} type={'button'} onClick={()=>navigate(-1)}>Back</Button>
          <Button type={'submit'}>Submit</Button>
        </div>
      </form>
    </div>
  );
};

export default AdminEditPlansPage;
