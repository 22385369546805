export const FONT_SIZE_CONST = {
  SM: 'SM',
  LG: 'LG',
  XL: 'XL',
};

export const FONT_TYPE_CONST = {
  T: 'T',
  B: 'B',
  EB: 'EB',
};

// Font size mapper
export const FONT_SIZE_MAPPER = {
  [FONT_SIZE_CONST.SM]: '18px',
  [FONT_SIZE_CONST.LG]: '24px',
  [FONT_SIZE_CONST.XL]: '30px',
};

// Font type mapper
export const FONT_TYPE_MAPPER = {
  [FONT_TYPE_CONST.T]: 'normal',
  [FONT_TYPE_CONST.B]: 'bold',
  [FONT_TYPE_CONST.EB]: 'bolder',
};

// counter direction mapper
export const COUNTER_DIRECTION_MAPPER = {
  RF: {
    value: 'RF',
    name: 'Run time forward',
  },
  RB: {
    value: 'RB',
    name: 'Run time backward',
  },
};

// counter direction mapper
export const MEDIA_POSITION_MAPPER = {
  L: {
    value: 'L',
    name: 'Left',
  },
  B: {
    value: 'B',
    name: 'Background',
  },
  R: {
    value: 'R',
    name: 'Right',
  },
};

// action types form / CTA button
export const ACTION_TYPE_MAPPER = {
  AB: {
    name: 'Action Button',
    value: 'AB',
  },
  F: {
    name: 'Form',
    value: 'F',
  },
};

// validation mappers
export const VALIDATION_MAPPER = {
  E: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  P: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
  ALL: /.*/,
};

// alignment mapper
export const ALIGNMENT_MAPPER = {
  L: 'left',
  C: 'center',
  R: 'right',
};
