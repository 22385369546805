export const PRIMARY_COLOR = '#8573F2';

// validation const
export const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const COUNTER_PATTERN = /[0-9]{2}:[0-9]{2}/;
export const MAX_TEXT_FIELD_LENGTH = 300;
export const MAX_TEXT_AREA_FIELD_LENGTH = 300;
export const MAX_LINK_FIELD_LENGTH = 300;
export const MAX_PASSWORD_FIELD_LENGTH = 30;
export const MAX_FILE_SIZE = 35; // in MB
export const ALLOWED_MEDIA_TYPES = ['video/mp4', 'image/gif', 'image/png'];
export const URL_PATTERN = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
export const URL_PATTERN_WITH_ROUTES = new RegExp('((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
'((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
'(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
'(\\#[-a-z\\d_]*)?$', 'i');
export const CALENDLY_URL_PATTERN = /(\w+)\/?(\w+)/;
export const SHOPIFY_STORE_NAME_PATTERN = new RegExp('^[a-zA-Z0-9][a-zA-Z0-9\-]*');
export const PHONE_PATTERN = new RegExp(/\+[0-9]{4,12}/, 'i');

// Application constant
let APPLICATION_URLS = {
  BASE_REACT_URL: 'http://localhost:3009/',
  BASE_URL: 'http://localhost:3009/',
  BETA_KEY: '05985e96-35cb-494b-8d05-c32101eb1de1',
  WIDGET_PREVIEW_URL: 'http://localhost:9006/',
  WIDGET_URL: {
    B: 'http://localhost:9000/',
    P: 'http://localhost:9001/',
    E: 'http://localhost:9002/',
    R: 'http://localhost:9003/',
    PS: 'http://localhost:9005/',
    BS: 'http://localhost:9007/',
  },
};

// For production
if (process.env.REACT_APP_ENV === 'prod') {
  APPLICATION_URLS = {
    BASE_REACT_URL: 'https://customer.getpopsuite.com/',
    BASE_URL: 'https://backend.getpopsuite.com/',
    BETA_KEY: 'd1373233-64b8-4760-9eec-282c2e7522a2',
    WIDGET_PREVIEW_URL: 'http://preview-widget.getpopsuite.com/',
    WIDGET_URL: {
      B: 'https://bubble.getpopsuite.com/',
      P: 'https://popup.getpopsuite.com/',
      E: 'https://embed.getpopsuite.com/',
      R: 'https://rating.getpopsuite.com/',
      PS: 'https://popup-survey.getpopsuite.com/',
      BS: 'https://bubble-survey.getpopsuite.com/',
    },
  };
}

export const BASE_REACT_URL = APPLICATION_URLS.BASE_REACT_URL;
export const BASE_URL = APPLICATION_URLS.BASE_URL;
export const WIDGET_URL = APPLICATION_URLS.WIDGET_URL;
export const BETA_KEY = APPLICATION_URLS.BETA_KEY;
export const WIDGET_PREVIEW_URL = APPLICATION_URLS.WIDGET_PREVIEW_URL;

// Local storage const
export const JWT_AUTH_KEY = 'JWT_AUTH_KEY';
export const JWT_AUTH_KEY_ADMIN = 'JWT_AUTH_KEY_ADMIN';
export const USER_DETAILS = 'USER_DETAILS';
export const USER_DETAILS_ADMIN = 'USER_DETAILS';
export const REGISTERED_USER_ID = 'REGISTERED_USER_ID';
export const PREVIEW_DATA = 'PREVIEW_DATA';

// Customer constants
export const LEAD = 'LEAD';
export const OTHER = 'OTHER';

// Media contants
export const MEDIA_TYPE_CONST = {
  I: 'I',
  V: 'V',
};

// Media file type mapper
export const MEDIA_FILE_TYPE_MAPPER = {
  'video/mp4': MEDIA_TYPE_CONST.V,
  'image/gif': MEDIA_TYPE_CONST.I,
  'image/png': MEDIA_TYPE_CONST.I,
};

// All widget type mappers
export const WIDGET_TYPE_CONST = {
  B: 'B',
  P: 'P',
  PS: 'PS',
  E: 'E',
  R: 'R',
  BS: 'BS',
};

// Live status mapper
export const liveStatusMapper = {
  UL: {
    text: 'No Live',
    value: 'UL',
    color: 'red',
  },
  L: {
    text: 'Live',
    value: 'L',
    color: 'green',
  },
};

// Integration type mapper
export const INTEGRATION_TYPE_MAPPER = {
  'M': {
    text: 'Mailchimp',
    value: 'M',
  },
  'SH': {
    text: 'Shopify',
    value: 'SH',
  },
};

// Stripe contants
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PK;

// plan type
export const PLAN_TYPE_MAPPER = {
  R: 'Recurring',
  UL: 'Life time',
};

// User type mapper
export const USER_TYPE_MAPPER = {
  U: 'User',
  DEMO: 'Demo',
};

// demo url
export const DEMO_WIDGET_URL = 'https://bubble.getpopsuite.com/deployer.js?linkId=02e6e12f-9a19-4a5f-87b9-bb6841021fc2';
