import React, {useCallback, useEffect, useState} from 'react';
import TopSideBarTemplate from '../../templates/topSideBar.template';
import Heading from '../../components/heading';
import IntegrationCard from './components/integartionCard.component';
import Shopify from '../../icons/integrations/shopify.icon';
import MailChimp from '../../icons/integrations/mailChimp.icon';
import {USER_DETAILS} from '../../constants/application.const';
import {getValue} from '../../config/localStorage.config';
import {useDispatch, useSelector} from 'react-redux';
import {DELETE_INTEGRATION_FAILED, DELETE_INTEGRATION_SUCCESS, GET_INTEGRATION_STATUS_SUCCESS, GET_INTEGRATION_SUCCESS} from '../../constants/reducer.const';
import {deleteIntegrationAction, deleteIntegrationClearAction, getIntegrationListAction, getIntegrationStatusAction} from '../../actions/integrations.actions';
import ShopifyIntegrationModal from './modals/shopifyIntegration.modal';
import MailchimpIntegrationModal from './modals/mailChimp.modal';
import {toast} from 'react-toastify';
import Klaviyo from '../../icons/integrations/kalviyo.icon';
import Pabbly from '../../icons/integrations/pabbly.icon';
import KlaviyoIntegrationModal from './modals/klaviyo.modal';
import PabblyIntegrationModal from './modals/pabbly.modal';

/**
 * Integrations page
 *
 * @return {JSX}
 */
const Integrations = ()=>{
  const [curretUserPlan, setCurrentUserPlan] = useState({});
  const [shopifyIntegrationModal, setShopifyIntegrationModal] = useState(false);
  const [mailchimpIntegrationModal, setMailchimpIntegrationModal] = useState(false);
  const [klaviyoIntegrationModal, setKlaviyoIntegrationModal] = useState(false);
  const [pabblyIntegrationModal, setPabblyIntegrationModal] = useState(false);
  const [integrationStatus, setIntegrationStatus] = useState({});
  const [integrationList, setIntegrationList] = useState([]);
  const integrationStore = useSelector((state)=>state.integration);
  const dispatch = useDispatch();

  // Get user data from local storage
  const getLocalStorageUserData = ()=>{
    const userData = getValue(USER_DETAILS);
    if (userData) {
      setCurrentUserPlan(JSON.parse(userData)?.plan);
    }
  };

  // get all integrations
  const getAllIntegrations = ()=>{
    dispatch(getIntegrationListAction());
  };

  // Get integration status
  const getIntegrationStatus = ()=>{
    dispatch(getIntegrationStatusAction({}));
  };

  // Delete integration
  const deleteIntegration = (key)=>{
    const integration = integrationList?.filter((d)=>d[key]);
    dispatch(deleteIntegrationAction({id: integration[0]?.id}));
  };

  useEffect(()=>{
    if (integrationStore?.integrationList?.type === GET_INTEGRATION_SUCCESS) {
      setIntegrationList(integrationStore?.integrationList?.payload?.data);
    }
    if (integrationStore?.integrationDelete?.type === DELETE_INTEGRATION_SUCCESS) {
      toast(integrationStore?.integrationDelete?.payload?.message, {
        type: 'success',
      });
      dispatch(deleteIntegrationClearAction());
      getLocalStorageUserData();
      getAllIntegrations();
      getIntegrationStatus();
    }
    if (integrationStore?.integrationList?.type === DELETE_INTEGRATION_FAILED) {
      dispatch(deleteIntegrationClearAction());
      toast(selector?.payload?.message, {
        type: 'error',
      });
    }
    if (integrationStore?.integrationStatus?.type === GET_INTEGRATION_STATUS_SUCCESS) {
      setIntegrationStatus(integrationStore?.integrationStatus?.payload?.data);
    }
  }, [integrationStore?.integrationList, integrationStore?.integrationStatus, integrationStore?.integrationDelete]);

  // Called initially
  useEffect(()=>{
    getLocalStorageUserData();
    getAllIntegrations();
    getIntegrationStatus();
  }, []);

  // shopify integration modal
  const ShopifyModalFormWrapper = useCallback(()=>{
    if (shopifyIntegrationModal) {
      return (
        <ShopifyIntegrationModal
          onClose={()=>{
            setShopifyIntegrationModal(false);
          }}
        />
      );
    } else {
      return (
        <></>
      );
    }
  }, [shopifyIntegrationModal]);

  // mailchimp integration modal
  const MailchimpModalFormWrapper = useCallback(()=>{
    if (mailchimpIntegrationModal) {
      return (
        <MailchimpIntegrationModal
          onClose={(refreshStatus)=>{
            setMailchimpIntegrationModal(false);
            if (refreshStatus) {
              getLocalStorageUserData();
              getAllIntegrations();
              getIntegrationStatus();
            }
          }}
        />
      );
    } else {
      return (
        <></>
      );
    }
  }, [mailchimpIntegrationModal]);

  // klaviyo integration modal
  const KlaviyoModalFormWrapper = useCallback(()=>{
    if (klaviyoIntegrationModal) {
      return (
        <KlaviyoIntegrationModal
          onClose={(refreshStatus)=>{
            setKlaviyoIntegrationModal(false);
            if (refreshStatus) {
              getLocalStorageUserData();
              getAllIntegrations();
              getIntegrationStatus();
            }
          }}
        />
      );
    } else {
      return (
        <></>
      );
    }
  }, [klaviyoIntegrationModal]);

  // pabbly integration modal
  const PabblyModalFormWrapper = useCallback(()=>{
    if (pabblyIntegrationModal) {
      return (
        <PabblyIntegrationModal
          onClose={(refreshStatus)=>{
            setPabblyIntegrationModal(false);
            if (refreshStatus) {
              getLocalStorageUserData();
              getAllIntegrations();
              getIntegrationStatus();
            }
          }}
        />
      );
    } else {
      return (
        <></>
      );
    }
  }, [pabblyIntegrationModal]);

  return (
    <TopSideBarTemplate>
      <div className='pt-4'>
        <Heading className={'font-semibold text-xl'}>Integrations</Heading>
      </div>

      {/* Integartion List */}
      <div className='max-h-screen overflow-y-auto'>
        <div className='grid md:grid-cols-2 grid-cols-1 mt-6 gap-2'>
          <IntegrationCard
            connected={Number(integrationStatus?.shopify) >0}
            planBlock={!curretUserPlan?.modules?.P?.INTEGRATIONS?.SHOPIFY}
            icon={<Shopify/>}
            heading={'Shopify'}
            description={'Add your App to your Shopify store to address and solve the unique needs and challenges of your business. Embed your App on any landing page or add as a widget.'}
            connectButtonClickHandler={()=>{
              setShopifyIntegrationModal(true);
            }}
            disconnectCallback={()=>{
              deleteIntegration('shopifyConfig');
            }}
            howToIntegrateLink={'https://popsuite.tawk.help/article/one-click-shopify-integration'}
          />
          {/* <IntegrationCard
            connected={Number(integrationStatus?.calendly) >0}
            planBlock={!curretUserPlan?.modules?.P?.INTEGRATIONS?.CALENDLY}
            icon={<Calendly
              width='100'
              height='40'
            />}
            heading={'Calendly'}
            description={'Embed Calendly on your website to streamline scheduling, increase conversion rates, and offer a seamless customer experience.'}
          />
          <IntegrationCard
            connected={Number(integrationStatus?.zapier) >0}
            planBlock={!curretUserPlan?.modules?.P?.INTEGRATIONS?.ZAPIER}
            icon={<Zapier/>}
            heading={'Zapier'}
            description={'Workflow automation for everyone. Zapier automates your work across 4000+ app integrations, so you can focus on what matters.'}
          /> */}
          <IntegrationCard
            connected={Number(integrationStatus?.mailchimp) >0}
            planBlock={!curretUserPlan?.modules?.P?.INTEGRATIONS?.MAILCHIMP}
            icon={<MailChimp/>}
            heading={'Mailchimp'}
            description={'Mailchimp is an marketing automation platform and email marketing service for managing mailing lists and creating email marketing campaigns to send to customers.'}
            connectButtonClickHandler={()=>{
              setMailchimpIntegrationModal(true);
            }}
            disconnectCallback={()=>{
              deleteIntegration('mailChimpConfig');
            }}
            howToIntegrateLink={'https://popsuite.tawk.help/article/one-click-mailchimp-integration'}
          />
          <IntegrationCard
            connected={Number(integrationStatus?.klaviyo) >0}
            planBlock={!curretUserPlan?.modules?.P?.INTEGRATIONS?.KLAVIYO}
            icon={<Klaviyo/>}
            heading={'Klaviyo'}
            description={'Klaviyo, an ecommerce marketing automation platform for email marketing and sms syncs your tech stack with your website store to scale your business.'}
            connectButtonClickHandler={()=>{
              setKlaviyoIntegrationModal(true);
            }}
            disconnectCallback={()=>{
              deleteIntegration('klaviyoConfig');
            }}
            howToIntegrateLink={'https://popsuite.tawk.help/article/one-click-mailchimp-integration'}
          />
          <IntegrationCard
            connected={Number(integrationStatus?.pabbly) >0}
            planBlock={!curretUserPlan?.modules?.P?.INTEGRATIONS?.PABBLY}
            icon={<Pabbly/>}
            heading={'Pabbly Email Automation'}
            description={'Use Pabbly to automate sending mails to captured leads.<br/>With pabbly email integration you can Segment your subscribers & target the right customer by sending relevant emails to customers'}
            connectButtonClickHandler={()=>{
              setPabblyIntegrationModal(true);
            }}
            disconnectCallback={()=>{
              deleteIntegration('pabblyConfig');
            }}
            howToIntegrateLink={'https://popsuite.tawk.help/article/one-click-mailchimp-integration'}
          />
        </div>
      </div>
      {/* Integration modals */}
      {ShopifyModalFormWrapper()}
      {MailchimpModalFormWrapper()}
      {KlaviyoModalFormWrapper()}
      {PabblyModalFormWrapper()}
    </TopSideBarTemplate>
  );
};

export default Integrations;
