import {all, call, put, takeLatest} from 'redux-saga/effects';
import {GET_REDEMPTION_CODE_FAILED, GET_REDEMPTION_CODE_REQUEST, GET_REDEMPTION_CODE_SUCCESS, UPGRADE_REDEMPTION_FAILED, UPGRADE_REDEMPTION_REQUEST, UPGRADE_REDEMPTION_SUCCESS} from '../constants/reducer.const';
import {logoutUnauthorized} from '../helpers/common.helper';
import * as redemptionService from '../services/redemption.service';

/**
 * Upgrade plan redemption
 *
 * @param {Object} action
 */
function* upgradeRedemption(action) {
  try {
    const response = yield call(redemptionService.upgradeRedemption, action.payload);
    yield put({type: UPGRADE_REDEMPTION_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: UPGRADE_REDEMPTION_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * Upgrade plan redemption (watcher)
 */
function* upgradeRedemptionWatcher() {
  yield takeLatest(UPGRADE_REDEMPTION_REQUEST, upgradeRedemption);
}

/**
 * Get redemption codes
 *
 * @param {Object} action
 */
function* getAllRedemptionCodes(action) {
  try {
    const response = yield call(redemptionService.getAllRedemptionCodes, action.payload);
    yield put({type: GET_REDEMPTION_CODE_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: GET_REDEMPTION_CODE_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * Get redemption codes (watcher)
 */
function* getAllRedemptionCodesWatcher() {
  yield takeLatest(GET_REDEMPTION_CODE_REQUEST, getAllRedemptionCodes);
}


/**
 * exporting sagas after they are initialised
 */
export default function* rootSaga() {
  yield all([
    upgradeRedemptionWatcher(),
    getAllRedemptionCodesWatcher(),
  ]);
};

