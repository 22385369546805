import React from 'react';
import PropTypes from 'prop-types';

/**
 * Upgrade icon
 *
 * @return {JSX}
 */
function UpgradeIcon({color, height, width, ...props}) {
  return (
    <svg height={height || '32px'} viewBox="0 0 32 32" width={width || '32px'} fill={'currentColor'} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M17.6703 2.66699C18.0003 2.66699 18.2776 2.94699 18.2776 3.28033V7.57366C18.2776 10.0137 20.2578 12.0137 22.6868 12.027C23.6769 12.027 24.469 12.0403 25.0762 12.0403C25.4855 12.0403 26.1587 12.027 26.7264 12.027C27.0564 12.027 27.3337 12.2937 27.3337 12.627V23.347C27.3337 26.6537 24.667 29.3337 21.3931 29.3337H10.898C7.45247 29.3337 4.66699 26.5203 4.66699 23.0537V8.68033C4.66699 5.37366 7.32046 2.66699 10.6208 2.66699H17.6703ZM15.5185 11.6537C15.3865 11.6537 15.2545 11.6803 15.1356 11.7337C15.0168 11.787 14.9112 11.8537 14.8188 11.947L11.0432 15.787C10.6604 16.1737 10.6604 16.8003 11.0432 17.187C11.4261 17.5737 12.0465 17.5737 12.4294 17.187L14.5284 15.0537V21.4937C14.5284 22.0403 14.964 22.4803 15.5185 22.4803C16.0597 22.4803 16.4954 22.0403 16.4954 21.4937V15.0537L18.5944 17.187C18.9772 17.5737 19.5977 17.5737 19.9805 17.187C20.3766 16.8003 20.3766 16.1737 19.9937 15.787L16.2049 11.947C16.1125 11.8537 16.0069 11.787 15.8881 11.7337C15.7693 11.6803 15.6505 11.6537 15.5185 11.6537ZM20.194 3.87499C20.194 3.30033 20.8831 3.01499 21.2778 3.42966C22.7062 4.92833 25.1999 7.54832 26.594 9.01232C26.9781 9.41632 26.6956 10.087 26.1399 10.0883C25.0547 10.0923 23.7768 10.0883 22.8567 10.079C21.3966 10.079 20.194 8.86433 20.194 7.38966V3.87499Z"/>
    </svg>
  );
}

UpgradeIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};


export default UpgradeIcon;
