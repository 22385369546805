import React, {useEffect} from 'react';
import Heading from '../../../components/heading';
import CloseIcon from '../../../icons/close.icon';
import PropTypes from 'prop-types';
import Input from '../../../components/input/input.component';
import Button from '../../../components/button/button.component';
import {useForm} from 'react-hook-form';
import ErrorText from '../../../components/errorComponents/errorText.component';
import {PabblyIntegrationDetails} from '../../../validationSchemas/integration.validation';
import {yupResolver} from '@hookform/resolvers/yup';
import {addIntegrationAction, clearIntegrationAction} from '../../../actions/integrations.actions';
import {useDispatch, useSelector} from 'react-redux';
import {ADD_INTEGRATION_FAILED, ADD_INTEGRATION_SUCCESS} from '../../../constants/reducer.const';
import {toast} from 'react-toastify';

/**
 * Pabbly integration modal
 *
 * @return {JSX}
 */
const PabblyIntegrationModal = ({onClose})=> {
  const {register, handleSubmit, formState: {errors}} = useForm({
    resolver: yupResolver(PabblyIntegrationDetails),
  });
  const integrationStore = useSelector((state)=>state.integration);
  const dispatch = useDispatch();

  // handling on connect
  const onConnect = (values)=>{
    dispatch(addIntegrationAction({
      apiKey: values?.apiKey,
      type: 'PABBLY',
    }));
  };

  useEffect(()=>{
    if (integrationStore?.integration?.type === ADD_INTEGRATION_SUCCESS) {
      dispatch(clearIntegrationAction());
      toast(integrationStore?.integration?.payload?.message, {
        type: 'success',
      });
      onClose(true);
    }

    if (integrationStore?.integration?.type === ADD_INTEGRATION_FAILED) {
      dispatch(clearIntegrationAction());
      toast(integrationStore?.integration?.payload?.message, {
        type: 'error',
      });
    }
  }, [integrationStore?.integration]);

  return (
    <>
      <div className="fixed inset-0 bg-stone-900 bg-opacity-75 transition-opacity z-50" aria-hidden="true"></div>
      <form onSubmit={handleSubmit(onConnect)} className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white w-1/4 p-4 rounded-lg'>
        <Heading className='font-semibold text-lg flex justify-between  mt-4'>
          Integrate Pabbly
          <CloseIcon className='cursor-pointer w-4 h-4' onClick={()=>{
            onClose();
          }}/>
        </Heading>
        <div id="input-container">
          <label className='text-sm font-normal mt-4 flex items-center mb-1'>Enter your API key(Bearer Token)</label>
          <Input
            type="text"
            placeholder="Ex: YsCVGTUa44do"
            errorStatus={errors?.apiKey?.message}
            {...register('apiKey')}
          />
          <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errors?.apiKey?.message}</ErrorText>
        </div>
        <div className='flex mt-8 justify-end'>
          <Button type="submit">Connect</Button>
        </div>
      </form>
    </>
  );
};

PabblyIntegrationModal.propTypes = {
  onClose: PropTypes.func,
};

export default PabblyIntegrationModal;
