import React from 'react';
import WelcomeGif from '../../../../assets/images/welcome.gif';
import Button from '../../../../components/button/button.component';
import CloseIcon from '../../../../icons/close.icon';
import Proptypes from 'prop-types';

/**
 * Welcome modal
 *
 * @return {JSX}
 */
const WelcomeModal = ({close})=>{
  return (
    <>
      <div className="fixed inset-0 bg-stone-900 bg-opacity-75 transition-opacity z-50" aria-hidden="true"></div>
      <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white w-1/2 rounded-lg'>
        <div className='flex justify-center items-center py-8'>
          <img src={WelcomeGif} alt="popsuite" className='max-w-none' style={{width: '24rem'}}/>
          <CloseIcon className='cursor-pointer w-4 h-4 absolute right-4 top-4' onClick={()=>{
            close();
          }}/>
        </div>
        <div className='bg-primary text-white py-4 flex justify-center flex-col rounded-b-lg px-4'>
          <p className='mt-2 text-md text-center'>
            Greet your website visitor with our flagship widget Video Bubble and start building email lists with in-build Form builder.
          </p>
          <div className='flex justify-center mt-8'>
            <Button
              onClick={()=>{
                close();
              }}
              classOverride={`
                flex items-center justify-center px-4 py-2 mr-2 text-lg font-bold text-primary duration-150 bg-white border border-transparent rounded-lg hover:bg-white active:bg-white focus:outline-none focus:shadow-outline-white
              `}
            >Get Started</Button>
            <Button
              onClick={()=>{
                window.open(
                    'https://popsuite.tawk.help/',
                );
              }}
              classOverride={`
                flex items-center justify-center px-4 py-2 text-lg font-bold text-primary duration-150 bg-white border border-transparent rounded-lg hover:bg-white active:bg-white focus:outline-none focus:shadow-outline-white
              `}
            >Watch the Tutorials</Button>
          </div>
        </div>
      </div>
    </>
  );
};

WelcomeModal.propTypes = {
  close: Proptypes.func,
};

export default WelcomeModal;
