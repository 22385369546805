import React from 'react';

// User icon
const UserIcon = ({...props})=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 48 48" fill={'currentColor'}><path d="M1.9 40V35.3Q1.9 33.55 2.8 32.125Q3.7 30.7 5.3 30Q8.95 28.4 11.875 27.7Q14.8 27 17.9 27Q21 27 23.9 27.7Q26.8 28.4 30.45 30Q32.05 30.7 32.975 32.125Q33.9 33.55 33.9 35.3V40ZM31.1 26.85Q34.55 27.25 37.6 28.025Q40.65 28.8 42.55 29.8Q44.2 30.75 45.15 32.15Q46.1 33.55 46.1 35.3V40H36.9V35.3Q36.9 32.15 35.3 30.125Q33.7 28.1 31.1 26.85ZM17.9 23.95Q14.6 23.95 12.5 21.85Q10.4 19.75 10.4 16.45Q10.4 13.15 12.5 11.05Q14.6 8.95 17.9 8.95Q21.2 8.95 23.3 11.05Q25.4 13.15 25.4 16.45Q25.4 19.75 23.3 21.85Q21.2 23.95 17.9 23.95ZM35.9 16.45Q35.9 19.75 33.8 21.85Q31.7 23.95 28.4 23.95Q27.85 23.95 27.175 23.875Q26.5 23.8 25.95 23.6Q27.15 22.35 27.775 20.525Q28.4 18.7 28.4 16.45Q28.4 14.2 27.775 12.475Q27.15 10.75 25.95 9.3Q26.5 9.15 27.175 9.05Q27.85 8.95 28.4 8.95Q31.7 8.95 33.8 11.05Q35.9 13.15 35.9 16.45ZM4.9 37H30.9V35.3Q30.9 34.5 30.425 33.75Q29.95 33 29.25 32.7Q25.65 31.1 23.2 30.55Q20.75 30 17.9 30Q15.05 30 12.575 30.55Q10.1 31.1 6.5 32.7Q5.8 33 5.35 33.75Q4.9 34.5 4.9 35.3ZM17.9 20.95Q19.85 20.95 21.125 19.675Q22.4 18.4 22.4 16.45Q22.4 14.5 21.125 13.225Q19.85 11.95 17.9 11.95Q15.95 11.95 14.675 13.225Q13.4 14.5 13.4 16.45Q13.4 18.4 14.675 19.675Q15.95 20.95 17.9 20.95ZM17.9 16.45Q17.9 16.45 17.9 16.45Q17.9 16.45 17.9 16.45Q17.9 16.45 17.9 16.45Q17.9 16.45 17.9 16.45Q17.9 16.45 17.9 16.45Q17.9 16.45 17.9 16.45Q17.9 16.45 17.9 16.45Q17.9 16.45 17.9 16.45ZM17.9 30Q17.9 30 17.9 30Q17.9 30 17.9 30Q17.9 30 17.9 30Q17.9 30 17.9 30Q17.9 30 17.9 30Q17.9 30 17.9 30Q17.9 30 17.9 30Q17.9 30 17.9 30Z"/></svg>
  );
};

export default UserIcon;
