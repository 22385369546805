import React, {useEffect, useRef, useState} from 'react';
import Button from '../../../components/button/button.component';
import BackIcon from '../../../icons/back.icon';
// import CloseIcon from '../../../icons/close.icon';
import WidgetName from './components/widgetName.component';
import {useNavigate, useParams} from 'react-router-dom';
import PopupSettings from './components/popupSettings.component';
import PopupWidgetComponent from '../../../popupWidgetComponent';
import ContentSettings from './components/contentSettings.component';
import ActionSettings from './components/actionSettings.component';
import {useDispatch, useSelector} from 'react-redux';
import {addWidgetPopup} from '../../../validationSchemas/widget.validation';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {addUpdateWidgetPreviewAction, addWidgetAction, clearWidget, clearWidgetPreviewSettings, clearWidgetSettings, updateWidgetAction, updateWidgetSettings} from '../../../actions/widget.actions';
import {ADD_UPDATE_WIDGET_PREVIEW_FAILED, ADD_UPDATE_WIDGET_PREVIEW_SUCCESS, ADD_WIDGET_FAILED, ADD_WIDGET_SUCCESS, UPDATE_WIDGET_FAILED, UPDATE_WIDGET_SUCCESS} from '../../../constants/reducer.const';
import {toast} from 'react-toastify';
import {WIDGET_PREVIEW_URL, WIDGET_TYPE_CONST, WIDGET_URL} from '../../../constants/application.const';
import EmbedWidgetComponent from '../../../embedWidgetComponent';
import Bubble from '../../../bubbleComponent';
import BubbleSurvey from '../../../bubbleSurveyComponent';
import Heading from '../../../components/heading';
import {WIDGET_TYPE_NAME_MAPPER} from '../../../constants/widget.const';
import ReviewWidgetComponent from '../../../reviewWidgetComponent';
import EntryNotificationSettings from './components/entryNotificationSettings.component';
import PopupSurveyWidgetComponent from '../../../popupSurveyWidgetComponent';
import QuestionSettings from './components/questionSettings.component';
import PreviewIcon from '../../../icons/preview.icon';
import PreviewInputModal from './modals/previewInput.modal';
import SaveIcon from '../../../icons/save.icon';

/**
 * Add widget page
 *
 * @return {JSX}
 */
function AddWidget() {
  const routerParams = useParams();
  const widgetStore = useSelector((state)=>state.widget);
  const [widgetSubmitLoader, setWidgetSubmitLoader] = useState(false);
  const [widgetConfig, setWidgetConfig] = useState({});
  /**
   * Selected widget will only contain the linkId while creating a new widget
   */
  const [selectedWidget, setSelectedWidget] = useState({});
  const [previewWindowInputToggle, setPreviewWindowInputToggle] = useState(false);
  const [operationType, setOperationType] = useState('A');
  const {register, handleSubmit, setValue, formState: {errors}} = useForm({
    resolver: yupResolver(addWidgetPopup),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const widgetFormRef = useRef();

  useEffect(()=>{
    if (widgetStore?.widgetSettings) {
      setSelectedWidget(widgetStore?.selectedWidget?.payload);
      setWidgetConfig(widgetStore?.widgetSettings);
    }

    if (widgetStore?.widget?.type === ADD_WIDGET_SUCCESS) {
      setWidgetSubmitLoader(false);
      dispatch(clearWidgetSettings());
      toast(widgetStore?.widget?.payload?.message, {
        type: 'success',
      });
      navigate(`/widgets?publishOpen=o`);
    }

    if (widgetStore?.widgetPreview?.type === ADD_UPDATE_WIDGET_PREVIEW_SUCCESS) {
      setSelectedWidget({
        linkId: widgetStore?.widgetPreview?.payload?.data?.linkId,
      });
      previewSubmit(widgetStore?.widgetPreview?.payload?.data?.linkId);
      setWidgetSubmitLoader(false);
      dispatch(clearWidgetPreviewSettings());
    }

    if (widgetStore?.widgetPreview?.type === ADD_UPDATE_WIDGET_PREVIEW_FAILED) {
      setWidgetSubmitLoader(false);
      dispatch(clearWidgetPreviewSettings());
    }

    if (widgetStore?.widget?.type === ADD_WIDGET_FAILED) {
      setWidgetSubmitLoader(false);
      dispatch(clearWidgetSettings());
      dispatch(clearWidget());
      toast(widgetStore?.widget?.payload?.message, {
        type: 'error',
      });
    }

    if (widgetStore?.widget?.type === UPDATE_WIDGET_SUCCESS) {
      setWidgetSubmitLoader(false);
      dispatch(clearWidgetSettings());
      toast(widgetStore?.widget?.payload?.message, {
        type: 'success',
      });
      navigate('/widgets');
    }

    if (widgetStore?.widget?.type === UPDATE_WIDGET_FAILED) {
      setWidgetSubmitLoader(false);
      dispatch(clearWidgetSettings());
      toast(widgetStore?.widget?.payload?.message, {
        type: 'error',
      });
    }
  }, [widgetStore?.widgetSettings, widgetStore?.widget, widgetStore?.widgetPreview]);

  // Add/Edit widget
  const addEditWidgetSubmit = ()=>{
    const payload = {
      ...widgetConfig,
      widgetType: routerParams?.type,
      video: widgetConfig?.videoDetails?.id,
    };
    if (operationType === 'A') {
      setWidgetSubmitLoader(true);
      dispatch(addWidgetAction(payload));
    }
    if (operationType === 'U') {
      payload['widgetId'] = routerParams?.id;
      setWidgetSubmitLoader(true);
      dispatch(updateWidgetAction(payload));
    }
  };

  // Save for preview
  const saveForPreview = ()=>{
    const payload = {
      ...widgetConfig,
      id: selectedWidget?.id,
      linkId: widgetStore?.selectedWidget?.payload?.originalLinkId,
      widgetType: routerParams?.type,
      video: widgetConfig?.videoDetails?.id,
    };
    dispatch(addUpdateWidgetPreviewAction(payload));
  };

  // called when widget id is changed or updated
  useEffect(()=>{
    if (routerParams?.id) {
      setOperationType('U');
    }
  }, [routerParams?.id]);

  // Preview submit
  const previewSubmit = (linkId)=>{
    const widgetUrl = `${WIDGET_URL[routerParams?.type]}deployer.js?linkId=${linkId}&preview=1`;
    window.open(`${WIDGET_PREVIEW_URL}?widget_url=${widgetUrl}`, '_newtab');
  };

  return (
    <div className="pl-5 overflow-hidden rounded-xl">
      <nav className='flex flex-row justify-between'>
        {/* <Button
          classOverride={'bg-transparent border-0 flex items-center text-sm'}
          buttonIcon={<BackIcon height={'15'} width={'15'}/>}
          onClick={()=>navigate(-1)}
        >
            Back
        </Button> */}
        <div className={'text-purple-500 hover:text-white flex'}>
          {/* <Button
            classOverride={'flex items-center justify-center px-2 py-1 text-sm font-medium text-purple-500 duration-150 bg-transparent border border-2 border-purple-500 rounded-lg hover:text-white hover:bg-purple-500 active:bg-purple-500 focus:outline-none focus:shadow-outline-purple mr-20 hidden'}
            buttonIcon={widgetSubmitLoader?<></>:<StarIcon/>}
            loader={widgetSubmitLoader}
            onClick={()=>{
              setWidgetSubmitLoader(true);
              widgetFormRef?.current?.dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}));
            }}
          >
              Save
          </Button> */}
          {/* <Button
            buttonIcon={<PreviewIcon/>}
            onClick={()=>{
              saveForPreview();
              setPreviewWindowInputToggle(true);
            }}
          >
              Preview
          </Button> */}
        </div>
        {/* <CloseIcon
          height={'15'}
          width={'15'}
          className={'cursor-pointer'}
          onClick={()=>navigate('/widgets')}
        /> */}
      </nav>
      <div className='grid grid-cols-6 overflow-hidden h-screen py-5'>
        <form onKeyDown={(event)=>{
          if (event.target.keyCode === 13 || event?.key === 'Enter') {
            event.preventDefault();
          }
        }} onSubmit={handleSubmit(addEditWidgetSubmit)} className='col-span-2 max-h-screen overflow-scroll px-4 bg-white rounded-xl' ref={widgetFormRef}>
          <div className='absolute bg-white py-2 z-50' style={{'width': '410px'}}>
            <Button
              classOverride={'bg-transparent border-0 flex items-center text-md mb-4 mt-4'}
              buttonIcon={<BackIcon height={'20'} width={'20'}/>}
              onClick={()=>navigate(-1)}
            >
              Back
            </Button>
          </div>
          <WidgetName
            register={register}
            errors={errors}
          />
          <PopupSettings
            className="mt-8"
            register={register}
            errors={errors}
            setValue={setValue}
            widgetType={routerParams?.type}
          />
          <ContentSettings
            className={'mt-4'}
            register={register}
            errors={errors}
            setValue={setValue}
            widgetType={routerParams?.type}
          />
          {(routerParams?.type === 'PS' || routerParams?.type === 'BS') && <QuestionSettings
            className={'mt-4'}
            register={register}
            errors={errors}
            setValue={setValue}
            widgetType={routerParams?.type}
          />}
          <div
            onMouseEnter={()=>{
              dispatch(updateWidgetSettings({
                bubbleWidgetToggle: true,
              }));
            }}
          >
            <ActionSettings
              className={'mt-4'}
              register={register}
              errors={errors}
              setValue={setValue}
              widgetType={routerParams?.type}
            />
            <EntryNotificationSettings
              className={'my-4'}
              register={register}
              errors={errors}
              setValue={setValue}
              widgetType={routerParams?.type}
            />
          </div>
          {/* Submit button */}
          <Button
            className={'mt-4 w-full mb-8 hidden'}
            type={'submit'}
            loader={widgetSubmitLoader}
          >{operationType === 'A'?'Add':'Update'} widget</Button>
        </form>
        <div className='col-span-4'>
          <div className='flex grid grid-cols-3 mt-4'>
            <div></div>
            <div className='flex items-center justify-center'>
              <Heading className={`text-center font-bold text-lg text-${WIDGET_TYPE_NAME_MAPPER[routerParams?.type]?.color}-500`}>
                {WIDGET_TYPE_NAME_MAPPER[routerParams?.type]?.value}
              </Heading>
            </div>
            <div className='flex justify-end items-center'>
              <Button
                classOverride={'flex items-center justify-center px-2 py-1 text-sm font-medium text-purple-500 duration-150 bg-transparent border border-2 border-purple-500 rounded-lg hover:text-white hover:bg-purple-500 active:bg-purple-500 focus:outline-none focus:shadow-outline-purple mr-2'}
                buttonIcon={widgetSubmitLoader?<></>:<PreviewIcon />}
                loader={widgetSubmitLoader}
                onClick={()=>{
                  saveForPreview();
                  // setPreviewWindowInputToggle(true);
                }}
              >
                Preview
              </Button>
              <Button
                classOverride={'flex items-center justify-center px-2 py-1 text-sm font-medium text-purple-500 duration-150 bg-transparent border border-2 border-purple-500 rounded-lg hover:text-white hover:bg-purple-500 active:bg-purple-500 focus:outline-none focus:shadow-outline-purple mr-20'}
                buttonIcon={widgetSubmitLoader?<></>:<SaveIcon/>}
                loader={widgetSubmitLoader}
                onClick={()=>{
                  setWidgetSubmitLoader(true);
                  widgetFormRef?.current?.dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}));
                }}
              >
              Save
              </Button>
            </div>
          </div>
          <div className='relative flex justify-center items-center h-full'>
            {routerParams?.type === WIDGET_TYPE_CONST.P &&
            <PopupWidgetComponent
              mode='dev'
              popupSize={widgetConfig?.popupSize}
              headingText={widgetConfig?.headingText}
              headingConfig={widgetConfig?.headingConfig}
              subHeadingText={widgetConfig?.subHeadingText}
              subHeadingConfig={widgetConfig?.subHeadingConfig}
              mediaDetails={widgetConfig?.videoDetails}
              counterStatus={widgetConfig?.counterStatus}
              counterConfig={widgetConfig?.counterConfig}
              mediaPosition={widgetConfig?.mediaPosition}
              actionButtonName={widgetConfig?.actionButtonName}
              actionButtonLink={widgetConfig?.actionButtonLink}
              actionButtonConfig={widgetConfig?.actionButtonConfig}
              actionType={widgetConfig?.actionType}
              fieldConfig={widgetConfig?.fieldConfig}
              displayPreference={widgetConfig?.displayPreference}
              displayPreferenceConfig={widgetConfig?.displayPreferenceConfig}
              popupPosition={widgetConfig?.popupPosition}
              pages={widgetConfig?.pages}
            />
            }
            {routerParams?.type === WIDGET_TYPE_CONST.PS &&
            <PopupSurveyWidgetComponent
              mode='dev'
              popupSize={widgetConfig?.popupSize}
              headingText={widgetConfig?.headingText}
              headingConfig={widgetConfig?.headingConfig}
              subHeadingText={widgetConfig?.subHeadingText}
              subHeadingConfig={widgetConfig?.subHeadingConfig}
              mediaDetails={widgetConfig?.videoDetails}
              logoDetails={widgetConfig?.logoDetails}
              counterStatus={widgetConfig?.counterStatus}
              counterConfig={widgetConfig?.counterConfig}
              mediaPosition={widgetConfig?.mediaPosition}
              actionButtonName={widgetConfig?.actionButtonName}
              actionButtonConfig={widgetConfig?.actionButtonConfig}
              actionType={widgetConfig?.actionType}
              fieldConfig={widgetConfig?.fieldConfig}
              displayPreference={widgetConfig?.displayPreference}
              displayPreferenceConfig={widgetConfig?.displayPreferenceConfig}
              popupPosition={widgetConfig?.popupPosition}
              surveyQuestionSettings={widgetConfig?.surveyQuestionSettings}
              surveyQuestionAnswerType={widgetConfig?.surveyQuestionAnswerType}
              pages={widgetConfig?.pages}
              couponCodeNotification={widgetConfig?.couponConfigNotification}
              couponCodeNotificationCode={widgetConfig?.couponCodeNotification}
              couponDisplayNotification={widgetConfig?.couponDisplayNotification}
              entrySuccessConfig={widgetConfig?.entrySuccessConfig}
              entryPopupSuccessText={widgetConfig?.entryPopupSuccessText}
              entryFailConfig={widgetConfig?.entryFailConfig}
              entryPopupFailText={widgetConfig?.entryPopupFailText}
              branding={widgetConfig?.branding}
              toggleStatusMessageSuccess={widgetConfig?.toggleStatusMessageSuccess}
              toggleStatusMessageFail={widgetConfig?.toggleStatusMessageFail}
              ctaButtonNotificationEnable={widgetConfig?.ctaButtonNotificationEnable}
              ctaButtonNotificationName={widgetConfig?.ctaButtonNotificationName}
              ctaButtonNotificationLink={widgetConfig?.ctaButtonNotificationLink}
              ctaButtonNotificationConfig={widgetConfig?.ctaButtonNotificationConfig}
            />
            }
            {routerParams?.type === WIDGET_TYPE_CONST.E &&
            <EmbedWidgetComponent
              mode='dev'
              popupSize={widgetConfig?.popupSize}
              headingText={widgetConfig?.headingText}
              headingConfig={widgetConfig?.headingConfig}
              subHeadingText={widgetConfig?.subHeadingText}
              subHeadingConfig={widgetConfig?.subHeadingConfig}
              mediaDetails={widgetConfig?.videoDetails}
              counterStatus={widgetConfig?.counterStatus}
              counterConfig={widgetConfig?.counterConfig}
              mediaPosition={widgetConfig?.mediaPosition}
              actionButtonName={widgetConfig?.actionButtonName}
              actionButtonLink={widgetConfig?.actionButtonLink}
              actionButtonConfig={widgetConfig?.actionButtonConfig}
              actionType={widgetConfig?.actionType}
              fieldConfig={widgetConfig?.fieldConfig}
              pages={widgetConfig?.pages}
            />
            }
            {routerParams?.type === WIDGET_TYPE_CONST.B && <Bubble
              mode='dev'
              styleType={widgetConfig?.bubbleType}
              size={widgetConfig?.popupSize}
              title={widgetConfig?.titleText}
              borderColor={widgetConfig?.bubbleBorderColor}
              headingConfig={widgetConfig?.headingConfig}
              headerText={widgetConfig?.bubbleActionHeading}
              headerTextConfig={widgetConfig?.bubbleActionHeadingConfig}
              mediaDetails={widgetConfig?.videoDetails}
              counterStatus={widgetConfig?.counterStatus}
              counterConfig={widgetConfig?.counterConfig}
              mediaPosition={widgetConfig?.mediaPosition}
              actionButtonName={widgetConfig?.actionButtonName}
              actionButtonLink={widgetConfig?.actionButtonLink}
              actionButtonConfig={widgetConfig?.actionButtonConfig}
              actionType={widgetConfig?.actionType}
              fieldConfig={widgetConfig?.fieldConfig}
              widgetOpenStatus={widgetConfig?.bubbleWidgetToggle}
              pages={widgetConfig?.pages}
              couponConfig={widgetConfig?.couponConfig}
              couponCode={widgetConfig?.couponCode}
              gradiantConfig={widgetConfig?.gradiantConfig}
              couponDisplay={widgetConfig?.couponDisplay}
              couponCodeNotification={widgetConfig?.couponConfigNotification}
              couponCodeNotificationCode={widgetConfig?.couponCodeNotification}
              couponDisplayNotification={widgetConfig?.couponDisplayNotification}
              entrySuccessConfig={widgetConfig?.entrySuccessConfig}
              entryPopupSuccessText={widgetConfig?.entryPopupSuccessText}
              entryFailConfig={widgetConfig?.entryFailConfig}
              entryPopupFailText={widgetConfig?.entryPopupFailText}
              branding={widgetConfig?.branding}
              toggleStatusMessageSuccess={widgetConfig?.toggleStatusMessageSuccess}
              toggleStatusMessageFail={widgetConfig?.toggleStatusMessageFail}
              ctaButtonNotificationEnable={widgetConfig?.ctaButtonNotificationEnable}
              ctaButtonNotificationName={widgetConfig?.ctaButtonNotificationName}
              ctaButtonNotificationLink={widgetConfig?.ctaButtonNotificationLink}
              ctaButtonNotificationConfig={widgetConfig?.ctaButtonNotificationConfig}
            />}
            {routerParams?.type === WIDGET_TYPE_CONST.BS && <BubbleSurvey
              mode='dev'
              styleType={widgetConfig?.bubbleType}
              size={widgetConfig?.popupSize}
              title={widgetConfig?.titleText}
              borderColor={widgetConfig?.bubbleBorderColor}
              headingConfig={widgetConfig?.headingConfig}
              headerText={widgetConfig?.bubbleActionHeading}
              headerTextConfig={widgetConfig?.bubbleActionHeadingConfig}
              mediaDetails={widgetConfig?.videoDetails}
              counterStatus={widgetConfig?.counterStatus}
              counterConfig={widgetConfig?.counterConfig}
              mediaPosition={widgetConfig?.mediaPosition}
              actionButtonName={widgetConfig?.actionButtonName}
              actionButtonLink={widgetConfig?.actionButtonLink}
              actionButtonConfig={widgetConfig?.actionButtonConfig}
              actionType={widgetConfig?.actionType}
              fieldConfig={widgetConfig?.fieldConfig}
              widgetOpenStatus={widgetConfig?.bubbleWidgetToggle}
              pages={widgetConfig?.pages}
              couponConfig={widgetConfig?.couponConfig}
              couponCode={widgetConfig?.couponCode}
              gradiantConfig={widgetConfig?.gradiantConfig}
              couponDisplay={widgetConfig?.couponDisplay}
              couponCodeNotification={widgetConfig?.couponConfigNotification}
              couponCodeNotificationCode={widgetConfig?.couponCodeNotification}
              couponDisplayNotification={widgetConfig?.couponDisplayNotification}
              entrySuccessConfig={widgetConfig?.entrySuccessConfig}
              entryPopupSuccessText={widgetConfig?.entryPopupSuccessText}
              entryFailConfig={widgetConfig?.entryFailConfig}
              entryPopupFailText={widgetConfig?.entryPopupFailText}
              branding={widgetConfig?.branding}
              toggleStatusMessageSuccess={widgetConfig?.toggleStatusMessageSuccess}
              toggleStatusMessageFail={widgetConfig?.toggleStatusMessageFail}
              ctaButtonNotificationEnable={widgetConfig?.ctaButtonNotificationEnable}
              ctaButtonNotificationName={widgetConfig?.ctaButtonNotificationName}
              ctaButtonNotificationLink={widgetConfig?.ctaButtonNotificationLink}
              ctaButtonNotificationConfig={widgetConfig?.ctaButtonNotificationConfig}
              surveyQuestionSettings={widgetConfig?.surveyQuestionSettings}
              surveyQuestionAnswerType={widgetConfig?.surveyQuestionAnswerType}
            />}
            {routerParams?.type === WIDGET_TYPE_CONST.R && <ReviewWidgetComponent
              mode='dev'
              popupSize={widgetConfig?.popupSize}
              headingText={widgetConfig?.headingText}
              headingConfig={widgetConfig?.headingConfig}
              actionButtonName={widgetConfig?.actionButtonName}
              actionButtonLink={widgetConfig?.actionButtonLink}
              actionButtonConfig={widgetConfig?.actionButtonConfig}
              backgroundColor={widgetConfig?.reviewPopupBackgroundColor}
              starColor={widgetConfig?.starColor}
              displayPreference={widgetConfig?.displayPreference}
              displayPreferenceConfig={widgetConfig?.displayPreferenceConfig}
              pages={widgetConfig?.pages}
              fieldConfig={widgetConfig?.fieldConfig}
            />}
          </div>
        </div>
      </div>
      {/* Preview window toggle */}
      {previewWindowInputToggle && <PreviewInputModal
        widgetType={routerParams?.type}
        widgetDetails={selectedWidget}
        onClose={()=>{
          setPreviewWindowInputToggle(false);
        }}
      />}
    </div>
  );
}

export default AddWidget;
