import React from 'react';
import PropTypes from 'prop-types';

/**
 * Play icon
 *
 * @return {JSX}
 */
const MediaIcon = ({height, width, color, ...props})=>{
  return (
    <svg width={width || '32'} height={height || '32'} fill={color || 'currentColor'} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M15.8738 6C19.1032 6 21.3578 8.22538 21.3578 11.4146V20.5854C21.3578 23.7746 19.1032 26 15.8738 26H8.15105C4.92168 26 2.66699 23.7746 2.66699 20.5854V11.4146C2.66699 8.22538 4.92168 6 8.15105 6H15.8738ZM26.611 9.17198C27.1963 8.87418 27.883 8.90531 28.4416 9.25726C29.0003 9.60785 29.3337 10.217 29.3337 10.883V21.1178C29.3337 21.7852 29.0003 22.393 28.4416 22.7436C28.1363 22.9344 27.795 23.0319 27.451 23.0319C27.1643 23.0319 26.8776 22.9642 26.6096 22.8275L24.6349 21.8312C23.9043 21.4603 23.4509 20.7158 23.4509 19.8887V12.1107C23.4509 11.2823 23.9043 10.5378 24.6349 10.1696L26.611 9.17198Z"/>
    </svg>
  );
};

MediaIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};


export default MediaIcon;

