import React from 'react';

/**
 * Collapse Down icon
 *
 * @return {JSX}
 */
const CollapseDownIcon = ({...props})=>{
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" {...props} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.6805 19.4029C12.6128 19.337 12.3235 19.0881 12.0855 18.8562C10.5887 17.4969 8.13867 13.9509 7.39083 12.0949C7.27067 11.813 7.01633 11.1004 7 10.7197C7 10.3548 7.084 10.0071 7.25433 9.67519C7.49233 9.26149 7.86683 8.92962 8.309 8.74777C8.61583 8.6307 9.534 8.44886 9.55033 8.44886C10.5548 8.26701 12.187 8.16699 13.9907 8.16699C15.7092 8.16699 17.2748 8.26701 18.2945 8.4159C18.3108 8.43294 19.4518 8.61479 19.8427 8.81369C20.5567 9.17852 21 9.89114 21 10.6538V10.7197C20.9825 11.2164 20.5392 12.2608 20.5228 12.2608C19.7738 14.0168 17.444 17.481 15.8958 18.8733C15.8958 18.8733 15.498 19.2654 15.2495 19.4359C14.8925 19.7018 14.4503 19.8337 14.0082 19.8337C13.5147 19.8337 13.055 19.6848 12.6805 19.4029Z" fill="white"/>
    </svg>
  );
};

export default CollapseDownIcon;

