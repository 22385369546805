import {all, call, put, takeLatest} from 'redux-saga/effects';
import {ADD_WIDGET_SUCCESS, ADD_WIDGET_FAILED, ADD_WIDGET_REQUEST, GET_WIDGET_SUCCESS, GET_WIDGET_FAILED, GET_WIDGET_REQUEST, DELETE_WIDGET_SUCCESS, DELETE_WIDGET_FAILED, DELETE_WIDGET_REQUEST, UPDATE_WIDGET_SUCCESS, UPDATE_WIDGET_FAILED, UPDATE_WIDGET_REQUEST, UPDATE_WIDGET_LIVE_STATUS_SUCCESS, UPDATE_WIDGET_LIVE_STATUS_FAILED, UPDATE_WIDGET_LIVE_STATUS_REQUEST, GET_WIDGET_ANALYTICS_SUCCESS, GET_WIDGET_ANALYTICS_FAILED, GET_WIDGET_ANALYTICS_REQUEST, ADD_UPDATE_WIDGET_PREVIEW_REQUEST, ADD_UPDATE_WIDGET_PREVIEW_SUCCESS, ADD_UPDATE_WIDGET_PREVIEW_FAILED} from '../constants/reducer.const';
import {logoutUnauthorized} from '../helpers/common.helper';
import * as widgetService from '../services/widget.service';

/**
 * add widget saga
 *
 * @param {Object} action
 */
function* addWidgetSaga(action) {
  try {
    const response = yield call(widgetService.addWidget, action.payload);
    yield put({type: ADD_WIDGET_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: ADD_WIDGET_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * add widget watcher
 */
function* addWidgetWatcher() {
  yield takeLatest(ADD_WIDGET_REQUEST, addWidgetSaga);
}

/**
 * update widget saga
 *
 * @param {Object} action
 */
function* updateWidgetSaga(action) {
  try {
    const response = yield call(widgetService.updateWidget, action.payload);
    yield put({type: UPDATE_WIDGET_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: UPDATE_WIDGET_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * update widget watcher
 */
function* updateWidgetWatcher() {
  yield takeLatest(UPDATE_WIDGET_REQUEST, updateWidgetSaga);
}

/**
 * update widget live status saga
 *
 * @param {Object} action
 */
function* updateWidgetLiveSaga(action) {
  try {
    const response = yield call(widgetService.updateWidgetLiveStatus, action.payload);
    yield put({type: UPDATE_WIDGET_LIVE_STATUS_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: UPDATE_WIDGET_LIVE_STATUS_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * update widget live status watcher
 */
function* updateWidgetLiveWatcher() {
  yield takeLatest(UPDATE_WIDGET_LIVE_STATUS_REQUEST, updateWidgetLiveSaga);
}


/**
 * get widget saga
 *
 * @param {Object} action
 */
function* getWidgetSaga(action) {
  try {
    const response = yield call(widgetService.getAllWidgets, action.payload);
    yield put({type: GET_WIDGET_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: GET_WIDGET_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * get widget watcher
 */
function* getWidgetWatcher() {
  yield takeLatest(GET_WIDGET_REQUEST, getWidgetSaga);
}

/**
 * get widget analytics saga
 *
 * @param {Object} action
 */
function* getWidgetAnalyticsSaga(action) {
  try {
    const response = yield call(widgetService.getWidgetAnalytics, action.payload);
    yield put({type: GET_WIDGET_ANALYTICS_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: GET_WIDGET_ANALYTICS_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * get widget watcher
 */
function* getWidgetAnalyticsWatcher() {
  yield takeLatest(GET_WIDGET_ANALYTICS_REQUEST, getWidgetAnalyticsSaga);
}

/**
 * delete widget
 *
 * @param {Object} action
 */
function* deleteWidgetSaga(action) {
  try {
    const response = yield call(widgetService.deleteWidget, action.payload);
    yield put({type: DELETE_WIDGET_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: DELETE_WIDGET_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * delete widget watcher
 */
function* deleteWidgetWatcher() {
  yield takeLatest(DELETE_WIDGET_REQUEST, deleteWidgetSaga);
}

/**
 * Add/Update preview widget details
 *
 * @param {Object} action
 */
function* addUpdateWidgetPreviewSaga(action) {
  try {
    const response = yield call(widgetService.addUpdateWidgetPreview, action.payload);
    yield put({type: ADD_UPDATE_WIDGET_PREVIEW_SUCCESS, payload: response});
  } catch (e) {
    if (e?.response?.data?.statusCode === 401) {
      logoutUnauthorized();
    }
    yield put({type: ADD_UPDATE_WIDGET_PREVIEW_FAILED, payload: e?.response?.data || {message: 'Server Error(please try again)'}});
  }
}

/**
 * Add/Update preview widget watcher
 */
function* addUpdateWidgetPreviewWatcher() {
  yield takeLatest(ADD_UPDATE_WIDGET_PREVIEW_REQUEST, addUpdateWidgetPreviewSaga);
}

/**
 * exporting sagas after they are initialised
 */
export default function* rootSaga() {
  yield all([
    addWidgetWatcher(),
    updateWidgetWatcher(),
    getWidgetWatcher(),
    deleteWidgetWatcher(),
    updateWidgetLiveWatcher(),
    getWidgetAnalyticsWatcher(),
    addUpdateWidgetPreviewWatcher(),
  ]);
};

