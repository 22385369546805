import React, {useEffect} from 'react';
// import {useDispatch, useSelector} from 'react-redux';
import {PLAN_TYPE_MAPPER} from '../../constants/application.const';
import Heading from '../../components/heading';
import ErrorText from '../../components/errorComponents/errorText.component';
import Input from '../../components/input/input.component';
import Select from '../../components/select/select.component';
import {addEditPlanSchema} from '../../validationSchemas/plan.validation';
import ButtonGroup from '../../components/button/buttonGroup.component';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Button from '../../components/button/button.component';
import {useNavigate} from 'react-router-dom';
import {ADD_PLAN_ADMIN_FAILED, ADD_PLAN_ADMIN_SUCCESS} from '../../constants/reducer.const';
import {addPlanAdmin, clearAddPlanAdmin} from '../../actions/plans.actions';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import TextArea from '../../components/textarea';

/**
 * Admin add plans page
 *
 * @return {JSX}
 */
const AdminAddPlansPage = ()=>{
  const dispatch = useDispatch();
  const planSelector = useSelector((state)=>state?.plan);
  const {register, handleSubmit, setValue, formState: {errors}} = useForm({
    resolver: yupResolver(addEditPlanSchema),
  });
  const navigate = useNavigate();

  useEffect(()=>{
    setValue('type', 'R');
    setValue('shopifyExclusive', false);
    setValue('status', true);
    setValue('enableIntegrations', true);
    setValue('enableBranding', true);
  }, []);

  useEffect(()=>{
    if (planSelector?.addPlanAdminDetails?.type === ADD_PLAN_ADMIN_SUCCESS) {
      toast(planSelector?.addPlanAdminDetails?.payload?.message, {
        type: 'success',
      });
      dispatch(clearAddPlanAdmin());
      navigate('/admin/plans');
    }
    if (planSelector?.addPlanAdminDetails?.type === ADD_PLAN_ADMIN_FAILED) {
      toast(planSelector?.addPlanAdminDetails?.payload?.message, {
        type: 'error',
      });
      dispatch(clearAddPlanAdmin());
    }
  }, [planSelector?.addPlanAdminDetails]);

  const addPlan = (value)=>{
    // converting mb to bytes for storing in db
    value.storageCap = value.storageCap*1000000;
    dispatch(addPlanAdmin({...value}));
  };

  return (
    <div className='px-8 pt-8 h-full overflow-y-auto'>
      <Heading className={'font-semibold text-xl'}>Add Plan</Heading>
      <form onSubmit={handleSubmit(addPlan)} className="grid grid-cols-12 gap-4 min-w-0 p-4 bg-white rounded-lg drop-shadow-md mt-4 mb-4">
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Plan Name</span>
          <Input
            parentAdditionalClass={'mt-1'}
            type='text'
            placeholder="Plan Name"
            name="name"
            {...register('name')}
          />
          <ErrorText displayStatus={true}>{errors?.name?.message}</ErrorText>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Plan Price($)</span>
          <Input
            parentAdditionalClass={'mt-1'}
            type='number'
            placeholder="Example: 10"
            name="price"
            {...register('price')}
          />
          <ErrorText displayStatus={true}>{errors?.price?.message}</ErrorText>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Storage Cap(in MB)</span>
          <Input
            parentAdditionalClass={'mt-1'}
            type='number'
            placeholder="Storage cap"
            name="storageCap"
            {...register('storageCap')}
          />
          <ErrorText displayStatus={true}>{errors?.storageCap?.message}</ErrorText>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Plan Type</span>
          <Select
            defaultSelected={PLAN_TYPE_MAPPER.R}
            onChange={(event)=>{
              setValue('type', event);
            }}
            options={[
              {
                value: 'R',
                text: PLAN_TYPE_MAPPER.R,
              },
              {
                value: 'UL',
                text: PLAN_TYPE_MAPPER.UL,
              },
            ]}
          />
          <ErrorText displayStatus={true}>{errors?.type?.message}</ErrorText>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Shopify Exclusive</span>
          <div className='mt-2'>
            <ButtonGroup
              defaultSelect={'N'}
              onSelect={(value)=>{
                setValue('shopifyExclusive', value==='Y');
              }}
              buttons={[
                {
                  name: 'Yes',
                  value: 'Y',
                },
                {
                  name: 'No',
                  value: 'N',
                },
              ]}
            />
          </div>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Active Status</span>
          <div className='mt-2'>
            <ButtonGroup
              defaultSelect={'A'}
              onSelect={(value)=>{
                setValue('status', value);
              }}
              buttons={[
                {
                  name: 'Active',
                  value: 'A',
                },
                {
                  name: 'Block',
                  value: 'B',
                },
              ]}
            />
          </div>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Order Precedence</span>
          <Input
            parentAdditionalClass={'mt-1'}
            type='number'
            placeholder="Ex: 99"
            name="orderPrecedence"
            {...register('orderPrecedence')}
          />
          <ErrorText displayStatus={true}>{errors?.orderPrecedence?.message}</ErrorText>
        </label>

        <span className="text-gray-700 ddark:text-gray-400 font-semibold col-span-12">Module Configuration</span>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Widget limit</span>
          <Input
            parentAdditionalClass={'mt-1'}
            type='number'
            placeholder="Example: 10"
            name="widgetLimit"
            {...register('widgetLimit')}
          />
          <ErrorText displayStatus={true}>{errors?.widgetLimit?.message}</ErrorText>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Domain limit</span>
          <Input
            parentAdditionalClass={'mt-1'}
            type='number'
            placeholder="Example: 10"
            name="domainLimit"
            {...register('domainLimit')}
          />
          <ErrorText displayStatus={true}>{errors?.domainLimit?.message}</ErrorText>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Enable Integrations</span>
          <div className='mt-2'>
            <ButtonGroup
              defaultSelect={'Y'}
              onSelect={(value)=>{
                setValue('enableIntegrations', value==='Y');
              }}
              buttons={[
                {
                  name: 'Yes',
                  value: 'Y',
                },
                {
                  name: 'No',
                  value: 'N',
                },
              ]}
            />
          </div>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Enable Branding</span>
          <div className='mt-2'>
            <ButtonGroup
              defaultSelect={'Y'}
              onSelect={(value)=>{
                setValue('enableBranding', value==='Y');
              }}
              buttons={[
                {
                  name: 'Yes',
                  value: 'Y',
                },
                {
                  name: 'No',
                  value: 'N',
                },
              ]}
            />
          </div>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Plan Type</span>
          <div className='mt-2'>
            <ButtonGroup
              defaultSelect={'N'}
              onSelect={(value)=>{
                setValue('planType', value);
              }}
              buttons={[
                {
                  name: 'Normal',
                  value: 'N',
                },
                {
                  name: 'SaasZilla',
                  value: 'SZ',
                },
              ]}
            />
          </div>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Impression count</span>
          <Input
            parentAdditionalClass={'mt-1'}
            type='number'
            placeholder="Impression count"
            name="impressionCount"
            {...register('impressionCount')}
          />
          <ErrorText displayStatus={true}>{errors?.impressionCount?.message}</ErrorText>
        </label>
        <label className="block text-sm col-span-6">
          <span className="text-gray-700 ddark:text-gray-400">Plan Description</span>
          <TextArea
            resize={true}
            parentAdditionalClass={'mt-1'}
            type='text'
            placeholder="Plan Description"
            name="description"
            {...register('description')}
          />
          <ErrorText displayStatus={true}>{errors?.description?.message}</ErrorText>
        </label>
        <div className='flex mt-8 justify-end col-span-12'>
          <Button classOverride={'flex items-center justify-center px-3 py-2 mr-2 text-sm font-medium text-purple-600 duration-150 bg-transparent border-2 border-purple-600 rounded-lg hover:border-purple-700 active:border-purple-500 focus:outline-none focus:shadow-outline-purple'} type={'button'} onClick={()=>navigate(-1)}>Back</Button>
          <Button type={'submit'}>Submit</Button>
        </div>
      </form>
    </div>
  );
};

export default AdminAddPlansPage;
