import {ADD_INTEGRATION_REQUEST, DELETE_INTEGRATION_CLEAR, DELETE_INTEGRATION_REQUEST, GET_INTEGRATION_REQUEST, GET_INTEGRATION_REQUEST_CLEAR, GET_INTEGRATION_STATUS_REQUEST, GET_PABBLY_LIST_CLEAR, GET_PABBLY_LIST_REQUEST, GET_SHOPIFY_ACCESS_TOKEN_CLEAR, GET_SHOPIFY_ACCESS_TOKEN_REQUEST, GET_SHOPIFY_INSTALL_STATUS_REQUEST, INSTALL_SHOPIFY_CLEAR, INSTALL_SHOPIFY_REQUEST, INTEGRATION_REQUEST_CLEAR, SHOPIFY_OAUTH_REDIRECTION_REQUEST, UNINSTALL_SHOPIFY_CLEAR, UNINSTALL_SHOPIFY_REQUEST, UPDATE_INTEGRATION_REQUEST, UPDATE_SHOPIDY_EMAIL_CLEAR, UPDATE_SHOPIDY_EMAIL_REQUEST} from '../constants/reducer.const';

export const addIntegrationAction = (payload)=>({type: ADD_INTEGRATION_REQUEST, payload});
export const getIntegrationListAction = (payload)=>({type: GET_INTEGRATION_REQUEST, payload});
export const clearIntegrationAction = ()=>({type: INTEGRATION_REQUEST_CLEAR, payload: {}});
export const clearIntegrationListAction = ()=>({type: GET_INTEGRATION_REQUEST_CLEAR, payload: {}});

export const shopifyOauthRedirectionAction = (payload)=>({type: SHOPIFY_OAUTH_REDIRECTION_REQUEST, payload});
export const getShopifyAccessTokenAction = (payload)=>({type: GET_SHOPIFY_ACCESS_TOKEN_REQUEST, payload});
export const clearShopifyAccessTokenAction = ()=>({type: GET_SHOPIFY_ACCESS_TOKEN_CLEAR, payload: {}});
export const getIntegrationStatusAction = (payload)=>({type: GET_INTEGRATION_STATUS_REQUEST, payload});
export const getShopifyInstallStatusAction = ()=>({type: GET_SHOPIFY_INSTALL_STATUS_REQUEST, payload: {}});
export const installShopifyAppAction = (payload)=>({type: INSTALL_SHOPIFY_REQUEST, payload});
export const installShopifyAppClearAction = ()=>({type: INSTALL_SHOPIFY_CLEAR, payload: {}});
export const unInstallShopifyAppAction = ()=>({type: UNINSTALL_SHOPIFY_REQUEST, payload: {}});
export const unInstallShopifyAppClearAction = ()=>({type: UNINSTALL_SHOPIFY_CLEAR, payload: {}});
export const deleteIntegrationAction = (payload)=>({type: DELETE_INTEGRATION_REQUEST, payload});
export const deleteIntegrationClearAction = ()=>({type: DELETE_INTEGRATION_CLEAR, payload: {}});
export const updateIntegrationAction = (payload)=>({type: UPDATE_INTEGRATION_REQUEST, payload});
export const updateShopifyEmailAction = (payload)=>({type: UPDATE_SHOPIDY_EMAIL_REQUEST, payload});
export const updateShopifyEmailClearAction = ()=>({type: UPDATE_SHOPIDY_EMAIL_CLEAR, payload: {}});

// Pabbly list
export const getAllPabblyListAction = ()=>({type: GET_PABBLY_LIST_REQUEST, payload: {}});
export const getAllPabblyListClear = ()=>({type: GET_PABBLY_LIST_CLEAR, payload: {}});
