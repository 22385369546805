import React from 'react';

// Analytics icon
const AnalyticsIcon = ({...props})=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 96 960 960" fill={'currentColor'}><path d="M120 936v-76l60-60v136h-60Zm165 0V700l60-60v296h-60Zm165 0V640l60 61v235h-60Zm165 0V701l60-60v295h-60Zm165 0V540l60-60v456h-60ZM120 700v-85l280-278 160 160 280-281v85L560 582 400 422 120 700Z"/></svg>
  );
};

export default AnalyticsIcon;
