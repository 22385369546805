import {DETAILS_DASHBOARD_FAILURE, DETAILS_DASHBOARD_REQUEST, DETAILS_DASHBOARD_SUCCESS} from '../constants/reducer.const';

const initialState = {};

/**
 * Dashboard reducer function
 *
 * @param {any} state
 * @param {any} action
 * @return {any}
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case DETAILS_DASHBOARD_SUCCESS: return {...state, ...action};
    case DETAILS_DASHBOARD_FAILURE: return {...state, ...action};
    case DETAILS_DASHBOARD_REQUEST: return {};
    default: return {...state};
  }
}
