import React, {useEffect, useState} from 'react';
import ButtonGroup from '../../../../components/button/buttonGroup.component';
import Chips from '../../../../components/chips';
import ChipContainer from '../../../../components/chips/chipContainer.component';
import Input from '../../../../components/input/input.component';
import TextArea from '../../../../components/textarea';
import InfoWrapper from '../../../../components/wrappers/infoWrapper.component';
import SettingsIcon from '../../../../icons/settings.icon';
import HeadingModal from '../modals/heading.modal';
import SubHeadingModal from '../modals/subHeading.modal';
import {useDispatch, useSelector} from 'react-redux';
import {updateCollapseAction, updateWidgetSettings} from '../../../../actions/widget.actions';
import PropTypes from 'prop-types';
import ErrorText from '../../../../components/errorComponents/errorText.component';
import {COUNTER_DEFAULT_VALUES, HEADING_DEFAULT_VALUES, SUB_HEADING_DEFAULT_VALUES, BUBBLE_ACTION_HEADING, COUPON_DEFAULT_VALUES, GRADIANT_DEFAULT_VALUES} from '../../../../constants/defaultValues.const';
import {COUNTER_DIRECTION_MAPPER, MEDIA_POSITION_MAPPER, MEDIA_TYPE_MAPPER} from '../../../../constants/widget.const';
import {useNavigate} from 'react-router-dom';
import CounterConfigModal from '../modals/counterConfig.modal';
import {getValue} from '../../../../config/localStorage.config';
import {USER_DETAILS} from '../../../../constants/application.const';
import Popover from '../../../../components/popover';
import Heading from '../../../../components/heading';
import UpgradeButton from '../../../../components/button/upgradeButton.component';
import CheckBox from '../../../../components/checkbox';
import {dateConverterForInput, hexToHsla} from '../../../../helpers/common.helper';
import CouponConfigModal from '../modals/couponConfig.modal';
import Button from '../../../../components/button/button.component';
import GradiantBackgroundModal from '../modals/gradiantBackground.modal';
import CollapsibleComponent from './collapsible.component';
import ColorInput from '../../../../components/input/colorInput.component';

/**
 * Content settings component
 *
 * @return {JSX}
 */
const ContentSettings = ({register, errors, setValue, widgetType, ...props})=>{
  const [headingModalToggle, setHeadingModalToggle] = useState(false);
  const widgetStore = useSelector((state)=>state.widget);
  const [subHeadingModalToggle, setSubHeadingModalToggle] = useState(false);
  const [counterConfigModal, setCounterConfigModal] = useState(false);
  const [gradiantBackgroundModal, setGradiantBackgroundModal] = useState(false);
  const [couponConfigModal, setCouponConfigModal] = useState(false);
  const [headingConfig, setHeadingConfig] = useState({...HEADING_DEFAULT_VALUES});
  const [couponConfig, setCouponConfig] = useState({...COUPON_DEFAULT_VALUES});
  const [subHeadingConfig, setSubHeadingConfig] = useState({...SUB_HEADING_DEFAULT_VALUES});
  const [gradiantConfig, setGradiantConfig] = useState({...GRADIANT_DEFAULT_VALUES});
  const [bubbleActionHeadingConfig, setBubbleActionHeadingConfig] = useState({...BUBBLE_ACTION_HEADING});
  const [widgetConfig, setWidgetConfig] = useState({});
  const [counterConfig, setCounterConfig] = useState({...COUNTER_DEFAULT_VALUES});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [curretUserPlan, setCurrentUserPlan] = useState({});
  const [toggleCollapsible, setToggleCollapsible] = useState(false);

  // Get user data from local storage
  const getLocalStorageUserData = ()=>{
    const userData = getValue(USER_DETAILS);
    if (userData) {
      setCurrentUserPlan(JSON.parse(userData)?.plan);
    }
  };

  // Called initially
  useEffect(()=>{
    getLocalStorageUserData();
  }, []);

  useEffect(()=>{
    setToggleCollapsible(false);
    if (widgetStore?.collapse_widget?.payload?.MS) {
      setToggleCollapsible(true);
    }
  }, [widgetStore?.collapse_widget]);

  // Close heading modal
  const closeHeadingModal = ()=>{
    setHeadingModalToggle(false);
  };

  // Close coupon modal
  const closeCouponModal = ()=>{
    setCouponConfigModal(false);
  };

  // Sub Close heading modal
  const closeSubHeadingModal = ()=>{
    setSubHeadingModalToggle(false);
  };

  // Close counter config modal
  const closeCounterConfigModal = ()=>{
    setCounterConfigModal(false);
  };

  // Close gradiant background modal
  const closeGradiantBackgroundModal = ()=>{
    setGradiantBackgroundModal(false);
  };

  // called initially
  useEffect(()=>{
    updateCounterValue('direction', COUNTER_DIRECTION_MAPPER.RB.value);
    dispatch(updateWidgetSettings({
      mediaPosition: widgetStore?.widgetSettings?.mediaPosition || MEDIA_POSITION_MAPPER.L.value,
      counterConfig: widgetStore?.widgetSettings?.counterConfig?{...widgetStore?.widgetSettings?.counterConfig}:{...counterConfig},
    }));
  }, []);

  useEffect(()=>{
    if (widgetStore?.widgetSettings) {
      setWidgetConfig(widgetStore?.widgetSettings);
      setCounterConfig({...widgetStore?.widgetSettings?.counterConfig});
      setHeadingConfig({...widgetStore?.widgetSettings?.headingConfig});
      setCouponConfig({...widgetStore?.widgetSettings?.couponConfig});
      setSubHeadingConfig({...widgetStore?.widgetSettings?.subHeadingConfig});
      setBubbleActionHeadingConfig({...widgetStore?.widgetSettings?.bubbleActionHeadingConfig});

      // Set only if gradiantConfig exists
      if (widgetStore?.widgetSettings?.gradiantConfig) {
        setGradiantConfig({...widgetStore?.widgetSettings?.gradiantConfig});
      }
    }
  }, [widgetStore?.widgetSettings]);

  // update counter config value (state update)
  const updateCounterValue = (key, value)=>{
    const counterConfigTemp = {...counterConfig};
    counterConfigTemp[key] = value;
    setCounterConfig(counterConfigTemp);
    dispatch(updateWidgetSettings({
      counterConfig: counterConfigTemp,
    }));
  };


  return (
    <div {...props}>
      <CollapsibleComponent
        type={'MS'}
        heading={'Media Settings'}
        defaultValue={toggleCollapsible}
        toggleCallback={(toggle)=>{
          setToggleCollapsible(toggle);
          const tempCollapse = {...widgetStore?.collapse_widget};
          dispatch(updateWidgetSettings({
            toggleStatusMessageSuccess: false,
          }));
          Object.keys(tempCollapse).map((key)=>tempCollapse[key] = false);
          tempCollapse['MS'] = toggle;
          dispatch(updateCollapseAction({...tempCollapse}));
        }}
      />

      <div className={toggleCollapsible?'px-2':'hidden'}>
        {/* Media container */}
        {widgetType !== 'R' && <div id="media-container">
          <label className="text-sm font-normal mt-4 flex items-center">
            <span className="mr-1">Media</span>
          </label>
          {/* <InfoWrapper
          description='The image/video container wont be displayed if no media is set'
          additionalClass='mt-2'
        /> */}
          <ButtonGroup
            className={'mt-2'}
            label={''}
            planBlock={!curretUserPlan?.modules?.P?.WIDGETS?.CONTENT_SETTINGS?.MEDIA?.TYPE}
            onSelect={(value)=>{
              if (value !== MEDIA_TYPE_MAPPER.NM.value) {
                navigate(`/widgets/media-selection/${value}`);
              } else {
                dispatch(updateWidgetSettings({
                  videoDetails: {
                    type: MEDIA_TYPE_MAPPER.NM.value,
                  },
                }));
              }
            }}
            defaultSelect={widgetConfig?.videoDetails?.type}
            buttons={widgetType === 'PS' ?[
              {
                name: MEDIA_TYPE_MAPPER.I.name,
                value: MEDIA_TYPE_MAPPER.I.value,
              },
              {
                name: MEDIA_TYPE_MAPPER.V.name,
                value: MEDIA_TYPE_MAPPER.V.value,
              },
              {
                name: MEDIA_TYPE_MAPPER.NM.name,
                value: MEDIA_TYPE_MAPPER.NM.value,
              },
            ]: [
              {
                name: MEDIA_TYPE_MAPPER.I.name,
                value: MEDIA_TYPE_MAPPER.I.value,
              },
              {
                name: MEDIA_TYPE_MAPPER.V.name,
                value: MEDIA_TYPE_MAPPER.V.value,
              },
              {
                name: MEDIA_TYPE_MAPPER.U.name,
                value: MEDIA_TYPE_MAPPER.U.value,
              },
            ]}
          />
          {widgetConfig?.videoDetails?.originalFileName && <ChipContainer additionalClass='mt-2'>
            <Chips classOverride='text-xs p-1 bg-slate-200 rounded-lg'>File : {widgetConfig?.videoDetails?.originalFileName}</Chips>
          </ChipContainer>}
          {widgetType !== 'B' && widgetType !== 'BS' && widgetType !== 'PS' && <ButtonGroup
            className={'mt-2'}
            label={''}
            planPopupPosition={'bottom'}
            planBlock={!curretUserPlan?.modules?.P?.WIDGETS?.CONTENT_SETTINGS?.MEDIA?.POSITION}
            onSelect={(value)=>{
              dispatch(updateWidgetSettings({
                mediaPosition: value,
              }));
            }}
            defaultSelect={widgetStore?.widgetSettings?.mediaPosition}
            buttons={[
              {
                name: MEDIA_POSITION_MAPPER.L.name,
                value: MEDIA_POSITION_MAPPER.L.value,
              },
              {
                name: MEDIA_POSITION_MAPPER.B.name,
                value: MEDIA_POSITION_MAPPER.B.value,
              },
              {
                name: MEDIA_POSITION_MAPPER.R.name,
                value: MEDIA_POSITION_MAPPER.R.value,
              },
            ]}
          />}

          {/* For popup survey widget only*/}
          {widgetType === 'PS' && <ButtonGroup
            className={'mt-2'}
            label={''}
            planPopupPosition={'bottom'}
            planBlock={!curretUserPlan?.modules?.P?.WIDGETS?.CONTENT_SETTINGS?.MEDIA?.POSITION}
            onSelect={(value)=>{
              dispatch(updateWidgetSettings({
                mediaPosition: value,
              }));
            }}
            defaultSelect={widgetStore?.widgetSettings?.mediaPosition}
            buttons={[
              {
                name: MEDIA_POSITION_MAPPER.L.name,
                value: MEDIA_POSITION_MAPPER.L.value,
              },
              {
                name: MEDIA_POSITION_MAPPER.R.name,
                value: MEDIA_POSITION_MAPPER.R.value,
              },
            ]}
          />}
        </div>}

        {/* Heading / Title*/}
        <div id="heading-container">
          {/* heading */}
          {widgetType !== 'B' && widgetType !== 'BS' && (<>
            <label className="text-sm font-normal mt-4 flex items-center">
              <span className="mr-1">Heading</span><SettingsIcon className={'cursor-pointer'} height={'20'} width={'20'} onClick={()=>{
                setHeadingModalToggle(true);
              }}/>
            </label>
            <Input
              placeholder={'Ex: Special sale one live !!!'}
              {...register('headingText', {
                onChange: (event)=>{
                  dispatch(updateWidgetSettings({
                    headingText: event?.target?.value,
                  }));
                },
              })}
              defaultValue={widgetStore?.widgetSettings?.headingText}
              errorStatus={errors?.headingText}
            />
            <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errors?.headingText?.message}</ErrorText>
          </>)}
          {/* title */}
          {(widgetType === 'B' || widgetType === 'BS') && (<>
            <label className="text-sm font-normal mt-4 mb-2 flex items-center">
              <span className="mr-1">Welcome</span><SettingsIcon className={'cursor-pointer'} height={'20'} width={'20'} onClick={()=>{
                setHeadingModalToggle(true);
              }}/>
            </label>
            <Input
              placeholder={'Ex: Sign me up now'}
              {...register('titleText', {
                onChange: (event)=>{
                  dispatch(updateWidgetSettings({
                    titleText: event?.target?.value,
                  }));
                },
              })}
              defaultValue={widgetStore?.widgetSettings?.titleText}
              errorStatus={errors?.titleText}
            />
            <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errors?.titleText?.message}</ErrorText>
          </>)}
          <ChipContainer additionalClass='mt-2' onClick={()=>{
            setHeadingModalToggle(true);
          }}>
            {Object.keys(headingConfig)?.map((configKey, index)=>(
              <Chips key={index} classOverride={`text-xs p-1 ${headingConfig[configKey]?.chipColor?'text-white':'bg-slate-200'} rounded-lg mr-2`} style={{backgroundColor: headingConfig[configKey]?.chipColor}}>{headingConfig[configKey]?.displayName} : {headingConfig[configKey]?.value}</Chips>
            ))}
          </ChipContainer>
        </div>
        {/* <div
        onMouseEnter={()=>{
          dispatch(updateWidgetSettings({
            bubbleWidgetToggle: true,
          }));
        }}
      > */}
        {/* Sub Heading */}
        {(widgetType !== 'B' && widgetType !== 'BS' && widgetType !== 'R') && <div id="sub-heading-container">
          <label className="text-sm font-normal mt-4 mb-2 flex items-center">
            <span className="mr-1">Sub Heading</span><SettingsIcon className={'cursor-pointer'} height={'20'} width={'20'} onClick={()=>{
              setSubHeadingModalToggle(true);
            }}/>
          </label>
          <TextArea
            {...register('subHeadingText', {
              onChange: (event)=>{
                dispatch(updateWidgetSettings({
                  subHeadingText: event?.target?.value,
                }));
              },
            })}
            defaultValue={widgetStore?.widgetSettings?.subHeadingText}
            placeholder={'Ex: Special sale one live !!!'}
            errorStatus={errors?.subHeadingText}
            rows={4}
          />
          <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errors?.subHeadingText?.message}</ErrorText>
          <ChipContainer additionalClass='mt-2' onClick={()=>{
            setSubHeadingModalToggle(true);
          }}>
            {Object.keys(subHeadingConfig)?.map((configKey, index)=>(
              <Chips key={index} classOverride={`text-xs p-1 ${subHeadingConfig[configKey]?.chipColor?'text-white':'bg-slate-200'} rounded-lg mr-2`} style={{backgroundColor: subHeadingConfig[configKey]?.chipColor}}>{subHeadingConfig[configKey]?.displayName} : {subHeadingConfig[configKey]?.value}</Chips>
            ))}
          </ChipContainer>
        </div>}

        {/** Bubble widget inner title */}
        {(widgetType === 'B' || widgetType === 'BS') && <div id="sub-heading-container">
          <label className="text-sm font-normal mt-4 mb-2 flex items-center">
            <span className="mr-1">Widget Heading</span><SettingsIcon className={'cursor-pointer'} height={'20'} width={'20'} onClick={()=>{
              setSubHeadingModalToggle(true);
            }}/>
          </label>
          <Input
            placeholder={'Ex: Click here'}
            {...register('bubbleActionHeading', {
              onChange: (event)=>{
                dispatch(updateWidgetSettings({
                  bubbleActionHeading: event?.target?.value,
                }));
              },
            })}
            defaultValue={widgetStore?.widgetSettings?.bubbleActionHeading}
            errorStatus={errors?.bubbleActionHeading}
          />
          <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errors?.bubbleActionHeading?.message}</ErrorText>
          {widgetStore?.widgetSettings?.bubbleActionHeadingConfig && <ChipContainer additionalClass='mt-2' onClick={()=>{
            setSubHeadingModalToggle(true);
          }}>
            {Object.keys(widgetStore?.widgetSettings?.bubbleActionHeadingConfig)?.map((configKey, index)=>(
              <Chips key={index} classOverride={`text-xs p-1 ${widgetStore?.widgetSettings?.bubbleActionHeadingConfig[configKey]?.chipColor?'text-white':'bg-slate-200'} rounded-lg mr-2`} style={{backgroundColor: widgetStore?.widgetSettings?.bubbleActionHeadingConfig[configKey]?.chipColor}}>{widgetStore?.widgetSettings?.bubbleActionHeadingConfig[configKey]?.displayName} : {widgetStore?.widgetSettings?.bubbleActionHeadingConfig[configKey]?.value}</Chips>
            ))}
          </ChipContainer>}
        </div>}

        {/* Logo container */}
        {widgetType !== 'R' && widgetType !== 'B' && widgetType !== 'BS' && <div id="media-container">
          <label className="text-sm font-normal mt-4 flex items-center">
            <span className="mr-1">Logo</span>
          </label>
          {widgetConfig?.logoDetails?.originalFileName && <ChipContainer additionalClass='mt-2'>
            <Chips classOverride='text-xs p-1 bg-slate-200 rounded-lg flex flex-row items-center' enableClose={true} closeOnClick={()=>{
              dispatch(updateWidgetSettings({
                logoDetails: {},
              }));
            }}>
              Logo file : {widgetConfig?.logoDetails?.originalFileName}
            </Chips>
          </ChipContainer>}
          <Button
            className={'w-full mt-2'}
            onClick={()=>{
              navigate(`/widgets/media-selection/${MEDIA_TYPE_MAPPER.I.value}?uploadModule=LOGO`);
            }}
          >Upload Logo</Button>
        </div>}

        {/* Gradiant settings */}
        {(widgetType === 'B' || widgetType === 'BS') && <div id="media-container">
          <label className="text-sm font-normal mt-4 flex items-center">
            <span className="mr-1">Widget Action Background Settings</span>
          </label>
          <Button
            className={'w-full mt-2'}
            onClick={()=>{
              setGradiantBackgroundModal(true);
            }}
          >Change Backgroud Settings</Button>
          {widgetStore?.widgetSettings?.gradiantConfig && <ChipContainer
            onClick={()=>{
              setGradiantBackgroundModal(true);
            }}
            additionalClass='mt-2'>
            {Object.keys(widgetStore?.widgetSettings?.gradiantConfig)?.filter((configKey)=>(configKey!=='hlsaValue'))?.map((configKey, index)=>(
              <Chips key={index} classOverride={`text-xs p-1 ${widgetStore?.widgetSettings?.gradiantConfig[configKey]?.chipColor?'text-white':'bg-slate-200'} rounded-lg mr-2`} style={{backgroundColor: widgetStore?.widgetSettings?.gradiantConfig[configKey]?.chipColor}}>{widgetStore?.widgetSettings?.gradiantConfig[configKey]?.displayName} : {widgetStore?.widgetSettings?.gradiantConfig[configKey]?.value}</Chips>
            ))}
          </ChipContainer>
          }
        </div>}

        {/* Action counter */}
        {widgetType !== 'R' && widgetType !== 'PS' && <div id="action-counter-container" className={`relative ${!curretUserPlan?.modules?.P?.WIDGETS?.CONTENT_SETTINGS?.MEDIA?.ACTION_COUNTER?'p-4 mt-2':''}`}>
          {!curretUserPlan?.modules?.P?.WIDGETS?.CONTENT_SETTINGS?.MEDIA?.ACTION_COUNTER && <div className="absolute inset-0 bg-stone-700 bg-opacity-75 transition-opacity z-50 rounded-lg flex justify-center items-center" aria-hidden="true">
            <Popover
              additionalPopoverClass='mt-2'
              popupOverComponent={
                <div className='w-60 p-4'>
                  <Heading className="text-md font-bold">Need this feature?</Heading>
                  <p className='mt-2'>
                      Upgrade to unlock more features!!!
                  </p>
                </div>
              }
            >
              <UpgradeButton />
            </Popover>
          </div>}
          <label className="text-sm font-normal mt-4 flex items-center">
          Countdown Timer
          </label>
          <CheckBox
            label='Enable'
            parentAdditionalClass='mt-2'
            defaultValue={widgetStore?.widgetSettings?.counterStatus}
            onChange={(value)=>{
              dispatch(updateWidgetSettings({
                counterStatus: value,
              }));
            }}
          />
          {widgetStore?.widgetSettings?.counterStatus && <>
            <ButtonGroup
              className={'mt-2'}
              label={''}
              onSelect={(value)=>{
                updateCounterValue('direction', value);
              }}
              defaultSelect={widgetStore?.widgetSettings?.counterConfig?.direction}
              buttons={[
                {
                  name: COUNTER_DIRECTION_MAPPER.RB.name,
                  value: COUNTER_DIRECTION_MAPPER.RB.value,
                },
                {
                  name: COUNTER_DIRECTION_MAPPER.RF.name,
                  value: COUNTER_DIRECTION_MAPPER.RF.value,
                },
              ]}
            />
            <InfoWrapper
              description='Mention starting or ending date accordingly'
              additionalClass='mt-2 bg-purple-300 text-purple-600'
            >
              <div className='flex justify-around mt-2 items-center'>
                <Input
                  type='datetime-local'
                  parentAdditionalClass="mr-2 w-full"
                  className="bg-primary text-white"
                  {...register('counterDate', {
                    onChange: (event)=>{
                      updateCounterValue('counterDate', event.target.value);
                    },
                  })}
                  defaultValue={dateConverterForInput(widgetStore?.widgetSettings?.counterConfig?.counterDate)}
                />
                <div id="counter-color" className='mt-1'>
                  <ColorInput
                    defaultValue={widgetStore?.widgetSettings?.counterConfig?.backgroundColor?.value}
                    ignoreMarginTop={true}
                    onChangeValue={(value)=>{
                      const configTemp = {...counterConfig, fieldColor: {
                        value: value,
                        displayName: 'Field color',
                        chipColor: value,
                      }};
                      setCounterConfig({...configTemp});
                      dispatch(updateWidgetSettings({
                        counterConfig: configTemp,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className='flex items-center mt-2 cursor-pointer hidden' onClick={()=>{
                setCounterConfigModal(true);
              }}>
                <span className='text-sm text-slate-600 mr-1 underline'>Additional Settions</span><SettingsIcon className={'text-slate-600'} height={'20'} width={'20'}/>
              </div>
            </InfoWrapper>
            {widgetStore?.widgetSettings?.counterStatus && <ChipContainer additionalClass='mt-2'>
              <Chips classOverride='text-sm text-white font-thin p-1 bg-slate-200 rounded-lg' style={{backgroundColor: widgetStore?.widgetSettings?.counterConfig?.fieldColor?.value}}>Field color : {widgetStore?.widgetSettings?.counterConfig?.fieldColor?.value}</Chips>
            </ChipContainer>}
          </>}

          <label className="text-sm font-normal mt-4 flex items-center">
            Dislay Coupon?
          </label>
          <CheckBox
            label='Enable'
            parentAdditionalClass='mt-2'
            defaultValue={widgetStore?.widgetSettings?.couponDisplay}
            onChange={(value)=>{
              dispatch(updateWidgetSettings({
                couponDisplay: value,
              }));
            }}
          />
          {widgetStore?.widgetSettings?.couponDisplay && <div className='mt-4'>
            <label className="text-sm font-normal flex items-center">
              <span className="mr-1">Coupon code</span><SettingsIcon className={'cursor-pointer'} height={'20'} width={'20'} onClick={()=>{
                setCouponConfigModal(true);
              }}/>
            </label>
            <div className='flex items-center'>
              <Input
                placeholder={'Ex: POP876'}
                parentAdditionalClass={'mr-2 w-full mt-2'}
                {...register('couponCode', {
                  onChange: (event)=>{
                    dispatch(updateWidgetSettings({
                      couponCode: event?.target?.value,
                    }));
                  },
                })}
                errorStatus={Boolean(errors?.couponCode)}
                defaultValue={widgetStore?.widgetSettings?.couponCode}
              />
            </div>
            <ErrorText displayStatus={true}>{errors?.couponCode?.message}</ErrorText>
            <ChipContainer additionalClass='mt-2 w-full' onClick={()=>{
              setCouponConfigModal(true);
            }}>
              {Object.keys(couponConfig)?.map((configKey, index)=>(
                <Chips key={index} classOverride={`text-xs p-1 ${couponConfig[configKey]?.chipColor?'text-white':'bg-slate-200'} rounded-lg mr-2`} style={{backgroundColor: couponConfig[configKey]?.chipColor}}>{couponConfig[configKey]?.displayName} : {couponConfig[configKey]?.value}</Chips>
              ))}
            </ChipContainer>
          </div>}
        </div>}
      </div>

      {/* </div> */}
      {/* Heading modal */}
      {headingModalToggle && <HeadingModal
        widgetType={widgetType}
        configValues={{...headingConfig}}
        className={`transition-all`}
        onClose={()=>closeHeadingModal()}
        onConfigChange={(config)=>{
          setHeadingConfig({...config});
          dispatch(updateWidgetSettings({
            headingConfig: config,
          }));
        }}
      />}

      {/* Coupon config modal */}
      {couponConfigModal && <CouponConfigModal
        widgetType={widgetType}
        configValues={{...couponConfig}}
        className={`transition-all`}
        onClose={()=>closeCouponModal()}
        onConfigChange={(config)=>{
          console.log(config);
          setCouponConfig({...config});
          dispatch(updateWidgetSettings({
            couponConfig: config,
          }));
        }}
      />}

      {/* sub heading modal */}
      {subHeadingModalToggle && <SubHeadingModal
        widgetType={widgetType}
        configValues={((widgetType === 'B' || widgetType === 'BS'))?({...bubbleActionHeadingConfig}):({...subHeadingConfig})}
        className={`transition-all`}
        onClose={()=>closeSubHeadingModal()}
        onConfigChange={(config)=>{
          if ((widgetType === 'B' || widgetType === 'BS')) {
            setBubbleActionHeadingConfig({...config});
            dispatch(updateWidgetSettings({
              bubbleActionHeadingConfig: config,
            }));
          } else {
            setSubHeadingConfig({...config});
            dispatch(updateWidgetSettings({
              subHeadingConfig: config,
            }));
          }
        }}
      />}
      {/* counter config modal */}
      {counterConfigModal && <CounterConfigModal
        configValues={{...counterConfig}}
        className={`transition-all`}
        onClose={()=>closeCounterConfigModal()}
        onConfigChange={(config)=>{
          setCounterConfig({...config});
          dispatch(updateWidgetSettings({
            counterConfig: config,
          }));
        }}
      />}
      {/* Gradiant background settings modal */}
      {gradiantBackgroundModal && <GradiantBackgroundModal
        configValues={{...gradiantConfig}}
        className={`transition-all`}
        onClose={()=>closeGradiantBackgroundModal()}
        onConfigChange={(config)=>{
          setCounterConfig({...config});
          config.hlsaValue = hexToHsla(config?.backgroundColor?.value, config?.opacity?.value);
          dispatch(updateWidgetSettings({
            gradiantConfig: config,
          }));
        }}
      />}
    </div>
  );
};

ContentSettings.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
  setValue: PropTypes.any,
  widgetType: PropTypes.string,
};

export default ContentSettings;
