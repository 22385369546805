import React from 'react';
import BubbleIcon from '../../../../icons/widgetTypes/bubble.icon';
import PropTypes from 'prop-types';
import PopupVideoIcon from '../../../../icons/widgetTypes/popupVideo.icon';
import PopupTextIcon from '../../../../icons/widgetTypes/popupText.icon';
import Heading from '../../../../components/heading';
import {WIDGET_TYPE_CONST} from '../../../../constants/application.const';
import ReviewPopupIcon from '../../../../icons/widgetTypes/reviewPopup.icon';

/**
 * Widget type card container
 *
 * @return {JSX}
 */
const WidgetTypeCards = ({type, heading, description, additionalClass, comingSoon, ...props})=>{
  const IconSwitcher = ({type})=>{
    if (type === WIDGET_TYPE_CONST.B) {
      return (
        <BubbleIcon/>
      );
    }
    if (type === WIDGET_TYPE_CONST.BS) {
      return (
        <BubbleIcon/>
      );
    }
    if (type === WIDGET_TYPE_CONST.P) {
      return (
        <PopupVideoIcon/>
      );
    }
    if (type === WIDGET_TYPE_CONST.PS) {
      return (
        <PopupVideoIcon/>
      );
    }
    if (type === WIDGET_TYPE_CONST.E) {
      return (
        <PopupTextIcon/>
      );
    }
    if (type === WIDGET_TYPE_CONST.R) {
      return (
        <ReviewPopupIcon/>
      );
    }
    return (<></>);
  };

  return (
    <div className={`relative border-slate-200 border-2 rounded-lg bg-white hover:drop-shadow-md cursor-pointer ${additionalClass} w-96`} {...props}>
      {comingSoon && <>
        <div className='absolute bg-slate-200 h-full w-full opacity-60'></div>
        <div className="absolute flex justify-center items-center h-full w-full">
          <div className='bg-purple-600 absolute z-50 opacity-100 rounded-md p-2 text-white text-lg font-bold'>Coming Soon</div>
        </div>
      </>}
      <div className='p-2 flex'>
        <div id="icon_container">
          <IconSwitcher type={type}/>
        </div>
        <div className='text-left flex justify-center flex-col'>
          <Heading className='text-md font-semibold'>{heading}</Heading>
          <p className='mt-2 text-sm'>{description}</p>
        </div>
      </div>
    </div>
  );
};

WidgetTypeCards.propTypes = {
  type: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  additionalClass: PropTypes.string,
  comingSoon: PropTypes.bool,
};

export default WidgetTypeCards;
