import React, {useState, useEffect} from 'react';
import Heading from '../heading';
import SiteLogo from '../../assets/images/siteLogo.jpeg';
import LoaderIcon from '../../icons/loader.icon';
import CancelIcon from '../../icons/cancel.icon';
import TickIcon from '../../icons/tick.icon';
import {Link, useNavigate} from 'react-router-dom';
import {useStripe} from '@stripe/react-stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {REGISTERED_USER_ID, STRIPE_PUBLISHABLE_KEY} from '../../constants/application.const';
import {getValue} from '../../config/localStorage.config';
import {useDispatch} from 'react-redux';
import {updatePaymentStatusAction} from '../../actions/plans.actions';

const StatusComponent = ()=>{
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret',
    );

    const updatePaymentStatus = () => {
      dispatch(updatePaymentStatusAction({
        updateId: getValue(REGISTERED_USER_ID),
        status: 'P',
      }));
    };

    // Retrieve the PaymentIntent
    stripe
        .retrievePaymentIntent(clientSecret)
        .then(({paymentIntent}) => {
          switch (paymentIntent.status) {
            case 'succeeded':
              setMessage('Success! Payment received.');
              setPaymentStatus('S');
              updatePaymentStatus();
              setTimeout(()=>{
                navigate('/');
              }, 3000);
              break;

            case 'processing':
              setMessage('Payment processing. We\'ll update you when payment is received.');
              setPaymentStatus('I');
              break;

            case 'requires_payment_method':
              setMessage('Payment failed. Please try another payment method.');
              setPaymentStatus('F');
              setTimeout(()=>{
                navigate('/');
              }, 3000);
              break;

            default:
              setMessage('Something went wrong. Try again later');
              setPaymentStatus('F');
              setTimeout(()=>{
                navigate('/');
              }, 3000);
              break;
          }
        });
  }, [stripe]);

  return (
    <div className='flex h-full justify-center items-center flex-col'>
      <div className='flex'>
        {paymentStatus && <img
          src={SiteLogo}
          width='120px'
          className='mr-4 rounded-lg'
        />}
        {paymentStatus === 'S' && <TickIcon
          height={'120px'}
          width={'120px'}
          className='text-green-500'
        />}
        {paymentStatus === 'F' && <CancelIcon
          height={'120px'}
          width={'120px'}
          className='text-red-500'
        />}
        {paymentStatus === 'I' && <LoaderIcon
          color='purple'
          additionalClass='w-28 h-28'
        />}
      </div>
      <Heading className='font-semibold text-xl mt-4 flex items-center text-slate-800'>{message}</Heading>
      {paymentStatus === 'S' && <p className='text-sm text-slate-600'>Redirecting to <Link to={'/'}className='underline text-blue-400 cursor-pointer'>login</Link></p>}
    </div>
  );
};

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const StripePaymentStatus = ()=>{
  const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
  );
  return (
    <Elements stripe={stripePromise} options={{
      clientSecret,
    }}>
      <StatusComponent/>
    </Elements>
  );
};

export default StripePaymentStatus;
