import React, {useState} from 'react';
import PropTypes from 'prop-types';

/**
 * Bubble Question component
 *
 * @return {JSX}
 */
const BubbleQuestionComponent = ({
  surveyQuestionSettings,
  surveyQuestionAnswerType,
  submitFunctionCallback,
  changePage,
  buttonDesignStyle,
  oneQuestionStatus,
  onSurveyQuestionUpdate,
  optionColor,
  questTextColor,
})=>{
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [requiredErrorStatus, setRequiredErrorStatus] = useState(false);
  const [hoverIndexMapper, setHoverIndexMapper] = useState({});
  const [answers, setAnswers] = useState({});

  /**
   * Navigate question
   *
   * @param {number} direction
   */
  const navigateQuestion = (direction)=>{
    let tempIndex = currentQuestionIndex;
    tempIndex = tempIndex+direction;
    setCurrentQuestionIndex(tempIndex);
  };

  /**
   * Update answers from customers
   *
   * @param {string} optionsId
   * @param {string} questionsId
   * @param {boolean} removeStatus
   */
  const updateAnswers = (optionsId, questionsId, removeStatus=false)=>{
    setRequiredErrorStatus(false);
    const tempAnswers = {...answers};
    if (surveyQuestionAnswerType === 'SC') {
      tempAnswers[questionsId] = optionsId;
    }
    if (surveyQuestionAnswerType === 'MC' || surveyQuestionAnswerType === 'MC2') {
      if (!tempAnswers[questionsId] || typeof tempAnswers[questionsId] !== 'object') {
        tempAnswers[questionsId] = [optionsId];
      } else if (!removeStatus) {
        tempAnswers[questionsId].push(optionsId);
      } else if (removeStatus) {
        tempAnswers[questionsId]?.splice(tempAnswers[questionsId]?.indexOf(optionsId), 1);
      }
    }
    onSurveyQuestionUpdate({...tempAnswers});
    setAnswers({...tempAnswers});
  };

  // Get container design
  const getContainerDesign = ()=>{
    if (surveyQuestionAnswerType === 'MC2') {
      return ({
        display: 'flex',
        justifyContent: 'space-evenly',
      });
    } else {
      return ({});
    }
  };

  return (
    <>
      <div id="questions-container" style={{
        marginTop: '5px',
      }}>
        {surveyQuestionSettings?.map((data, index)=>(
          <>
            <div key={data?.questionId}>
              {(currentQuestionIndex === index) && <>
                <p style={{
                  fontSize: '16px',
                  margin: '10px 0rem 10px',
                  color: questTextColor,
                }}>{data?.questionText}</p>
                <ul style={{
                  ...getContainerDesign(),
                  paddingLeft: '0px',
                  margin: '0px',
                  display: (surveyQuestionAnswerType === 'MC2')?'flex':'',
                  flexDirection: (surveyQuestionAnswerType === 'MC2')?'column':'',
                }}>
                  {
                    data?.options?.map((options, index)=>(
                      <>
                        {surveyQuestionAnswerType !== 'MC2' && <li style={{
                          fontSize: '12px',
                          // paddingTop: '0.5rem',
                          // paddingBottom: '0.5rem',
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '0.5rem',
                          padding: '10px',
                          border: surveyQuestionAnswerType === 'SC'?'solid #767676 1px':'',
                          borderRadius: '10px',
                          backgroundColor: ((options?.optionId === answers[data?.questionId])?optionColor:'#ffff'),
                        }} key={options?.optionId} onClick={()=>{
                          if (surveyQuestionAnswerType === 'SC') {
                            updateAnswers(options?.optionId, data?.questionId);
                          }
                        }}>
                          {surveyQuestionAnswerType === 'SC' && <input type="radio" name="answer_survey"
                            style={{
                              transform: 'scale(1.2)',
                              marginRight: '1rem',
                            }}
                            defaultChecked={answers[data?.questionId] === options?.optionId}
                            onChange={()=>{
                              updateAnswers(options?.optionId, data?.questionId);
                            }}
                            checked={options?.optionId === answers[data?.questionId]}
                          />}
                          {surveyQuestionAnswerType === 'MC' && <input type="checkbox" name={options?.optionId}
                            defaultChecked={answers[data?.questionId] && (answers[data?.questionId]?.indexOf(options?.optionId) !== -1)}
                            style={{
                              transform: 'scale(2.0)',
                              marginRight: '1rem',
                            }}
                            onChange={(event)=>{
                              updateAnswers(options?.optionId, data?.questionId, !event?.target?.checked);
                            }}
                          />}
                          <span style={{
                            color: '#000',
                          }}>{options?.optionText}</span>
                        </li>}
                        {
                          surveyQuestionAnswerType === 'MC2' && (
                            <button
                              key={options?.optionId}
                              style={{
                                fontSize: '12px',
                                backgroundColor: (hoverIndexMapper[options?.optionId] || (answers[data?.questionId] && (answers[data?.questionId]?.indexOf(options?.optionId) !== -1)))?buttonDesignStyle?.backgroundColor:'#fff',
                                border: '1px',
                                borderColor: buttonDesignStyle?.backgroundColor,
                                borderStyle: 'solid',
                                padding: '10px',
                                width: '100%',
                                marginBottom: '1rem',
                                cursor: 'pointer',
                                color: (hoverIndexMapper[options?.optionId] || (answers[data?.questionId] && (answers[data?.questionId]?.indexOf(options?.optionId) !== -1)))?'#fff':'#767676',
                                // marginRight: (index !== data?.options?.length-1)?'10px':'',
                                // marginLeft: (index !== 0)?'10px':'',
                                borderRadius: '5px',
                              }}
                              onMouseOver={()=>{
                                return;
                                const tempData = {...hoverIndexMapper};
                                tempData[options?.optionId] = true;
                                setHoverIndexMapper({...tempData});
                              }}
                              onMouseOut={()=>{
                                return;
                                const tempData = {...hoverIndexMapper};
                                tempData[options?.optionId] = false;
                                setHoverIndexMapper({...tempData});
                              }}
                              onClick={()=>{
                                updateAnswers(options?.optionId, data?.questionId, answers[data?.questionId]?.includes(options?.optionId));
                              }}
                            >{options?.optionText}</button>
                          )
                        }
                      </>
                    ))
                  }
                </ul>
              </>}
            </div>
            {requiredErrorStatus && currentQuestionIndex === index && <div style={{color: 'red', fontSize: '12px'}}>Select atleast one answer</div>}
            {currentQuestionIndex === index && <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
              {(currentQuestionIndex>0) && <button onClick={()=>{
                changePage(0);
              }} style={{
                ...buttonDesignStyle,
                backgroundColor: '#ffff',
                border: `1px solid ${buttonDesignStyle?.backgroundColor}`,
                color: buttonDesignStyle?.backgroundColor,
                marginRight: '1rem',
                fontSize: '12px',
              }}>Go to first page(<span style={{fontWeight: 'bolder'}}>Only available in demo</span>)</button>}
              {!oneQuestionStatus && (currentQuestionIndex>0) && <button onClick={()=>{
                navigateQuestion(-1);
              }} style={{
                ...buttonDesignStyle,
                backgroundColor: '#ffff',
                border: `1px solid ${buttonDesignStyle?.backgroundColor}`,
                color: buttonDesignStyle?.backgroundColor,
                fontSize: '12px',
                marginRight: '1rem',
              }}>Back</button>}
              {(currentQuestionIndex<(surveyQuestionSettings?.length-1)) && <button onClick={()=>{
                if ((typeof answers[data?.questionId] !== 'object' && answers[data?.questionId]) || (typeof answers[data?.questionId] === 'object' && answers[data?.questionId]?.length)) {
                  navigateQuestion(1);
                } else {
                  setRequiredErrorStatus(true);
                }
              }} style={{
                ...buttonDesignStyle,
                border: 'none',
                fontSize: '12px',
              }}>Next</button>}
              {(currentQuestionIndex===(surveyQuestionSettings?.length-1)) && !oneQuestionStatus && <button onClick={()=>{
                if ((typeof answers[data?.questionId] !== 'object' && answers[data?.questionId]) || (typeof answers[data?.questionId] === 'object' && answers[data?.questionId]?.length)) {
                  submitFunctionCallback(answers);
                } else {
                  setRequiredErrorStatus(true);
                }
              }} style={{
                ...buttonDesignStyle,
                border: 'none',
                padding: '12px 20px',
              }}>Submit</button>}
            </div>}
          </>
        ))}
      </div>
    </>
  );
};

BubbleQuestionComponent.propTypes = {
  surveyQuestionSettings: PropTypes.any,
  surveyQuestionAnswerType: PropTypes.string,
  submitFunctionCallback: PropTypes.func,
  changePage: PropTypes.func,
  buttonDesignStyle: PropTypes.any,
  oneQuestionStatus: PropTypes.bool,
  onSurveyQuestionUpdate: PropTypes.func,
  optionColor: PropTypes.string,
  questTextColor: PropTypes.string,
};

export default BubbleQuestionComponent;
