import React, {useEffect, useState} from 'react';
import Button from '../../components/button/button.component';
import BlockIcon from '../../icons/block.icon';
import Chips from '../../components/chips';
import Heading from '../../components/heading';
import {useDispatch, useSelector} from 'react-redux';
import {ADD_DEMO_USER_SUCCESS, GET_USERS_ADMIN_SUCCESS, UPDATE_USERS_STATUS_ADMIN_FAILED, UPDATE_USERS_STATUS_ADMIN_SUCCESS} from '../../constants/reducer.const';
import {getAllAdminUsersAction, updateUserStatusAction, updateUserStatusClear} from '../../actions/users.actions';
import ActiveIcon from '../../icons/active.icon';
import {toast} from 'react-toastify';
import ExportIcon from '../../icons/export.icon';
import {exportCustomersCsv} from '../../services/admin.service';
import {downloadFile, toMb} from '../../helpers/common.helper';
import UserDetailsModal from './modals/userDetails.modal';
import AddDemoUserModal from './modals/addDemoUser.modal';
import {addDemoUserClear} from '../../actions/admin.actions';
import {USER_TYPE_MAPPER} from '../../constants/application.const';

// For admin
const CustomersAdminPage = ()=>{
  const userSelector = useSelector((state)=>state?.user);
  const [userList, setUserList] = useState([]);
  const [userModalToggle, setUserModalToggle] = useState(false);
  const [userAddModalToggle, setUserAddModalToggle] = useState(false);
  const adminSelector = useSelector((state)=>state?.admin);
  const [selectedUser, setSelectedUser] = useState({});
  const dispatch = useDispatch();

  const getAllUsers = ()=>{
    dispatch(getAllAdminUsersAction());
  };

  /**
   * Export customers
   */
  const exportCustomers = async ()=>{
    const responseRaw = await exportCustomersCsv({});
    downloadFile(responseRaw, 'text/csv', 'registered_user.csv');
  };

  useEffect(()=>{
    getAllUsers();
  }, []);

  useEffect(()=>{
    if (userSelector?.users?.type === GET_USERS_ADMIN_SUCCESS) {
      setUserList(userSelector?.users?.payload?.data);
    }
    if (userSelector?.userStatus?.type === UPDATE_USERS_STATUS_ADMIN_SUCCESS) {
      dispatch(updateUserStatusClear());
      toast(userSelector?.userStatus?.payload?.message, {
        type: 'success',
      });
      getAllUsers();
    }

    if (userSelector?.userStatus?.type === UPDATE_USERS_STATUS_ADMIN_FAILED) {
      dispatch(updateUserStatusClear());
      toast(userSelector?.userStatus?.payload?.message, {
        type: 'error',
      });
    }

    if (adminSelector?.addDemoUser?.type === ADD_DEMO_USER_SUCCESS) {
      dispatch(addDemoUserClear());
      toast(adminSelector?.addDemoUser?.payload?.message, {
        type: 'success',
      });
      setUserAddModalToggle(false);
      getAllUsers();
    }
  }, [userSelector?.users, userSelector?.userStatus, adminSelector?.addDemoUser]);

  const updateUserStatus = (updateId, status)=>{
    dispatch(updateUserStatusAction({
      updateId,
      status,
    }));
  };

  return (
    <div className='px-8 pt-8 h-full'>
      <Heading className={'font-semibold text-xl'}>Customers</Heading>
      <div className='flex mt-4 justify-end'>
        <Button
          buttonIcon={<ExportIcon />}
          buttonText='Export'
          type={'button'}
          onClick={()=>exportCustomers()}
          classOverride={`flex items-center justify-center px-3 py-2 text-sm font-medium text-white duration-150 bg-blue-600 border border-transparent rounded-lg hover:bg-blue-700 active:bg-blue-500 focus:outline-none focus:shadow-outline-blue mr-2`}
        />
        <Button
          buttonText='Add Demo User'
          type={'button'}
          onClick={()=>setUserAddModalToggle(true)}
          classOverride={`flex items-center justify-center px-3 py-2 text-sm font-medium text-white duration-150 bg-green-600 border border-transparent rounded-lg hover:bg-green-700 active:bg-green-500 focus:outline-none focus:shadow-outline-green`}
        />
      </div>
      <div className="flex flex-col w-full overflow-hidden mt-4 border border-slate-300 rounded-lg ">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-y-auto max-h-96">
              <table className="border-collapse min-w-full rounded-md bg-white">
                <thead className="rounded-md">
                  <tr className='text-xs font-semibold tracking-wide text-left text-gray-500 uppercase ddark:border-gray-700 bg-gray-50 ddark:text-gray-400 ddark:bg-gray-800 rounded-md border-b mb-4'>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">S.no</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">Name</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">Email</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">Plan</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">Storage Stats</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">User Type</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">Status</th>
                    <th scope="col" className="text-sm font-medium text-gray-900 px-2 py-1 text-left capitalize text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userList?.map((data, index)=>(
                    <tr key={index}>
                      <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap text-center">{index+1}</td>
                      <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap text-center"><a className="text-blue-600 cursor-pointer" onClick={()=>{
                        setSelectedUser({...data});
                        setUserModalToggle(true);
                      }}>{data?.name}</a></td>
                      <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap text-center">{data?.email}</td>
                      <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap text-center ">
                        <div className='flex justify-center'>
                          <Chips
                            text={data?.plan?.name}
                            color={'teal'}
                          />
                        </div>
                      </td>
                      <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap text-center ">
                        {toMb(data?.storageConsumed)?.toFixed(2)}mb/{toMb(data?.plan?.storageCap)?.toFixed(2)}mb
                      </td>
                      <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap text-center ">
                        <div className='flex justify-center'>
                          {USER_TYPE_MAPPER[data?.userType]}
                        </div>
                      </td>
                      <td className="text-sm text-gray-900 font-light px-2 py-1 whitespace-nowrap text-center ">
                        <div className='flex justify-center'>
                          <Chips
                            text={data?.auditFields?.status === 'A'?'Active':'Inactive'}
                            color={data?.auditFields?.status === 'A'?'green':'red'}
                          />
                        </div>
                      </td>
                      <td className="text-sm text-gray-900 font-light px-2 py-1 text-center ">
                        <div className='flex justify-center'>
                          {data?.auditFields?.status === 'A' && <Button classOverride={'flex items-center justify-center px-2 py-1 text-md font-medium leading-5 transition-colors duration-150 bg-red-200 border border-transparent rounded-lg hover:bg-red-300 focus:outline-none focus:shadow-outline-red text-red-600 border-2'}
                            onClick={()=>{
                              updateUserStatus(data?.id, 'B');
                            }}
                          >
                            <BlockIcon
                              height={'20px'}
                              width={'20px'}
                            />
                          </Button>}
                          {data?.auditFields?.status === 'B' && <Button classOverride={'flex items-center justify-center px-2 py-1 text-md font-medium leading-5 transition-colors duration-150 bg-green-200 border border-transparent rounded-lg hover:bg-green-300 focus:outline-none focus:shadow-outline-green text-green-600 border-2'}
                            onClick={()=>{
                              updateUserStatus(data?.id, 'A');
                            }}
                          >
                            <ActiveIcon
                              height={'20px'}
                              width={'20px'}
                            />
                          </Button>}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {userModalToggle && <UserDetailsModal
        userDetails={{...selectedUser}}
        onClose={(refreshStatus)=>{
          setUserModalToggle(false);
          if (refreshStatus) {
            getAllUsers();
          }
        }}
      />}
      { userAddModalToggle && <AddDemoUserModal
        onClose={()=>{
          setUserAddModalToggle(false);
        }}
      />}
    </div>
  );
};

export default CustomersAdminPage;
