import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {ALIGNMENT_MAPPER, FONT_SIZE_MAPPER, FONT_TYPE_MAPPER, POPUP_POSITION_MAPPER, POPUP_SIZE_MAPPER} from './application.const';
import RatingComponent from './rating.component';
import {useForm} from 'react-hook-form';

/**
 * Review component
 *
 * @return {JSX}
 */
const ReviewWidgetComponent = ({
  mode,
  position,
  headingText,
  headingConfig,
  actionButtonConfig,
  actionButtonLink,
  actionButtonName,
  backgroundColor,
  starColor,
  popupSize,
  displayPreference,
  displayPreferenceConfig,
  pages,
  fieldConfig,
})=>{
  const {register, handleSubmit, formState: {errors}} = useForm();

  useEffect(()=>{
    if (displayPreference && (mode !== 'dev' && pages?.split(',').indexOf(window?.location?.pathname) !== -1)) {
      setTimeout(()=>{
        // alert('done');
      }, displayPreferenceConfig?.delayTime);
    }
  }, [displayPreference, displayPreferenceConfig?.delayTime, window?.location?.pathname]);

  // Handling on submit
  const onFormSubmit = ()=>{};

  return (
    <div
      style={{
        position: mode === 'dev'?'':'fixed',
        bottom: mode === 'dev'?'':POPUP_POSITION_MAPPER[position]?.bottom,
        left: mode === 'dev'?'':POPUP_POSITION_MAPPER[position]?.left || '',
        right: mode === 'dev'?'':POPUP_POSITION_MAPPER[position]?.right || '',
        backgroundColor: backgroundColor || '#fff',
        padding: '2rem',
        minWidth: '15rem',
        maxWidth: '30rem',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: '10px',
        boxShadow: `1px 1px 5px ${backgroundColor || '#000'}`,
        zoom: POPUP_SIZE_MAPPER[popupSize || 'XL'],
      }}
    >
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <svg
          height="20px"
          width="20px"
          fill="currentColor"
          viewBox="0 0 20 20"
          role="img"
          aria-hidden="true"
          style={{cursor: 'pointer'}}
          onClick={()=>{
            if (mode !== 'dev') {
              document.querySelector(`#${DIV_NAME}`).style.display = 'none';
            }
          }}
        >
          <path
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
            fillRule="evenodd"
          ></path>
        </svg>
      </div>
      <h3 style={{fontWeight: FONT_TYPE_MAPPER[headingConfig?.fontType?.value], fontSize: FONT_SIZE_MAPPER[headingConfig?.fontSize?.value], color: headingConfig?.textColor?.value, textAlign: 'center', wordBreak: 'break-word', marginTop: '1rem', textAlign: ALIGNMENT_MAPPER[headingConfig?.textAlignment?.value]}}>{ headingText || 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem magni labore nesciunt laboriosam accusamus quos est iure numquam fuga corporis officia, repellat quam explicabo facilis expedita culpa excepturi vitae aliquid?' }</h3>
      <form onSubmit={handleSubmit(onFormSubmit)} style={{width: '100%'}}>
        <RatingComponent
          starColor={starColor}
        />
        {fieldConfig?.reviewDescription?.enabledStatus?.value && <textarea
          placeholder={fieldConfig?.reviewDescription?.fieldPlaceholder?.value || 'Leave us a feedback'}
          {...register('reviewDescription', {
            required: (fieldConfig?.reviewDescription?.requiredStatus?.value)?.toString() == 'true',
          })}
          style={{
            marginTop: '10px',
            border: 'solid 1px #ccc',
            borderRadius: '5px',
            resize: 'none',
            padding: '10px',
            width: '100%',
          }}
          rows={4}
        />}
        {(fieldConfig?.reviewDescription?.enabledStatus?.value && errors.reviewDescription?.type === 'required') && <p style={{color: 'red', fontSize: '12px'}}>Field required</p>}
        <div style={{display: 'flex', justifyContent: 'center', marginTop: '1rem'}}>
          <button
            style={{
              padding: '4px 10px',
              backgroundColor: actionButtonConfig?.actionButtonColor?.value || 'purple',
              borderRadius: '5px',
              marginTop: '10px',
              color: '#fff',
              fontSize: '1rem',
            }}
            onClick={()=>{
              if (actionButtonLink) {
                window.open(
                    actionButtonLink,
                    '_blank',
                );
              }
            }}
            type={'submit'}
          >{actionButtonName || 'Submit'}</button>
        </div>
      </form>
    </div>
  );
};

// Prop types
ReviewWidgetComponent.propTypes = {
  mode: PropTypes.string,
  position: PropTypes.string,
  headingText: PropTypes.string,
  headingConfig: PropTypes.any,
  actionButtonConfig: PropTypes.any,
  actionButtonLink: PropTypes.string,
  actionButtonName: PropTypes.any,
  backgroundColor: PropTypes.string,
  starColor: PropTypes.string,
  popupSize: PropTypes.string,
  displayPreference: PropTypes.string,
  displayPreferenceConfig: PropTypes.any,
  pages: PropTypes.string,
  fieldConfig: PropTypes.any,
};

export default ReviewWidgetComponent;
