import React, {useEffect, useState} from 'react';
import Chips from '../../../../components/chips';
import Heading from '../../../../components/heading';
import EllipsisIcon from '../../../../icons/ellipsis.icon';
import LinkIcon from '../../../../icons/linkIcon.icon';
import PropTypes from 'prop-types';
import {BASE_URL, liveStatusMapper, MEDIA_TYPE_CONST, USER_DETAILS} from '../../../../constants/application.const';
import {WIDGET_TYPE_NAME_MAPPER} from '../../../../constants/widget.const';
import CopyWidgetDeployLink from '../../../../components/modal/copyWidgetDeployLink.component';
import PencilIcon from '../../../../icons/pencil.icon';
import BinIcon from '../../../../icons/bin.icon';
import DropDownWrapper from '../../../../components/dropDownWrapper/DropDownWrapper.component';
import Button from '../../../../components/button/button.component';
import {deleteWidgetAction, updateWidgetSettings} from '../../../../actions/widget.actions';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import ReviewPopupIcon from '../../../../icons/widgetTypes/reviewPopup.icon';
import {getValue} from '../../../../config/localStorage.config';
import DeleteConfirmModal from '../modals/deleteConfirm.modal';

/**
 * Widget list card
 *
 * @return {JSX}
 */
const WidgetListCard = ({widgetName, thumbnailBasePath, widgetDetails, mediaBasePath, onWidgetSelect, selected})=>{
  const [hover, setHover] = useState(false);
  const [curretUserPlan, setCurrentUserPlan] = useState({});
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [dropDownToggle, setDropDownToggle] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Get user data from local storage
  const getLocalStorageUserData = ()=>{
    const userData = getValue(USER_DETAILS);
    if (userData) {
      const planData = JSON.parse(userData)?.plan;
      setCurrentUserPlan(planData);
    }
  };

  // Called initially
  useEffect(()=>{
    getLocalStorageUserData();
  }, []);

  // get media path for video and image
  const getMediaPath = (type)=>{
    if (type === MEDIA_TYPE_CONST.V) {
      return `${BASE_URL}${thumbnailBasePath}${widgetDetails?.widgetSettings?.videoDetails?.fileName?.split('.')[0]}.png`;
    }
    return `${BASE_URL}${mediaBasePath}${widgetDetails?.widgetSettings?.videoDetails?.fileName}`;
  };

  /**
   * on link copy modal
   */
  const onLinkCopyModalClose = ()=>{
    setShowLinkModal(false);
  };

  // Handle more options
  const onOptionSelect = (value)=>{
    setDropDownToggle(false);
    if (value === 'DE' && widgetDetails?.id) {
      setShowDeleteConfirm(true);
    }
    if (value === 'E' && widgetDetails?.id) {
      dispatch(updateWidgetSettings({
        ...widgetDetails?.widgetSettings,
      }));
      navigate(`/widgets/edit/${widgetDetails?.widgetSettings?.widgetType}/${widgetDetails?.id}`);
    }
  };

  return (
    <>
      <div className={`rounded-md grid grid-cols-3 mb-4 hover:bg-primary p-4 gap-4 cursor-pointer hover:text-white border-slate-300 border-2 hover:border-primary ${selected?'bg-primary text-white border-primary':'text-neutral-900'}`}
        onMouseEnter={()=> setHover(true)}
        onMouseLeave={()=> setHover(false)}
        onClick={onWidgetSelect}
      >
        {widgetDetails?.widgetSettings?.widgetType !== 'R' && <img src={getMediaPath(widgetDetails?.widgetSettings?.videoDetails?.type)} className='rounded-md border-2 border-white h-20 w-20'/>}
        {widgetDetails?.widgetSettings?.widgetType === 'R' && <ReviewPopupIcon
          height={'80'}
          width={'80'}
        />}
        <div className='flex flex-col items-start'>
          <Heading className={'text-sm'}>{widgetName}</Heading>
          <Chips
            color={WIDGET_TYPE_NAME_MAPPER[widgetDetails?.widgetSettings?.widgetType]?.color}
            text={WIDGET_TYPE_NAME_MAPPER[widgetDetails?.widgetSettings?.widgetType]?.value}
            additionalClass={'mt-2'}
          />
        </div>
        <div className='flex items-end flex-col justify-between'>
          <div id="more_options_container">
            <Button
              classOverride={'hidden'}
              onClick={()=>{
                setDropDownToggle(true);
              }}
            >
              <EllipsisIcon/>
            </Button>
            <div className='flex flex-row'>
              <PencilIcon
                color={(hover || selected)?'#ffff':'#232323'}
                additionalClass={'mr-2'}
                onClick={()=>{
                  onOptionSelect('E');
                }}
              />
              <BinIcon
                color={(hover || selected)?'#ffff':'#232323'}
                onClick={()=>{
                  onOptionSelect('DE');
                }}
              />
            </div>
            <DropDownWrapper
              open={dropDownToggle}
              onClose={()=>setDropDownToggle(false)}
              onOptionClick={onOptionSelect}
              buttonOptions={[
                {text: 'Edit', icon: <PencilIcon/>, value: 'E'},
                {text: 'Delete', icon: <BinIcon color="#ef4444"/>, class: 'text-red-500', value: 'DE'},
              ]}
            />
          </div>
          {(!curretUserPlan?.shopifyExclusive && hover) ? <Chips
            color={'white'}
            additionalClass={'mt-2'}
            onClick={()=>{
              setShowLinkModal(true);
            }}
          >
            <LinkIcon className={'h-6 w-6'}/>
          </Chips>:<Chips
            color={liveStatusMapper[widgetDetails?.liveStatus]?.color}
            text={liveStatusMapper[widgetDetails?.liveStatus]?.text}
            additionalClass={'mt-2'}
          />}
        </div>
      </div>
      {/* Widget details modal */}
      {showLinkModal && <CopyWidgetDeployLink
        onClose={onLinkCopyModalClose}
        details={{linkId: widgetDetails?.linkId}}
        widgetType={widgetDetails?.widgetSettings?.widgetType}
      />}
      {showDeleteConfirm && <DeleteConfirmModal
        onClose={()=>{
          setShowDeleteConfirm(false);
        }}
        onYes={()=>{
          dispatch(deleteWidgetAction(widgetDetails?.id));
          setShowDeleteConfirm(false);
        }}
      />}
    </>
  );
};

WidgetListCard.propTypes = {
  widgetName: PropTypes.string,
  thumbnailBasePath: PropTypes.string,
  mediaBasePath: PropTypes.string,
  widgetDetails: PropTypes.any,
  onWidgetSelect: PropTypes.func,
  selected: PropTypes.bool,
};

export default WidgetListCard;
