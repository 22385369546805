import React from 'react';
import PropTypes from 'prop-types';

/**
 * Publish icon
 *
 * @return {JSX}
 */
function PublishIcon({color, height, width, ...props}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height || '24px'} width={width || '24px'} fill='currentColor' viewBox="0 0 48 48" {...props}><path d="M22.5 40V21.45L16.5 27.45L14.35 25.3L24 15.65L33.65 25.3L31.5 27.45L25.5 21.45V40ZM8 18.15V11Q8 9.8 8.9 8.9Q9.8 8 11 8H37Q38.2 8 39.1 8.9Q40 9.8 40 11V18.15H37V11Q37 11 37 11Q37 11 37 11H11Q11 11 11 11Q11 11 11 11V18.15Z"/></svg>
  );
}

PublishIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default PublishIcon;
