import React from 'react';
import Heading from '../../../../components/heading';
import CloseIcon from '../../../../icons/close.icon';
import PublishTabSwitcher from '../components/publishTabSwitcher.component';
import Proptypes from 'prop-types';

/**
 * Live status modal
 *
 * @return {JSX}
 */
const PublishModal = ({onClose})=>{
  return (
    <>
      <div className="fixed inset-0 bg-stone-900 bg-opacity-75 transition-opacity z-50" aria-hidden="true"></div>
      <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white w-1/2 p-4 rounded-lg'>
        <Heading className='font-semibold text-lg flex justify-between  mt-4'>
          Publish
          <CloseIcon className='cursor-pointer w-4 h-4' onClick={()=>{
            onClose();
          }}/>
        </Heading>
        <div id="content" className="mt-4">
          <PublishTabSwitcher
            okButtonCallback={()=>{
              onClose();
            }}
          />
        </div>
      </div>
    </>
  );
};

PublishModal.propTypes = {
  onClose: Proptypes.func,
};

export default PublishModal;
