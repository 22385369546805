import {CLEAR_VIDEO_DETAILS, UPDATE_WIDGET_SETTINGS, VIDEO_DETAILS, ADD_WIDGET_SUCCESS, ADD_WIDGET_FAILED, WIDGET_REQUEST_CLEAR, GET_WIDGET_SUCCESS, GET_WIDGET_FAILED, WIDGET_LIST_REQUEST_CLEAR, DELETE_WIDGET_SUCCESS, DELETE_WIDGET_FAILED, WIDGET_DELETE_REQUEST_CLEAR, WIDGET_SETTINGS_CLEAR, UPDATE_WIDGET_SUCCESS, UPDATE_WIDGET_FAILED, UPDATE_WIDGET_LIVE_STATUS_SUCCESS, UPDATE_WIDGET_LIVE_STATUS_FAILED, UPDATE_WIDGET_LIVE_STATUS_REQUEST_CLEAR, GET_WIDGET_ANALYTICS_SUCCESS, GET_WIDGET_ANALYTICS_FAILED, GET_WIDGET_ANALYTICS_REQUEST_CLEAR, SELECTED_WIDGET, ADD_UPDATE_WIDGET_PREVIEW_FAILED, ADD_UPDATE_WIDGET_PREVIEW_CLEAR, ADD_UPDATE_WIDGET_PREVIEW_SUCCESS, COLLAPSE_WIDGET} from '../constants/reducer.const';

const initialState = {
  widgetSettings: {},
  videoDetails: {},
  widget: {},
  collapse_widget: {
    payload: {
      'GS': true,
      'MS': false,
      'AS': false,
      'CTA': false,
      'M': false,
      'QS': false,
    },
  },
};

/**
 * Widget settings reducer function
 *
 * @param {any} state
 * @param {any} action
 * @return {any}
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_WIDGET_SETTINGS: return {...state, widgetSettings: {...state?.widgetSettings, ...action.payload}};
    case VIDEO_DETAILS: return {...state, videoDetails: action.payload};
    case CLEAR_VIDEO_DETAILS: return {...state, videoDetails: {}};
    case WIDGET_SETTINGS_CLEAR: return {...state, widgetSettings: {}};

    case ADD_WIDGET_SUCCESS: return {...state, widget: {...action}};
    case ADD_WIDGET_FAILED: return {...state, widget: {...action}};
    case WIDGET_REQUEST_CLEAR: return {...state, widget: {}};

    case ADD_UPDATE_WIDGET_PREVIEW_SUCCESS: return {...state, widgetPreview: {...action}};
    case ADD_UPDATE_WIDGET_PREVIEW_FAILED: return {...state, widgetPreview: {...action}};
    case ADD_UPDATE_WIDGET_PREVIEW_CLEAR: return {...state, widgetPreview: {}};

    case UPDATE_WIDGET_SUCCESS: return {...state, widget: {...action}};
    case UPDATE_WIDGET_FAILED: return {...state, widget: {...action}};
    case WIDGET_REQUEST_CLEAR: return {...state, widget: {}};

    case UPDATE_WIDGET_LIVE_STATUS_SUCCESS: return {...state, widgetLiveStatus: {...action}};
    case UPDATE_WIDGET_LIVE_STATUS_FAILED: return {...state, widgetLiveStatus: {...action}};
    case UPDATE_WIDGET_LIVE_STATUS_REQUEST_CLEAR: return {...state, widgetLiveStatus: {}};

    case GET_WIDGET_ANALYTICS_SUCCESS: return {...state, widgetAnalytics: {...action}};
    case GET_WIDGET_ANALYTICS_FAILED: return {...state, widgetAnalytics: {...action}};
    case GET_WIDGET_ANALYTICS_REQUEST_CLEAR: return {...state, widgetAnalytics: {}};

    case DELETE_WIDGET_SUCCESS: return {...state, widgetDelete: {...action}};
    case DELETE_WIDGET_FAILED: return {...state, widgetDelete: {...action}};
    case WIDGET_DELETE_REQUEST_CLEAR: return {...state, widgetDelete: {}};

    case GET_WIDGET_SUCCESS: return {...state, widgetList: {...action}};
    case GET_WIDGET_FAILED: return {...state, widgetList: {...action}};
    case WIDGET_LIST_REQUEST_CLEAR: return {...state, widget: {}};

    case SELECTED_WIDGET: return {...state, selectedWidget: {...action}};

    case COLLAPSE_WIDGET: return {...state, collapse_widget: {...action}};
    default: return {...state};
  }
}
