import React from 'react';
import PropTypes from 'prop-types';

/**
 * Tick icon component
 *
 * @return {JSX}
 */
const TickIcon = ({height, width, ...props}) => {
  return (
    <svg width={height || '20px'} height={width || '20px'} viewBox="0 0 27 28" fill={'currentColor'} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13.3333 0C5.97333 0 0 6.16 0 13.75C0 21.34 5.97333 27.5 13.3333 27.5C20.6933 27.5 26.6667 21.34 26.6667 13.75C26.6667 6.16 20.6933 0 13.3333 0ZM13.3333 24.75C7.45333 24.75 2.66667 19.8137 2.66667 13.75C2.66667 7.68625 7.45333 2.75 13.3333 2.75C19.2133 2.75 24 7.68625 24 13.75C24 19.8137 19.2133 24.75 13.3333 24.75ZM19.4533 7.6725L10.6667 16.7337L7.21333 13.1863L5.33333 15.125L10.6667 20.625L21.3333 9.625L19.4533 7.6725Z" fill={'currentColor'}/>
    </svg>
  );
};

TickIcon.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default TickIcon;
