import React, {useEffect, useState} from 'react';
import TopSideBarTemplate from '../../templates/topSideBar.template';
import Heading from '../../components/heading';
import TopBar from './components/topBar.component';
import {getAllVideoAction, getAllVideoClear} from '../../actions/videoUpload.action';
import {useDispatch, useSelector} from 'react-redux';
import MediaLoader from '../../loaders/media.loader';
import MediaRenderer from '../../components/mediaRenderer';
import {BASE_URL, USER_DETAILS} from '../../constants/application.const';
import {VIDEO_DELETE_SUCCESS, VIDEO_LIST_SUCCESS, VIDEO_UPDATE_SUCCESS, VIDEO_UPLOAD_SUCCESS} from '../../constants/reducer.const';
import InfoWrapper from '../../components/wrappers/infoWrapper.component';
import {getValue} from '../../config/localStorage.config';
import {getPercentage, toMb} from '../../helpers/common.helper';

/**
 * Media library page
 *
 * @return {JSX}
 */
const MediaLibrary = ()=>{
  const dispatch = useDispatch();
  const videoLibrarySelector = useSelector((state)=>state.videoLibrary);
  const [videoList, setVideoList] = useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const [videoBasePath, setVideoBasePath] = useState('');
  const [storageConsumedUser, setStorageConsumedUser] = useState(0);
  const [currentFilterType, setCurrentFilterType] = useState('');
  const [searchText, setSearchText] = useState('');
  const [currentUserPlan, setCurrentUserPlan] = useState({});
  const [warningWindowStatus, setWarningWindowStatus]= useState({
    warning1: true,
    warning2: true,
  });

  const getAllVideos = (type = currentFilterType, keyword = searchText)=>{
    setPageLoader(true);
    dispatch(getAllVideoAction({
      type: typeof type === 'undefined'?currentFilterType:type,
      keyword,
    }));
  };

  const changeWarningStatus = (warningType)=>{
    const temp = {...warningWindowStatus};
    temp[warningType] = false;
    setWarningWindowStatus({...temp});
  };

  // Get user data from local storage
  const getLocalStorageUserData = ()=>{
    const userData = getValue(USER_DETAILS);
    if (userData) {
      setCurrentUserPlan(JSON.parse(userData)?.plan);
    }
  };

  useEffect(()=>{
    getAllVideos();
    getLocalStorageUserData();
  }, []);

  useEffect(()=>{
    if (videoLibrarySelector.type === VIDEO_LIST_SUCCESS) {
      setVideoBasePath(videoLibrarySelector?.payload?.misc?.videoBasePath);
      setStorageConsumedUser(videoLibrarySelector?.payload?.misc?.storageConsumed);
      dispatch(getAllVideoClear());
      setPageLoader(false);
      setVideoList([...videoLibrarySelector?.payload?.data]);
    }
    if (videoLibrarySelector?.type === VIDEO_UPDATE_SUCCESS) {
      dispatch(getAllVideoClear());
      getAllVideos();
    }
    if (videoLibrarySelector.type === VIDEO_DELETE_SUCCESS) {
      dispatch(getAllVideoClear());
      getAllVideos();
    }
    if (videoLibrarySelector.type === VIDEO_UPLOAD_SUCCESS) {
      dispatch(getAllVideoClear());
      getAllVideos();
    }
  }, [videoLibrarySelector, videoLibrarySelector?.type]);

  return (
    <TopSideBarTemplate>
      {warningWindowStatus?.warning1 && <InfoWrapper
        closeButtonEnable={true}
        onClose={()=>{
          changeWarningStatus('warning1');
        }}
        additionalClass={'bg-amber-200'}
        additionalClassInner={'text-grey-800'}
        description={`Kindly use only png, gifs and mp4 files and do not exceed the limit of 35mb per media as exceeding the limit it may affect the loading speed of your page`}
      />}
      {warningWindowStatus?.warning2 && <InfoWrapper
        closeButtonEnable={true}
        onClose={()=>{
          changeWarningStatus('warning2');
        }}
        additionalClass={'bg-amber-200'}
        additionalClassInner={'text-grey-800'}
        description={<p>compress your media before uploading or click here <a href="https://cloudconvert.com/" className="text-primary" rel='nofollow'>https://cloudconvert.com/</a>  for quick compress</p>}
      />}
      {(getPercentage(storageConsumedUser, currentUserPlan?.storageCap)>50) && <InfoWrapper
        additionalClass={'bg-red-300'}
        additionalClassInner={'text-red-800'}
        description={`You are using our Beta version. You have consumed ${toMb(storageConsumedUser)?.toFixed(2)}mb of available ${toMb(currentUserPlan?.storageCap)?.toFixed(2)}mb storage.`}
      />}
      <div className='pt-4'>
        <Heading className={'font-semibold text-xl'}>Media library</Heading>
      </div>
      <TopBar
        storageConsumedUser={storageConsumedUser}
        storageCap={currentUserPlan?.storageCap}
        onMediaFilterChange={(value)=>{
          setCurrentFilterType(value);
          getAllVideos(value);
        }}
        onSearch={(keyword)=>{
          setSearchText(keyword);
          getAllVideos(currentFilterType, keyword);
        }}
      />
      {pageLoader ? <MediaLoader
        additionalSettings='mt-4'
      />:
        <div id="media-list-container" className='flex flex-row flex-wrap w-full mt-4  overflow-y-auto' style={{height: 'calc(100% - 260px)'}}>
          {videoList?.map((mediaData, index)=>(
            <MediaRenderer
              key={index}
              type={mediaData?.type}
              manageMode={true}
              src={`${BASE_URL}${videoBasePath}${mediaData?.fileName}`}
              additionalClass={'mr-4 mt-4'}
              selectionButton={{
                buttonName: 'Use',
              }}
              heading={mediaData?.originalFileName}
              videoDetails={{...mediaData}}
            />
          ))}
        </div>}
    </TopSideBarTemplate>
  );
};

export default MediaLibrary;
