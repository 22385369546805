import React, {useEffect} from 'react';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Button from '../../../components/button/button.component';
import Heading from '../../../components/heading';
import {getValue} from '../../../config/localStorage.config';
import {USER_DETAILS, WIDGET_TYPE_CONST} from '../../../constants/application.const';
import BackIcon from '../../../icons/back.icon';
import WidgetTypeCards from './components/widgetTypeCards.component';

/**
 * Create widget
 *
 * @return {JSX}
 */
const CreateWidget = ()=>{
  const navigate = useNavigate();
  const [curretUserPlan, setCurrentUserPlan] = useState({});

  useEffect(()=>{
    const userData = getValue(USER_DETAILS);
    if (userData) {
      setCurrentUserPlan(JSON.parse(userData)?.plan);
    }
  }, []);

  return (
    <div className='bg-slate-200 h-screen px-40'>
      <div className='bg-white h-screen'>
        <nav className='py-4 px-2'>
          <Button
            classOverride={'bg-transparent border-0 flex items-center text-sm'}
            buttonIcon={<BackIcon height={'15'} width={'15'}/>}
            onClick={()=>navigate(-1)}
          >
            Back
          </Button>
        </nav>
        <div id="body-container" className={'mt-4 w-full text-center'}>
          <Heading className={'text-xl font-semibold'}>Select a widget type</Heading>
          <div id="widget-container" className='grid grid-cols-2 gap-2 mt-8'>
            {/* <div className='flex flex-col items-center mt-8'> */}
            <div className='flex justify-center items-center'>
              <WidgetTypeCards
                type={WIDGET_TYPE_CONST.B}
                heading='Bubble Widget'
                description={`A bubble widget is a component that floats in the page’s bottom corners, when clicked shows additional details.`}
                additionalClass={'mb-2'}
                comingSoon={!curretUserPlan?.modules?.P?.CREATE_WIDGETS?.BUBBLE}
                onClick={()=>{
                  if (curretUserPlan?.modules?.P?.CREATE_WIDGETS?.BUBBLE) {
                    navigate(`/widgets/add/${WIDGET_TYPE_CONST.B}`);
                  }
                }}
              />
            </div>
            <div className='flex justify-center items-center'>
              <WidgetTypeCards
                type={WIDGET_TYPE_CONST.BS}
                heading='Bubble Survey Widget'
                description={`A bubble suvry widget is a component that floats in the page’s bottom corners, when clicked shows additional details.`}
                additionalClass={'mb-2'}
                comingSoon={!curretUserPlan?.modules?.P?.CREATE_WIDGETS?.BUBBLE_SURVEY}
                onClick={()=>{
                  if (curretUserPlan?.modules?.P?.CREATE_WIDGETS?.BUBBLE_SURVEY) {
                    navigate(`/widgets/add/${WIDGET_TYPE_CONST.BS}`);
                  }
                }}
              />
            </div>
            <div className='flex justify-center items-center'>
              <WidgetTypeCards
                type={WIDGET_TYPE_CONST.PS}
                heading='Popup Survey Widget'
                description={`A popup survey widget that shows either when entering or leaving a particular page.`}
                additionalClass={'mb-2'}
                comingSoon={!curretUserPlan?.modules?.P?.CREATE_WIDGETS?.POPUP_SURVEY}
                onClick={()=>{
                  if (curretUserPlan?.modules?.P?.CREATE_WIDGETS?.POPUP_SURVEY) {
                    navigate(`/widgets/add/${WIDGET_TYPE_CONST.PS}`);
                  }
                }}
              />
            </div>
            <div className='flex justify-center items-center hidden'>
              <WidgetTypeCards
                type={WIDGET_TYPE_CONST.P}
                heading='Popup widget'
                description={`A popup widget that shows either when entering or leaving a particular page.`}
                additionalClass={'mb-2'}
                comingSoon={!curretUserPlan?.modules?.P?.CREATE_WIDGETS?.POPUP}
                onClick={()=>{
                  if (curretUserPlan?.modules?.P?.CREATE_WIDGETS?.POPUP) {
                    navigate(`/widgets/add/${WIDGET_TYPE_CONST.P}`);
                  }
                }}
              />
            </div>
            <div className='flex justify-center items-center relative hidden'>
              <WidgetTypeCards
                type={WIDGET_TYPE_CONST.E}
                heading='Embed widget'
                description={`A div widget that fits inside of a page.`}
                comingSoon={!curretUserPlan?.modules?.P?.CREATE_WIDGETS?.EMBED}
                onClick={()=>{
                  if (curretUserPlan?.modules?.P?.CREATE_WIDGETS?.EMBED) {
                    navigate(`/widgets/add/${WIDGET_TYPE_CONST.E}`);
                  }
                }}
              />
            </div>
            <div className='flex justify-center items-center hidden'>
              <WidgetTypeCards
                type={WIDGET_TYPE_CONST.R}
                heading='Review widget'
                description={`A widget to submit review of the site.`}
                comingSoon={!curretUserPlan?.modules?.P?.CREATE_WIDGETS?.REVIEW}
                onClick={()=>{
                  if (curretUserPlan?.modules?.P?.CREATE_WIDGETS?.REVIEW) {
                    navigate(`/widgets/add/${WIDGET_TYPE_CONST.R}`);
                  }
                }}
              />
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default CreateWidget;
