import {ADD_INTEGRATION_SUCCESS, ADD_INTEGRATION_FAILED, INTEGRATION_REQUEST_CLEAR, GET_INTEGRATION_REQUEST, GET_INTEGRATION_SUCCESS, GET_INTEGRATION_FAILED, GET_SHOPIFY_ACCESS_TOKEN_SUCCESS, GET_SHOPIFY_ACCESS_TOKEN_FAILED, GET_SHOPIFY_ACCESS_TOKEN_CLEAR, GET_INTEGRATION_STATUS_SUCCESS, GET_INTEGRATION_STATUS_FAILED, GET_INTEGRATION_STATUS_CLEAR, GET_SHOPIFY_INSTALL_STATUS_SUCCESS, GET_SHOPIFY_INSTALL_STATUS_FAILED, GET_SHOPIFY_INSTALL_STATUS_CLEAR, INSTALL_SHOPIFY_SUCCESS, INSTALL_SHOPIFY_FAILED, INSTALL_SHOPIFY_CLEAR, UNINSTALL_SHOPIFY_SUCCESS, UNINSTALL_SHOPIFY_FAILED, UNINSTALL_SHOPIFY_CLEAR, DELETE_INTEGRATION_SUCCESS, DELETE_INTEGRATION_FAILED, UPDATE_INTEGRATION_SUCCESS, UPDATE_INTEGRATION_FAILED, GET_INTEGRATION_REQUEST_CLEAR, DELETE_INTEGRATION_CLEAR, GET_PABBLY_LIST_SUCCESS, GET_PABBLY_LIST_FAILED, GET_PABBLY_LIST_CLEAR, UPDATE_SHOPIDY_EMAIL_FAILED, UPDATE_SHOPIDY_EMAIL_CLEAR, UPDATE_SHOPIDY_EMAIL_SUCCESS} from '../constants/reducer.const';

const initialState = {};

/**
 * Integration settings reducer function
 *
 * @param {any} state
 * @param {any} action
 * @return {any}
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_INTEGRATION_SUCCESS: return {...state, integration: {...action}};
    case ADD_INTEGRATION_FAILED: return {...state, integration: {...action}};
    case INTEGRATION_REQUEST_CLEAR: return {...state, integration: {}};

    case UPDATE_SHOPIDY_EMAIL_SUCCESS: return {...state, shopifyEmailUpdate: {...action}};
    case UPDATE_SHOPIDY_EMAIL_FAILED: return {...state, shopifyEmailUpdate: {...action}};
    case UPDATE_SHOPIDY_EMAIL_CLEAR: return {...state, shopifyEmailUpdate: {}};

    case GET_SHOPIFY_ACCESS_TOKEN_SUCCESS: return {...state, shopifyAccessToken: {...action}};
    case GET_SHOPIFY_ACCESS_TOKEN_FAILED: return {...state, shopifyAccessToken: {...action}};
    case GET_SHOPIFY_ACCESS_TOKEN_CLEAR: return {...state, shopifyAccessToken: {}};

    case GET_INTEGRATION_STATUS_SUCCESS: return {...state, integrationStatus: {...action}};
    case GET_INTEGRATION_STATUS_FAILED: return {...state, integrationStatus: {...action}};
    case GET_INTEGRATION_STATUS_CLEAR: return {...state, integrationStatus: {}};

    case GET_SHOPIFY_INSTALL_STATUS_SUCCESS: return {...state, shopifyInstallStatus: {...action}};
    case GET_SHOPIFY_INSTALL_STATUS_FAILED: return {...state, shopifyInstallStatus: {...action}};
    case GET_SHOPIFY_INSTALL_STATUS_CLEAR: return {...state, shopifyInstallStatus: {}};

    case INSTALL_SHOPIFY_SUCCESS: return {...state, shopifyInstallResponse: {...action}};
    case INSTALL_SHOPIFY_FAILED: return {...state, shopifyInstallResponse: {...action}};
    case INSTALL_SHOPIFY_CLEAR: return {...state, shopifyInstallResponse: {}};

    case UNINSTALL_SHOPIFY_SUCCESS: return {...state, shopifyUnInstallResponse: {...action}};
    case UNINSTALL_SHOPIFY_FAILED: return {...state, shopifyUnInstallResponse: {...action}};
    case UNINSTALL_SHOPIFY_CLEAR: return {...state, shopifyUnInstallResponse: {}};

    case GET_INTEGRATION_REQUEST: return {};
    case GET_INTEGRATION_REQUEST_CLEAR: return {...state, integrationList: {}};
    case GET_INTEGRATION_SUCCESS: return {...state, integrationList: {...action}};
    case GET_INTEGRATION_FAILED: return {...state, integrationList: {...action}};

    case DELETE_INTEGRATION_CLEAR: return {...state, integrationDelete: {}};
    case DELETE_INTEGRATION_SUCCESS: return {...state, integrationDelete: {...action}};
    case DELETE_INTEGRATION_FAILED: return {...state, integrationDelete: {...action}};

    case UPDATE_INTEGRATION_SUCCESS: return {...state, integrationUpdate: {...action}};
    case UPDATE_INTEGRATION_FAILED: return {...state, integrationUpdate: {...action}};

    case GET_PABBLY_LIST_SUCCESS: return {...state, getPabblyList: {...action}};
    case GET_PABBLY_LIST_FAILED: return {...state, getPabblyList: {...action}};
    case GET_PABBLY_LIST_CLEAR: return {...state, getPabblyList: {}};

    default: return {...state};
  }
}
