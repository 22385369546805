import React from 'react';
import CloseIcon from '../../icons/close.icon';
import PropTypes from 'prop-types';
import BubbleIcon from '../../icons/widgetTypes/bubble.icon';
import PopupVideoIcon from '../../icons/widgetTypes/popupVideo.icon';
import PopupTextIcon from '../../icons/widgetTypes/popupText.icon';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {updateVideoDetails} from '../../actions/widget.actions';

/**
 * Widget selection modal for using widget
 *
 * @return {JSX}
 */
const WidgetSelectionModal = ({onClose, videoDetails, videoBasePath})=>{
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div
      className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
    >
      <div
        className="px-6 py-4 overflow-hidden bg-white rounded-t-lg ddark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
        id="modal"
      >
        <header className="flex justify-end">
          <button
            className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded ddark:hover:text-gray-200 hover: hover:text-gray-700"
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </button>
        </header>
        <div className="mt-4 mb-6">
          <p
            className="mb-2 text-lg font-semibold text-gray-700 ddark:text-gray-300 my-4 text-center font-bold"
          >
            Select widget type
          </p>
          <p className="text-sm text-gray-700 ddark:text-gray-400 flex flex-row justify-between">
            <BubbleIcon
              onClick={()=>{
                dispatch(updateVideoDetails({...videoDetails, videoBasePath}));
                navigate('/widgets/add/B');
              }}
              className="cursor-pointer"
              height='100'
              width='100'
            />
            <PopupVideoIcon
              className="cursor-pointer"
              height='100'
              width='100'
            />
            <PopupTextIcon
              className="cursor-pointer"
              height='100'
              width='100'
            />
          </p>
        </div>
      </div>
    </div>
  );
};

WidgetSelectionModal.propTypes = {
  onClose: PropTypes.func,
  videoDetails: PropTypes.any,
  videoBasePath: PropTypes.string,
};


export default WidgetSelectionModal;
