import React, {useEffect, useState} from 'react';
import Heading from '../../../components/heading';
import CloseIcon from '../../../icons/close.icon';
import PropTypes from 'prop-types';
import Button from '../../../components/button/button.component';
import {useDispatch, useSelector} from 'react-redux';
import {getAllPlansAdmin} from '../../../actions/plans.actions';
import {DOWNGRADE_REDEMPTION_FAILED, DOWNGRADE_REDEMPTION_SUCCESS, GET_ALL_PLANS_ADMIN_SUCCESS} from '../../../constants/reducer.const';
import ReactTooltip from 'react-tooltip';
import {adminDowngradeRedemptionAction, adminDowngradeRedemptionClearAction} from '../../../actions/admin.actions';
import {toast} from 'react-toastify';

/**
 * User details modal
 *
 * @return {JSX}
 */
const UserDetailsModal = ({userDetails, onClose})=>{
  const dispatch = useDispatch();
  const planSelector = useSelector((state)=>state?.plan);
  const adminSelector = useSelector((state)=>state?.admin);
  const [planMapper, setPlanMapper] = useState({});

  // called initially
  useEffect(()=>{
    dispatch(getAllPlansAdmin());
  }, []);

  // Mapping Order Precedence with its details
  const orderPrecedenceMapper = (data)=>{
    const mapper = {};
    data?.forEach((d)=>{
      mapper[d?.orderPrecedence] = d;
    });
    setPlanMapper(mapper);
  };

  useEffect(()=>{
    if (planSelector?.planAdminDetails?.type === GET_ALL_PLANS_ADMIN_SUCCESS) {
      orderPrecedenceMapper(planSelector?.planAdminDetails?.payload?.data);
    }
    if (adminSelector?.downgradeRedemption?.type === DOWNGRADE_REDEMPTION_SUCCESS) {
      dispatch(adminDowngradeRedemptionClearAction());
      toast(adminSelector?.downgradeRedemption?.payload?.message, {
        type: 'success',
      });
      onClose(true);
    }
    if (adminSelector?.downgradeRedemption?.type === DOWNGRADE_REDEMPTION_FAILED) {
      dispatch(adminDowngradeRedemptionClearAction());
      toast(adminSelector?.downgradeRedemption?.payload?.message, {
        type: 'error',
      });
    }
  }, [planSelector?.planAdminDetails, adminSelector?.downgradeRedemption]);

  // Admin downgrade redemption
  const adminDowngradeRedemption = (payload)=>{
    dispatch(adminDowngradeRedemptionAction(payload));
  };

  return (
    <>
      <div className="fixed inset-0 bg-stone-900 bg-opacity-75 transition-opacity z-50" aria-hidden="true"></div>
      <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white w-1/2 p-4 rounded-lg'>
        <Heading className='font-semibold text-lg flex justify-between  mt-4'>
          {userDetails?.name}
          <CloseIcon className='cursor-pointer w-4 h-4' onClick={()=>{
            onClose();
          }}/>
        </Heading>
        <div className='grid grid-cols-2 gap-4 my-4'>
          <div id="text-size-container">
            <label className='text-sm font-normal mt-4 flex items-center mb-1 text-gray-700'>Name</label>
            <p className='font-bold'>{userDetails?.name}</p>
          </div>
          <div id="text-size-container">
            <label className='text-sm font-normal mt-4 flex items-center mb-1 text-gray-700'>Email</label>
            <p className='font-bold'>{userDetails?.email}</p>
          </div>
          <div id="text-size-container">
            <label className='text-sm font-normal mt-4 flex items-center mb-1 text-gray-700'>Company</label>
            <p className='font-bold'>{userDetails?.company}</p>
          </div>
          <div id="text-size-container">
            <label className='text-sm font-normal mt-4 flex items-center mb-1 text-gray-700'>
              Plan
              {userDetails?.saaszillacode?.length>1 && <Button className={'ml-2 px-2 py-1'}data-tip data-for="plan-details" onClick={()=>adminDowngradeRedemption({userId: userDetails?.id})}>Downgrade</Button>}
              <ReactTooltip
                id="plan-details"
                place="top"
                effect="solid"
              >
                <span>This will downgrade the user to {planMapper[userDetails?.saaszillacode?.length-1]?.name}</span>
              </ReactTooltip>
            </label>
            <p className='font-bold text-teal-700'>{userDetails?.plan?.name}</p>
          </div>
          <div id="text-size-container">
            <label className='text-sm font-normal mt-4 flex items-center mb-1 text-gray-700'>Status</label>
            <p className={`font-bold text-${userDetails?.auditFields?.status === 'A'?'green':'red'}-700`}>{userDetails?.auditFields?.status === 'A'?'Active':'Inactive'}</p>
          </div>
          <div id="text-size-container">
            <label className='text-sm font-normal mt-4 flex items-center mb-1 text-gray-700'>Created date</label>
            <p className='font-bold'>{userDetails?.auditFields?.createdDate}</p>
          </div>
          <div id="text-size-container">
            <label className='text-sm font-normal mt-4 flex items-center mb-1 text-gray-700'>Country</label>
            <p className='font-bold'>{userDetails?.country || '---'}</p>
          </div>
          <div id="text-size-container">
            <label className='text-sm font-normal mt-4 flex items-center mb-1 text-gray-700'>Industry</label>
            <p className='font-bold'>{userDetails?.industry || '---'}</p>
          </div>
          <div id="text-size-container">
            <label className='text-sm font-normal mt-4 flex items-center mb-1 text-gray-700'>Phone</label>
            <p className='font-bold'>{userDetails?.phone || '---'}</p>
          </div>
          {userDetails?.saaszillacode && <div id="text-size-container">
            <label className='text-sm font-normal mt-4 flex items-center mb-1 text-gray-700'>Enabled Saaszilla Code(s)</label>
            <p className='font-bold break-words text-sm'>{userDetails?.saaszillacode?.join(',') || '---'}</p>
          </div>}
        </div>
        <div className='flex mt-8 justify-end'>
          <Button onClick={()=>onClose()}>Close</Button>
        </div>
      </div>
    </>
  );
};

/**
 * text={data?.auditFields?.status === 'A'?'Active':'Inactive'}
                            color={data?.auditFields?.status === 'A'?'green':'red'}
 */

UserDetailsModal.propTypes = {
  onClose: PropTypes.func,
  userDetails: PropTypes.any,
};

export default UserDetailsModal;
