import moment from 'moment';
import {clearStorage} from '../config/localStorage.config';

/**
 * get all true keys
 *
 * @param {an} jsonObj
 * @return {Array<string>}
 */
export const getAllTrueProperties = (jsonObj)=>{
  const filteredArray = [];
  Object.keys(jsonObj).forEach((key)=>{
    if (jsonObj[key]) {
      filteredArray.push(key);
    }
  });
  return filteredArray;
};

/**
 * Object to query string
 *
 * @param {any} obj
 * @return {string}
 */
export const objectToQueryString = (obj)=>{
  const str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }
  return '?'+str.join('&');
};

// Convert to milliseconds
export const convertToMilliSeonds = (h = 0, m = 0, s = 0) => ((h*60*60+m*60+s)*1000);

// Convert date for default value input
export const dateConverterForInput = (date = '')=>{
  if (!date) return;
  const now = new Date(date);
  const utcString = now.toISOString().substring(0, 19);
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const hour = now.getHours();
  const minute = now.getMinutes();
  const localDatetime = year + '-' +
                      (month < 10 ? '0' + month.toString() : month) + '-' +
                      (day < 10 ? '0' + day.toString() : day) + 'T' +
                      (hour < 10 ? '0' + hour.toString() : hour) + ':' +
                      (minute < 10 ? '0' + minute.toString() : minute) +
                      utcString.substring(16, 19);
  return localDatetime;
};

// Get month of a date
export const getMonthOfDate = (date)=>{
  const check = moment(date, 'YYYY/MM/DD');
  const month = check.format('M');
  return month;
};

// Download file from browser
export const downloadFileBrowser = (fileLink)=>{
  fetch(fileLink, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
            new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `FileName`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
};

/**
 * logout due to unauthrized
 */
export const logoutUnauthorized = ()=>{
  clearStorage();
  location.href = '/';
};

/**
 * Dowload file from server
 *
 * @param {string} rawData
 * @param {string} type
 * @param {string} fileName
 */
export const downloadFile = (rawData, type, fileName)=>{
  const data = new Blob([rawData], {type});
  const csvURL = window.URL.createObjectURL(data);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('id', 'download_temp');
  tempLink.setAttribute('download', fileName);
  tempLink.click();
};

/**
 * Convert bytes to MB
 *
 * @param {number} value
 * @return {number}
 */
export const toMb = (value) => {
  return Number(value) / (1000 * 1000);
};

/**
 * Convert bytes to GB
 *
 * @param {number} value
 * @return {number}
 */
export const toGb = (value) => {
  return Number(value) / (1000 * 1000 * 1000);
};

/**
 * Convert byte to MB and GB dynamically
 *
 * @param {number} value
 * @return {string}
 */
export const toDynamicMetric = (value)=>{
  if (value > 1000*1000*1000) {
    return (Number(value) / (1000 * 1000 *1000)).toFixed(2)+'gb';
  } else {
    return (Number(value) / (1000 * 1000)).toFixed(2)+'mb';
  }
};

// Get percentage
export const getPercentage = (a, b)=>{
  return (Number(a)/Number(b))*100;
};

/**
 * Count number of unique domains in the array of string
 *
 * @param {Array<string>} urlStringArray
 * @return {Number}
 */
export const domainCounter = (urlStringArray = [])=>{
  const mapper = {};
  urlStringArray?.forEach((url)=>{
    const rootUrl = (new URL(url))?.hostname;
    if (!mapper[rootUrl]) mapper[rootUrl]=0;
    mapper[rootUrl]++;
  });
  let count = 0;
  for (const key in mapper) {
    if (key && mapper[key]) {
      count++;
    }
  }
  return count;
};

/**
 * limit text for displaying
 *
 * @param {string} text
 * @return {string}
 */
export const limitTextForDisplay = (text)=>{
  return (text?.length>20)?`${text?.slice(0, 20)}...`:text;
};

/**
 * Convert given options to selection option format
 *
 * @param {Array<any>} array
 * @param {string} valueKey
 * @param {string} textKey
 * @return {any}
 */
export const formatArraytoSelectOptions = (array, valueKey, textKey)=>{
  const outputArray = [];
  array?.forEach((data)=>{
    outputArray.push({
      value: data[valueKey],
      text: data[textKey],
    });
  });
  return outputArray;
};

/**
 * Generate random color
 *
 * @return {string}
 */
export const generateRandomColor = ()=> '#000000'.replace(/0/g, function() {
  return (~~(Math.random()*16)).toString(16);
});

/**
 * Convert Hex to HSLA
 *
 * @param {string} hex
 * @param {number} opacity
 * @return {string}
 */
export const hexToHsla = (hex, opacity=1)=>{
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);

  r /= 255, g /= 255, b /= 255;
  const max = Math.max(r, g, b); const min = Math.min(r, g, b);
  let h; let s; let l = (max + min) / 2;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
    }
    h /= 6;
  }

  s = s*100;
  s = Math.round(s);
  l = l*100;
  l = Math.round(l);
  h = Math.round(360*h);

  const colorInHSL = 'hsla(' + h + ', ' + s + '%, ' + l + '%,'+opacity+')';
  return colorInHSL;
};

/**
 * Generate Random number
 *
 * @param {Number} min
 * @param {Number} max
 * @return {Number}
 */
export const generateRandom = (min = 0, max = 100)=>{
  // find diff
  const difference = max - min;

  // generate random number
  let rand = Math.random();

  // multiply with difference
  rand = Math.floor( rand * difference);

  // add with min value
  rand = rand + min;

  return rand;
};
