import React from 'react';
import PropTypes from 'prop-types';

/**
 * Text area Component
 *
 * @param {any} ref
 * @return {JSX}
 */
const TextArea = React.forwardRef(({additionalClass, errorStatus, resize, ...props}, ref)=>{
  return (
    <textarea ref={ref} className={`mt-1 px-3 py-2 bg-white placeholder-slate-400 ${errorStatus?'border-2 border-red-700 focus:outline-none focus:border-red-500':'border border-purple-300 focus:outline-none focus:border-purple-500 focus:ring-purple-500 focus:ring-1'} block w-full rounded-md sm:text-sm ${resize?'':'resize-none'} ${additionalClass}`} {...props}/>
  );
});

TextArea.displayName = 'TextArea';

TextArea.propTypes = {
  additionalClass: PropTypes.string,
  errorStatus: PropTypes.string,
  resize: PropTypes.bool,
};

export default TextArea;
