import React, {useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {Link, useSearchParams} from 'react-router-dom';
import InputComponent from '../../components/input/input.component';
import Button from '../../components/button/button.component';
import ErrorText from '../../components/errorComponents/errorText.component';
import {MAX_PASSWORD_FIELD_LENGTH} from '../../constants/application.const';
import {loginSchema} from '../../validationSchemas/auth.validation';
import {clearUserAction, loginUserAction} from '../../actions/users.actions';
import {GET_SHOPIFY_ACCESS_TOKEN_SUCCESS, UPDATE_SHOPIDY_EMAIL_SUCCESS, USER_LOGIN_FAILURE, USER_LOGIN_SUCCESS} from '../../constants/reducer.const';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import LoginLogo from '../../assets/images/widget-image.png';
import QuestionIcon from '../../icons/question.icon';
import ReactTooltip from 'react-tooltip';
import Input from '../../components/input/input.component';
import {clearShopifyAccessTokenAction, getShopifyAccessTokenAction, updateShopifyEmailAction, updateShopifyEmailClearAction} from '../../actions/integrations.actions';

/**
 * Login design
 *
 * @return {JSX}
 */
const LoginPage = ()=>{
  const [searchParams] = useSearchParams();
  const {register, handleSubmit, formState: {errors}, getValues} = useForm({
    resolver: yupResolver(loginSchema),
  });
  const dispatch = useDispatch();
  const selector = useSelector((state)=>state?.user);
  const integrationSelector = useSelector((state)=>state?.integration);
  // const navigate = useNavigate();
  const [loginLoader, setLoginLoader] = useState(false);

  const getAccessTokenShopify = (payload)=>{
    dispatch(getShopifyAccessTokenAction(payload));
  };

  const loginSubmit = (value)=>{
    setLoginLoader(true);
    dispatch(loginUserAction(value));
  };

  useEffect(()=>{
    if (selector.type === USER_LOGIN_SUCCESS) {
      setLoginLoader(false);
      dispatch(clearUserAction());

      toast(selector?.payload?.message, {
        type: 'success',
      });
      // navigate('/widgets');

      // Update shopify email
      if (searchParams.get('shop')) {
        dispatch(updateShopifyEmailAction({
          storeEmail: getValues('storeEmail'),
        }));
      } else {
        location.href = '/widgets';
      }
    }
    if (selector.type === USER_LOGIN_FAILURE) {
      setLoginLoader(false);
      dispatch(clearUserAction());
      toast(selector?.payload?.message, {
        type: 'error',
      });
    }
  }, [selector]);

  useEffect(()=>{
    if (integrationSelector?.shopifyEmailUpdate?.type === UPDATE_SHOPIDY_EMAIL_SUCCESS) {
      dispatch(updateShopifyEmailClearAction());
      getAccessTokenShopify({
        code: searchParams.get('code'),
        shop: searchParams.get('shop')?.split('.')[0],
      });
    }

    if (integrationSelector?.shopifyAccessToken?.type === GET_SHOPIFY_ACCESS_TOKEN_SUCCESS) {
      dispatch(clearShopifyAccessTokenAction());
      location.href = '/widgets';
    }
  }, [integrationSelector?.shopifyEmailUpdate, integrationSelector?.shopifyAccessToken]);

  return (
    <div className="flex justify-center items-center h-screen bg-gray-50">
      <div
        className="w-6/12 h-3/4 overflow-hidden bg-white rounded-lg grid grid-cols-12"
      >
        <div className='col-span-5 flex justify-center flex-col relative '>
          <img src={LoginLogo} alt="popsuite" className='-ml-16 max-w-none' style={{width: '24rem'}}/>
          <div className='flex justify-center text-2xl font-bold text-primary w-56 ml-5'>Be the face of your business</div>
        </div>
        <div className='col-span-7 bg-primary flex items-center px-8'>
          <div className='w-full h-full flex justify-between flex-col py-12 pt-24'>
            <form className="w-full" onSubmit={handleSubmit(loginSubmit)} noValidate>
              <h1
                className="mb-4 text-xl font-semibold text-white "
              >
              Login
              </h1>
              <label className="block text-sm">
                <span className="text-white">Email</span>
                <InputComponent
                  name="username"
                  placeholder={'Enter email'}
                  type={'email'}
                  {...register('username')}
                />
                <ErrorText displayStatus={true}>{errors.username?.message}</ErrorText>
              </label>
              <label className="block mt-4 text-sm">
                <span className="text-white">Password</span>
                <InputComponent
                  name="password"
                  placeholder={'Enter Password'}
                  type={'password'}
                  {...register('password')}
                  maxLength={MAX_PASSWORD_FIELD_LENGTH}
                />
                <ErrorText displayStatus={true}>{errors.password?.message}</ErrorText>
              </label>
              {searchParams.get('shop') &&
              <>
                <label className='text-sm font-normal mt-4 flex items-center mb-1 text-white'>
                  Enter your shopify shop email
                  <QuestionIcon
                    height={20}
                    width={20}
                    className={'ml-1'}
                    data-tip
                    data-for="store-email-instructions"
                  />
                  <ReactTooltip
                    id="store-email-instructions"
                    place="top"
                    effect="solid"
                  >
                    <span>This is the email associated with your shopify account</span>
                  </ReactTooltip>
                </label>
                <input
                  type="hidden"
                  name="loginFormType"
                  {...register('loginFormType')}
                  defaultValue={searchParams.get('shop')?'SPY':''}
                />
                <div className='flex items-center w-full'>
                  <Input
                    type="email"
                    placeholder="Ex: example@mail.com"
                    errorStatus={errors?.storeEmail?.message}
                    parentAdditionalClass={'w-full'}
                    {...register('storeEmail')}
                  />
                </div>
                <ErrorText displayStatus={true} additionalClass={'mt-1'}>{errors?.storeEmail?.message}</ErrorText>
              </>
              }
              <Button
                loader={loginLoader}
                buttonText='Log In'
                classOverride={`flex items-center justify-center px-3 py-2 text-sm font-medium text-primary duration-150 bg-white border border-transparent rounded-lg hover:bg-white active:bg-white focus:outline-none focus:shadow-outline-purple w-full mt-4`}
                type={'submit'}
                disabled={loginLoader}
              />
            </form>
            <div className='flex justify-center flex-col items-center'>
              <p className="mt-4">
                <Link
                  className="text-sm font-medium text-white hover:underline"
                  to="password-reset"
                >
                Forgot your password?
                </Link>
              </p>
              <p className="mt-1">
                <Link
                  className="text-sm font-medium text-white hover:underline"
                  to={searchParams.get('shop')?`/register/SPY`:'/register'}
                >
                Create account
                </Link>
              </p>
            </div>
            <div className='row grid grid-cols-12'>
              <div className='col-span-6 text-left'>
                <a href='https://www.getpopsuite.com/terms' target="_blank" className="text-xs font-medium text-white hover:underline" rel="noreferrer">Terms Of Use and Conditions</a>
              </div>
              <div className='col-span-6 text-right'>
                <a href='https://www.getpopsuite.com/privacy' target="_blank" className="text-xs font-medium text-white hover:underline" rel="noreferrer">Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
