import React from 'react';
import Button from '../../../../components/button/button.component';
import Heading from '../../../../components/heading';
import CloseIcon from '../../../../icons/close.icon';
import Proptypes from 'prop-types';

/**
 * Delete Confirm modal
 *
 * @return {JSX}
 */
const DeleteConfirmModal = ({onClose, onYes})=>{
  return (
    <>
      <div className="fixed inset-0 bg-stone-900 bg-opacity-75 transition-opacity z-50" aria-hidden="true"></div>
      <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white w-1/2 rounded-lg p-4'>
        <Heading className='font-semibold text-lg flex justify-between mt-4 text-red-600'>
          Delete the widget?
          <CloseIcon className='cursor-pointer w-4 h-4 text-black' onClick={()=>{
            onClose();
          }}/>
        </Heading>
        <div className='py-4 flex justify-center flex-col rounded-b-lg'>
          <p className='text-md'>
            All the data related to your widget will be lost
          </p>
          <div className='flex justify-end'>
            <Button
              className={'bg-red-600'}
              onClick={()=>{
                onYes();
              }}
            >
                Confirm
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

DeleteConfirmModal.propTypes = {
  onClose: Proptypes.func,
  onYes: Proptypes.func,
};

export default DeleteConfirmModal;
