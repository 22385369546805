import React from 'react';
import {Navigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {JWT_AUTH_KEY_ADMIN} from '../../constants/application.const';
import {getValue} from '../../config/localStorage.config';

/**
 * protected router admin
 *
 * @return {JSX}
 */
const AdminProtectedRoute = ({children}) => {
  const isAuthenticated = getValue(JWT_AUTH_KEY_ADMIN);
  if (!isAuthenticated) {
    return <Navigate to="/admin" replace />;
  }

  return children;
};

AdminProtectedRoute.propTypes = {
  children: PropTypes.any,
};

export default AdminProtectedRoute;
