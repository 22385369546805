import React, {useEffect, useState} from 'react';
import CollapseDownIcon from '../../../../icons/collapseDown.icon';
import CollapseUpIcon from '../../../../icons/collapseUp.icon';
import PropTypes from 'prop-types';
import GeneralSettingsIcon from '../../../../icons/generalSetting.icon';
import MediaSettingsIcon from '../../../../icons/mediaSetting.icon';
import AutoResponderSettingsIcon from '../../../../icons/autoresponderSetting.icon';
import CtaSettingsIcon from '../../../../icons/ctaSetting.icon';
import MessageSettingsIcon from '../../../../icons/messageSetting.icon';
import QuestionSettingsIcon from '../../../../icons/questionSetting.icon';

/**
 * Collapsible component for add and edit widget
 *
 * @return {JSX}
 */
const CollapsibleComponent = ({parentClass, heading, toggleCallback, defaultValue, type})=>{
  const [toggle, setToggle] = useState(defaultValue);

  useEffect(()=>{
    setToggle(defaultValue);
  }, [defaultValue]);

  const iconSwitcher = ()=>{
    return {
      'GS': (<GeneralSettingsIcon height={20} width={20}/>),
      'MS': (<MediaSettingsIcon height={20} width={20} />),
      'AS': (<AutoResponderSettingsIcon height={20} width={20} />),
      'CTA': (<CtaSettingsIcon height={20} width={20} />),
      'M': (<MessageSettingsIcon height={20} width={20} />),
      'QS': (<QuestionSettingsIcon height={20} width={20} />),
    }[type];
  };

  return (
    <div className={`bg-primary text-white rounded-xl p-2.5 flex justify-between items-center cursor-pointer ${parentClass}`} onClick={()=>{
      const tempToggle = toggle;
      setToggle(!tempToggle);
      toggleCallback(!tempToggle);
    }}>
      <div className='flex items-center'>
        <div className='mr-2 bg-white text-primary p-2 rounded-xl'>
          {iconSwitcher()}
        </div>
        <div className='text-sx'>
          {heading}
        </div>
      </div>
      <div>
        {!toggle && <CollapseDownIcon />}
        {toggle && <CollapseUpIcon />}
      </div>
    </div>
  );
};


CollapsibleComponent.propTypes = {
  parentClass: PropTypes.string,
  type: PropTypes.string,
  heading: PropTypes.string,
  defaultValue: PropTypes.bool,
  toggleCallback: PropTypes.func,
};

export default CollapsibleComponent;
