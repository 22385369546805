import axios from 'axios';
import {JWT_AUTH_KEY, JWT_AUTH_KEY_ADMIN} from '../constants/application.const';
import {getValue} from './localStorage.config';

// Request interceptor
axios.interceptors.request.use(function(config) {
  const token = getValue(JWT_AUTH_KEY)?getValue(JWT_AUTH_KEY):getValue(JWT_AUTH_KEY_ADMIN);
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});
