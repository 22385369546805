import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getAllVideoAction, getAllVideoClear} from '../../actions/videoUpload.action';
import VideoWidgetCard from '../../components/cards/videoWidgetCard.component';
import {VIDEO_DELETE_SUCCESS, VIDEO_LIST_SUCCESS, VIDEO_UPDATE_SUCCESS, VIDEO_UPLOAD_SUCCESS} from '../../constants/reducer.const';
import LoaderIcon from '../../icons/loader.icon';
import TopSideBarTemplate from '../../templates/topSideBar.template';
import VideoTopBar from './videoTopBar.component';

/**
 * Video Library
 *
 * @return {JSX}
 */
function VideoLibrary() {
  const dispatch = useDispatch();
  const videoLibrarySelector = useSelector((state)=>state.videoLibrary);
  const [videoList, setVideoList] = useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const [videoBasePath, setVideoBasePath] = useState('');

  const getAllVideos = useCallback(()=>{
    setPageLoader(true);
    dispatch(getAllVideoAction());
  }, []);

  useEffect(()=>{
    getAllVideos();
  }, []);

  useEffect(()=>{
    if (videoLibrarySelector.type === VIDEO_LIST_SUCCESS) {
      setVideoBasePath(videoLibrarySelector?.payload?.misc?.videoBasePath);
      dispatch(getAllVideoClear());
      setPageLoader(false);
      setVideoList(videoLibrarySelector?.payload?.data);
    }
    if (videoLibrarySelector.type === VIDEO_UPLOAD_SUCCESS) {
      getAllVideos();
    }
    if (videoLibrarySelector.type === VIDEO_DELETE_SUCCESS) {
      getAllVideos();
    }
    if (videoLibrarySelector?.type === VIDEO_UPDATE_SUCCESS) {
      getAllVideos();
    }
  }, [videoLibrarySelector]);

  return (
    <TopSideBarTemplate>
      {pageLoader &&
      <LoaderIcon
        color="primary"
        additionalClass='absolute inset-1/2 h-10 w-10'
      />
      }
      {!pageLoader && <>
        <VideoTopBar/>
        <div className='flex flex-wrap w-full'>
          {videoList?.map((data, index)=>(
            <VideoWidgetCard
              additionalClasses='mr-2 mb-4'
              key={index}
              cardTitle={data?.originalFileName}
              videoId={data?.id}
              videoDetails={{...data}}
              videoBasePath={videoBasePath}
            />
          ))}
        </div>
      </>}
    </TopSideBarTemplate>
  );
};

export default VideoLibrary;
