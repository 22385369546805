import {ADD_PLAN_ADMIN_CLEAR, ADD_PLAN_ADMIN_REQUEST, EDIT_PLAN_ADMIN_CLEAR, EDIT_PLAN_ADMIN_REQUEST, GET_ALL_PLANS_ADMIN_REQUEST, GET_PLAN_REQUEST, INITIATE_PAYMENT_STRIPE_REQUEST, UPDATE_PAYMENT_STATUS_REQUEST} from '../constants/reducer.const';

export const getPlanDetailsAction = (payload)=>({type: GET_PLAN_REQUEST, payload});
export const initiatePaymentAction = (payload)=>({type: INITIATE_PAYMENT_STRIPE_REQUEST, payload});
export const updatePaymentStatusAction = (payload)=>({type: UPDATE_PAYMENT_STATUS_REQUEST, payload});

// For Admin
export const getAllPlansAdmin = (payload)=>({type: GET_ALL_PLANS_ADMIN_REQUEST, payload});
export const addPlanAdmin = (payload)=>({type: ADD_PLAN_ADMIN_REQUEST, payload});
export const clearAddPlanAdmin = ()=>({type: ADD_PLAN_ADMIN_CLEAR, payload: {}});
export const editPlanAdmin = (payload)=>({type: EDIT_PLAN_ADMIN_REQUEST, payload});
export const clearEditPlanAdmin = ()=>({type: EDIT_PLAN_ADMIN_CLEAR, payload: {}});
