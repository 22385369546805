import React, {useEffect, useState} from 'react';
import Heading from '../../../../components/heading';
import CloseIcon from '../../../../icons/close.icon';
import PropTypes from 'prop-types';
import ColorInput from '../../../../components/input/colorInput.component';
import Button from '../../../../components/button/button.component';
import {ACTION_BUTTON_DEFAULT_VALUES} from '../../../../constants/defaultValues.const';

/**
 * Button link
 *
 * @return {JSX}
 */
const ButtonLinkModal = ({onClose, onConfigChange, configValues})=>{
  const [config, setConfig] = useState({...configValues});

  useEffect(()=>{
    if (configValues) {
      setConfig({...configValues});
    }
  }, [configValues]);

  // Reset form
  const resetForm = ()=>{
    setConfig({...ACTION_BUTTON_DEFAULT_VALUES});
    onConfigChange({...ACTION_BUTTON_DEFAULT_VALUES});
  };

  return (
    <>
      <div className="fixed inset-0 bg-stone-900 bg-opacity-75 transition-opacity z-50" aria-hidden="true"></div>
      <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white w-1/3 p-4 rounded-lg'>
        <Heading className='font-semibold text-lg flex justify-between  mt-4'>
          Button Link Config
          <CloseIcon className='cursor-pointer w-4 h-4' onClick={()=>{
            onClose();
          }}/>
        </Heading>
        <div id="text-size-container">
          <label className='text-sm font-normal mt-4 flex items-center'>Choose Button Text Color</label>
          <ColorInput
            defaultValue={config?.actionButtonColor?.value}
            onChangeValue={(value)=>{
              setConfig({...config, actionButtonColor: {
                value: value,
                displayName: 'Button color',
                chipColor: value,
              }});
              onConfigChange({...config, actionButtonColor: {
                value: value,
                displayName: 'Button color',
                chipColor: value,
              }});
            }}
          />
        </div>
        <div className='flex mt-8 justify-end'>
          <Button classOverride={'flex items-center justify-center px-3 py-2 mr-2 text-sm font-medium text-purple-600 duration-150 bg-transparent border-2 border-purple-600 rounded-lg hover:border-purple-700 active:border-purple-500 focus:outline-none focus:shadow-outline-purple'} type={'button'} onClick={()=>resetForm()}>Reset</Button>
          <Button onClick={()=>onClose()}>Confirm</Button>
        </div>
      </div>
    </>
  );
};

ButtonLinkModal.propTypes = {
  onClose: PropTypes.func,
  onConfigChange: PropTypes.func,
  configValues: PropTypes.any,
};

export default ButtonLinkModal;
