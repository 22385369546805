import React, {useEffect, useState} from 'react';
import Heading from '../../../components/heading';
import CloseIcon from '../../../icons/close.icon';
import PropTypes from 'prop-types';
import Button from '../../../components/button/button.component';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {demoUserSchema} from '../../../validationSchemas/auth.validation';
import {MAX_PASSWORD_FIELD_LENGTH, MAX_TEXT_FIELD_LENGTH} from '../../../constants/application.const';
import ErrorText from '../../../components/errorComponents/errorText.component';
import InputComponent from '../../../components/input/input.component';
import {getAllPlansAdmin} from '../../../actions/plans.actions';
import {ADD_DEMO_USER_FAILED, ADD_DEMO_USER_SUCCESS, GET_ALL_PLANS_ADMIN_SUCCESS} from '../../../constants/reducer.const';
import Select from '../../../components/select/select.component';
import {toast} from 'react-toastify';
import {addDemoUserAction} from '../../../actions/admin.actions';

/**
 * Add demo user modal
 *
 * @return {JSX}
 */
const AddDemoUserModal = ({onClose})=>{
  const dispatch = useDispatch();
  const adminSelector = useSelector((state)=>state?.admin);
  const planSelector = useSelector((state)=>state?.plan);
  const [plans, setPlans] = useState([]);
  const {register, handleSubmit, setValue, formState: {errors}} = useForm({
    resolver: yupResolver(demoUserSchema),
  });
  const [submitLoader, setSubmitLoader] = useState(false);

  // Add Demo user
  const addUser = (value)=>{
    setSubmitLoader(true);
    dispatch(addDemoUserAction({...value}));
  };

  // called initially
  useEffect(()=>{
    dispatch(getAllPlansAdmin());
  }, []);

  useEffect(()=>{
    if (planSelector?.planAdminDetails?.type === GET_ALL_PLANS_ADMIN_SUCCESS) {
      const temp = [{
        value: '',
        text: 'Select a plan',
      }];
      planSelector?.planAdminDetails?.payload?.data?.forEach((d)=>{
        temp.push({
          value: d?.id,
          text: d?.name,
        });
      });
      setPlans([...temp]);
    }
    if (adminSelector?.addDemoUser?.type === ADD_DEMO_USER_SUCCESS) {
      setSubmitLoader(false);
    }
    if (adminSelector?.addDemoUser?.type === ADD_DEMO_USER_FAILED) {
      setSubmitLoader(false);
      toast(adminSelector?.addDemoUser?.payload?.message, {
        type: 'error',
      });
    }
  }, [planSelector?.planAdminDetails, adminSelector?.addDemoUser]);

  return (
    <>
      <div className="fixed inset-0 bg-stone-900 bg-opacity-75 transition-opacity z-50" aria-hidden="true"></div>
      <form onSubmit={handleSubmit(addUser)} className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 bg-white w-1/2 p-4 rounded-lg'>
        <Heading className='font-semibold text-lg flex justify-between  mt-4'>
          Add Demo User
          <CloseIcon className='cursor-pointer w-4 h-4' onClick={()=>{
            onClose();
          }}/>
        </Heading>
        <div className='grid grid-cols-2 gap-4 my-4'>
          <label className="block text-sm">
            <span className="ddark:text-gray-400">Name</span>
            <InputComponent
              name="name"
              placeholder={'Enter name'}
              type={'text'}
              {...register('name')}
              maxLength={MAX_TEXT_FIELD_LENGTH}
            />
            <ErrorText displayStatus={true}>{errors.name?.message}</ErrorText>
          </label>
          <label className="block text-sm">
            <span className="ddark:text-gray-400">Company Name</span>
            <InputComponent
              name="company"
              placeholder={'Enter company name'}
              type={'text'}
              {...register('company')}
              maxLength={MAX_TEXT_FIELD_LENGTH}
            />
            <ErrorText displayStatus={true}>{errors.company?.message}</ErrorText>
          </label>
          <label className="block text-sm">
            <span className="ddark:text-gray-400">Email</span>
            <InputComponent
              name="email"
              placeholder={'Enter email'}
              type={'email'}
              {...register('email')}
            />
            <ErrorText displayStatus={true}>{errors.email?.message}</ErrorText>
          </label>
          <label className="block text-sm">
            <span className="ddark:text-gray-400">Password</span>
            <InputComponent
              name="password"
              placeholder={'Enter Password'}
              type={'password'}
              {...register('password')}
              maxLength={MAX_PASSWORD_FIELD_LENGTH}
            />
            <ErrorText displayStatus={true}>{errors.password?.message}</ErrorText>
          </label>
          <label className="block text-sm">
            <span className="ddark:text-gray-400">Plan</span>
            <Select
              onChange={(value)=>{
                setValue('plan', value);
              }}
              options={[...plans]}
            />
            <ErrorText displayStatus={true}>{errors.plan?.message}</ErrorText>
          </label>
        </div>
        <div className='flex mt-8 justify-end'>
          <Button
            classOverride={'flex items-center justify-center px-3 py-2 mr-2 text-sm font-medium text-purple-600 duration-150 bg-transparent border-2 border-purple-600 rounded-lg hover:border-purple-700 active:border-purple-500 focus:outline-none focus:shadow-outline-purple'}
            type={'button'}
            onClick={()=>onClose()}
            className={'mr-2'}
          >Close</Button>
          <Button
            type={'submit'}
            loader={submitLoader}
          >Submit</Button>
        </div>
      </form>
    </>
  );
};

AddDemoUserModal.propTypes = {
  onClose: PropTypes.func,
};

export default AddDemoUserModal;
